import * as React from 'react';
import PropTypes from 'prop-types';
import Title from '../typography/Title';
import ic_idea from '../../assets/img/ic_idea.svg';
import { sanitizeHtmlString } from '../../utils/anti-xss/Sanitize';

/*
TODO:
see comments on PR #216
- improve list display by using HTML List, 
- improve accessibility by adding `aria` property to forms
*/

interface IGuidelinesProps {
    guidelines: string;
}

export const bulletStyle: React.CSSProperties = {
    content: '',
    color: 'transparent',
    backgroundColor: '#B3BBC4',
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    marginTop: '7px',
};

const Guidelines: React.FunctionComponent<IGuidelinesProps> = (props) => {
    const guides = props.guidelines;
    return (
        <div className="w-full bg-blue-light p-8" id="approvers-log">
            <div className="w-full mb-4 flex flex-row content-end">
                <img style={{ maxWidth: '22px', maxHeight: '21px' }} className="mr-2" src={ic_idea} />
                <Title type="h4">Guidelines:</Title>
            </div>
            {/* <ul>
                {guides.length
                    ? guides.map((item, ind) => (
                          <li key={ind}>
                              <Text color="secondary">{item}</Text>
                          </li>
                      ))
                    : null}
            </ul> */}
            <div
                className="w-full unreset ck-content mb-4 wrap-links"
                dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(guides) }}
            />
        </div>
    );
};

Guidelines.propTypes = {
    guidelines: PropTypes.string.isRequired,
};
export default Guidelines;
