import React from 'react';

interface ITableBodyProps {
    children: React.ReactNode;
}

const TableBody = (props: ITableBodyProps) => {
    // TODO: Customize if necessary
    return <tbody>{props.children}</tbody>;
};

export default TableBody;
