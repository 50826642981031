import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/RootReducer';
import { GeneralInfoView } from './GeneralInfoView';
import Text from '../../../layouts/typography/Text';
import Preloader from '../../../layouts/preloaders/Preloader';

interface IMemoViewContentGeneralInfo extends React.ComponentPropsWithRef<'div'> {
    id: string;
}

const MemoViewContentGeneralInfo = (props: IMemoViewContentGeneralInfo) => {
    const { state, memo } = useSelector((state: RootState) => state.MemoView);

    switch (state) {
        case 'fulfilled':
            return (
                <div
                    className="w-full px-1 md:px-4 flex flex-col-reverse md:flex-row justify-between"
                    id={props.id}
                    aria-label={props['aria-label']}
                >
                    <GeneralInfoView
                        memo_id={memo.memo_id}
                        memo_type={memo.used_template_name ? memo.used_template_name : ''}
                        owner={memo.memo_owner_name ? memo.memo_owner_name : ''}
                        owner_email={memo.memo_owner_email ? memo.memo_owner_email : ''}
                        owner_group={'This user does not have any group.'} // groups are unsupported as of the moment.
                    />
                </div>
            );
        case 'pending':
            return (
                <div className="w-full h-64 px-1 md:px-4 flex flex-col-reverse md:flex-row justify-between bg-white">
                    <Preloader type="dots" />
                </div>
            );
        case 'rejected':
            return <Text>There was an error while sending the request.</Text>;
        default:
            return null;
    }
};

export default MemoViewContentGeneralInfo;
