import React from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import Input, { inputTextErrorStyle, inputTextStyle } from '../form/Input';

interface IDatePickerInput extends Omit<ReactDatePickerProps, 'onChange'> {
    id: string;
    type: string;
    hasError?: boolean;
    onClick?: (e: any) => void;
    onChange?: (e: any) => void;
}

const DatePickerInput = React.forwardRef((props: IDatePickerInput, ref: React.Ref<HTMLInputElement>) => {
    const { id, type, hasError, placeholderText, onClick, onChange, tabIndex } = props;
    return (
        <Input
            ref={ref}
            className="w-full py-2 px-2 my-1 font-regular text-sm w-full"
            id={id}
            name={id}
            type={type}
            style={hasError ? inputTextErrorStyle : inputTextStyle}
            value={props.value}
            onClick={onClick}
            onChange={onChange}
            placeholder={placeholderText}
            tabIndex={tabIndex}
            data-testid="datepicker-custom-input"
        />
    );
});

DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;
