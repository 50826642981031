import * as React from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

interface ITableBodyCellProps extends React.ComponentPropsWithoutRef<'td'> {
    type?: string;
    children?: React.ReactNode;
    navigateTo?: string;
}

const TableBodyCell: React.FunctionComponent<ITableBodyCellProps> = (props) => {
    const destination = props.navigateTo;

    const onItemClick = (dest?: string) => {
        if (dest) {
            navigate(`${dest}`);
        }
    };

    switch (props.type) {
        case 'status':
            return (
                <td
                    className="py-3 px-1 text-sm font-regular text-left whitespace-nowrap"
                    data-testid="table-body-cell"
                >
                    {props.children}
                </td>
            );
        case 'action':
            return (
                <td className="py-3 px-3 text-sm font-regular text-left" data-testid="table-body-cell">
                    <span className="w-full inline-flex align-middle flex-row-reverse justify-start">
                        {props.children}
                    </span>
                </td>
            );
        default:
            return (
                <td
                    className="py-3 px-3 text-sm font-regular text-left"
                    onClick={() => {
                        onItemClick(destination);
                    }}
                    data-testid="table-body-cell"
                >
                    {props.children}
                </td>
            );
    }
};

TableBodyCell.propTypes = {
    type: PropTypes.string,
    children: PropTypes.node,
    navigateTo: PropTypes.string,
};

export default TableBodyCell;
