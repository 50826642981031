import PropTypes from 'prop-types';
import * as React from 'react';
import Datatable from '../datatable/Datatable';
import ApprovalsHistoryTableBody from './ApprovalsHistoryTableBody';

interface IForApprovalHistoryProps {
    memo_type?: string;
    headers?: any;
    children?: React.ReactNode;
    start_date?: any;
    end_date?: any;
}

const ForApprovalHistory: React.FunctionComponent<IForApprovalHistoryProps> = (props: IForApprovalHistoryProps) => {
    const [config, setConfig] = React.useState({
        endpoint: 'getForMyApprovalHistoryWithUserID',
        templatePath: 'approvals.history',
        queryParams: {
            limit: 15,
            page_no: 1,
            start_date: null,
            end_date: null,
            memo_type: '',
        },
    });

    const { memo_type, start_date, end_date } = props;

    React.useEffect(() => {
        setConfig({
            ...config,
            queryParams: {
                ...config.queryParams,
                memo_type: memo_type as string,
                start_date: start_date,
                end_date: end_date,
            },
        });
    }, [memo_type, start_date, end_date]);

    const initialHeaders = [
        {
            label: 'Memo ID',
            key: 'memo_id',
        },
        {
            label: 'Memo type',
            key: 'used_template_name',
        },
        {
            label: 'Subject',
            key: 'memo_subject',
        },
        {
            label: 'Requested by',
            key: 'memo_owner_name',
        },
        {
            label: 'Date of action',
            key: 'date_last_modified',
        },
        {
            label: 'Action taken',
            key: 'memo_status',
        },
    ];

    return (
        <div>
            <Datatable
                config={config}
                defaultHeaders={initialHeaders}
                bodyComponent={<ApprovalsHistoryTableBody />}
                pageRedirectTo="/approvals/history"
            />
        </div>
    );
};

ForApprovalHistory.propTypes = {
    children: PropTypes.node,
};

export default ForApprovalHistory;
