import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Memo } from '../models/Memo';

export interface MemoViewState {
    state: string;
    memo: Memo;
}

const initialState: MemoViewState = {
    state: 'pending',
    memo: {
        memo_id: '',
        memo_subject: '',
        memo_owner_id: '',
        memo_owner_email: '',
        used_template_id: '',
        memo_status: '',
        date_created: '',
        date_submitted: '',
        date_last_modified: '',
        attachment_urls: [],
        memo_content: [],
        template_content: [],
        current_sequence: 0,
        approvers: [],
        watchers: [],
        pdf_url: '',
        flags: [],
    },
};

const MemoViewSlice = createSlice({
    name: 'MemoViewSlice',
    initialState,
    reducers: {
        setMemo(state, action: PayloadAction<Memo>) {
            state.memo = action.payload;
        },
        setState(state, action: PayloadAction<string>) {
            state.state = action.payload;
        },
        clearView(state) {
            state.memo = initialState.memo;
        },
    },
});

export const { setMemo, setState, clearView } = MemoViewSlice.actions;

export default MemoViewSlice.reducer;
