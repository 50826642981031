import { Link } from '@reach/router';
import * as React from 'react';
import { flavor } from '../../../AppFlavor';

const logoStyles: React.CSSProperties = {
    width: '115px',
};

const Branding: React.FunctionComponent = () => {
    return (
        <div className="my-4 mx-6">
            <Link to={flavor.defaultPath}>
                <img src={flavor.theme.appHeader.branding.logo} style={logoStyles} alt="Memo App" />
            </Link>
        </div>
    );
};

export default Branding;
