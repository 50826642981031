import React from 'react';
import Card from '../../../layouts/cards/Card';
import DataLayout, { DataLayoutProps } from '../../../layouts/cards/DataLayout';

export interface IGeneralInfoProps {
    memo_id: string;
    memo_type: string;
    owner: string;
    owner_email: string;
    owner_group: string;
    children?: React.ReactNode;
}

export function GeneralInfoView(props: IGeneralInfoProps) {
    const { memo_id, memo_type, owner, owner_email } = props;

    const cardInfo = {
        type: 'info',
        title: 'General info',
    };

    const leftInfo: DataLayoutProps[] = [
        {
            label: 'Memo ID',
            data: memo_id,
        },
        {
            label: "Requestor's name",
            data: owner,
        },
    ];

    const rightInfo: DataLayoutProps[] = [
        {
            label: 'Memo type',
            data: memo_type,
        },
        {
            label: 'Email address',
            data: owner_email,
        },
    ];

    return (
        <Card type={cardInfo.type} title={cardInfo.title}>
            <div className="flex flex-col md:flex-row w-full justify-between">
                <div className="w-full md:w-1/2">
                    <DataLayout items={leftInfo} />
                </div>
                <div className="w-full md:w-1/2">
                    <DataLayout items={rightInfo} />
                </div>
            </div>
        </Card>
    );
}

export default GeneralInfoView;
