import { Link } from '@reach/router';
import React from 'react';
import bell from '../../assets/img/bell_blue.svg';
import { Notification as NotificationModel } from '../../models/Notification';
import { useDetectOnEndScroll } from '../../utils/hooks/useDetectOnEndScroll';
import { useDetectOutsideClick } from '../../utils/hooks/useDetectOutsideClick';
import Bell from './Bell';
import './Notifications.css';
import NotificationsList from './NotificationsList';

interface INotificationProps {
    hasUnread: boolean;
    unread: number;
    disableBell: boolean;
    state: string;
    notifications: NotificationModel[];
    onToggle: (data: boolean) => void;
    onScrollEnd: (data: boolean) => void;
    onClick: (data: NotificationModel) => void;
}

const Notification = (props: INotificationProps) => {
    const [isVisible, setVisible] = React.useState(false);
    const detectOutsideHook = useDetectOutsideClick(true);
    const detectScrollHook = useDetectOnEndScroll(false);

    const onToggle = () => {
        const toggle = !isVisible;
        setVisible(toggle);
        props.onToggle(toggle);
    };

    React.useEffect(() => {
        if (!detectOutsideHook.isComponentVisible) {
            setVisible(false);
            props.onToggle(false);
        }
    }, [detectOutsideHook.isComponentVisible]);

    React.useEffect(() => {
        if (detectScrollHook.isEnd && isVisible) {
            props.onScrollEnd(detectScrollHook.isEnd);
        }
    }, [detectScrollHook.isEnd]);

    React.useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isVisible]);

    return (
        <div ref={detectOutsideHook.ref} className="notifications">
            <Bell onToggle={onToggle} hasUnread={props.hasUnread} disabled={props.disableBell} />
            {isVisible && (
                <div id="notifications" className="wrapper flex flex-col relative">
                    <div className="w-full h-auto px-3 my-1">
                        <div className="py-2 flex flex-row items-center">
                            <span className="mx-2">
                                <img className="bell-mini" src={bell} alt="Notifications" />
                            </span>
                            <h4 className="font-bold mx-2 text-base">
                                Notifications {props.unread ? `(${props.unread})` : ''}
                            </h4>
                        </div>
                    </div>
                    <NotificationsList
                        scrollRef={detectScrollHook.ref}
                        state={props.state}
                        notifications={props.notifications}
                        onClick={props.onClick}
                    />
                    <div className="mt-auto p-4 text-center">
                        <Link to="/notifications" className="text-blue text-sm">
                            View all notifications
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Notification;
