import React from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import RichTextEditorV2 from '../../../layouts/form/RichTextEditorV2';
import { TemplateValidation } from '../../../models/TemplateValidation';

export interface IEditorChangeEvent {
    currentTarget: {
        id: string;
        dataset: {
            index?: number;
            sectionindex?: number;
        };
        value: string;
    };
    target: {
        value: string;
    };
}

interface IEditorProps extends React.ComponentPropsWithoutRef<'input'> {
    id: string;
    label: string;
    errors?: DeepMap<FieldValues, FieldError>;
    validations?: TemplateValidation;
    defaultValue?: string;
    children?: React.ReactNode;
    index?: number;
    sectionIndex?: number;
    onChange?: (e: IEditorChangeEvent) => void;
}

const Editor = React.forwardRef((props: IEditorProps, ref: React.Ref<HTMLInputElement>) => {
    const [editorState, setEditorState] = React.useState(props.defaultValue);
    const editorContainerRef = React.createRef<HTMLDivElement>();
    const [hasError, setHasError] = React.useState(false);
    const { errors, id } = props;
    let editorInstance: any = null;

    React.useEffect(() => {
        if (errors && errors[id]) {
            setHasError(true);
            editorContainerRef.current?.scrollIntoView();
        }
    }, [errors]);

    const onEditorReady = (event: any) => {
        editorInstance = event;
        if (editorInstance) {
            editorInstance.editing.view.change((writer: any) => {
                writer.setStyle('min-height', '325px', editorInstance.editing.view.document.getRoot());
            });
        }
    };

    const onEditorChanged = (event: any, editor: any) => {
        if (hasError) {
            setHasError(false);
        }
        setEditorState(editor.getData());
        const e: IEditorChangeEvent = {
            currentTarget: {
                id: id,
                dataset: {
                    index: props.index,
                    sectionindex: props.sectionIndex,
                },
                value: editor.getData(),
            },
            target: {
                value: editor.getData(),
            },
        };
        if (props.onChange) {
            props.onChange(e);
        }
        // dispatch(setEditorState(editor.getData()));
        // Emit the data back to form
        return event;
    };

    return (
        <div>
            <RichTextEditorV2
                {...props}
                ref={ref}
                editorContainerRef={editorContainerRef}
                editorInstance={editorInstance}
                hasError={hasError}
                data={editorState}
                onReady={onEditorReady}
                onChange={onEditorChanged}
            />
        </div>
    );
});

Editor.displayName = 'Editor';
export default Editor;
