import React from 'react';
import bell from '../../assets/img/bell.svg';
import './Notifications.css';

interface IBellProps extends React.ComponentPropsWithoutRef<'button'> {
    onToggle: (event: React.MouseEvent<HTMLElement>) => void;
    hasUnread: boolean;
}

const Bell = (props: IBellProps) => {
    const { onToggle, hasUnread } = props;

    return (
        <div className="w-auto inline-block relative">
            {hasUnread ? <span className="unread-bell" /> : ''}
            <button
                className="p-4 cursor-pointer"
                onClick={onToggle}
                disabled={props.disabled}
                data-testid="notif-bell"
            >
                <img className="bell" src={bell} alt="Notifications" />
            </button>
        </div>
    );
};

export default Bell;
