import * as React from 'react';
import { flavor } from '../../../AppFlavor';
import Text from '../../typography/Text';
import './profile.css';
import ProfileInitials from './ProfileInitials';
import ProfilePicture from './ProfilePicture';
import DropdownNav from './../dropdown-nav/DropdownNav';

interface IProfileProps {
    user: any;
    logout: () => void;
}

const Profile = (props: IProfileProps) => {
    const { displayName, photoURL } = props.user;
    const [toggled, setToggle] = React.useState(false);

    const onMenuToggle = () => {
        setToggle(!toggled);
    };

    return (
        <div className="flex flex-row items-center w-auto ml-auto md:ml-0">
            <div className="p-2 flex flex-row">
                <div className="dropdown flex w-auto">
                    <button
                        id="profile"
                        data-testid="profile"
                        style={{ outline: 'none' }}
                        className="flex flex-row items-center outline-none"
                        onClick={onMenuToggle}
                    >
                        <Text>
                            <p className={`${flavor.theme.appHeader.fontColor} hidden md:flex`}>{displayName}</p>
                        </Text>
                        {photoURL ? (
                            <ProfilePicture photoURL={photoURL} />
                        ) : (
                            <ProfileInitials initials={displayName[0]} />
                        )}
                    </button>
                    {toggled ? <DropdownNav onLogout={props.logout} /> : ''}
                </div>
            </div>
        </div>
    );
};

export default Profile;
