import React from 'react';
import './MemoLogs.css';

interface IMemoLogGroupListProp {
    children?: JSX.Element[] | JSX.Element;
}

const MemoLogGroupList = (props: IMemoLogGroupListProp) => {
    return (
        <div className="w-full mb-4 md:mb-16 p-2 __outer-list">
            <ul className="w-full ml-0">
                {Array.isArray(props.children)
                    ? React.Children.map(props.children, (child: JSX.Element) => React.cloneElement(child))
                    : props.children}
            </ul>
        </div>
    );
};

export default MemoLogGroupList;
