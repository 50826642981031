import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Link from '@ckeditor/ckeditor5-link/src/link';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// 3rd-party Rich-text Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import React, { RefObject } from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { TemplateValidation } from '../../models/TemplateValidation';
import Text from '../typography/Text';
import './unreset.scss';

export interface IRichTextEditorV2Props extends React.ComponentPropsWithoutRef<'input'> {
    id: string;
    label: string;
    annotation?: string;
    errors?: DeepMap<FieldValues, FieldError>;
    validations?: TemplateValidation;
    editorInstance: any;
    editorContainerRef: RefObject<HTMLDivElement>;
    data: any;
    onReady: any;
    onChange: any;
    hasError: boolean;
    footer?: React.ReactNode;
}

const RichTextEditorV2 = React.forwardRef((props: IRichTextEditorV2Props, ref: React.Ref<HTMLInputElement>) => {
    const {
        errors,
        id,
        label,
        annotation,
        validations,
        data,
        onReady,
        onChange,
        hasError,
        editorContainerRef,
        footer,
    } = props;
    const editorConfiguration = {
        plugins: [
            Essentials,
            Heading,
            Bold,
            Italic,
            Underline,
            Strikethrough,
            Paragraph,
            Font,
            Alignment,
            Link,
            BlockQuote,
            AutoLink,
            Indent,
            IndentBlock,
            ListStyle,
            Table,
            TableToolbar,
            Image,
            ImageCaption,
            ImageStyle,
            ImageResize,
            LinkImage,
            ImageUpload,
            ImageToolbar,
            Base64UploadAdapter,
        ],
        toolbar: {
            items: [
                'fontfamily',
                'fontsize',
                '|',
                'bold',
                'italic',
                'underline',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'alignment:left',
                'alignment:center',
                'alignment:right',
                'alignment:justify',
                'uploadImage',
                'insertTable',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'outdent',
                'indent',
                '|',
                'undo',
                'redo',
            ],
            viewportTopOffset: 160,
            shouldNotGroupWhenFull: false,
        },
        image: {
            styles: ['alignLeft', 'alignCenter', 'alignRight'],

            resizeOptions: [
                {
                    name: 'resizeImage:original',
                    label: 'Original',
                    value: null,
                },
                {
                    name: 'resizeImage:50',
                    label: '50%',
                    value: '50',
                },
                {
                    name: 'resizeImage:75',
                    label: '75%',
                    value: '75',
                },
            ],
            toolbar: [
                'imageStyle:alignLeft',
                'imageStyle:alignCenter',
                'imageStyle:alignRight',
                '|',
                'resizeImage',
                '|',
                'imageTextAlternative',
            ],
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
    };

    const getErrorStyles = (): React.CSSProperties => {
        if (hasError) {
            return {
                border: '1px solid red',
                borderRadius: '5px',
            };
        }
        return {};
    };

    return (
        <div ref={editorContainerRef}>
            <Text type="bold">
                {label}
                {validations?.required ? <span className="text-red">*</span> : ''}
            </Text>
            {annotation ? (
                <p className="text-xs font-regular w-full break-words text-gray-2 mb-1 whitespace-pre-wrap">
                    {annotation}
                </p>
            ) : (
                ''
            )}
            <div className="w-full h-auto mt-1 unreset error">
                <div style={getErrorStyles()} className="w-auto h-auto">
                    <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={data}
                        onReady={onReady}
                        onChange={onChange}
                        disabled={props.disabled}
                        tabIndex={props.tabIndex}
                    />
                </div>
                {footer ? footer : ''}
                {errors ? (
                    errors[id] ? (
                        <p
                            style={{ margin: '3px', fontSize: '0.75rem', lineHeight: '1rem' }}
                            className="font-regular text-red"
                        >
                            This field is required.
                        </p>
                    ) : (
                        ''
                    )
                ) : (
                    ''
                )}
                <input ref={ref} id={id} name={id} type="hidden" disabled={props.disabled} value={data} />
            </div>

            {/* This is for debugging purposes. */}
            {/* <div className="unreset ck-content" dangerouslySetInnerHTML={{ __html: editorState }} /> */}
        </div>
    );
});

RichTextEditorV2.displayName = 'RichTextEditorV2';

export default RichTextEditorV2;
