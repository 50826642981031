import { useLocation } from '@reach/router';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useSelector } from 'react-redux';
import loadError from '../../../assets/img/load_error.svg';
import emptyMemo from '../../../assets/img/memo-empty-state.svg';
import Button from '../../../layouts/buttons/Button';
import Status from '../../../layouts/status/Status';
import Table, { TableImgStyle } from '../../../layouts/table/Table';
import TableBodyCell from '../../../layouts/table/TableBodyCell';
import TableHeaderCell from '../../../layouts/table/TableHeaderCell';
import TableRow from '../../../layouts/table/TableRow';
import Text from '../../../layouts/typography/Text';
import { RootState } from '../../../redux/RootReducer';
import { getDateString } from '../../../utils/date/Date';
import Preloader from '../../../layouts/preloaders/Preloader';
import TableBody from '../../../layouts/table/TableBody';
import TableHeader from '../../../layouts/table/TableHeader';
import TablePagination from '../../../layouts/table/TablePagination';
import { MemoSearchResult } from '../../../models/MemoSearchResult';

interface ISearchTableProps {
    searchToken?: string;
    memo_type?: string;
    headers?: any;
    children?: React.ReactNode;
    start_date?: any;
    end_date?: any;
}

const SearchTable: React.FunctionComponent<ISearchTableProps> = (props: ISearchTableProps) => {
    const { user } = useSelector((state: RootState) => state.Auth);
    const [searchResult, setSearchResult] = React.useState<MemoSearchResult[]>([]);
    const [request_state, setRequestState] = React.useState('idle');
    const [request_err_msg, setRequestErrorMessage] = React.useState('');
    const [pages, setPages] = React.useState(1);
    const [current_page, setCurrentPage] = React.useState(1);
    const location = useLocation();

    const headers = [
        {
            label: 'Memo ID',
            key: 'memo_id',
        },
        {
            label: 'Memo type',
            key: 'used_template_name',
        },
        {
            label: 'Subject',
            key: 'memo_subject',
        },
        {
            label: 'Content',
            key: 'memo_rte_content',
        },
        {
            label: 'Date created',
            key: 'date_submitted',
        },
        {
            label: 'Requested by',
            key: 'memo_owner_name',
        },
        {
            label: 'Status',
            key: 'memo_status',
        },
    ];

    const searchESapi = async (payload: any, filters?: any) => {
        const searchFn = firebase.app().functions('asia-east2').httpsCallable('search_memo'); // change this
        const response = await searchFn({
            query: payload.token,
            page: payload.page,
            size: payload.size,
            filters: filters,
        });
        const memos = response.data.results;

        const page_info = response.data.meta;
        return { memos, page_info };
    };

    React.useEffect(() => {
        setSearchResult([]);
        const search = new URLSearchParams(location.search);
        const pageQuery = search.get('page') ? Number(search.get('page')) : 1;
        setCurrentPage(pageQuery);
        const memo_type = props.memo_type;
        const start_date = props.start_date;
        const end_date = props.end_date;

        let isMounted = true;
        /**
         * Search for a particular user under the domain.
         * @param token - Search token for user account.
         */
        const searchMemo = async (token?: string) => {
            setSearchResult([]);
            setRequestState('pending');

            const requestPayload: any = {
                token: token,
                page: pageQuery,
                size: 20,
            };

            const filters: any = {
                all: [
                    {
                        memo_status: ['SUBMITTED', 'APPROVED', 'RETURNED', 'REJECTED', 'WITHDRAWN', 'SELF_RETURNED'],
                    },
                ],
            };

            if (start_date && end_date) {
                filters.all.push({
                    date_submitted: {
                        from: start_date.toISOString(),
                        to: end_date.toISOString(),
                    },
                });
            }

            if (memo_type && memo_type !== 'ALL') {
                filters.all.push({
                    used_template_name: memo_type,
                });
            }

            if (token) {
                try {
                    if (pageQuery <= 0) {
                        setCurrentPage(1);
                    }
                    setRequestState('pending');
                    const res = await searchESapi(requestPayload, filters);
                    if (isMounted && res !== undefined) {
                        if (pageQuery > res.page_info.total_pages) {
                            setCurrentPage(res.page_info.total_pages);
                        }
                        setPages(res.page_info.total_pages);
                        setRequestState('fulfilled');
                        setSearchResult(res.memos);
                    }
                } catch (err) {
                    setRequestState('rejected');
                    console.error(err);
                    setRequestErrorMessage(JSON.stringify(err));
                    setSearchResult([]);
                }
            }
        };

        searchMemo(props.searchToken);

        return () => {
            isMounted = false;
        };
    }, [props.memo_type, props.start_date, props.end_date, location]);

    return (
        <div>
            <Table>
                <TableHeader>
                    <TableRow type="header">
                        {headers.map((header) => {
                            return <TableHeaderCell key={header.key}>{header.label}</TableHeaderCell>;
                        })}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {searchResult.map((item: any) => {
                        let parent;

                        if (item.memo_owner_id.raw === user.uid) {
                            parent = '/memos/active/';
                        } else if (item.watchers.raw.includes(user.uid)) {
                            parent = '/watch/';
                        } else if (item.approvers.raw.includes(user.uid)) {
                            parent = '/approvals/';
                        }

                        return (
                            <TableRow key={item.memo_id.raw} navigateTo={parent + item.memo_id.raw}>
                                {headers.map((header: any) => {
                                    if (item[header.key] === undefined) {
                                        return <TableBodyCell key={item[header.key]}></TableBodyCell>;
                                    } else {
                                        if (
                                            ['date_submitted', 'date_last_modified', 'date_created'].includes(
                                                header.key,
                                            )
                                        ) {
                                            return (
                                                <TableBodyCell>{getDateString(item[header.key]?.raw)}</TableBodyCell>
                                            );
                                        }
                                        if (header.key === 'memo_status') {
                                            return (
                                                <TableBodyCell>
                                                    <Status type={item.memo_status?.raw?.toLowerCase()} />
                                                </TableBodyCell>
                                            );
                                        }
                                        if (header.key === 'memo_rte_content') {
                                            return (
                                                <TableBodyCell>
                                                    <p className="text-sm overflow-ellipsis whitespace-nowrap w-full overflow-hidden">
                                                        {item[header.key].raw.substring(0, 30) + '...'}
                                                    </p>
                                                </TableBodyCell>
                                            );
                                        }
                                        return (
                                            <TableBodyCell key={item[header.key].raw}>
                                                {item[header.key].raw}
                                            </TableBodyCell>
                                        );
                                    }
                                })}
                            </TableRow>
                        );
                    })}
                    {request_state === 'pending' ? (
                        <tr>
                            <td colSpan={headers.length}>
                                <div className="w-full mx-auto">
                                    <Preloader type="dots" />
                                </div>
                            </td>
                        </tr>
                    ) : (
                        ''
                    )}
                </TableBody>
            </Table>
            {request_state === 'fulfilled' && searchResult.length === 0 ? (
                <div className="w-full py-8 flex flex-col justify-center items-center text-center">
                    <img src={emptyMemo} style={TableImgStyle} />
                    <div className="mt-8">
                        <Text>No memos to show.</Text>
                    </div>
                </div>
            ) : null}
            {request_state === 'rejected' ? (
                <div className="w-full py-8 flex flex-col justify-center items-center text-center">
                    <img src={loadError} style={TableImgStyle} alt="Request Error" />
                    <div className="mt-8">
                        <Text>Something went wrong in fetching data</Text>
                        <Text>{request_err_msg}</Text>
                    </div>
                    <div className="mt-2 p-2">
                        <Button color="primary" onClick={() => window.location.reload()}>
                            Try again
                        </Button>
                    </div>
                </div>
            ) : null}
            <TablePagination
                baseHref={`/search/${props.searchToken}`}
                totalPages={pages}
                currentPage={current_page}
                showPageSummary={request_state === 'pending'}
            />
        </div>
    );
};

SearchTable.propTypes = {
    children: PropTypes.node,
    searchToken: PropTypes.string,
};

export default SearchTable;
