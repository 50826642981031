import React from 'react';
import Button from '../../../layouts/buttons/Button';
import Preloader from '../../../layouts/preloaders/Preloader';
import Status from '../../../layouts/status/Status';
import emptyMemo from '../../../assets/img/memo-empty-state.svg';
import Table, { TableImgStyle } from '../../../layouts/table/Table';
import TableBody from '../../../layouts/table/TableBody';
import TableBodyCell from '../../../layouts/table/TableBodyCell';
import TableHeader from '../../../layouts/table/TableHeader';
import TableHeaderCell from '../../../layouts/table/TableHeaderCell';
import TableRow from '../../../layouts/table/TableRow';
import Text from '../../../layouts/typography/Text';
import { Template } from '../../../models/Template';
import { getDateString } from '../../../utils/date/Date';
import loadError from '../../../assets/img/load_error.svg';
import firebase from 'firebase/app';
import _ from 'lodash';

interface ITemplatesTableProps {
    headers: Array<{
        key: string;
        label: string;
    }>;
    navigateTo: string;
    indexField: string;
    endpoint: string;
    query: {
        field_path: string;
        field_value: string;
    };
    actions: Array<{
        label: string;
        event: (payload: any) => Promise<any>;
        disabled?: (template: Template) => boolean;
    }>;
}

const TemplatesTable = (props: ITemplatesTableProps) => {
    const { headers, navigateTo, indexField, endpoint, query, actions } = props;
    const [requestState, setRequestState] = React.useState('idle');
    const [errors, setErrors] = React.useState('');
    const [items, setItems] = React.useState<Template[]>([]);

    const fetch = async () => {
        try {
            const get = firebase.app().functions('asia-east2').httpsCallable(endpoint);
            const httpResponse = await get(query);
            const data = _.map(httpResponse.data, (template) => {
                return {
                    ...template,
                    category_name: template.category_name || 'Uncategorized',
                };
            });
            setItems(data);
            setRequestState('fulfilled');
        } catch (err) {
            setRequestState('rejected');
            setErrors(JSON.stringify(err));
        }
    };

    React.useEffect(() => {
        setRequestState('pending');
        let isMounted = true;
        if (isMounted) {
            fetch();
        }
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div>
            <Table>
                <TableHeader>
                    <TableRow type="header">
                        {headers.map((header) => {
                            return <TableHeaderCell key={header.key}>{header.label}</TableHeaderCell>;
                        })}
                        {actions.length ? <TableHeaderCell>Actions</TableHeaderCell> : ''}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {items.map((item: any) => {
                        return (
                            <TableRow key={item[indexField]}>
                                {headers.map((header: any) => {
                                    if (['date_submitted', 'date_last_modified', 'date_created'].includes(header.key)) {
                                        return <TableBodyCell>{getDateString(item[header.key])}</TableBodyCell>;
                                    }
                                    return <TableBodyCell key={item[header.key]}>{item[header.key]}</TableBodyCell>;
                                })}
                                {actions.length ? (
                                    <TableBodyCell type="action">
                                        {actions.map((action, index) => {
                                            if (action.disabled && action.disabled(item)) {
                                                return '';
                                            }
                                            return (
                                                <button
                                                    key={index}
                                                    className="text-sm text-blue underline md:mx-2"
                                                    type="button"
                                                    onClick={() => {
                                                        action.event(item).then(() => {
                                                            fetch();
                                                        });
                                                    }}
                                                    disabled={action.disabled ? action.disabled(item) : false}
                                                >
                                                    {action.label}
                                                </button>
                                            );
                                        })}
                                    </TableBodyCell>
                                ) : (
                                    ''
                                )}
                            </TableRow>
                        );
                    })}
                    {requestState === 'pending' ? (
                        <tr>
                            <td colSpan={headers.length + 1}>
                                <div className="w-full mx-auto">
                                    <Preloader type="dots" />
                                </div>
                            </td>
                        </tr>
                    ) : (
                        ''
                    )}
                </TableBody>
            </Table>
            {requestState === 'fulfilled' && items.length === 0 ? (
                <div className="w-full py-8 flex flex-col justify-center items-center text-center">
                    <img src={emptyMemo} style={TableImgStyle} />
                    <div className="mt-8">
                        <Text>Seems empty.</Text>
                    </div>
                </div>
            ) : null}
            {requestState === 'rejected' ? (
                <div className="w-full py-8 flex flex-col justify-center items-center text-center">
                    <img src={loadError} style={{ height: '170px' }} alt="Request Error" />
                    <div className="mt-8">
                        <Text>Something went wrong in fetching data</Text>
                        <Text>{errors}</Text>
                    </div>
                    <div className="mt-2 p-2">
                        <Button color="primary" onClick={() => window.location.reload()}>
                            Try again
                        </Button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default TemplatesTable;
