import React from 'react';
import { flavor } from '../../AppFlavor';
import './Stepper.css';
import ic_check_white from '../../assets/img/ic_check_white.svg';

interface IStepperProps {
    width?: string;
    children: Array<any>;
}

/**
 * Creates a stepper component
 * @param props - Props for stepper
 * @returns A JSX element with a stepper
 */
const Stepper = (props: IStepperProps) => {
    const [children, setChildren] = React.useState<React.ReactNode[]>([]);

    React.useEffect(() => {
        setChildren(props.children);
    }, [props.children]);

    return (
        <div className={props.width || 'w-full'}>
            {/* Stepper List UI */}
            <div className="w-full lg:w-3/4 flex flex-row items-start">
                {children.map((child: any, index) => {
                    const { state } = child.props;
                    return (
                        <div key={index} className="inline-flex p-1 flex-1 flex-col items-center relative">
                            {/* Stepper Line */}
                            {index > 0 ? (
                                <div className="absolute flex-auto cssdf">
                                    <span
                                        className={`border-t ${
                                            state === 'active' || state === 'completed'
                                                ? 'border-blue-dark'
                                                : 'border-gray-1'
                                        } block`}
                                    ></span>
                                </div>
                            ) : (
                                ''
                            )}
                            {/* Stepper Button */}
                            <span
                                className={`flex flex-shrink mt-1 ${
                                    state === 'active' ? 'stepper-active' : ''
                                } items-center justify-center w-9 h-9 rounded-full ${
                                    state === 'active' || state === 'completed'
                                        ? flavor.theme.stepper.active.background
                                        : 'bg-gray-1'
                                }`}
                            >
                                {state === 'completed' ? (
                                    <img width="12" height="12" src={ic_check_white} alt="Completed" />
                                ) : (
                                    <p className="font-regular text-sm text-white">{index + 1}</p>
                                )}
                            </span>

                            {/* Stepper Item label */}
                            {child}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Stepper;
