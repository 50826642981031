import * as React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/RootReducer';
import Title from '../../../layouts/typography/Title';
import Subtitle from '../../../layouts/typography/Subtitle';
import MyMemosSelection from './MyMemosSelection';
import ForMyApprovalSelection from './ForMyApprovalSelection';
import MemosToWatchSelection from './MemosToWatchSelection';
import Container from '../../../layouts/container/Container';

interface IDashboardProps {
    children?: React.ReactNode;
}

const Dashboard: React.FunctionComponent<IDashboardProps> = () => {
    const { user } = useSelector((state: RootState) => state.Auth);

    const getWelcomeText = (str: string | null) => {
        if (!str) {
            return 'Welcome';
        }
        return `Welcome, ${str.split(' ')[0]}`;
    };

    return (
        <Container>
            <div className="w-full">
                <Title>{getWelcomeText(user.displayName)}</Title>
                <div className="my-2">
                    <Subtitle>Here are the memos waiting for you</Subtitle>
                </div>
            </div>
            <div className="w-full my-2 py-2 px-2 h-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/3 h-full md:px-2 mt-4">
                    <MyMemosSelection />
                </div>
                <div className="w-full md:w-1/3 w-1/3 h-full md:px-2 mt-4">
                    <ForMyApprovalSelection />
                </div>
                <div className="w-full md:w-1/3 w-1/3 h-full md:px-2 mt-4">
                    <MemosToWatchSelection />
                </div>
            </div>
        </Container>
    );
};

Dashboard.propTypes = {
    children: PropTypes.node,
};

export default Dashboard;
