import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import * as React from 'react';
import Breadcrumbs from '../../../layouts/breadcrumbs/Breadcrumbs';
import SideMenu from '../../../layouts/menus/side-menu/SideMenu';
import SideMenuItem from '../../../layouts/menus/side-menu/SideMenuItem';
import Subtitle from '../../../layouts/typography/Subtitle';
import Title from '../../../layouts/typography/Title';
import Privacy from './Privacy';
import Terms from './Terms';

interface ISupportPublic {
    children?: React.ReactNode;
}

const SupportPublic: React.FunctionComponent<ISupportPublic> = () => {
    const location = useLocation();

    const displayText = /* istanbul ignore next */ (path: string) => {
        if (path === '/terms') {
            return <Terms />;
        } else if (path === '/privacy') {
            return <Privacy />;
        }
    };

    const links = [
        {
            label: 'Terms & Conditions',
            path: '/terms',
        },
        {
            label: 'Privacy Policy',
            path: '/privacy',
        },
    ];

    const redirect = (data: any) => {
        console.log(data);
    };

    return (
        <div className="w-11/12 h-full mx-auto p-0 md:p-16 pt-32 md:pt-32 mb-16">
            <div className="w-full mb-8" id="breadcrumbs">
                <Breadcrumbs
                    items={[
                        {
                            label: '< Back',
                            link: '/',
                        },
                    ]}
                />
            </div>
            <div className="w-full">
                <Title>Help &amp; support</Title>
                <div className="my-2">
                    <Subtitle>We’re here to help. Browse our guides or get in touch with us</Subtitle>
                </div>
            </div>
            <div className="w-full my-2 pt-4 pb-2 h-full flex flex-col justify-between md:flex-row">
                <div className="w-1/4 mr-2 hidden md:flex">
                    <SideMenu>
                        {links.map((link) => (
                            <SideMenuItem
                                key={link.path}
                                label={link.label}
                                value={link.path}
                                active={link.path === location.pathname}
                                onClick={redirect}
                            />
                        ))}
                    </SideMenu>
                </div>
                <div className="w-full md:w-3/4 ml-2 bg-white ">{displayText(location.pathname)}</div>
            </div>
        </div>
    );
};

SupportPublic.propTypes = {
    children: PropTypes.node,
};

export default SupportPublic;
