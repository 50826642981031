import { navigate } from '@reach/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../layouts/buttons/Button';
import Container from '../../../layouts/container/Container';
import Tab from '../../../layouts/tab/Tab';
import TabLayout from '../../../layouts/tab/TabLayout';
import Subtitle from '../../../layouts/typography/Subtitle';
import Title from '../../../layouts/typography/Title';
import { getTemplateById } from '../../../redux/FormBuilderSlice';
import { AppDispatch } from '../../../redux/Store';
import TemplatesTable from './TemplatesTable';
import { unwrapResult } from '@reduxjs/toolkit';
import { Template } from '../../../models/Template';
import { ModalContent } from '../../../models/ModalContent';
import Modal from '../../../layouts/modals/Modal';
import Text from '../../../layouts/typography/Text';
import firebase from 'firebase/app';
import ic_alert from '../../../assets/img/ic_alert.svg';
import ic_check from '../../../assets/img/ic_check.svg';

interface ITemplates {
    children?: React.ReactNode;
}

const Templates: React.FunctionComponent<ITemplates> = () => {
    const dispatch: AppDispatch = useDispatch();
    const initialModal = {
        isVisible: false,
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    };
    const [modalContent, setModalContent] = React.useState<ModalContent>(initialModal);

    const goToForm = (id: string | null) => {
        if (id) {
            navigate(`/templates/edit/${id}`);
        } else {
            navigate('/templates/create');
        }
    };

    const loadTemplate = async (id: string, version_id: string | null = null) => {
        try {
            setModalContent({
                ...modalContent,
                isVisible: true,
            });

            let res: any = null;

            if (version_id) {
                res = await dispatch(getTemplateById({ id: id, version_id: version_id as string }));
            } else {
                res = await dispatch(getTemplateById({ id: id }));
            }
            const data = (unwrapResult(res) as unknown) as Template;
            if (!data) {
                setModalContent({
                    ...modalContent,
                    isVisible: true,
                    icon: ic_alert,
                    loading: false,
                    title: 'This template cannot be edited',
                    subtitle: 'This template is not supported for editing.',
                    buttons: [
                        {
                            label: 'Dismiss',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                            },
                        },
                    ],
                });
                return;
            }
            if (data.api_version && data.api_version !== 'v2') {
                setModalContent({
                    ...modalContent,
                    isVisible: true,
                    icon: ic_alert,
                    loading: false,
                    title: 'This template cannot be edited',
                    subtitle: 'This template is not supported for editing.',
                    buttons: [
                        {
                            label: 'Dismiss',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                            },
                        },
                    ],
                });
                return;
            }

            // Else, update the redux store and prepare for edit.
            setModalContent(initialModal);
            setTimeout(() => {
                if (data.template_id && data.version_id) {
                    navigate(`/templates/edit/${data.template_id}?v=${data.version_id}`);
                } else {
                    navigate(`/templates/edit/${data.template_id}`);
                }
            }, 300);
        } catch (err) {
            setModalContent({
                ...modalContent,
                isVisible: true,
                loading: false,
                icon: ic_alert,
                title: 'There was an error loading the template',
                subtitle:
                    'Please try again after a few seconds. If the issue persists, contact memo-support@globe.com.ph',
                buttons: [
                    {
                        label: 'Dismiss',
                        event: () => {
                            setModalContent({ ...modalContent, isVisible: false });
                        },
                    },
                ],
            });
        }
    };

    const setPublishStatus = async (id: string, version_id: string | null = null, status: string) => {
        try {
            let modalTitle = '';
            let modalSubTitle = '';

            setModalContent({
                ...initialModal,
                isVisible: true,
                loading: true,
            });

            let endpoint = '';
            if (status === 'PUBLISHED') {
                endpoint = 'admin-publish_template';
                modalTitle = 'The template has been published successfully';
                modalSubTitle = 'The template and its changes have been published to users.';
            }
            if (status === 'UNPUBLISHED') {
                endpoint = 'admin-unpublish_template';
                modalTitle = 'The template has been unpublished';
                modalSubTitle = 'The template shall no longer be avaible to users.';
            }

            const fetch = firebase.app().functions('asia-east2').httpsCallable(endpoint);
            await fetch({ template_id: id, version_id: version_id });
            setModalContent({
                ...modalContent,
                isVisible: true,
                icon: ic_check,
                loading: false,
                title: modalTitle,
                subtitle: modalSubTitle,
                buttons: [
                    {
                        label: 'Got it!',
                        event: () => {
                            setModalContent({ ...modalContent, isVisible: false });
                        },
                    },
                ],
            });
        } catch (err) {
            setModalContent({
                ...modalContent,
                isVisible: true,
                loading: false,
                title: 'There was an error while sending the request',
                subtitle:
                    'Please try again after a few seconds. If the issue persists, contact memo-support@globe.com.ph',
                buttons: [
                    {
                        label: 'Dismiss',
                        event: () => {
                            setModalContent({ ...modalContent, isVisible: false });
                        },
                    },
                ],
            });
        }
    };

    const deleteVersion = async (template: Template) => {
        try {
            if (!template.version_id) return;

            setModalContent({
                ...initialModal,
                isVisible: true,
                loading: true,
            });

            const fetch = firebase.app().functions('asia-east2').httpsCallable('admin-delete_template');
            await fetch({ template_id: template.template_id, version_id: template.version_id });
            setModalContent({
                ...modalContent,
                isVisible: true,
                icon: ic_check,
                loading: false,
                title: 'Version deleted',
                subtitle: 'You have successfully deleted the version.',
                buttons: [
                    {
                        label: 'Got it!',
                        event: () => {
                            setModalContent({ ...modalContent, isVisible: false });
                        },
                    },
                ],
            });
        } catch (err) {
            setModalContent({
                ...modalContent,
                isVisible: true,
                loading: false,
                title: 'There was an error while sending the request',
                subtitle:
                    (err as any).message ||
                    'Please try again after a few seconds. If the issue persists, contact memo-support@globe.com.ph',
                buttons: [
                    {
                        label: 'Dismiss',
                        event: () => {
                            setModalContent({ ...modalContent, isVisible: false });
                        },
                    },
                ],
            });
        }
    };

    return (
        <Container>
            <div className="w-full">
                <Title>Templates</Title>
                <div className="my-2">
                    <Subtitle>Create and manage templates</Subtitle>
                </div>
                <div className="w-full mt-8">
                    <TabLayout>
                        <Tab label="Published" href="/templates">
                            <div className="w-full h-auto mt-2">
                                <div className="w-full flex justify-end mb-4">
                                    <Button color="primary" onClick={() => goToForm(null)}>
                                        Create template
                                    </Button>
                                </div>
                                <TemplatesTable
                                    headers={[
                                        {
                                            key: 'category_name',
                                            label: 'Category',
                                        },
                                        {
                                            key: 'template_name',
                                            label: 'Template name',
                                        },
                                        {
                                            key: 'date_last_modified',
                                            label: 'Date last modified',
                                        },
                                    ]}
                                    query={{
                                        field_path: 'status',
                                        field_value: 'PUBLISHED',
                                    }}
                                    actions={[
                                        {
                                            label: 'Unpublish',
                                            event: async (template: Template) => {
                                                await setPublishStatus(template.template_id, null, 'UNPUBLISHED');
                                            },
                                        },
                                        {
                                            label: 'Edit',
                                            event: async (template: Template) => {
                                                loadTemplate(template.template_id);
                                            },
                                            disabled: (template: Template) =>
                                                !template.api_version || template.api_version !== 'v2',
                                        },
                                    ]}
                                    navigateTo={'/templates/published/'}
                                    indexField="template_id"
                                    endpoint="admin-get_templates"
                                />
                            </div>
                        </Tab>
                        <Tab label="Drafts" href="/templates/drafts">
                            <div className="w-full h-auto mt-2">
                                <div className="w-full flex justify-end mb-4">
                                    <Button color="primary" onClick={() => goToForm(null)}>
                                        Create template
                                    </Button>
                                </div>
                                <TemplatesTable
                                    headers={[
                                        {
                                            key: 'category_name',
                                            label: 'Category',
                                        },
                                        {
                                            key: 'template_name',
                                            label: 'Template name',
                                        },
                                        {
                                            key: 'date_last_modified',
                                            label: 'Date last modified',
                                        },
                                    ]}
                                    query={{
                                        field_path: '',
                                        field_value: '',
                                    }}
                                    actions={[
                                        {
                                            label: 'Publish',
                                            event: async (template: Template) => {
                                                await setPublishStatus(
                                                    template.template_id,
                                                    template.version_id,
                                                    'PUBLISHED',
                                                );
                                            },
                                        },
                                        {
                                            label: 'Edit',
                                            event: async (template: Template) => {
                                                loadTemplate(template.template_id, template.version_id);
                                            },
                                            disabled: (template: Template) =>
                                                !template.api_version || template.api_version !== 'v2',
                                        },
                                        {
                                            label: 'Delete',
                                            event: async (template: Template) => {
                                                await deleteVersion(template);
                                            },
                                        },
                                    ]}
                                    navigateTo={'/templates/published/'}
                                    indexField="template_id"
                                    endpoint="admin-get_drafted_templates"
                                />
                            </div>
                        </Tab>
                        <Tab label="Unpublished" href="/templates/unpublished">
                            <div className="w-full h-auto mt-2">
                                <div className="w-full flex justify-end mb-4">
                                    <Button color="primary" onClick={() => goToForm(null)}>
                                        Create template
                                    </Button>
                                </div>
                                <TemplatesTable
                                    headers={[
                                        {
                                            key: 'category_name',
                                            label: 'Category',
                                        },
                                        {
                                            key: 'template_name',
                                            label: 'Template name',
                                        },
                                        {
                                            key: 'date_last_modified',
                                            label: 'Date last modified',
                                        },
                                    ]}
                                    query={{
                                        field_path: 'status',
                                        field_value: 'UNPUBLISHED',
                                    }}
                                    actions={[
                                        {
                                            label: 'Publish',
                                            event: async (template: Template) => {
                                                await setPublishStatus(template.template_id, null, 'PUBLISHED');
                                            },
                                        },
                                        {
                                            label: 'Edit',
                                            event: async (template: Template) => {
                                                loadTemplate(template.template_id);
                                            },
                                            disabled: (template: Template) =>
                                                !template.api_version || template.api_version !== 'v2',
                                        },
                                        {
                                            label: 'Delete',
                                            event: async (template: Template) => {
                                                await deleteVersion(template);
                                            },
                                        },
                                    ]}
                                    navigateTo={'/templates/unpublished/'}
                                    indexField="template_id"
                                    endpoint="admin-get_templates"
                                />
                            </div>
                        </Tab>
                    </TabLayout>
                </div>
            </div>
            <Modal visible={modalContent.isVisible as boolean}>
                {modalContent.loading ? (
                    <div className="w-full flex flex-col items-center justify-center">
                        <Text>Loading your template</Text>
                    </div>
                ) : (
                    <div className="w-full flex flex-col items-center">
                        <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                        <Title type="h4">{modalContent.title}</Title>
                        <div className="my-2 text-center">
                            <Text>{modalContent.subtitle}</Text>
                        </div>
                        {modalContent.buttons.length > 1 ? (
                            <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        weight="font-regular"
                                        onClick={modalContent.buttons[0].event}
                                    >
                                        {modalContent.buttons[0].label}
                                    </Button>
                                </div>
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        color="primary"
                                        weight="font-regular"
                                        type="button"
                                        onClick={modalContent.buttons[1].event}
                                    >
                                        {modalContent.buttons[1].label}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="my-2">
                                <Button
                                    type="button"
                                    color="primary"
                                    weight="font-regular"
                                    onClick={modalContent.buttons[0].event}
                                >
                                    {modalContent.buttons[0].label}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        </Container>
    );
};

export default Templates;
