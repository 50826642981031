import React, { useEffect, useState } from 'react';
import { MemoLog } from '../../models/MemoLog';
import { getDaysElapsed } from '../../utils/date/Date';
import MemoLogFooter from './MemoLogFooter';
import MemoLogHeader from './MemoLogHeader';
import './MemoLogs.css';
import ic_check_white from '../../assets/img/ic_check_white.svg';
import { Approver } from '../../models/Approver';
import { Contact } from '../../models/Contact';
import { User } from '../../models/User';

interface IMemoLogGroupProps {
    user?: User;
    allow_sub_approvals?: boolean;
    children?: JSX.Element[] | JSX.Element;
    log_entry: MemoLog & { expanded?: boolean };
    onSubApprovalClicked?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ApproverItemView = (
    approver: Contact &
        Approver & {
            allow_sub_approvals: boolean;
            onSubApprovalClicked?: (event: React.MouseEvent<HTMLButtonElement>) => void;
        },
) => {
    return (
        <div key={approver.email} className="w-full inline-flex flex-row items-center ml-4" role="approver">
            {approver.approval_status.toLocaleLowerCase() === 'approved' ? (
                <img className={`w-3 h-3 p-0.5 bg-green rounded-full mr-2`} src={ic_check_white} alt="approved" />
            ) : approver.approval_status.toLocaleLowerCase() === 'rejected' ? (
                <div className={`w-3 h-3 p-0.5 bg-red-status rounded-full mr-2`} />
            ) : (
                <span className="w-3 h-3 mr-2" />
            )}
            <div className="w-10/12 md:w-8/12 lg:w-10/12 flex flex-col ">
                <p className="px-1 truncate">{approver.name}</p>
                {(() => {
                    if (approver.sub_approvers && approver.sub_approvers.length) {
                        return (
                            <div className="w-full flex flex-col relative px-1 mt-3 mb-1 ml-2 __sub-approval">
                                <div className="__sub-approval-marker">
                                    <h4 className="text-sm font-bold text-gray-3 ml-2">Sub-approvers</h4>
                                </div>
                                {approver.sub_approvers.map((sub_approver) => (
                                    <ApproverItemView
                                        {...sub_approver}
                                        key={sub_approver.email}
                                        allow_sub_approvals={false}
                                    />
                                ))}
                            </div>
                        );
                    }
                })()}

                {approver.allow_sub_approvals && (
                    <div className="px-1 mb-2">
                        <button
                            className="text-xs font-regular text-blue underline"
                            onClick={approver.onSubApprovalClicked}
                        >
                            Edit sub-approvers
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

const MemoLogGroupListItem = (props: IMemoLogGroupProps) => {
    const viewRef = React.useRef<HTMLLIElement>(null);
    const {
        status,
        is_current,
        sequence_no,
        date_last_modified,
        approvers,
        watchers,
        expanded,
        approval_type,
    } = props.log_entry;

    const [isExpanded, setExpanded] = useState(is_current);
    const [totalApprovers, setTotalApprovers] = useState(0);
    const [approvalsCount, setApprovalsCount] = useState(0);

    useEffect(() => {
        setExpanded(expanded as boolean);
    }, [expanded]);

    useEffect(() => {
        if (approvers != null) {
            setTotalApprovers(approvers.length);
            let tmpCount = 0;
            approvers.forEach((approver) => {
                approver.approval_status === 'APPROVED' ? tmpCount++ : null;
            });
            setApprovalsCount(tmpCount);
        }
    }, []);

    const _onClick = () => {
        setExpanded(!isExpanded);
    };

    return (
        <li ref={viewRef} className={`w-full relative ${is_current ? `${status} current` : status}`}>
            <MemoLogHeader
                level_no={Number(sequence_no) + 1}
                status={status}
                is_current={is_current}
                is_expanded={isExpanded}
                days_elapsed={getDaysElapsed(date_last_modified)}
                onClick={_onClick}
                approvalType={approval_type}
                approvalCount={approvalsCount}
                totalApprovers={totalApprovers}
            />
            <div
                className={`w-full h-auto ${status === 'pending' && !is_current && 'opacity-50'} ${
                    !isExpanded ? 'border-b border-gray-1' : ''
                }`}
                role="content"
            >
                {isExpanded && (
                    <>
                        <div className="w-full h-auto flex flex-col relative border-t border-r border-l border-gray-1 font-regular text-sm font-regular">
                            <ul className="ml-0 relative">
                                <li className="w-full mx-1 mt-4 pb-1 approverList" role="approver-list">
                                    <h4 className="font-bold block mx-4 pt-1 text-gray-3">Approvals</h4>
                                    {approvers.map((approver) => (
                                        <ApproverItemView
                                            {...approver}
                                            key={approver.approver_id}
                                            allow_sub_approvals={
                                                (props.allow_sub_approvals &&
                                                    props.user?.uid === approver.approver_user_id) as boolean
                                            }
                                            onSubApprovalClicked={props.onSubApprovalClicked}
                                        />
                                    ))}
                                </li>
                            </ul>
                            <div className="w-full relative mt-2 pr-10 p-2 __inner-list">
                                <ul className="ml-0 relative remarks">
                                    {Array.isArray(props.children)
                                        ? React.Children.map(props.children, (child: JSX.Element) => child)
                                        : props.children}
                                </ul>
                            </div>
                        </div>
                        <MemoLogFooter watchers={watchers} />
                    </>
                )}
            </div>
        </li>
    );
};

export default MemoLogGroupListItem;
