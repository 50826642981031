import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from './redux/Store';
import { Provider } from 'react-redux';

// Firebase
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import { flavor } from './AppFlavor';
import Admin from './Admin';

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});
app.analytics();

const remoteConfig = app.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
    allowed_draft_button_statuses: JSON.stringify({
        values: ['SAVE_AS_DRAFT'],
    }),
    is_cancel_active_memo_table_enabled: false,
    is_draft_actions_enabled: false,
    edit_whitelisted_statuses: JSON.stringify({
        values: ['SAVE_AS_DRAFT', 'RETURNED', 'RETURNED_DRAFT', 'SELF_RETURNED', 'SELF_RETURNED_DRAFT'],
    }),
    download_whitelisted_statuses: JSON.stringify({
        values: ['APPROVED'],
    }),
    is_filter_by_memo_type_enabled: false,
    is_filter_by_date_range_enabled: false,
    is_download_xlsx_enabled: false,
    is_table_options_visible: false,
    enable_download_pdf: false,
    hosted_domains: JSON.stringify({
        values: ['globe.com.ph'],
    }),
    is_global_search_enabled: false,
    admin_emails: JSON.stringify({
        values: [],
    }),
    max_remarks_attachment: 3,
};

// firebase.auth().useEmulator('http://localhost:9099');

ReactDOM.render(
    <React.StrictMode>
        <Provider store={Store}>{flavor.buildType === 'admin' ? <Admin /> : <App />}</Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
