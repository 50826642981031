import { useLocation } from '@reach/router';
import * as React from 'react';
import Badge from '../../../layouts/badge/Badge';
import Status from '../../../layouts/status/Status';
import TableBodyCell from '../../../layouts/table/TableBodyCell';
import TableRow from '../../../layouts/table/TableRow';
import Text from '../../../layouts/typography/Text';
import { Memo } from '../../../models/Memo';
import { getDateString, getDaysElapsed } from '../../../utils/date/Date';
import Datatable, { DatatableComponentProps } from '../datatable/Datatable';

interface IToWatchMemosProps {
    memo_type?: string;
    headers?: any;
    children?: React.ReactNode;
    start_date?: any;
    end_date?: any;
}

const ToWatchTableBody = (props: DatatableComponentProps) => {
    if (!props.memos || !props.headers) return null;

    return (
        <>
            {props.memos.map((_memo: Memo) => {
                const memo = (_memo as unknown) as Record<string, any>;
                return (
                    <TableRow key={memo.memo_id} navigateTo={`/watch/${memo.memo_id}`}>
                        {props.headers!.map((header) => {
                            if (['date_submitted', 'date_last_modified', 'date_created'].includes(header.key)) {
                                return (
                                    <TableBodyCell key={header.key}>{getDateString(memo[header.key])}</TableBodyCell>
                                );
                            }
                            if (header.key === 'days_elapsed') {
                                return (
                                    <TableBodyCell key={header.key}>
                                        {memo[header.key]}
                                        {getDaysElapsed(memo.date_submitted)}
                                    </TableBodyCell>
                                );
                            }
                            if (header.key === 'memo_status') {
                                return (
                                    <TableBodyCell key={header.key} type="status">
                                        <Status type={memo[header.key].toLowerCase()} />
                                        <Text color="secondary">
                                            Days elapsed: {getDaysElapsed(memo.date_last_modified)}
                                        </Text>
                                    </TableBodyCell>
                                );
                            }
                            if (header.key === 'current_approver') {
                                return (
                                    <TableBodyCell key={header.key}>
                                        {Array.isArray(memo[header.key]) && (
                                            <div className="w-auto">
                                                <p className="inline-block mr-0 md:mr-2">{memo[header.key][0]}</p>
                                                {memo[header.key].length - 1 !== 0 ? (
                                                    <Badge text={`+${memo[header.key].length - 1} approvers`} />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        )}
                                    </TableBodyCell>
                                );
                            }
                            return <TableBodyCell key={header.key}>{memo[header.key]}</TableBodyCell>;
                        })}
                    </TableRow>
                );
            })}
        </>
    );
};

const ToWatchMemos = (props: IToWatchMemosProps) => {
    const [config, setConfig] = React.useState({
        endpoint: 'getMemosToWatchActiveWithUserID',
        templatePath: 'watch.memostowatch',
        queryParams: {
            limit: 15,
            page_no: 1,
            start_date: null,
            end_date: null,
            memo_type: '',
        },
    });

    const { memo_type, start_date, end_date } = props;
    const location = useLocation();

    React.useEffect(() => {
        setConfig({
            ...config,
            queryParams: {
                ...config.queryParams,
                memo_type: memo_type as string,
                start_date: start_date,
                end_date: end_date,
            },
        });
    }, [memo_type, start_date, end_date, location]);

    const initialHeaders = [
        {
            label: 'Memo ID',
            key: 'memo_id',
        },
        {
            label: 'Memo type',
            key: 'used_template_name',
        },
        {
            label: 'Subject',
            key: 'memo_subject',
        },
        {
            label: 'Requested by',
            key: 'memo_owner_name',
        },
        {
            label: 'Date created',
            key: 'date_submitted',
        },
        {
            label: 'Status',
            key: 'memo_status',
        },
    ];

    return (
        <div>
            <Datatable
                config={config}
                defaultHeaders={initialHeaders}
                bodyComponent={<ToWatchTableBody />}
                pageRedirectTo="/watch"
            />
        </div>
    );
};

export default ToWatchMemos;
