import React from 'react';

export const useDetectOutsideClick = (initialValue: boolean) => {
    const [isComponentVisible, setComponentVisible] = React.useState(initialValue);
    const ref = React.useRef<any>(null);

    const handleClick = (event: any) => {
        try {
            if (ref.current && ref.current.contains(event.target)) {
                setComponentVisible(true);
            } else {
                setComponentVisible(false);
            }
        } catch (err) {}
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    });

    return { ref, isComponentVisible, setComponentVisible };
};
