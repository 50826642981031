import * as React from 'react';
import PropTypes from 'prop-types';
import Text from '../typography/Text';
import File from '../panels/File';
import '../form/unreset.scss';

export interface DataLayoutProps {
    label: string | null;
    data: any;
    type?: string;
}

interface IDataLayoutProps {
    items: DataLayoutProps[];
    memo_id?: string;
    onDownload?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onFileClick?: (event: any) => void;
}

export const getUploadView = (
    data: any,
    label: string,
    onDownload?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    onClick?: (event: any) => void,
    key?: any,
) => {
    if (data && data.length) {
        return (
            <div key={key} className="mb-4" data-testid="data-file">
                <Text type="bold">Supporting document(s)</Text>
                {data?.map((file: any, index: number) => (
                    <File
                        type="download"
                        name={file.name}
                        url={file.url}
                        key={index}
                        onIconClick={onDownload}
                        onClick={onClick}
                    />
                ))}
            </div>
        );
    }
    return (
        <div key={key} className="mb-4" data-testid="data-file">
            <Text type="bold">{label}</Text>
            <p className="text-sm w-full font-regular">No data</p>
        </div>
    );
};

export const getRTEOutputView = (data: string, key?: any) => {
    return (
        <div
            key={key}
            className="w-full unreset ck-content mb-4 overflow-x-scroll md:overflow-x-hidden"
            dangerouslySetInnerHTML={{ __html: data }}
            data-testid="data-rte"
        />
    );
};

export const getCurrencyView = (data: string, key?: any) => {
    return (
        <div key={key} className="w-full mb-4" data-testid="data-currency">
            <Text type="bold">Total Requested Amount</Text>
            <p className="text-sm">{data}</p>
        </div>
    );
};

export const getRadioView = (item: any, key: any) => {
    return (
        <div key={`${key}-${Date.now()}`} className="w-full mb-4" data-testid="data-radio">
            <Text type="bold">{item.label}</Text>
            <p className="text-sm font-regular">{item.data}</p>
        </div>
    );
};

export const getCheckboxView = (item: any, key: any) => {
    return (
        <div key={key`-${Date.now()}`} className="w-full h-auto mb-4" data-testid="data-checkbox">
            <Text type="bold">{item.label}</Text>
            <p className="text-sm font-regular">{}</p>
        </div>
    );
};

const DataLayout: React.FunctionComponent<IDataLayoutProps> = (props) => {
    return (
        <div className="w-full h-full">
            <ol className="w-full h-full flex flex-col">
                {props.items.map((item, index) => {
                    switch (item.type) {
                        case 'FILE_UPLOAD':
                            return getUploadView(
                                item.data,
                                item.label as string,
                                props.onDownload,
                                props.onFileClick,
                                index,
                            );
                        case 'TEXT_RTE':
                            return getRTEOutputView(item.data, index);
                        case 'TEXT_RTE_PREFILLED':
                            return getRTEOutputView(item.data, index);
                        case 'TEXT_CURRENCY':
                            return getCurrencyView(item.data, index);
                        case 'RADIO':
                            return getRadioView(item, index);
                        case 'TEXT':
                            return (
                                <div key={index} className="mb-4" data-testid="data-text">
                                    <Text type="bold">{item.label}</Text>
                                    <Text>
                                        <p className="text-sm w-full break-words">{item.data}</p>
                                    </Text>
                                </div>
                            );
                        default:
                            return (
                                <div key={index} className="mb-4" data-testid="data-default">
                                    <Text type="bold">{item.label}</Text>
                                    <Text>
                                        <p className="text-sm overflow-ellipsis whitespace-nowrap w-full overflow-hidden">
                                            {item.data}
                                        </p>
                                    </Text>
                                </div>
                            );
                    }
                })}
            </ol>
        </div>
    );
};

DataLayout.propTypes = {
    items: PropTypes.array.isRequired,
    memo_id: PropTypes.string,
    onDownload: PropTypes.func,
    onFileClick: PropTypes.func,
};

export default DataLayout;
