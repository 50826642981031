import _ from 'lodash';
import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useCellHeightAdjuster } from '../../../utils/hooks/useCellHeightAdjuster';
const ResponsiveLayout = WidthProvider(Responsive);
const columns = { lg: 2, md: 2, sm: 2, xs: 2, xxs: 1 };

interface IStaticGridProps {
    _metadata: any;
    items: JSX.Element[];
    margins?: [number, number];
}

const StaticGrid = (props: IStaticGridProps) => {
    const { _metadata, items } = props;
    const [mode, setMode] = React.useState('sm');
    const [observer, setObserver] = React.useState<MutationObserver | null>(null);
    const [mutation, setMutation] = React.useState<MutationRecord[]>([]);

    const defaultRowHeight = 55;

    const gridRef = React.useRef<HTMLDivElement>(null);
    const { layout } = useCellHeightAdjuster(_metadata, null, mutation, defaultRowHeight);

    // For every DOM mutations, we need to update the height of the grid element
    /**
     * Callback for Mutation Observer events.
     * @param mutations - List of mutations emitted by the Mutation Observer
     */
    const mutationCallback = (mutations: MutationRecord[]) => {
        setMutation(mutations);
    };

    /**
     * Initializes the mutation class
     */
    React.useEffect(() => {
        // Creates an observer instance
        const observer = new MutationObserver(mutationCallback);
        // Set the observer instance to local state.
        setObserver(observer);

        const styleOverride = document.createElement('style');
        styleOverride.textContent = `
            .react-resizable-handle.react-resizable-handle-se {
                opacity: 0;
            }
        `;

        document.body.appendChild(styleOverride);

        return () => {
            document.body.removeChild(styleOverride);
        };
    }, []);

    React.useEffect(() => {
        // Creates the observer instance configuration
        const config = { attributes: true, childList: true, subtree: true };
        if (!observer) return;

        // Observes the target and relay mutations to callback
        observer.observe(gridRef.current as Node, config);

        // Unmount the observer.
        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [observer, gridRef]);

    const generateLayout = () => {
        const layouts = _.map(layout, (metadata) => {
            return {
                ...metadata,
                static: false,
            };
        });
        const sortedLayouts = _.sortBy(layouts, 'y');

        const hasDuplicates = _.some(sortedLayouts, (element, index) => sortedLayouts.indexOf(element) !== index);

        /** must be ignored since it cannot be tested in unit tests */
        /* istanbul ignore if */
        if (['xs', 'xxs'].includes(mode) && hasDuplicates) {
            let lastOccuredY = 0;

            for (let index = 0; index < sortedLayouts.length; index++) {
                const previous = sortedLayouts[index - 1] ? sortedLayouts[index - 1] : null;

                if (previous) {
                    lastOccuredY = previous.y + sortedLayouts[index].h;
                    sortedLayouts[index].y = lastOccuredY;
                }
            }
        }
        return {
            lg: sortedLayouts,
            md: sortedLayouts,
            sm: sortedLayouts,
            xs: sortedLayouts,
            xxs: sortedLayouts,
        };
    };

    const onBreakpointChange = (mode: string) => {
        setMode(mode);
    };

    return (
        <div className="w-full" ref={gridRef}>
            <ResponsiveLayout
                rowHeight={defaultRowHeight}
                cols={columns}
                isDroppable={true}
                layouts={generateLayout()}
                onBreakpointChange={onBreakpointChange}
                draggableHandle=".field-drag-handle"
                measureBeforeMount={false}
                compactType="vertical"
                verticalCompact={true}
                margin={props.margins ? props.margins : [10, 10]}
                useCSSTransforms={false}
                isResizable={false}
            >
                {items}
            </ResponsiveLayout>
        </div>
    );
};

export default StaticGrid;
