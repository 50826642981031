import * as React from 'react';
import ic_delete from '../../assets/img/ic_delete.svg';
import PropTypes from 'prop-types';
import { Contact } from '../../models/Contact';

interface IPersonProps {
    id: string;
    field: string;
    contact: Contact;
    disabled?: boolean;
    onRemove?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const contactPhotoStyles: React.CSSProperties = {
    minWidth: '30px',
    minHeight: '30px',
    maxWidth: '30px',
    maxHeight: '30px',
    borderRadius: '50%',
};

const personContainerStyles: React.CSSProperties = {
    borderRadius: '7px',
    border: '0.5px solid #C8D7E2',
};

const Person: React.FunctionComponent<IPersonProps> = (props) => {
    const { email, name, photoURL } = props.contact;
    const { disabled } = props;

    return (
        <div
            style={personContainerStyles}
            className={`${
                disabled ? 'bg-gray-disabled' : 'bg-blue-semilight'
            } w-auto h-auto flex flex-row p-2 items-center my-1 mr-2`}
        >
            {photoURL ? <img style={contactPhotoStyles} src={photoURL} alt="photo" /> : ''}
            <div className="flex overflow-ellipsis whitespace-no-wrap overflow-hidden flex-col justify-center mx-2 px-2">
                {name ? (
                    <p className="text-sm font-regular overflow-ellipsis whitespace-no-wrap overflow-hidden">{name}</p>
                ) : (
                    ''
                )}
                {email ? (
                    <p className="text-sm font-regular overflow-ellipsis whitespace-no-wrap overflow-hidden">{email}</p>
                ) : (
                    ''
                )}
            </div>
            {email && !disabled ? (
                <button
                    type="button"
                    className="p-2 ml-auto flex"
                    onClick={props.onRemove}
                    value={email}
                    data-sequence={props.id}
                    data-field={props.field}
                    data-testid="audience-panel"
                >
                    <img style={{ maxWidth: '10px', maxHeight: '10px' }} src={ic_delete} />
                </button>
            ) : (
                ''
            )}
        </div>
    );
};

Person.propTypes = {
    id: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    contact: PropTypes.any,
    onRemove: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Person;
