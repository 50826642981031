import firebase from 'firebase/app';
import { navigate, useLocation } from '@reach/router';
import * as React from 'react';
import { useSelector } from 'react-redux';
import Container from '../../../layouts/container/Container';
import Select, { SelectItems } from '../../../layouts/select/Select';
import Subtitle from '../../../layouts/typography/Subtitle';
import Title from '../../../layouts/typography/Title';
import SearchTable from './SearchTable';
import { RootState } from '../../../redux/RootReducer';
import { Template } from '../../../models/Template';
import Datepicker from '../../../layouts/datepicker/Datepicker';
import Text from '../../../layouts/typography/Text';
import moment from 'moment';

interface ISearchResultsProps {
    text?: string;
    path?: string;
    children?: React.ReactNode;
}

const SearchResults = (props: ISearchResultsProps) => {
    const [selectItems, setSelectItems] = React.useState<SelectItems[]>([]);
    const { user } = useSelector((state: RootState) => state.Auth);
    const [selectedMemoType, setSelectedMemoType] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const remoteConfig = firebase.remoteConfig();
    const location = useLocation();

    const resetPageNumber = () => {
        navigate(`${location.pathname}?page=1`);
    };

    React.useEffect(() => {
        const loadTemplate = async () => {
            try {
                await remoteConfig.fetchAndActivate();
                const getTemplates = firebase.app().functions('asia-east2').httpsCallable('get_templates');
                const http_response = await getTemplates({ user_id: user.uid });

                const response = http_response.data;
                if (response) {
                    const templates = response.map((item: Template | any) => {
                        return {
                            label: item.template_name,
                            value: item.template_id,
                            headers: item.headers,
                        };
                    });

                    setSelectItems(templates);
                }
            } catch (err) {
                console.error(err);
            }
        };

        loadTemplate();
    }, []);

    /** Handles dropdown event change from Select component.
     */
    const onSelectChanged = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        if (event.currentTarget.value) {
            setSelectedMemoType(event.currentTarget.value);
        }
        resetPageNumber();
    };

    /**
     * Handles datepicker event
     * @param event - React Datepicker event object
     */
    const onDateChanged = (event: any) => {
        const [start, end] = event;
        setStartDate(start);
        setEndDate(end);
        resetPageNumber();
    };

    return (
        <Container>
            <div className="w-full">
                <Title>Search results</Title>
                <div className="my-2">
                    <Subtitle>
                        Showing results for <b> &quot;{props.text}&quot; </b>
                    </Subtitle>
                </div>
            </div>
            <div className="w-full mt-8">
                <div className="py-4">
                    {remoteConfig.getBoolean('is_table_options_visible') ? (
                        <div id="table-options" className="flex flex-col md:flex-row md:justify-between">
                            <div id="table-filter-search" className="w-full md:w-2/3">
                                <div className="w-full flex flex-col md:flex-row md:items-center mb-4">
                                    <Text>Filter by:</Text>
                                    {remoteConfig.getBoolean('is_filter_by_date_range_enabled') ? (
                                        <div className="w-auto md:ml-4">
                                            <Datepicker id="dp" label="Date created" onChange={onDateChanged} />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {remoteConfig.getBoolean('is_filter_by_memo_type_enabled') ? (
                                        <div className="w-auto md:w-1/3 md:ml-4">
                                            <Select
                                                id="memotype_dropdown"
                                                type="inline"
                                                placeholder="Memo Type"
                                                onChange={onSelectChanged}
                                            >
                                                <option value="" disabled selected hidden>
                                                    Memo Type
                                                </option>
                                                <option value="ALL">All</option>
                                                {selectItems.map((item) => (
                                                    <option key={item.value} value={item.label}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </Select>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <SearchTable
                        searchToken={props.text}
                        memo_type={selectedMemoType}
                        start_date={startDate}
                        end_date={endDate}
                    />
                </div>
            </div>
        </Container>
    );
};

export default SearchResults;
