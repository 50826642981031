import { navigate, RouteComponentProps } from '@reach/router';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/remote-config';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loadError from '../../../assets/img/load_error.svg';
import Container from '../../../layouts/container/Container';
import Code from '../../../layouts/panels/Code';
import Preloader from '../../../layouts/preloaders/Preloader';
import { User } from '../../../models/User';
import { setUser } from '../../../redux/AuthSlice';
import { RootState } from '../../../redux/RootReducer';

interface ILoginRouteProps {
    title?: string;
    path?: RouteComponentProps;
}

const LoginRedirect = (props: ILoginRouteProps) => {
    const dispatch = useDispatch();
    const remoteConfig = firebase.remoteConfig();
    const [hostedDomains, setHostedDomains] = React.useState<string[]>([]);
    const [configStatus, setConfigStatus] = React.useState('idle');
    const [authStatus, setAuthStatus] = React.useState('idle');

    const [error, setError] = React.useState('');
    const { user } = useSelector((state: RootState) => state.Auth);

    const { title } = props;
    document.title = title as string;

    const loadConfig = async () => {
        try {
            setConfigStatus('pending');
            await remoteConfig.fetchAndActivate();
            const hd = remoteConfig.getString('hosted_domains');

            /* istanbul ignore else*/
            if (hd) {
                const values = JSON.parse(hd).values;
                setHostedDomains(values);
            }
            setConfigStatus('fulfilled');
        } catch (err) {
            setConfigStatus('rejected');
            setError(JSON.stringify(err));
            console.error('There was an error while sending the request.', err);
        }
    };

    const updateUser = (user: firebase.User | null) => {
        /* istanbul ignore else*/
        if (user) {
            const payload: User = {
                displayName: user.displayName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                providerId: user.providerId,
                uid: user.uid,
            };
            dispatch(setUser(payload));
        }
    };

    const authorize = async () => {
        try {
            setAuthStatus('pending');
            const searchParams = new URLSearchParams(window.location.search);

            const callbackURL =
                window.location.hostname === 'localhost'
                    ? `${window.location.protocol}//${window.location.hostname}:5001/${process.env.REACT_APP_PROJECT_ID}/us-central1/auth/auth/google/callback`
                    : `${window.location.origin}/auth/google/callback`;
            const request = await axios.post(callbackURL, searchParams, { withCredentials: true });

            console.log(hostedDomains, request.data.hd);
            /* istanbul ignore else*/
            if (!hostedDomains.includes(request.data.hd)) {
                throw { message: 'Unable to sign in. Entered email is unsupported.', code: '401-A' };
            }

            const credential = firebase.auth.GoogleAuthProvider.credential(request.data.id_token);
            const user = await firebase.auth().signInWithCredential(credential);

            // // Send request to backend to process user email.
            const login = firebase.app().functions('asia-east2').httpsCallable('log_user_logging_in');
            await login({ user_id: user.user?.uid });
            const sync = firebase.app().functions('asia-east2').httpsCallable('sync_account');
            await sync({});

            // Update the UI and the App Header with user info
            updateUser(user?.user);
            setAuthStatus('fulfilled');
            navigate('/dashboard');
        } catch (err: any) {
            if (err.response) {
                setError(JSON.stringify(err.response.data));
            } else {
                console.log(err);
                setError(JSON.stringify(err));
            }
            setAuthStatus('rejected');
            console.error(err);
        }
    };

    React.useEffect(() => {
        loadConfig();
    }, []);

    React.useEffect(() => {
        if (user.uid) {
            navigate('/dashboard');
        } else {
            if (configStatus === 'fulfilled') {
                authorize();
            }
        }
    }, [configStatus]);

    switch (authStatus) {
        case 'pending':
            // Added text statuses to indicate signing progress.
            return <Preloader />;
        case 'rejected':
            return (
                <Container>
                    <div
                        className="w-full flex flex-col items-center justify-center text-center"
                        data-testid="redirect-error"
                    >
                        <img src={loadError} style={{ height: '120px' }} alt="Request Error" />
                        <p className="font-bold text-md mt-8 my-2">There was an error while signing in.</p>
                        <div className="w-1/3">
                            <Code>
                                <p className="text-xs" data-testid="error-msg">
                                    {error}
                                </p>
                            </Code>
                        </div>
                    </div>
                </Container>
            );
        case 'fulfilled':
            return <Preloader text="Signing in." />;
        default:
            return <Preloader />;
    }
};

export default LoginRedirect;
