import { useLocation } from '@reach/router';
import * as React from 'react';
import Status from '../../../layouts/status/Status';
import TableBodyCell from '../../../layouts/table/TableBodyCell';
import TableRow from '../../../layouts/table/TableRow';
import { Memo } from '../../../models/Memo';
import { getDateString } from '../../../utils/date/Date';
import Datatable, { DatatableComponentProps } from '../datatable/Datatable';

interface IToWatchHistoryProps {
    memo_type?: string;
    headers?: string;
    children?: React.ReactNode;
    start_date?: any;
    end_date?: any;
}

const ToWatchHistoryTableBody = (props: DatatableComponentProps) => {
    if (!props.memos || !props.headers) return null;

    return (
        <>
            {props.memos.map((_memo: Memo) => {
                const memo = (_memo as unknown) as Record<string, any>;
                return (
                    <TableRow key={memo.memo_id} navigateTo={`/watch/history/${memo.memo_id}`}>
                        {props.headers!.map((header) => {
                            if (header.key === 'date_last_modified') {
                                return (
                                    <TableBodyCell key={header.key}>{getDateString(memo[header.key])}</TableBodyCell>
                                );
                            }
                            if (header.key === 'memo_status') {
                                return (
                                    <TableBodyCell>
                                        <Status type={memo[header.key].toLowerCase()} />
                                    </TableBodyCell>
                                );
                            }
                            return <TableBodyCell key={header.key}>{memo[header.key]}</TableBodyCell>;
                        })}
                    </TableRow>
                );
            })}
        </>
    );
};

const ToWatchHistory = (props: IToWatchHistoryProps) => {
    const [config, setConfig] = React.useState({
        endpoint: 'getMemosToWatchHistoryWithUserID',
        templatePath: 'watch.history',
        queryParams: {
            limit: 15,
            page_no: 1,
            start_date: null,
            end_date: null,
            memo_type: '',
        },
    });

    const { memo_type, start_date, end_date } = props;

    const initialHeaders = [
        {
            label: 'Memo ID',
            key: 'memo_id',
        },
        {
            label: 'Memo type',
            key: 'used_template_name',
        },
        {
            label: 'Subject',
            key: 'memo_subject',
        },
        {
            label: 'Requested by',
            key: 'memo_owner_name',
        },
        {
            label: 'Date of action',
            key: 'date_last_modified',
        },
        {
            label: 'Status',
            key: 'memo_status',
        },
    ];
    const location = useLocation();

    React.useEffect(() => {
        setConfig({
            ...config,
            queryParams: {
                ...config.queryParams,
                memo_type: memo_type as string,
                start_date: start_date,
                end_date: end_date,
            },
        });
    }, [memo_type, start_date, end_date, location]);

    return (
        <div>
            <Datatable
                config={config}
                defaultHeaders={initialHeaders}
                bodyComponent={<ToWatchHistoryTableBody />}
                pageRedirectTo="/watch/history"
            />
        </div>
    );
};

export default ToWatchHistory;
