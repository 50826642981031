import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copy from '../../../../assets/img/copy.svg';
import ic_delete from '../../../../assets/img/ic_delete.svg';
import ic_drag from '../../../../assets/img/ic_drag.svg';
import { TemplateContent } from '../../../../models/TemplateContent';
import { removeError, setActiveField, setErrors, updateSection } from '../../../../redux/FormBuilderSlice';
import { RootState } from '../../../../redux/RootReducer';
import { AppDispatch } from '../../../../redux/Store';

/**
 * Props for the Form Grid cell
 */
interface IFormGridCellProps {
    /**
     * Set to true if the section/grid is active.
     */
    focused: boolean;

    /**
     * The form item content.
     */
    item: TemplateContent;

    /**
     * The index for the current section of the grid.
     */
    sectionIndex: number;

    /**
     * The id for the current section of the grid.
     */
    sectionId: string;

    /**
     * A JSX node for the cell from the grid.
     */
    children?: React.ReactNode;
}

/**
 * The cell component for the Form Grid.
 * @param props
 * @returns
 */
const FormGridCell = (props: IFormGridCellProps) => {
    // Destructure the props.
    const { item, sectionId, sectionIndex, focused, children } = props;

    // Access the Redux state.
    const { _editing, template_content, errors } = useSelector((state: RootState) => state.FormBuilder);

    // Set the local state for the cell panel.
    // This controls the visibility of the cell panel, which enables the user to customize the form item
    // inside the cell.
    const [isEditing, setIsEditing] = React.useState(_editing && _editing.field_id === item.field_id);

    const checkCellValidation = () => {
        if (_editing && _editing.field_type === 'TEXT') {
            if (Number(item.validations.min_length) >= Number(item.validations.max_length)) {
                dispatch(
                    setErrors({
                        sectionId: props.sectionId,
                        sectionIndex: props.sectionIndex,
                        form_item: {
                            id: item.field_id,
                            message: 'Minimum characters must be less than maximum characters',
                        },
                    }),
                );
            } else {
                dispatch(removeError(item.field_id));
            }
        } else if (_editing && _editing.field_type === 'FILE_UPLOAD') {
            if (item.validations.allowed_limited_file_types && item.validations?.file_types?.length === 0) {
                dispatch(
                    setErrors({
                        sectionId: '',
                        sectionIndex: props.sectionIndex,
                        form_item: {
                            id: item.field_id,
                            message: 'Please choose a file type',
                        },
                    }),
                );
            } else {
                dispatch(removeError(item.field_id));
            }
        }
    };

    // Updates the local state if there are any cell that is currently selected.
    React.useEffect(() => {
        checkCellValidation();
        setIsEditing(_editing && _editing.field_id === item.field_id);
    }, [_editing]);

    // Creates the dispatch function for Redux reducers.
    const dispatch: AppDispatch = useDispatch();

    /**
     * Handler for clicking the cell.
     */
    const onClick = () => {
        if (!_editing || _editing?.field_id !== item.field_id) {
            dispatch(
                setActiveField({
                    ...item,
                    sectionIndex: sectionIndex,
                    sectionId: sectionId,
                }),
            );
        }
    };

    /**
     * A function that returns a class list for borders.
     * - Returns a dark blue border if the cell is being edited and the section is currently focused.
     * - Returns a dark gray border if the section is currently focused
     * - Returns a white border if neither being edited nor the section is focused.
     * @returns
     */
    const getBorders = () => {
        if (errors?.find((error) => error.form_item.id === item.field_id)) {
            return 'border-2 border-red';
        }
        if (isEditing && focused) {
            return 'border-2 border-blue-dark';
        } else if (focused) {
            return 'border border-gray-2';
        }
        return 'border-2 border-white';
    };

    /**
     * Handler for copying a grid's cell.
     */
    const copyCell = () => {
        // Deselect the current selected cell.
        setTimeout(() => {
            dispatch(setActiveField(null));
        });

        // Copy the current cell
        const formItem = _.cloneDeep(_editing);

        // Get the metadata of the section
        const metadata = template_content[sectionIndex]._metadata;

        // Get the cell layout from the metadata
        const itemMetadata = _.cloneDeep(_.find(metadata, (m) => m.i === formItem?.field_id));

        if (itemMetadata && formItem) {
            // Generate a new id
            const id = nanoid();

            // Update the Y value to Infinity
            // Setting the Y to infinity guarantees that the cell will be placed at the bottom
            _.set(itemMetadata, 'y', Infinity);

            // Set the cell layout's identifier to the new id.
            _.set(itemMetadata, 'i', id);

            // Set the field's id to the newly generated id.
            _.set(formItem, 'field_id', id);

            // Create a copy of the section from redux and apply the updated metadata and the form item.
            const section = {
                ...template_content[sectionIndex],
                value: [...template_content[sectionIndex].value, formItem],
                _metadata: [...template_content[sectionIndex]._metadata, itemMetadata],
            };

            // Dispatch the reducer that updates the store.
            dispatch(updateSection({ index: sectionIndex, content: { ...section } }));

            // Selects the newly created cell.
            setTimeout(() => {
                dispatch(
                    setActiveField({
                        ...formItem,
                        sectionIndex: sectionIndex,
                        sectionId: sectionId,
                    }),
                );
            });
        }
    };

    /**
     * Handler for deleting a grid cell.
     */
    const deleteCell = () => {
        // Construct a new section data that:
        // 1. Has filtered template content values except for the selected cell.
        // 2. Has filtered layout metadata except for the selected cell.
        const section = {
            ...template_content[sectionIndex],
            value: _.filter(template_content[sectionIndex].value, (content) => content.field_id !== item.field_id),
            _metadata: _.filter(template_content[sectionIndex]._metadata, (m) => m.i !== item.field_id),
        };

        // Dispatch the reducer and pass the updated section data.
        dispatch(updateSection({ index: sectionIndex, content: { ...section } }));
        dispatch(removeError(item.field_id));

        // Deselect the selected cell.
        setTimeout(() => {
            dispatch(setActiveField(null));
        });
    };

    // Returns a JSX element that represents a cell view.
    return (
        <>
            <div
                className={`w-full h-full flex box-border bg-white inline-flex flex-col rounded relative ${getBorders()}`}
                onClick={onClick}
                tabIndex={0}
                id={`cell-${item.field_id}`}
            >
                <div id={`cell-content-${item.field_id}`} className="w-full h-auto flex flex-col">
                    {/* If the cell is being edited, display the drag handle */}
                    {isEditing ? (
                        <div className="w-auto h-auto flex mx-auto bg-transparent">
                            <button className="field-drag-handle left-1/2" type="button">
                                <img className="w-6 h-6 p-1" src={ic_drag} alt="Drag me" />
                            </button>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="w-full h-auto px-4 py-4 inline-block relative">{children}</div>
                </div>

                {/* Is the cell is being edited or selected, display the toolbar below. */}
                {isEditing ? (
                    <div className="w-full h-auto mt-auto px-4">
                        <div
                            className="w-full flex flex-row pt-2 pb-2 justify-between h-auto border-t border-blue-dark"
                            style={{ borderColor: '#00000029' }}
                        >
                            <div className="w-auto ml-auto flex flex-row items-center">
                                {_editing?.field_id === 'TEXT_SUBJECT' ? (
                                    <>
                                        <button
                                            className="w-9 h-9 p-2 mx-2 opacity-50 cursor-not-allowed"
                                            type="button"
                                            disabled
                                        >
                                            <img src={copy} alt="Copy" />
                                        </button>
                                        <button
                                            className="w-9 h-9 p-2 mx-2 opacity-50 cursor-not-allowed"
                                            type="button"
                                            disabled
                                        >
                                            <img src={ic_delete} alt="Copy" />
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button className="w-9 h-9 p-2 mx-2" type="button" onClick={copyCell}>
                                            <img src={copy} alt="Copy" />
                                        </button>
                                        <button className="w-9 h-9 p-2 mx-2" type="button" onClick={deleteCell}>
                                            <img src={ic_delete} alt="Copy" />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default FormGridCell;
