import * as React from 'react';
import PropTypes from 'prop-types';

interface ITabProps {
    label: string;
    href: string;
    children: React.ReactNode;
}

const Tab: React.FunctionComponent<ITabProps> = (props) => {
    return <div>{props.children}</div>;
};

Tab.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default Tab;
