import React from 'react';
import Button from '../../../layouts/buttons/Button';
import Contacts from '../contacts/Contacts';
import Input from '../../../layouts/form/Input';
import { IModalProps } from '../../../layouts/modals/Modal';
import Person from '../../../layouts/panels/Person';
import Preloader from '../../../layouts/preloaders/Preloader';
import { Contact } from '../../../models/Contact';
import Title from '../../../layouts/typography/Title';
import { Approver } from '../../../models/Approver';

export interface ISubApprovalsProviderProps {
    defaultSubApprovers: Array<Contact & Approver>;
    httpState: string;
    exclusions: string[];
    onSubmit: (payload: any) => void;
    onClose: () => void;
    onApproverAdded: (email: string) => void;
    onApproverRemoved?: (emails: string[]) => void;
}

export const SubApprovalsProvider = <T extends IModalProps>(Component: React.ComponentType<T>) => {
    const WrappedComponent = (props: Omit<T, 'children'> & ISubApprovalsProviderProps) => {
        const [searchToken, setSearchToken] = React.useState('');
        const [selectedPeople, setSelectedPeople] = React.useState<Contact[]>([]);

        const cleanUp = () => {
            setSearchToken('');
            setSelectedPeople([]);
        };

        const onPersonSelected = (event: React.MouseEvent<HTMLDivElement>) => {
            const email = event.currentTarget.dataset.email as string;
            const photoURL = event.currentTarget.dataset.photo as string;
            const name = event.currentTarget.dataset.name as string;

            const approver: Contact = {
                email: email,
                photoURL: photoURL,
                name: name,
            };

            setSelectedPeople([...selectedPeople, approver]);
            setSearchToken('');
        };

        const onPersonRemoved = (event: any) => {
            const email = event.currentTarget.value;
            const approvers = selectedPeople.filter((person: Contact) => person.email !== email);
            setSelectedPeople(approvers);

            if (props.onApproverRemoved) {
                props.onApproverRemoved(approvers.map((approver) => approver.email));
            }
        };

        const onSearchTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchToken(event.target.value);
        };

        React.useEffect(() => {
            if (props.visible && props.defaultSubApprovers.length) {
                setSelectedPeople(props.defaultSubApprovers);
            }
        }, [props.visible, props.defaultSubApprovers]);

        React.useEffect(() => {
            return () => {
                cleanUp();
            };
        }, []);

        const onSubmit = (e: React.FormEvent) => {
            e.preventDefault();
            const payload = {
                approvers: selectedPeople, // ['email'],
            };
            props.onSubmit(payload);
        };

        const onClose = () => {
            cleanUp();
            props.onClose();
        };

        return (
            <Component {...((props as unknown) as T)}>
                {(() => {
                    if (props.httpState === 'pending') {
                        return (
                            <div className="w-full flex flex-col items-center justify-center">
                                <Preloader type="dots" text="Loading..." />
                            </div>
                        );
                    }
                    if (props.httpState === 'fulfilled') {
                        return (
                            <div className="alert alert-success">
                                <strong>Success!</strong> Approvals were successfully added.
                            </div>
                        );
                    } else if (props.httpState === 'rejected') {
                        return (
                            <div className="alert alert-danger">
                                <strong>Error!</strong> Approvals were not added.
                            </div>
                        );
                    } else {
                        return (
                            <form className="w-full flex flex-col" onSubmit={onSubmit}>
                                <div className="mb-4">
                                    <Title type="h4">Edit sub-level approvers</Title>
                                </div>
                                <fieldset>
                                    <h4 className="text-sm font-regular">Search approvers to be added</h4>
                                    <p className="text-xs text-gray font-regular">
                                        Hit enter after one name or email for multiple assignees
                                    </p>

                                    <div className="w-full inline-flex relative flex-col mt-2">
                                        <Contacts
                                            id={`approver-sub-approvals`}
                                            field="approvers"
                                            searchToken={searchToken}
                                            onPersonSelected={onPersonSelected}
                                            selectedPeople={selectedPeople
                                                .map((people) => people.email)
                                                .concat(props.exclusions)}
                                        >
                                            <Input
                                                id={`approver-sub-approvals-search`}
                                                label=""
                                                type="text"
                                                placeholder="Enter email address"
                                                onChange={onSearchTokenChange}
                                                data-testid="audience-input"
                                                value={searchToken}
                                            />
                                        </Contacts>
                                    </div>
                                    <div className="w-full flex flex-wrap">
                                        {selectedPeople.map((contact, index) => (
                                            <Person
                                                key={index}
                                                id={contact.email}
                                                field="sub-approvers"
                                                contact={contact}
                                                onRemove={onPersonRemoved}
                                            />
                                        ))}
                                    </div>
                                    <div className="w-full flex flex-row mt-2">
                                        <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                            <div className="w-full lg:w-1/3 flex flex-col">
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    weight="font-regular"
                                                    onClick={onClose}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="w-full lg:w-1/3 flex flex-col">
                                                <Button color="primary" weight="font-regular" type="submit">
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        );
                    }
                })()}
            </Component>
        );
    };
    WrappedComponent.displayName = 'SubApprovalsModal';
    return React.memo(WrappedComponent);
};
