import React from 'react';
import Text from '../../../layouts/typography/Text';

const Contact = (): any => {
    return (
        <div className="p-8">
            <div className="pb-2">
                <Text type="bold">
                    <h1 className="text-base">Contact us</h1>
                </Text>
            </div>
            <div>
                <div className="py-4" id="privacyPolicy">
                    <Text color="tertiary">
                        <p className="py-1">
                            Got any questions? Drop us an email at <b>memo-support@globe.com.ph</b>
                        </p>
                    </Text>
                </div>
            </div>
        </div>
    );
};

export default Contact;
