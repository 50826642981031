import { Link, navigate } from '@reach/router';
import React from 'react';

interface ITablePaginationProps {
    currentPage?: number; // to be removed
    totalPages?: number; // to be removed
    baseHref: string;
    startAfter?: string;
    endBefore?: string;
    disabled?: boolean;
    showPageSummary?: boolean;
}

const TablePagination = (props: ITablePaginationProps) => {
    const { startAfter, endBefore, baseHref } = props;

    const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('limit', e.currentTarget.value);
        navigate(`${baseHref}?${urlParams.toString()}`);
    };

    const getPath = (path: string) => {
        const currentPath = new URLSearchParams(window.location.search);
        const limitQuery = currentPath.get('limit');

        if (limitQuery) {
            return `${path}&limit=${limitQuery}`;
        }
        return path;
    };

    return (
        <div className="w-full flex flex-row justify-end">
            <div className="w-full md:w-auto h-auto p-2 flex md:flex-row flex-col-reverse items-center">
                <p className="h-full px-2 py-3 flex items-center text-xs font-regular">Rows per page:</p>
                <select
                    className="__select_no_bg my-1 pr-6 pl-2 py-2 cursor-pointer w-auto h-auto outline-none text-xs font-regular"
                    onChange={onSelectChange}
                >
                    <option value="15">15</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                </select>

                <div className="w-auto h-full ml-4 bg-white border border-1 border-gray-1">
                    <ul className="h-full flex flex-row items-center">
                        <li className="text-xs h-full font-regular cursor-pointer hover:bg-blue-light">
                            <Link to={getPath(`${baseHref}?q=latest`)} className={`h-full px-2 py-2 flex items-center`}>
                                First
                            </Link>
                        </li>
                        <li className="text-xs h-full font-regular cursor-pointer hover:bg-blue-light">
                            <Link
                                to={getPath(`${baseHref}?endBefore=${endBefore}`)}
                                className={`h-full px-2 py-3 flex items-center`}
                            >
                                Prev
                            </Link>
                        </li>
                        <li className="text-xs h-full font-regular cursor-pointer hover:bg-blue-light">
                            <Link
                                to={getPath(`${baseHref}?startAfter=${startAfter}`)}
                                className={`h-full px-2 py-3 flex items-center`}
                            >
                                Next
                            </Link>
                        </li>
                        <li className="text-xs h-full font-regular cursor-pointer hover:bg-blue-light">
                            <Link to={getPath(`${baseHref}?q=oldest`)} className={`h-full px-2 py-3 flex items-center`}>
                                Last
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TablePagination;
