import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '../toolbar/Toolbar';
import { Link } from '@reach/router';
import './MemoFeed.css';
import { MemoFeedItems } from '../../models/MemoFeedItems';
import MemoFeedContainer from './MemoFeedContainer';

interface IMemoFeedProps {
    title: string;
    redirectTo: string;
    icon?: string;
    items: Array<MemoFeedItems>;
    state?: string;
    count?: number;
    returned_count?: number;
    actionTitle: string;
    feed_type: string;
    onItemClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const MemoFeed: React.FunctionComponent<IMemoFeedProps> = (props) => {
    const { icon, title, count, items, state, redirectTo, onItemClick } = props;

    return (
        <div className="w-full h-full bg-blue-light flex flex-col" style={{ borderRadius: '0px 0px 8px 8px' }}>
            <Toolbar icon={icon} text={title} count={count} />

            <MemoFeedContainer
                items={items}
                state={state}
                returned_count={props.returned_count}
                redirectTo={redirectTo}
                onItemClick={onItemClick}
            />
            <div className="p-2 my-4 mt-auto">
                <Link className="text-sm font-regular text-blue" to={props.redirectTo}>
                    <div
                        className="bg-white flex items-center justify-center mx-4 p-4"
                        style={{ borderRadius: '8px', boxShadow: '0px 3px 6px #00000029' }}
                    >
                        {props.actionTitle}
                    </div>
                </Link>
            </div>
        </div>
    );
};

MemoFeed.propTypes = {
    title: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired,
    icon: PropTypes.string,
    onItemClick: PropTypes.func,
    items: PropTypes.array.isRequired,
    state: PropTypes.string,
    count: PropTypes.number,
    returned_count: PropTypes.number,
    feed_type: PropTypes.string.isRequired,
    actionTitle: PropTypes.string.isRequired,
};

export default MemoFeed;
