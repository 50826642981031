import * as React from 'react';
import PropTypes from 'prop-types';
import Text from '../typography/Text';
import { Player } from '@lottiefiles/react-lottie-player';
import bar from '../../assets/animations/loading_bar.json';
import dots from '../../assets/animations/loading_dots.json';
import logoMemo from '../../assets/img/new_logo_memo@half.png';

interface IPreloaderProps {
    text?: string | any;
    type?: string;
    width?: string;
}

/**
 *
 * Animation by Oz Hashimoto
 * Oz Hashimoto on @LottieFiles
 *
 */

const Preloader: React.FunctionComponent<IPreloaderProps> = (props) => {
    // TODO: Allows user to change the height and width of the preloader;
    switch (props.type) {
        case 'dots':
            return (
                <div
                    className="w-full h-auto flex flex-col items-center justify-center text-center inline-block relative"
                    data-testid="loading"
                    aria-label="Loading..."
                >
                    <Player autoplay style={{ width: props.width || '80px' }} loop src={dots} speed={1.5} />
                    <div className="absolute" style={{ top: '75%' }}>
                        <Text>{props.text}</Text>
                    </div>
                </div>
            );
        default:
            return (
                <div
                    className="w-full h-screen flex flex-col items-center justify-center"
                    data-testid="loading"
                    aria-label="Loading..."
                >
                    <img id="bar" style={{ position: 'absolute', top: '40%' }} src={logoMemo} alt="logo" />
                    <Player autoplay loop src={bar} style={{ width: props.width || '300px' }} />
                    <Text>{props.text}</Text>
                </div>
            );
    }
};

Preloader.propTypes = {
    text: PropTypes.string,
    type: PropTypes.oneOf(['bar', 'dots']),
    width: PropTypes.string,
};

export default Preloader;
