import { navigate } from '@reach/router';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/remote-config';
import React from 'react';
import { useSelector } from 'react-redux';
import ic_check from '../../../assets/img/ic_check.svg';
import ic_download from '../../../assets/img/ic_download.svg';
import ic_edit from '../../../assets/img/ic_edit.svg';
import ic_alert from '../../../assets/img/ic_alert.svg';
import Button from '../../../layouts/buttons/Button';
import Modal from '../../../layouts/modals/Modal';
import Preloader from '../../../layouts/preloaders/Preloader';
import Status from '../../../layouts/status/Status';
import Text from '../../../layouts/typography/Text';
import Title from '../../../layouts/typography/Title';
import { ModalContent } from '../../../models/ModalContent';
import { RootState } from '../../../redux/RootReducer';

const MemoViewHeaderStatus = () => {
    const { user, memo } = useSelector((rootState: RootState) => ({
        memo: rootState.MemoView.memo,
        user: rootState.Auth.user,
    }));

    const [editWhitelistedStatuses, setEditWhitelistedStatus] = React.useState([
        'SAVE_AS_DRAFT',
        'RETURNED',
        'RETURNED_DRAFT',
        'SELF_RETURNED',
        'SELF_RETURNED_DRAFT',
    ]);
    const [downloadWhitelistedStatuses, setDownloadWhitelistedStatus] = React.useState(['APPROVED']);

    const initialModalContent = {
        isVisible: false,
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    };

    const [modalContent, setModalContent] = React.useState<Omit<ModalContent, 'subtitle'> & { subtitle: any }>(
        initialModalContent,
    );

    const remoteConfig = firebase.remoteConfig();

    React.useEffect(() => {
        const loadRemoteConfig = async () => {
            try {
                await remoteConfig.fetchAndActivate();
                const whitelistedStatuses = remoteConfig.getString('edit_whitelisted_statuses');
                if (editWhitelistedStatuses) {
                    const values = JSON.parse(whitelistedStatuses).values;
                    setEditWhitelistedStatus(values);
                }
                const downloadStatuses = remoteConfig.getString('download_whitelisted_statuses');
                if (downloadWhitelistedStatuses) {
                    const values = JSON.parse(downloadStatuses).values;
                    setDownloadWhitelistedStatus(values);
                }
            } catch (err) {
                console.error(err);
            }
        };

        loadRemoteConfig();
    }, []);

    const goToEditForm = () => {
        navigate(`/memo/edit/${memo.memo_id}`);
    };

    const downloadPDF = async () => {
        let blobUrl = '';
        setModalContent({
            ...modalContent,
            isVisible: true,
            loading: true,
        });

        try {
            const token = await firebase.auth().currentUser?.getIdToken();
            const response = await axios.post(
                `https://asia-east2-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/create_pdf_report_http`,
                {
                    data: {
                        memo_id: memo.memo_id,
                    },
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    responseType: 'blob',
                },
            );

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const fileName = response.headers['content-disposition']
                ? response.headers['content-disposition'].split(';')[1].split('=')[1]
                : `${memo.memo_id}.pdf`;
            blobUrl = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', fileName);

            setModalContent({
                isVisible: true,
                loading: false,
                icon: ic_check,
                title: 'Download has started.',
                subtitle: (
                    <p className="font-regular text-normal w-auto text-sm">
                        Your download should automatically start within seconds.
                        <br /> If it doesn&apos;t,{' '}
                        <a href={blobUrl} download={fileName} className="text-blue-dark">
                            restart the download.
                        </a>
                    </p>
                ),
                buttons: [
                    {
                        label: 'Back to memo',
                        event: () => {
                            setModalContent({ ...initialModalContent, isVisible: false });
                            URL.revokeObjectURL(blobUrl);
                        },
                    },
                ],
            });
            setTimeout(() => {
                link.click();
                link.remove();
            }, 1000);
        } catch (err) {
            setModalContent({
                ...modalContent,
                isVisible: true,
                loading: false,
                icon: ic_alert,
                title: 'There was an error while generating the PDF.',
                subtitle: `Error ${JSON.stringify((err as any).message)}`,
                buttons: [
                    {
                        label: 'Back to memo',
                        event: () => {
                            setModalContent({ ...initialModalContent, isVisible: false });
                            URL.revokeObjectURL(blobUrl);
                        },
                    },
                ],
            });
        }
    };

    return (
        <div className="w-full flex flex-row justify-between items-center mb-1">
            <div className="m-1">
                <Status type={memo.memo_status.toLowerCase()} />
            </div>
            {editWhitelistedStatuses.includes(memo.memo_status) && memo.memo_owner_email === user.email && (
                <Button type="button" onClick={goToEditForm}>
                    <span className="mr-2">
                        <img style={{ width: '16px', height: '16px' }} src={ic_edit} alt="Edit memo" />
                    </span>
                    Edit
                </Button>
            )}
            {downloadWhitelistedStatuses.includes(memo.memo_status) && remoteConfig.getBoolean('enable_download_pdf') && (
                <Button type="button" onClick={() => downloadPDF()}>
                    <span className="mr-2">
                        <img style={{ width: '16px', height: '16px' }} src={ic_download} alt="Generate PDF" />
                    </span>
                    Download PDF
                </Button>
            )}
            <Modal visible={modalContent.isVisible as boolean}>
                {modalContent.loading ? (
                    <div className="w-full flex flex-col items-center justify-center">
                        <Preloader type="dots" text="Please wait..." />
                    </div>
                ) : (
                    <div
                        className={(() => {
                            if (modalContent.icon) {
                                return `w-full flex flex-col items-center`;
                            } else {
                                return 'w-full flex flex-col items-start';
                            }
                        })()}
                    >
                        {modalContent.icon && (
                            <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                        )}
                        <Title type="h4">{modalContent.title}</Title>
                        <div
                            className={(() => {
                                if (modalContent.icon) {
                                    return 'my-2';
                                } else {
                                    return 'my-0';
                                }
                            })()}
                        >
                            {modalContent.subtitle}
                        </div>
                        {(() => {
                            if (modalContent.buttons.length > 1) {
                                return (
                                    <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                        <div className="w-full flex flex-col px-2">
                                            <Button
                                                type="button"
                                                color="secondary"
                                                weight="font-regular"
                                                onClick={modalContent.buttons[0].event}
                                            >
                                                {modalContent.buttons[0].label}
                                            </Button>
                                        </div>
                                        <div className="w-full flex flex-col px-2">
                                            <Button
                                                color="primary"
                                                weight="font-regular"
                                                type="submit"
                                                onClick={modalContent.buttons[1].event}
                                            >
                                                {modalContent.buttons[1].label}
                                            </Button>
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="my-2">
                                        <Button
                                            type="button"
                                            color="primary"
                                            weight="font-regular"
                                            onClick={modalContent.buttons[0].event}
                                        >
                                            {modalContent.buttons[0].label}
                                        </Button>
                                    </div>
                                );
                            }
                        })()}
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default MemoViewHeaderStatus;
