import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { navigate } from '@reach/router';
import Preloader from '../../../layouts/preloaders/Preloader';
import { useDispatch } from 'react-redux';
import { User } from '../../../models/User';
import { setUser } from '../../../redux/AuthSlice';

interface ILoginRouteProps {
    title?: string;
    path?: RouteComponentProps;
    component: React.ComponentType;
}

const AdminLoginRoute: React.FunctionComponent<ILoginRouteProps> = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const [isReady, setIsReady] = useState(false);
    const location = useLocation();

    const updateUser = (user: User) => {
        const payload: User = {
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
            providerId: user.providerId,
            uid: user.uid,
        };
        dispatch(setUser(payload));
    };

    useEffect(() => {
        let page_title = 'Memo App (Beta)';

        if (rest.title) {
            page_title = 'Login | Memo App (Beta)';
            document.title = page_title;
            firebase.analytics().setCurrentScreen(location.pathname);
            firebase.analytics().logEvent('page_view', { page_path: location.pathname, page_title: rest.title });
        } else {
            document.title = page_title;
        }

        firebase.analytics().setCurrentScreen(location.pathname);
        firebase.analytics().logEvent('page_view', { page_path: location.pathname });
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                setIsReady(true);
            } else {
                updateUser(user);
                navigate('/categories');
            }
        });
    }, []);

    if (isReady) {
        return <Component {...rest} />;
    }
    return <Preloader />;
};

AdminLoginRoute.propTypes = {
    component: PropTypes.any,
};

export default AdminLoginRoute;
