import moment from 'moment';
import 'moment-timezone';

export const getOffsetISOString = (timestamp: string | undefined) => {
    return moment(timestamp).add(8, 'hours').toISOString();
};

export const getDateString = (timestamp: string | undefined, format = 'MMM DD, YYYY hh:mm A') => {
    return moment(timestamp).zone('+00:00').format(format).toString();
};

export const getDaysElapsed = (timestamp: string | undefined) => {
    const today = new Date().toISOString();
    const date = moment(timestamp).zone('+00:00').format('YYYY/MM/DD');
    const days_elapsed = moment(today).diff(date, 'days');
    return days_elapsed;
};

export const parseDate = (date: Date, format = 'default') => {
    if (format === 'ISO') {
        return moment(date).tz('Asia/Manila').toISOString();
    }
    return moment(date).tz('Asia/Manila').format('MM/DD/YYYY');
};

export const parseTime = (date: Date, format = 'default') => {
    if (format === 'ISO') {
        return moment(date).tz('Asia/Manila').toISOString();
    }
    return moment(date).tz('Asia/Manila').format('hh:mm a');
};
