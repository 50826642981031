import DOMPurify from 'dompurify';

export const sanitizeHtmlString = (str: string) => {
    DOMPurify.addHook('afterSanitizeAttributes', (node: any) => {
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    return DOMPurify.sanitize(str);
};
