import * as React from 'react';
import { User } from '../../models/User';
import NavItemLayout, { NavItemLayoutProps } from './NavItemLayout';

export interface INavProps extends React.ComponentPropsWithoutRef<'nav'> {
    user: User;
    logout: () => void;
    links: NavItemLayoutProps[];
    children?: React.ReactNode;
}

function Nav(props: INavProps) {
    return (
        <nav
            style={{ zIndex: 9999 }}
            className="bg-blue-dark inline-flex flex-col w-full h-screen md:h-full"
            role="navigation"
        >
            <hr className="h-lg bg-transparent visible md:hidden border-none" />
            <div className="w-full md:w-11/12 flex flex-col md:flex-row items-center h-auto mx-auto px-2 md:px-8">
                <NavItemLayout items={props.links} />
                <div className="w-full md:w-1/6 flex flex-col">{props.children}</div>
            </div>
        </nav>
    );
}

export default Nav;
