import { Action, configureStore, getDefaultMiddleware, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import RootReducer, { RootState } from './RootReducer';

const Store = configureStore({
    reducer: RootReducer,
    middleware: [...getDefaultMiddleware({ immutableCheck: false })],
});

export type AppDispatch = typeof Store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export default Store;
