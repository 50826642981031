import * as React from 'react';
import PropTypes from 'prop-types';
import NavItem from './NavItem';

export interface NavItemLayoutProps {
    label: string;
    link: string;
}

interface INavItemLayoutProps {
    items: NavItemLayoutProps[];
}

const NavItemLayout: React.FunctionComponent<INavItemLayoutProps> = (props) => {
    return (
        <div className="w-full px-0 md:w-5/6 h-full">
            <ol className="w-full h-auto flex flex-col md:h-full md:flex-row">
                {props.items.map((item, index) => {
                    return <NavItem key={index} label={item.label} link={item.link} />;
                })}
            </ol>
        </div>
    );
};

NavItemLayout.propTypes = {
    items: PropTypes.array.isRequired,
};

export default NavItemLayout;
