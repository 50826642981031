import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import ic_delete from '../../../../assets/img/ic_delete.svg';
import Input from '../../../../layouts/form/Input';
import TextArea from '../../../../layouts/form/Textarea';
import Select from '../../../../layouts/select/Select';
import Toggle from '../../../../layouts/toggle/Toggle';
import { TemplateCondition, TemplateConstant, TemplateContent } from '../../../../models/TemplateContent';
import { FormBuilderActiveField, FormBuilderError } from '../../../../redux/FormBuilderSlice';
import Store from '../../../../redux/Store';
import FormGridCellAnswerSelection from './FormGridCellAnswerSelection';
import FormGridCellNestedAnswerView from './FormGridCellNestedAnswerView';
import FormGridCellQuestion from './FormGridCellQuestion';

interface IFormGridCellAnswerViewProps {
    formItem: FormBuilderActiveField;
    onFormFieldChanged: (field: string, value: any) => void;
    onSectionChanged?: (sectionIndex: number, value: any) => void;
    errors?: FormBuilderError[] | null;
    hideAdvancedFields?: boolean;
}

const FormGridCellAnswerView = (props: IFormGridCellAnswerViewProps) => {
    const onChange = (e: React.ChangeEvent<any>) => {
        const value = e.currentTarget.value;
        const field = e.currentTarget.dataset.field;
        props.onFormFieldChanged(field, value);
    };

    /**
     * Adds options depending on the form field type.
     * - Adds checkboxes if the field type is CHECKBOX
     * - Adds radio buttons if the field type is RADIO
     * - Adds options if the field type is DROPDOWN
     */
    const addFormConstant = () => {
        // A new copy of the constants.
        const newConstants = [
            ...Array.from(props.formItem.constant as any[]),
            {
                label: 'New option',
                value: nanoid(),
            },
        ];
        props.onFormFieldChanged('constant', newConstants);
    };

    /**
     * Removes a constant from the list of form field's constant.
     * @param e
     */
    const removeFormConstant = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (Array.isArray(props.formItem.constant)) {
            const constants = _.clone(props.formItem.constant);
            const index = Number(e.currentTarget.dataset.index);
            constants.splice(index, 1);
            props.onFormFieldChanged('constant', constants);
        }
    };

    /**
     * Change event handler for toggling limited selection of file types.
     * @param toggled
     */
    const toggleFileTypes = (toggled: boolean) => {
        const field = 'validations.allowed_limited_file_types';
        const file_types = _.get(props.formItem, field);
        if (toggled) {
            // get the checked checkboxes
            // and assign them to file_types field
            props.onFormFieldChanged(field, true);
        } else {
            // else, update the file_types array to be an empty array
            props.onFormFieldChanged('validations.file_types', []);
            props.onFormFieldChanged(field, false);
        }
    };

    /**
     * Returns an array of file types depending on the checked options.
     * @param type
     * @returns
     */
    const getFileTypes = (type: string) => {
        // TODO: Move the constant values below to Remote Config.
        switch (type) {
            case 'DOCUMENTS':
                return ['doc', 'docx'];
            case 'PRESENTATION':
                return ['ppt', 'pptx'];
            case 'SPREADSHEETS':
                return ['xls', 'xlsx'];
            case 'PDF':
                return ['pdf'];
            case 'IMAGE':
                return ['jpeg', 'jpg', 'png', 'gif'];
            case 'COMPRESSED':
                return ['zip', 'rar'];
        }
    };

    /**
     * Change event handler for checking file type checkboxes.
     * @param e
     * @returns
     */
    const fileTypeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file_types = getFileTypes(e.currentTarget.value) as any[];
        const current_file_types = _.cloneDeep(props.formItem.validations.file_types) as string[];
        if (e.currentTarget.checked) {
            // add
            file_types.forEach((type: string) => {
                const typeIndex = _.findIndex(current_file_types, (t) => t === type);
                if (typeIndex !== -1) {
                    _.set(current_file_types, typeIndex, type);
                    props.onFormFieldChanged('validations.file_types', current_file_types);
                } else {
                    props.onFormFieldChanged('validations.file_types', [...current_file_types, ...file_types]);
                }
            });
            return;
        }
        // remove
        props.onFormFieldChanged(
            'validations.file_types',
            _.filter(current_file_types, (type) => !file_types.includes(type)),
        );
    };

    const setBranchingQuestion = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const id = nanoid();
        const constant: TemplateConstant = {
            ..._.cloneDeep(_.get(props.formItem.constant, index)),
        };

        if (e.currentTarget.value === 'ADD_BRANCH') {
            /**
             * Remove the hook to the section
             */

            const hookedSection = constant.branchingSection;
            if (hookedSection) {
                const section = _.find(
                    Store.getState().FormBuilder.template_content,
                    (content) => content.field_id === hookedSection.field_id,
                );

                if (section && props.onSectionChanged) {
                    props.onSectionChanged(section.sectionIndex, {
                        ...section,
                        conditions: null,
                    });
                }
            }

            /**
             * Adds a default branching question
             */
            props.onFormFieldChanged(`constant.${index}`, {
                ...constant,
                branchingSection: null,
                branchingQuestions: [
                    {
                        field_type: 'TEXT',
                        field_name: 'Untitled',
                        field_id: id,
                        annotation: '',
                        placeholder: '',
                        validations: {
                            required: false,
                            max_length: 300,
                        },
                        sectionIndex: props.formItem.sectionIndex,
                        sectionId: props.formItem.sectionId,
                    },
                ],
            });
        } else {
            /**
             * 1. Remove the branching questions for the constant.
             * 2. Add the section information to the branching section.`
             * 3. Add a condition to the section selected.
             */
            const section = _.find(
                Store.getState().FormBuilder.template_content,
                (content) => content.field_id === e.currentTarget.value,
            );

            if (!section) return;

            /**
             * Removes branching questions and set a branching section
             */
            props.onFormFieldChanged(`constant.${index}`, {
                ...constant,
                branchingQuestions: null,
                branchingSection: {
                    field_id: section.field_id,
                    field_type: section.field_type,
                    sectionId: section.field_id,
                    sectionIndex: section.sectionIndex,
                },
            });

            /**
             * Runs only the function for handling
             * section changes is passed.
             */
            if (props.onSectionChanged) {
                /**
                 * Check first if the section's conditions has already the same ref.
                 */
                const fieldRef = `${props.formItem.sectionIndex}.value.${index}.value`;
                let sectionConditions = section.conditions;

                const condition: TemplateCondition = {
                    field_ref: fieldRef,
                    operator: 'EQUALS',
                    value: constant.value as string,
                };

                if (sectionConditions && sectionConditions.length) {
                    const hasSameRef = _.find(sectionConditions, (condition) => condition.field_ref === fieldRef);

                    /**
                     * Only add the condition if it is not already present
                     * in the list of condition.
                     */
                    if (!hasSameRef) {
                        sectionConditions = [...sectionConditions, condition];
                    }
                } else {
                    sectionConditions = [condition];
                }

                console.log(sectionConditions);

                /**
                 * Updates the targeted section to have a condition data
                 */
                props.onSectionChanged(section.sectionIndex, {
                    ...section,
                    conditions: sectionConditions,
                });
            }
        }
    };

    const addBranchingQuestion = (index: number) => {
        const id = nanoid();
        const path = `constant.${index}.branchingQuestions`;
        const data = _.cloneDeep(_.get(props.formItem, path));
        const value = [
            ...data,
            {
                field_type: 'TEXT',
                field_name: 'Untitled',
                field_id: id,
                annotation: '',
                placeholder: '',
                validations: {
                    required: false,
                    max_length: 300,
                },
                sectionIndex: props.formItem.sectionIndex,
                sectionId: props.formItem.sectionId,
            },
        ];

        props.onFormFieldChanged(path, value);
    };

    const removeBranchingQuestion = (index: number, questionIndex: number) => {
        const path = `constant.${index}.branchingQuestions`;
        const questions = _.get(props.formItem, path);
        if (Array.isArray(questions)) {
            const constants = _.cloneDeep(questions);
            constants.splice(questionIndex, 1);
            props.onFormFieldChanged(path, constants);
        }
    };

    const getSections = () => {
        return _.map(
            _.filter(
                Store.getState().FormBuilder.template_content,
                (section) => section.sectionIndex !== props.formItem.sectionIndex,
            ),
            (content) => ({
                sectionIndex: content.sectionIndex,
                field_id: content.field_id,
                field_name: content.field_name,
            }),
        );
    };

    switch (props.formItem.field_type) {
        case 'TEXT':
            return (
                <>
                    {!props.hideAdvancedFields && (
                        <div
                            style={{ border: '0.25px solid #afafaf' }}
                            className="w-full p-3 border border-gray-light rounded mt-6"
                        >
                            <h4 className="text-sm font-bold">Advanced fields</h4>
                            <div className="w-full h-auto py-2">
                                <TextArea
                                    id="panel-field-description"
                                    label=""
                                    annotation="Field description (optional)"
                                    placeholder="This is your field description"
                                    value={props.formItem.annotation}
                                    onChange={onChange}
                                    data-field="annotation"
                                    rows={4}
                                />
                            </div>
                            <div className="w-full h-auto py-2">
                                <Input
                                    id="panel-field-placeholder"
                                    label=""
                                    annotation="Placeholder (optional)"
                                    type="text"
                                    placeholder="This is your placeholder"
                                    value={props.formItem.placeholder}
                                    onChange={onChange}
                                    data-field="placeholder"
                                />
                            </div>
                            <div className="w-full h-auto flex flex-row py-2">
                                <div className="w-full h-auto block mr-4">
                                    <Input
                                        id="panel-field-min-char"
                                        label=""
                                        annotation="Minimum characters"
                                        type="number"
                                        placeholder="0"
                                        min={0}
                                        value={Number(props.formItem.validations.min_length)}
                                        onChange={onChange}
                                        data-field="validations.min_length"
                                    />
                                    <p className="text-xs font-regular text-red">
                                        {props.errors?.find(
                                            (error) => error.form_item.id === props.formItem.field_id,
                                        ) &&
                                            props.errors?.find(
                                                (error) => error.form_item.id === props.formItem.field_id,
                                            )?.form_item.message}
                                    </p>
                                </div>
                                <div className="w-full h-auto block">
                                    <Input
                                        id="panel-field-max-char"
                                        label=""
                                        annotation="Maximum characters"
                                        type="number"
                                        placeholder="0"
                                        min={0}
                                        value={Number(props.formItem.validations.max_length)}
                                        onChange={onChange}
                                        data-field="validations.max_length"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            );
        case 'TEXT_CURRENCY':
            return (
                <>
                    {!props.hideAdvancedFields && (
                        <div
                            style={{ border: '0.25px solid #afafaf' }}
                            className="w-full p-3 border border-gray-light rounded mt-6"
                        >
                            <h4 className="text-sm font-bold">Advanced fields</h4>
                            <div className="w-full h-auto py-2">
                                <TextArea
                                    id="panel-field-description"
                                    label=""
                                    annotation="Field description (optional)"
                                    placeholder="This is your field description"
                                    value={props.formItem.annotation}
                                    onChange={onChange}
                                    data-field="annotation"
                                    rows={4}
                                />
                            </div>
                            <div className="w-full h-auto py-2">
                                <Input
                                    id="panel-field-placeholder"
                                    label=""
                                    annotation="Placeholder (optional)"
                                    type="text"
                                    placeholder="This is your placeholder"
                                    value={props.formItem.placeholder}
                                    onChange={onChange}
                                    data-field="placeholder"
                                />
                            </div>
                        </div>
                    )}
                </>
            );
        case 'CHECKBOX':
            return (
                <>
                    {/* The items being rendered in the cell */}
                    <div className="w-full h-auto flex flex-col py-2">
                        {_.map(props.formItem.constant, (constant: any, index: number) => (
                            <div
                                key={constant.value}
                                className="w-full h-auto flex flex-row"
                                data-testid="form-builder-multi-option"
                            >
                                {/* The icon for deletion */}
                                <div className="w-5 flex items-center justify-center">
                                    {props.formItem.constant && (props.formItem.constant as any[]).length > 1 && (
                                        <button
                                            className="w-3 h-3 p-2 flex items-center justify-center text-blue font-bold"
                                            onClick={removeFormConstant}
                                            type="button"
                                            data-index={index}
                                            data-testid="form-builder-constant-remove"
                                        >
                                            &times;
                                        </button>
                                    )}
                                </div>

                                {/* The content of the constant */}
                                <div className="w-full ml-1">
                                    <div className="w-1/2 h-auto block mr-4">
                                        {/* Input field for constant's label */}
                                        <Input
                                            id="panel-field-min-char"
                                            label=""
                                            annotation=""
                                            type="text"
                                            onChange={onChange}
                                            value={constant.label}
                                            data-field={`constant[${index}].label`}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addFormConstant}
                            className="w-auto h-auto p-2 block self-start text-sm text-blue font-regular underline"
                            data-testid="form-builder-constant-add"
                        >
                            + Add option
                        </button>
                    </div>

                    {/* The Advanced field section */}
                    <div
                        style={{ border: '0.25px solid #afafaf' }}
                        className="w-full p-3 border border-gray-light rounded mt-6"
                    >
                        <h4 className="text-sm font-bold">Advanced fields</h4>
                        <div className="w-full h-auto py-2">
                            <TextArea
                                id="panel-field-description"
                                label=""
                                annotation="Field description (optional)"
                                placeholder="This is your field description"
                                value={props.formItem.annotation}
                                onChange={onChange}
                                data-field="annotation"
                                rows={4}
                            />
                        </div>
                        <div className="w-full h-auto py-2">
                            <Input
                                id="panel-field-placeholder"
                                label=""
                                annotation="Placeholder (optional)"
                                type="text"
                                placeholder="This is your placeholder"
                                value={props.formItem.placeholder}
                                onChange={onChange}
                                data-field="placeholder"
                            />
                        </div>
                    </div>
                </>
            );
        case 'RADIO':
        case 'DROPDOWN':
            return (
                <>
                    {/* The items being rendered in the cell */}
                    <div className="w-full h-auto flex flex-col py-2">
                        {_.map(props.formItem.constant, (constant: TemplateConstant, index: number) => (
                            <div
                                key={constant.value as string}
                                className="w-full h-auto flex flex-row items-start"
                                data-testid="form-builder-multi-option"
                            >
                                {/* The icon for deletion */}
                                <div className="w-5 h-11 flex items-center justify-center">
                                    {props.formItem.constant && (props.formItem.constant as any[]).length > 1 && (
                                        <button
                                            className="w-3 h-3 p-2 flex items-center justify-center text-blue font-bold"
                                            onClick={removeFormConstant}
                                            type="button"
                                            data-index={index}
                                            data-testid="form-builder-constant-remove"
                                        >
                                            &times;
                                        </button>
                                    )}
                                </div>

                                {/* The content of the constant */}
                                <div className="w-full ml-1 pr-6">
                                    <div className="w-full h-auto flex flex-row">
                                        <div className="w-1/2 h-auto block">
                                            {/* Input field for constant's label */}
                                            <Input
                                                id="panel-field-min-char"
                                                label=""
                                                annotation=""
                                                type="text"
                                                onChange={onChange}
                                                value={constant.label}
                                                data-field={`constant[${index}].label`}
                                            />
                                        </div>
                                        {(() => {
                                            if (
                                                props.formItem.hasBranchingQuestions ||
                                                constant.branchingQuestions ||
                                                constant.branchingSection
                                            ) {
                                                return (
                                                    <div className="w-1/2 h-auto block pl-2">
                                                        <Select
                                                            id={`branching-${constant.label}`}
                                                            onChange={(e) => setBranchingQuestion(e, index)}
                                                            defaultValue={(() => {
                                                                if (constant.branchingQuestions) return 'ADD_BRANCH';
                                                                else if (constant.branchingSection) {
                                                                    return constant.branchingSection.field_id;
                                                                }
                                                            })()}
                                                        >
                                                            <option value="" disabled selected hidden>
                                                                Choose
                                                            </option>
                                                            <option value="ADD_BRANCH">Add a follow up question</option>
                                                            {getSections().map((section) => (
                                                                <option key={section.field_id} value={section.field_id}>
                                                                    Jump to {section.field_name}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </div>
                                                );
                                            }
                                        })()}
                                    </div>

                                    {/* The branch question perhaps */}

                                    {constant.branchingQuestions && (
                                        <div
                                            className="w-full bg-blue-light mt-2 mb-6 px-4 pt-4 pb-6"
                                            style={{ borderRadius: '5px' }}
                                        >
                                            {_.map(constant.branchingQuestions, (question, questionIndex) => (
                                                <div className="w-full h-auto mb-12 relative">
                                                    {constant.branchingQuestions!.length > 1 && (
                                                        <button
                                                            type="button"
                                                            className="w-auto h-auto absolute top-2 right-0"
                                                            onClick={() =>
                                                                removeBranchingQuestion(index, questionIndex)
                                                            }
                                                        >
                                                            <img
                                                                className="w-4 h-4"
                                                                src={ic_delete}
                                                                alt="Delete question"
                                                            />
                                                        </button>
                                                    )}
                                                    <FormGridCellQuestion
                                                        formItem={question}
                                                        onQuestionChanged={(e) => {
                                                            const path = `constant.${index}.branchingQuestions.${questionIndex}.field_name`;
                                                            props.onFormFieldChanged(path, e.currentTarget.value);
                                                        }}
                                                        onQuestionRequiredChanged={() => {
                                                            const path = `constant.${index}.branchingQuestions.${questionIndex}.validations.required`;
                                                            const data = _.cloneDeep(props.formItem);
                                                            const value = _.get(data, path);

                                                            props.onFormFieldChanged(path, !value);
                                                        }}
                                                    />
                                                    <FormGridCellAnswerSelection
                                                        formItem={question}
                                                        fieldTypes={['TEXT', 'DROPDOWN', 'TEXT_CURRENCY']}
                                                        disableBranchingQuestions={true}
                                                        onAnswerTypeChanged={(e) => {
                                                            const path = `constant.${index}.branchingQuestions.${questionIndex}`;
                                                            const data = _.cloneDeep(_.get(props.formItem, path));
                                                            _.set(data, 'field_type', e.currentTarget.value);
                                                            switch (e.currentTarget.value) {
                                                                case 'DROPDOWN':
                                                                    if (!Array.isArray(data.constant)) {
                                                                        _.set(data, `constant`, []);
                                                                    }
                                                                    break;
                                                                default:
                                                                    _.set(data, `constant`, []);
                                                            }
                                                            props.onFormFieldChanged(path, data);
                                                        }}
                                                    />

                                                    <FormGridCellAnswerView
                                                        formItem={_.get(
                                                            props.formItem,
                                                            `constant.${index}.branchingQuestions.${questionIndex}`,
                                                        )}
                                                        onFormFieldChanged={(field, value) => {
                                                            const path = `constant.${index}.branchingQuestions.${questionIndex}.${field}`;
                                                            props.onFormFieldChanged(path, value);
                                                        }}
                                                        hideAdvancedFields={true}
                                                    />
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                onClick={() => addBranchingQuestion(index)}
                                                className="w-auto h-auto p-2 block self-start text-sm text-blue font-regular underline mt-6"
                                                data-testid="form-builder-constant-add"
                                            >
                                                + Add a question
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addFormConstant}
                            className="w-auto h-auto p-2 block self-start text-sm text-blue font-regular underline"
                            data-testid="form-builder-constant-add"
                        >
                            + Add option
                        </button>
                    </div>

                    {/* The Advanced field section */}
                    {!props.hideAdvancedFields && (
                        <div
                            style={{ border: '0.25px solid #afafaf' }}
                            className="w-full p-3 border border-gray-light rounded mt-6"
                        >
                            <h4 className="text-sm font-bold">Advanced fields</h4>
                            <div className="w-full h-auto py-2">
                                <TextArea
                                    id="panel-field-description"
                                    label=""
                                    annotation="Field description (optional)"
                                    placeholder="This is your field description"
                                    value={props.formItem.annotation}
                                    onChange={onChange}
                                    data-field="annotation"
                                    rows={4}
                                />
                            </div>
                            <div className="w-full h-auto py-2">
                                <Input
                                    id="panel-field-placeholder"
                                    label=""
                                    annotation="Placeholder (optional)"
                                    type="text"
                                    placeholder="This is your placeholder"
                                    value={props.formItem.placeholder}
                                    onChange={onChange}
                                    data-field="placeholder"
                                />
                            </div>
                        </div>
                    )}
                </>
            );
        case 'FILE_UPLOAD':
            return (
                <>
                    <div
                        style={{ border: '0.25px solid #afafaf' }}
                        className="w-full p-3 border border-gray-light rounded mt-6"
                    >
                        <h4 className="text-sm font-bold">Advanced fields</h4>
                        <div className="w-full h-auto py-2">
                            <TextArea
                                id="panel-field-description"
                                label=""
                                annotation="Field description (optional)"
                                placeholder="This is your field description"
                                value={props.formItem.annotation}
                                onChange={onChange}
                                data-field="annotation"
                                rows={4}
                            />
                        </div>
                        <div className="w-full h-auto py-2">
                            <Input
                                id="panel-field-placeholder"
                                label=""
                                annotation="Placeholder (optional)"
                                type="text"
                                placeholder="This is your placeholder"
                                value={props.formItem.placeholder}
                                onChange={onChange}
                                data-field="placeholder"
                            />
                        </div>
                        <div className="w-full h-auto py-4 flex flex-row items-center justify-between">
                            <p className="w-full block break-words text-sm font-regular">
                                Allow only specific file types
                            </p>
                            <Toggle
                                onToggle={toggleFileTypes}
                                state={props.formItem.validations.allowed_limited_file_types as boolean}
                            />
                        </div>
                        <div className="w-full h-auto grid grid-cols-2 gap-1 px-2">
                            <Input
                                id="panel-field-upload-types-document"
                                label="Document (doc, docx)"
                                type="checkbox"
                                value="DOCUMENTS"
                                disabled={!props.formItem.validations.allowed_limited_file_types as boolean}
                                defaultChecked={
                                    _.difference(
                                        getFileTypes('DOCUMENTS'),
                                        props.formItem.validations.file_types as string[],
                                    ).length === 0
                                }
                                onChange={fileTypeChecked}
                                data-testid="file-upload-file-type-checkbox"
                            />
                            <Input
                                id="panel-field-upload-types-presentation"
                                label="Presentation (ppt, pptx)"
                                type="checkbox"
                                value="PRESENTATION"
                                disabled={!props.formItem.validations.allowed_limited_file_types as boolean}
                                defaultChecked={
                                    _.difference(
                                        getFileTypes('PRESENTATION'),
                                        props.formItem.validations.file_types as string[],
                                    ).length === 0
                                }
                                onChange={fileTypeChecked}
                                data-testid="file-upload-file-type-checkbox"
                            />
                            <Input
                                id="panel-field-upload-types-spreadsheet"
                                label="Spreadsheets (xls, xlsx)"
                                type="checkbox"
                                value="SPREADSHEETS"
                                disabled={!props.formItem.validations.allowed_limited_file_types as boolean}
                                defaultChecked={
                                    _.difference(
                                        getFileTypes('SPREADSHEETS'),
                                        props.formItem.validations.file_types as string[],
                                    ).length === 0
                                }
                                onChange={fileTypeChecked}
                                data-testid="file-upload-file-type-checkbox"
                            />
                            <Input
                                id="panel-field-upload-types-pdf"
                                label="PDF (pdf)"
                                type="checkbox"
                                value="PDF"
                                disabled={!props.formItem.validations.allowed_limited_file_types as boolean}
                                defaultChecked={
                                    _.difference(getFileTypes('PDF'), props.formItem.validations.file_types as string[])
                                        .length === 0
                                }
                                onChange={fileTypeChecked}
                                data-testid="file-upload-file-type-checkbox"
                            />
                            <Input
                                id="panel-field-upload-types-image"
                                label="Image (jpeg/jpg, png, gif)"
                                type="checkbox"
                                value="IMAGE"
                                disabled={!props.formItem.validations.allowed_limited_file_types as boolean}
                                defaultChecked={
                                    _.difference(
                                        getFileTypes('IMAGE'),
                                        props.formItem.validations.file_types as string[],
                                    ).length === 0
                                }
                                onChange={fileTypeChecked}
                                data-testid="file-upload-file-type-checkbox"
                            />
                            <Input
                                id="panel-field-upload-types-image"
                                label="Compressed (zip, rar)"
                                type="checkbox"
                                value="COMPRESSED"
                                disabled={!props.formItem.validations.allowed_limited_file_types as boolean}
                                defaultChecked={
                                    _.difference(
                                        getFileTypes('COMPRESSED'),
                                        props.formItem.validations.file_types as string[],
                                    ).length === 0
                                }
                                onChange={fileTypeChecked}
                                data-testid="file-upload-file-type-checkbox"
                            />
                            <p className="text-xs font-regular text-red mt-1">
                                {props.errors?.find((error) => error.form_item.id === props.formItem.field_id) &&
                                    props.errors?.find((error) => error.form_item.id === props.formItem.field_id)
                                        ?.form_item.message}
                            </p>
                        </div>
                    </div>
                </>
            );
        case 'TEXT_RTE':
        case 'TEXT_RTE_PREFILLED':
        case 'DATEPICKER':
        case 'TIMEPICKER':
            return (
                <>
                    <div
                        style={{ border: '0.25px solid #afafaf' }}
                        className="w-full p-3 border border-gray-light rounded mt-6"
                    >
                        <h4 className="text-sm font-bold">Advanced fields</h4>
                        <div className="w-full h-auto py-2">
                            <TextArea
                                id="panel-field-description"
                                label=""
                                annotation="Field description (optional)"
                                placeholder="This is your field description"
                                value={props.formItem.annotation}
                                onChange={onChange}
                                data-field="annotation"
                                rows={4}
                            />
                        </div>
                        <div className="w-full h-auto py-2">
                            <Input
                                id="panel-field-placeholder"
                                label=""
                                annotation="Placeholder (optional)"
                                type="text"
                                placeholder="This is your placeholder"
                                value={props.formItem.placeholder}
                                onChange={onChange}
                                data-field="placeholder"
                            />
                        </div>
                    </div>
                </>
            );
        case 'NESTED':
            return (
                <FormGridCellNestedAnswerView
                    formItem={props.formItem}
                    errors={props.errors}
                    onFormFieldChanged={props.onFormFieldChanged}
                />
            );
        default:
            return (
                <>
                    <p className="text-sm font-bold">There are no controls set up for this form item.</p>
                </>
            );
    }
};

export default FormGridCellAnswerView;
