import React from 'react';
import { useSelector } from 'react-redux';
import Preloader from '../../../layouts/preloaders/Preloader';
import Title from '../../../layouts/typography/Title';
import { RootState } from '../../../redux/RootReducer';
import { getDateString } from '../../../utils/date/Date';
import MemoViewHeaderStatus from './MemoViewHeaderStatus';
import MemoViewSubtitle from './MemoViewHeaderSubtitle';
import MemoViewTitle from './MemoViewHeaderTitle';

interface IMemoViewHeaderProps {
    children?: React.ReactNode;
}

const MemoViewHeader = (props: IMemoViewHeaderProps) => {
    const { state, memo } = useSelector((state: RootState) => state.MemoView);
    switch (state) {
        case 'pending':
            return (
                <div className="w-full md:w-2/3 px-4" id="page-header">
                    <Preloader type="dots" />
                </div>
            );
        case 'fulfilled':
            return (
                <div className="w-full md:w-2/3 px-4" id="page-header">
                    <MemoViewTitle title={memo.memo_subject} />
                    <MemoViewSubtitle timestamp={getDateString(memo.date_submitted)} />
                    <MemoViewHeaderStatus />
                    {props.children}
                </div>
            );
        case 'rejected':
            return <Title>Error :(</Title>;
        default:
            return null;
    }
};

export default MemoViewHeader;
