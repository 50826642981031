import React from 'react';
import Input from '../../../../layouts/form/Input';
import Toggle from '../../../../layouts/toggle/Toggle';
import { TemplateContent } from '../../../../models/TemplateContent';

interface IFormGridCellQuestionProps {
    formItem: TemplateContent;
    onQuestionChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onQuestionRequiredChanged: () => void;
}
const FormGridCellQuestion = (props: IFormGridCellQuestionProps) => {
    return (
        <div className="w-full h-auto py-2">
            <Input
                id="panel-field-label"
                label=""
                annotation="Question"
                type="text"
                placeholder="Enter question"
                value={props.formItem.field_name}
                onChange={props.onQuestionChanged}
                data-field="field_name"
                data-testid="text-field-name"
            />
            <div className="w-auto h-auto flex flex-row justify-end mt-2">
                <p className="text-xs font-regular mr-2">Required</p>
                <Toggle
                    onToggle={props.onQuestionRequiredChanged}
                    state={props.formItem.validations.required as boolean}
                />
            </div>
        </div>
    );
};

export default FormGridCellQuestion;
