import { navigate, useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import ic_alert from '../../../assets/img/ic_alert.svg';
import ic_check from '../../../assets/img/ic_check.svg';
import Breadcrumbs, { BreadcrumbsItem } from '../../../layouts/breadcrumbs/Breadcrumbs';
import Button from '../../../layouts/buttons/Button';
import Container from '../../../layouts/container/Container';
import Input from '../../../layouts/form/Input';
import Modal from '../../../layouts/modals/Modal';
import Subtitle from '../../../layouts/typography/Subtitle';
import Text from '../../../layouts/typography/Text';
import Title from '../../../layouts/typography/Title';
import { Category } from '../../../models/Category';
import { ModalContent } from '../../../models/ModalContent';
import firebase from 'firebase/app';
import Preloader from '../../../layouts/preloaders/Preloader';

interface ICreateCategory {
    id?: string;
    path?: string;
    children?: React.ReactNode;
}

const CategoryForm: React.FunctionComponent<ICreateCategory> = (props) => {
    const [mode, setMode] = React.useState('CREATE');
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [modalContent, setModalContent] = React.useState<ModalContent>({
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    });
    const [category, setCategory] = React.useState<Partial<Category>>({
        category_id: props.id || '',
        category_name: '',
    });
    const { register, handleSubmit, errors } = useForm();
    const [requestState, setRequestState] = React.useState('idle');

    const fetch = async (functionName: string, payload: any) => {
        try {
            setRequestState('pending');
            const callableFunction = firebase.app().functions('asia-east2').httpsCallable(functionName);
            const httpResponse = await callableFunction(payload);
            setRequestState('fulfilled');
            return httpResponse.data;
        } catch (err) {
            setRequestState('rejected');
        }
    };

    React.useEffect(() => {
        if (props.id) {
            setMode('EDIT');
            const getCategories = async () => {
                const res = await fetch('admin-get_category_by_id', { category_id: props.id });
                if (res) {
                    setCategory(res);
                } else {
                    setRequestState('rejected');
                }
            };
            getCategories();
        } else {
            setMode('CREATE');
            setRequestState('fulfilled');
        }
    }, []);

    const getBreadCrumbsItems = (path?: string): BreadcrumbsItem[] => {
        switch (path) {
            case 'categories/edit/:id':
                return [
                    {
                        label: 'Category',
                        link: '/categories',
                    },
                    {
                        label: 'Edit category',
                        link: 'categories/edit/:id',
                    },
                ];
            default:
                return [
                    {
                        label: 'Category',
                        link: '/categories',
                    },
                    {
                        label: 'Create category',
                        link: '/categories/create',
                    },
                ];
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCategory({
            ...category,
            category_name: e.currentTarget.value,
        });
    };

    const onSubmit = async () => {
        setModalVisible(true);
        setModalContent({
            loading: true,
            title: '',
            subtitle: '',
            buttons: [
                {
                    label: '',
                    event: () => {
                        return;
                    },
                },
            ],
        });

        try {
            if (mode === 'EDIT') {
                await fetch('admin-edit_category', category);
            } else {
                await fetch('admin-create_category', category);
            }

            setModalContent({
                loading: false,
                icon: ic_check,
                title: `${mode === 'EDIT' ? 'The category has been edited' : 'A new category has been added'}`,
                subtitle: `${
                    mode === 'EDIT'
                        ? `${category.category_name} has been edited`
                        : `${category.category_name} has been added to the list`
                }`,
                buttons: [
                    {
                        label: 'Okay, got it!',
                        event: () => {
                            setModalVisible(false);
                            navigate('/categories');
                        },
                    },
                ],
            });
        } catch (err) {
            setModalContent({
                loading: false,
                icon: ic_alert,
                title: 'Oooops, an error occurred',
                subtitle: 'Please try again later',
                buttons: [
                    {
                        label: 'Okay',
                        event: () => {
                            setModalVisible(false);
                        },
                    },
                ],
            });
        }
    };

    const cancel = () => {
        navigate('/categories');
    };

    switch (requestState) {
        case 'pending':
            return (
                <Container>
                    <Preloader type="dots" />
                </Container>
            );
        case 'fulfilled':
            return (
                <Container>
                    <div className="w-full mb-8 px-4" id="breadcrumbs">
                        <Breadcrumbs items={getBreadCrumbsItems(props.path)} />
                    </div>
                    <div className="w-full md:w-2/3 px-4" id="page-header">
                        <Title>{mode === 'EDIT' ? 'Edit category' : 'Create category'}</Title>
                        <div className="my-2">
                            <Subtitle>
                                Please fill out the form below to{' '}
                                {mode === 'EDIT' ? 'edit a category' : 'add a new category'}
                            </Subtitle>
                        </div>
                    </div>
                    <div
                        className="w-full my-2 px-1 md:px-4 flex flex-col-reverse md:flex-row justify-between mt-6"
                        id="page-content"
                    >
                        <div className="w-full md:w-2/3 mr-4" id="left-column">
                            <fieldset disabled={isModalVisible}>
                                <form
                                    aria-label="Create category form"
                                    onSubmit={handleSubmit(onSubmit)}
                                    data-testid="create-category"
                                >
                                    <div className="w-full bg-white p-8">
                                        <div className="w-full mb-4">
                                            <Title type="h4">General Information</Title>
                                            <div className="wfull md:w-2/3 flex flex-col mt-2">
                                                <Input
                                                    type="text"
                                                    label="Category name"
                                                    id="category_name"
                                                    placeholder="Category name"
                                                    onChange={onChange}
                                                    ref={register({
                                                        required: {
                                                            value: true,
                                                            message: 'This field is required.',
                                                        },
                                                    })}
                                                    errors={errors}
                                                    maxLength={150}
                                                    defaultValue={category ? category.category_name : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex flex-col-reverse justify-end md:flex-row items-center my-8">
                                        <Button type="button" weight="font-regular" onClick={cancel}>
                                            <u>Cancel</u>
                                        </Button>
                                        <div className="w-full flex flex-col md:w-auto md:flex-row">
                                            <Button color="primary" id="submit" weight="font-regular" value="submit">
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </fieldset>
                        </div>
                    </div>

                    <Modal visible={isModalVisible}>
                        {modalContent.loading ? (
                            <div className="w-full flex flex-col items-center justify-center">
                                <Text>Loading...</Text>
                            </div>
                        ) : (
                            <div className="w-full flex flex-col items-center">
                                <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                                <Title type="h4">{modalContent.title}</Title>
                                <div className="my-2">
                                    <Text>{modalContent.subtitle}</Text>
                                </div>
                                <div className="my-2">
                                    <Button
                                        type="button"
                                        color="primary"
                                        weight="font-regular"
                                        onClick={modalContent.buttons[0].event}
                                    >
                                        {modalContent.buttons[0].label}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Modal>
                </Container>
            );
        case 'rejected':
            return (
                <Container>
                    <Title>Error :(</Title>
                    <Subtitle>We cannot get the specified resource.</Subtitle>
                </Container>
            );
        default:
            return null;
    }
};

CategoryForm.propTypes = {
    id: PropTypes.string,
    path: PropTypes.string,
    children: PropTypes.node,
};

export default CategoryForm;
