import React from 'react';

interface IMemoViewColumn extends React.ComponentPropsWithoutRef<'div'> {
    width?: string;
    children?: React.ReactNode;
}

const MemoViewColumn = (props: IMemoViewColumn) => {
    return <div className={`${props.width ? props.width : 'w-full'} flex flex-col`}>{props.children}</div>;
};

export default MemoViewColumn;
