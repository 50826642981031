import _ from 'lodash';
import React from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateContent } from '../../../../models/TemplateContent';
import { updateSectionMetadata } from '../../../../redux/FormBuilderSlice';
import { RootState } from '../../../../redux/RootReducer';
import { AppDispatch } from '../../../../redux/Store';
import FormGridCell from './FormGridCell';
import FormGridCellPanel from './FormGridCellPanel';

/**
 * Recreates a react-grid-layout component that is wrapped on a WidthProvider HOC.
 * This component automatically recomputes DOM width based on resize event
 *
 * The native react-grid-layout only supports floating point values. This higher order function
 * automatically sets the breakpoints depending on the available device width.
 */
const ResponsiveLayout = WidthProvider(Responsive);

/**
 * An object that contains the column values per breakpoint. This is required for implementation that uses `data-grid` property
 * inside the react-grid-layout component.
 *
 * The component will fail to render if this object missing for `data-grid` elements.
 */
const columns = { lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 };

interface ITemplateSectionBuilderFormProps extends TemplateContent {
    /**
     * A unique UUID identifier that for a form item.
     */
    field_id: string;

    /**
     * Indicates if the section or the grid is currently being focused.
     */
    focused: boolean;

    /*
     * Required. Describes if there are any validations required for the grid.
     */
    validations: any;
}

/**
 * Returns a Form Grid.
 * @param props
 * @returns {JSX.Element}
 */
const FormGrid = (props: ITemplateSectionBuilderFormProps) => {
    // Connect to Redux object
    const { template_content, _editing } = useSelector((state: RootState) => state.FormBuilder);
    // Create a dispatch object for dispatching Redux reducers.
    const dispatch: AppDispatch = useDispatch();
    // Get the initial value for the section's grid.
    const formItems: TemplateContent[] = template_content[props.sectionIndex].value;
    // // Hook for computing cell height
    // const { computeLayout } = useCellHeightAdjuster([], null);

    // Create a local copy of the layout.
    // Current under experimental mode. Ideally, this should be reflected on Redux as well.
    const [tempLayout, setTempLayout] = React.useState(template_content[props.sectionIndex]._metadata);

    /**
     * Computes the cells of the grid
     * @param layout
     */
    const computeLayout = (layout: Layout[]) => {
        // Returns an array of layouts per cell
        return layout.map((metadata: any) => {
            /**
             * Get the cell element
             */
            const element = document.getElementById(`cell-content-${metadata.i}`);

            // If there is an element for the specified cell id, compute the height.
            if (element) {
                // Get the height from the element itself.
                const { height } = element.getBoundingClientRect();

                /**
                 * Get the toolbar height inside the cell.
                 * The toolbar is located at the bottom of the cell with `copy` and `delete` buttons.
                 */
                const toolbarHeight = _editing && _editing.field_id === metadata.i ? 53 : 0;

                /**
                 * Computes the cell height
                 * The cell height can be computed by:
                 * cellHeight = Math.ceil((cellElementHeight + cellPadding + toolbarHeight - gridMargin ) / (rowHeight + gridMargin))
                 */
                const h = Math.ceil((height + 24 + toolbarHeight - 10) / (60 + 10));

                // Returns a new layout object with the calculated height
                return {
                    ...metadata,
                    h: h,
                    minH: h,
                    maxH: h,
                };
            } else {
                // Else, return the original layout.
                return metadata;
            }
        });
    };

    // On load of the Form Grid component, run the cell height calculations
    React.useEffect(() => {
        // Set a delay for 100ms to wait for the DOM to hoist the elements.
        setTimeout(() => {
            // Computes the layout and returns a layout object per breakproints
            const newLayouts = computeLayout(template_content[props.sectionIndex]._metadata);
            // Update the local copy.
            setTempLayout(newLayouts);
        }, 100);
    }, []);

    // When there are any updates from the cell, rerun the cell height calculations.
    React.useEffect(() => {
        // Set a delay for 100ms to wait for the DOM to hoist the elements.
        setTimeout(() => {
            // Computes the layout and returns a layout object per breakproints
            const newLayouts = computeLayout(template_content[props.sectionIndex]._metadata);
            // Update the local copy.
            setTempLayout(newLayouts);
        }, 100);
    }, [template_content[props.sectionIndex], _editing]);

    /**
     * Handler for the event when a user stops resizing or dragging the cell.
     * @param layout
     */
    const onLayoutChange = (layout: Layout[]) => {
        // Compute the layout
        const newLayout = computeLayout(layout);

        if (_.isEqual(newLayout, layout)) {
            // Update the Redux state.
            dispatch(
                updateSectionMetadata({
                    index: props.sectionIndex,
                    metadata: newLayout,
                }),
            );
        }
    };

    /**
     * Returns a cell element that contains a specified form field.
     * @param formItems
     * @returns
     */
    const generateDOM = (formItems: TemplateContent[]) => {
        return _.map(formItems, (item) => {
            return (
                <div key={item.field_id} className="w-full inline-block relative">
                    <FormGridCell
                        key={item.field_id}
                        item={item}
                        focused={props.focused}
                        sectionIndex={props.sectionIndex}
                        sectionId={template_content[props.sectionIndex].field_id}
                    >
                        <FormGridCellPanel
                            sectionId={item.sectionId as string}
                            sectionIndex={item.sectionIndex}
                            formItem={{ ...item, sectionId: item.sectionId as string, sectionIndex: item.sectionIndex }}
                        />
                    </FormGridCell>
                </div>
            );
        });
    };

    /**
     * Returns an object that specifies the layout values per breakpoint.
     *
     * @returns {{
     * lg: Layout[];
     * md: Layout[];
     * sm: Layout[];
     * xs: Layout[];
     * xxs: Layout;
     * }}
     */
    const generateLayout = () => {
        const ITEMS_WITH_FIXED_LAYOUT = ['TEXT_RTE', 'TEXT_RTE_PREFILLED', 'FILE_UPLOAD'];
        const newLayout = tempLayout.map((layout: any) => {
            const currentFormItem = formItems.findIndex((c) => c.field_id === layout.i);

            if (currentFormItem !== -1) {
                if (ITEMS_WITH_FIXED_LAYOUT.includes(formItems[currentFormItem].field_type)) {
                    return {
                        x: layout.x,
                        y: layout.y,
                        w: layout.w,
                        h: layout.h,
                        i: layout.i,
                        minW: 2,
                        minH: layout.h,
                        maxW: 2,
                        maxH: layout.h,
                    };
                }
            }

            return {
                x: layout.x,
                y: layout.y,
                w: layout.w,
                h: layout.h,
                i: layout.i,
                minW: 1,
                minH: layout.h,
                maxW: 2,
                maxH: layout.h,
            };
        });
        return {
            lg: newLayout,
            md: newLayout,
            sm: newLayout,
            xs: newLayout,
            xxs: newLayout,
        };
    };

    /**
     * Returns a JSX element that implements a responsive react-grid-layout.
     */
    return (
        <div className="w-full">
            <ResponsiveLayout
                rowHeight={60}
                cols={columns}
                isDroppable={true}
                layouts={generateLayout()}
                draggableHandle=".field-drag-handle"
                measureBeforeMount={true}
                onLayoutChange={onLayoutChange}
                verticalCompact={true}
                margin={[10, 10]}
            >
                {generateDOM(formItems)}
            </ResponsiveLayout>
        </div>
    );
};

// Export the component.
export default FormGrid;
