import _ from 'lodash';
import React from 'react';
import { Layout } from 'react-grid-layout';
import { TemplateContent } from '../../models/TemplateContent';

export const useCellHeightAdjuster = (
    layouts: Layout[],
    cell: TemplateContent | null,
    obj: any = null,
    rowHeight = 60,
) => {
    const [layout, setLayout] = React.useState<Layout[]>(layouts);

    const computeLayout = (layout: Layout[]) => {
        return _.map(layout, (layoutItem) => {
            /**
             * Get the cell element
             */
            const element = document.getElementById(`cell-content-${layoutItem.i}`);

            // If there is an element for the specified cell id, compute the height.
            if (element) {
                // Get the height from the element itself.
                const { height } = element.getBoundingClientRect();

                /**
                 * Get the toolbar height inside the cell.
                 * The toolbar is located at the bottom of the cell with `copy` and `delete` buttons.
                 */
                // const toolbarHeight = cell?.field_id === layoutItem.i ? 53 : 0;

                /**
                 * Computes the cell height
                 * The cell height can be computed by:
                 * cellHeight = Math.ceil((cellElementHeight + cellPadding + toolbarHeight - gridMargin ) / (rowHeight + gridMargin))
                 */
                const h = Math.ceil(height / rowHeight);

                // Returns a new layout object with the calculated height
                return {
                    ...layoutItem,
                    h: h,
                    minH: h,
                    maxH: h,
                };
            } else {
                // Else, return the original layout.
                return layoutItem;
            }
        });
    };

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            const newLayout = computeLayout(layouts);
            setLayout(newLayout);
        }, 100);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [layouts, cell, obj]);

    return { layout, setLayout, computeLayout };
};
