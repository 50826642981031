import logoutIcon from '../../../assets/img/ic_logout.svg';
import helpIcon from '../../../assets/img/ic_help-circle.svg';
import PropTypes from 'prop-types';
import * as React from 'react';
import './../profile/profile.css';
import { Link, navigate } from '@reach/router';

/*
TODOs
1. improve switch type for Admin vs regular users
*/

interface IDropdownNavProps {
    userType?: string;
    onLogout?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export interface PrivateSideMenuItem {
    id: string;
    label: string;
    link: string;
    icon: string;
}

const links: PrivateSideMenuItem[] = [
    {
        id: 'support',
        label: 'Help & support',
        link: '/support/contact',
        icon: helpIcon,
    },
];

const DropdownNav: React.FunctionComponent<IDropdownNavProps> = (props) => {
    const { userType, onLogout } = props;

    switch (userType) {
        case 'ADMIN':
            return (
                <div id="dropdown" className="dropdown-content w-auto">
                    <div className="w-full md:w-1/3 flex flex-col items-center justify-between">
                        <button
                            id="logout"
                            data-testid="logout"
                            className="dropdown-button w-auto px-4 py-2 flex flex-row items-center whitespace-nowrap"
                            onClick={onLogout}
                        >
                            <img className="p-0 md:px-1" alt="logout" src={logoutIcon} />
                            <p className="px-4 text-normal">Logout</p>
                        </button>
                    </div>
                </div>
            );
        default:
            return (
                <div id="dropdown" className="dropdown-content w-auto">
                    {links.map((item, index) => {
                        return (
                            <Link
                                key={item.id + index}
                                to={item.link}
                                className="w-full md:w-1/3 flex flex-col items-center justify-between"
                            >
                                <button
                                    id={item.id}
                                    data-testid={item.id}
                                    className="dropdown-button w-auto px-4 py-2 flex flex-row items-center whitespace-nowrap"
                                >
                                    <img className="p-2 icon" alt={item.id} src={item.icon} />
                                    <p className="px-4 text-normal">{item.label}</p>
                                </button>
                            </Link>
                        );
                    })}
                    <div className="w-full md:w-1/3 flex flex-col items-center justify-between">
                        <button
                            id="logout"
                            data-testid="logout"
                            className="dropdown-button w-auto px-4 py-2 flex flex-row items-center whitespace-nowrap"
                            onClick={onLogout}
                        >
                            <img className="p-2 icon" alt="logout" src={logoutIcon} />
                            <p className="px-4 text-normal">Logout</p>
                        </button>
                    </div>
                </div>
            );
    }
};

DropdownNav.propTypes = {
    userType: PropTypes.string,
    onLogout: PropTypes.func,
};

export default DropdownNav;
