import { RouteComponentProps } from '@reach/router';
import firebase from 'firebase/app';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import login_bg from '../../../assets/img/login-bg.png';
import logo_globe from '../../../assets/img/logo_globe.png';
import logo_gcash from '../../../assets/img/logo_gcash.png';
import logo_memo from '../../../assets/img/logo_memo.png';
import ButtonSocial from '../../../layouts/buttons/ButtonSocial';
import Title from '../../../layouts/typography/Title';
import { User } from '../../../models/User';
import { setUser } from '../../../redux/AuthSlice';
import './Login.css';
import ic_alert from '../../../assets/img/ic_alert.svg';
import { ModalContent } from '../../../models/ModalContent';
import Modal from '../../../layouts/modals/Modal';
import Text from '../../../layouts/typography/Text';
import Button from '../../../layouts/buttons/Button';
import { flavor } from '../../../AppFlavor';

interface ILoginProps {
    path?: RouteComponentProps;
}

const AdminLogin: React.FunctionComponent<ILoginProps> = () => {
    const dispatch = useDispatch();
    const initialModal = {
        isVisible: false,
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    };
    const [modalContent, setModalContent] = React.useState<ModalContent>(initialModal);

    const updateUser = (user: firebase.User | null) => {
        if (user) {
            const payload: User = {
                displayName: user.displayName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                providerId: user.providerId,
                uid: user.uid,
            };
            dispatch(setUser(payload));
        }
    };

    const signIn = async () => {
        const redirectURI =
            window.location.hostname === 'localhost'
                ? `${window.location.protocol}//${window.location.hostname}:5001/${process.env.REACT_APP_PROJECT_ID}/us-central1/auth/auth/google`
                : `${window.location.origin}/auth/google`;
        window.location.replace(redirectURI);
    };

    const onClick = async () => {
        try {
            setModalContent({
                ...modalContent,
                isVisible: true,
                icon: ic_alert,
                loading: false,
                title: 'Note: Technical Preview',
                subtitle: `
                    <strong>
                        Welcome to the technical preview of the admin console.
                    </strong>
                    <br>
                    <p>
                        This includes features that are under active development and may be unstable for the time being.
                    </p>
                    <br>
                    <p>
                        As part of the limited users who will access the technical preview, 
                        we appreciate your discretion as we work to improve the service.
                        You can help us by giving us feedback/bug report here at this <a style="color: blue; text-decoration: underline;" href="https://docs.google.com/forms/d/1ReL_1rpELxiDu388vf5JuN87fr6x8TKzgXdqlPvWE-c/viewform?edit_requested=true">link</a>.
                    </p>
                    <br>
                    
                    Thank you so much.`,
                buttons: [
                    {
                        label: 'Continue',
                        event: signIn,
                    },
                ],
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="w-full h-screen flex items-center">
            <div className="w-full h-1/2 md:w-1/2 flex justify-center">
                <div className="p-8 z-10">
                    <div className="mb-2 md:mb-4">
                        <img style={{ width: '25%' }} src={logo_gcash} alt="Gcash" />
                    </div>
                    <div className="mb-8">
                        <img style={{ width: '50%' }} src={logo_memo} alt="Memo" />
                    </div>
                    <div className="w-auto h-auto mb-2 flex flex-row items-center">
                        <Title type="h4">Administrator</Title>
                    </div>
                    <div style={{ width: '310px' }}>
                        <div className="w-full flex flex-col">
                            <ButtonSocial
                                text="Login with Globe Google account"
                                provider="google"
                                onClick={async () => {
                                    await onClick();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={modalContent.isVisible as boolean}>
                {modalContent.loading ? (
                    <div className="w-full flex flex-col items-center justify-center">
                        <Text>Loading your template</Text>
                    </div>
                ) : (
                    <div className="w-full flex flex-col items-center">
                        <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                        <Title type="h4">{modalContent.title}</Title>
                        <div className="my-2 text-center">
                            <Text type="innerHTML">{modalContent.subtitle}</Text>
                        </div>
                        {modalContent.buttons.length > 1 ? (
                            <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        weight="font-regular"
                                        onClick={modalContent.buttons[0].event}
                                    >
                                        {modalContent.buttons[0].label}
                                    </Button>
                                </div>
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        color="primary"
                                        weight="font-regular"
                                        type="button"
                                        onClick={modalContent.buttons[1].event}
                                    >
                                        {modalContent.buttons[1].label}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="my-2">
                                <Button
                                    type="button"
                                    color="primary"
                                    weight="font-regular"
                                    onClick={modalContent.buttons[0].event}
                                >
                                    {modalContent.buttons[0].label}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
            <img className="md:w-3/5 absolute bottom-0 right-0 z-0" src={login_bg} alt="art" />
        </div>
    );
};

export default AdminLogin;
