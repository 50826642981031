import _ from 'lodash';
import React from 'react';
import { IDropZoneProps } from '../../../../layouts/form/DropZone';
import { TempFile } from '../../../../models/TempFile';

export const UploadProvider = <T extends IDropZoneProps>(Component: React.ComponentType<T>) => {
    const WrappedComponent = React.forwardRef(
        (
            props: Omit<T, 'fileState' | 'onDropzoneFileRemoved'> & {
                files: TempFile[];
                onChange: (files: TempFile[]) => void;
            },
            ref: any,
        ) => {
            const onDropzoneFileRemoved = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                const index = Number(event.currentTarget.value);
                const currentFiles = _.cloneDeep(props.files);
                currentFiles.splice(index, 1);
                props.onChange(currentFiles);
            };

            const _onChange = (acceptedFiles: TempFile[]) => {
                const currentFiles = _.cloneDeep(props.files);
                currentFiles.push(...acceptedFiles);
                props.onChange(currentFiles.slice(0, 5));
            };

            return (
                <Component
                    {...((props as unknown) as T)}
                    register={ref}
                    fileState={props.files}
                    onChange={_onChange}
                    onDropzoneFileRemoved={onDropzoneFileRemoved}
                />
            );
        },
    );
    WrappedComponent.displayName = Component.displayName || 'UploadProvider';
    return WrappedComponent;
};
