import React from 'react';
import { flavor } from '../../AppFlavor';
import Text from '../typography/Text';
import './Stepper.css';

interface IStepperItemProps {
    state?: string;
    label?: string;
    children?: React.ReactNode;
    // onClick: (e: any) => void;
}

const StepperItem = (props: IStepperItemProps) => {
    const { state, label } = props;

    switch (state) {
        case 'completed':
            return (
                <div className="w-full mt-2">
                    <p className="font-bold text-normal text-sm text-center">{label}</p>
                </div>
            );
        case 'active':
            return (
                <div className="w-full mt-2">
                    <p className="font-bold text-normal text-sm text-center">{label}</p>
                </div>
            );
        default:
            return (
                <div className="w-full mt-2">
                    <p className="font-bold text-normal text-sm text-center">{label}</p>
                </div>
            );
    }
};

export default StepperItem;
