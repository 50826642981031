import React from 'react';
import Subtitle from '../../../layouts/typography/Subtitle';

interface IMemoViewHeaderSubtitle {
    timestamp?: string;
}

const MemoViewHeaderSubtitle = (props: IMemoViewHeaderSubtitle) => {
    return (
        <div className="my-2 flex flex-row">
            <Subtitle type="secondary">Date created:</Subtitle>
            <div className="px-1" />
            <Subtitle>{props.timestamp}</Subtitle>
        </div>
    );
};

export default MemoViewHeaderSubtitle;
