import React from 'react';
import Status from '../../../layouts/status/Status';
import TableBodyCell from '../../../layouts/table/TableBodyCell';
import TableRow from '../../../layouts/table/TableRow';
import { Memo } from '../../../models/Memo';
import { getDateString, getDaysElapsed } from '../../../utils/date/Date';
import { DatatableComponentProps } from '../datatable/Datatable';
import Text from '../../../layouts/typography/Text';

const ApprovalTableBody = (props: DatatableComponentProps) => {
    if (!props.memos || !props.headers) return null;

    return (
        <>
            {props.memos.map((_memo: Memo, index) => {
                const memo = (_memo as unknown) as Record<string, any>;
                return (
                    <TableRow key={memo.id} navigateTo={`/approvals/${memo.memo_id}`}>
                        {props.headers!.map((header: any) => {
                            if (['date_submitted', 'date_last_modified', 'date_created'].includes(header.key)) {
                                return (
                                    <TableBodyCell key={memo[header.key]}>
                                        {getDateString(memo[header.key])}
                                    </TableBodyCell>
                                );
                            }
                            if (header.key === 'memo_status') {
                                return (
                                    <TableBodyCell type="status" key={memo[header.key]}>
                                        <Status type={memo.memo_status.toLowerCase()} />
                                        <Text color="secondary">
                                            Days elapsed: {getDaysElapsed(memo.date_submitted)}
                                        </Text>
                                    </TableBodyCell>
                                );
                            }
                            if (header.key === 'current_approver') {
                                return (
                                    <TableBodyCell key={memo[header.key]}>
                                        <div>{memo.current_approver ? memo.current_approver[0] : ''}</div>
                                    </TableBodyCell>
                                );
                            }
                            if (header.key === 'days_elapsed') {
                                return (
                                    <TableBodyCell key={memo[header.key]}>
                                        {getDaysElapsed(memo.date_last_modified)}
                                    </TableBodyCell>
                                );
                            }

                            const field = memo.find(header.key);

                            if (field) {
                                return <TableBodyCell key={memo[header.key] + `${index}`}>{field.value}</TableBodyCell>;
                            }

                            return <TableBodyCell key={memo[header.key]}>{memo[header.key]}</TableBodyCell>;
                        })}
                    </TableRow>
                );
            })}
        </>
    );
};
export default ApprovalTableBody;
