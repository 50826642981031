import { navigate } from '@reach/router';
import React from 'react';
import Status from '../../../layouts/status/Status';
import TableBodyCell from '../../../layouts/table/TableBodyCell';
import TableRow from '../../../layouts/table/TableRow';
import { Memo } from '../../../models/Memo';
import { getDateString } from '../../../utils/date/Date';
import { DatatableComponentProps } from '../datatable/Datatable';

const DraftsMemosTableBody = (props: DatatableComponentProps & { onDeleteClick: (memo: Memo) => void }) => {
    if (!props.memos || !props.headers) {
        return <TableRow />;
    }
    return (
        <>
            {props.memos!.map((_memo: Memo, index) => {
                const memo = (_memo as unknown) as Record<string, any>;
                return (
                    <TableRow key={index}>
                        {props.headers!.map((header: any) => {
                            if (['date_submitted', 'date_last_modified', 'date_created'].includes(header.key)) {
                                return (
                                    <TableBodyCell key={header.key}>{getDateString(memo[header.key])}</TableBodyCell>
                                );
                            }
                            if (header.key === 'memo_status') {
                                return (
                                    <TableBodyCell key={header.key}>
                                        <Status type={memo.memo_status.toLowerCase()} />
                                    </TableBodyCell>
                                );
                            }

                            const field = memo.find(header.key);

                            if (field) {
                                return <TableBodyCell key={memo[header.key] + `${index}`}>{field.value}</TableBodyCell>;
                            }

                            return <TableBodyCell key={memo[header.key]}>{memo[header.key]}</TableBodyCell>;
                        })}
                        <TableBodyCell key={`${memo.memo_id}-action`}>
                            <button
                                className="text-sm text-blue underline md:mx-2"
                                onClick={() => {
                                    navigate(`/memo/edit/${memo.memo_id}`);
                                }}
                            >
                                Edit
                            </button>
                            <button
                                className="text-sm text-blue underline md:mx-2"
                                onClick={() => {
                                    props.onDeleteClick(memo as Memo);
                                }}
                            >
                                Delete
                            </button>
                        </TableBodyCell>
                    </TableRow>
                );
            })}
        </>
    );
};

export default DraftsMemosTableBody;
