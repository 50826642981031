import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import memo_icon from '../../../assets/img/ic_my-memos.svg';
import MemoFeed from '../../../layouts/memo-feed/MemoFeed';
import { setActiveItems, setActiveState } from '../../../redux/MemoFeedSlice';
import { RootState } from '../../../redux/RootReducer';
import firebase from 'firebase/app';
import { Memo } from '../../../models/Memo';
import { MemoFeedItems } from '../../../models/MemoFeedItems';
import { navigate } from '@reach/router';

interface IMyMemosSelectionProps {
    children?: React.ReactNode;
}

const MyMemosSelection: React.FunctionComponent<IMyMemosSelectionProps> = () => {
    const { active } = useSelector((state: RootState) => state.MemoFeed);
    const [returned_memos_count, set_returned_memos_count] = React.useState(-1);

    const count_returned_memo = (array: any) => {
        let returned = 0;
        array.forEach((memo: any) => {
            if (memo.memo_status.toUpperCase() === 'RETURNED') {
                returned = returned + 1;
            }
        });

        return returned;
    };

    const dispatch = useDispatch();

    React.useEffect(() => {
        const loadMemos = async () => {
            try {
                const requestPayload: any = {
                    limit: 15,
                    page_no: 1,
                };

                const getMyMemos = firebase.app().functions('asia-east2').httpsCallable('getMyMemosActiveWithUserID');
                const http_response = await getMyMemos(requestPayload);
                const response = http_response.data.memos;

                let memos: MemoFeedItems[] = [];
                memos = response.map((memo: Memo) => {
                    return {
                        title: memo.memo_subject,
                        memo_id: memo.memo_id,
                        memo_type: memo.used_template_name,
                        memo_status: String(memo.memo_status).toLowerCase(),
                        timestamp: memo.date_submitted,
                        type: 'kanban_status',
                    };
                });
                set_returned_memos_count(count_returned_memo(memos));
                dispatch(setActiveItems(memos));
                dispatch(setActiveState('fulfilled'));
            } catch (err) {
                dispatch(setActiveItems([]));
                dispatch(setActiveState('rejected'));
                console.error(err);
            }
        };
        loadMemos();
    }, []);

    const onItemClick = (event: React.MouseEvent<HTMLElement>) => {
        const id = event.currentTarget.dataset.value;
        const parent = '/memos/active';
        navigate(parent + '/' + id);
    };

    return (
        <MemoFeed
            title="My memos"
            icon={memo_icon}
            redirectTo="/memos/active"
            items={active.items}
            state={active.state}
            count={active.items.length}
            returned_count={returned_memos_count}
            actionTitle="View all memos"
            onItemClick={onItemClick}
            feed_type="myMemos"
        />
    );
};

export default MyMemosSelection;
