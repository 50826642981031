import React from 'react';
import SideMenuItemActive from './SideMenuItemActive';
import SideMenuItemInactive from './SideMenuItemInactive';

interface SideMenuItem extends React.ComponentPropsWithoutRef<'li'> {
    label: string;
    value: any;
    active?: boolean;
    onClick?: (param: any) => void;
}

const SideMenuItem = (props: SideMenuItem) => {
    const { label, value, active, onClick } = props;

    const onItemClick = () => {
        if (!onClick) return;
        return onClick(value);
    };

    return (
        <li className="w-full" onClick={onItemClick}>
            {active ? <SideMenuItemActive label={label} /> : <SideMenuItemInactive label={label} />}
        </li>
    );
};

export default SideMenuItem;
