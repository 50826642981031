import * as React from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { TemplateValidation } from '../../models/TemplateValidation';
import { inputTextErrorStyle } from '../form/Input';
import './select.css';

interface ISelectProps extends React.ComponentPropsWithoutRef<'select'> {
    id: string;
    label?: string;
    annotation?: string;
    type?: string;
    children?: React.ReactNode;
    validations?: TemplateValidation;
    errors?: DeepMap<FieldValues, FieldError>;
}

export interface SelectItems {
    label: string;
    value: string;
    isDefault?: boolean;
}

export const selectStyles: React.CSSProperties = {
    border: '0.25px solid #afafaf',
    borderRadius: '5px',
};

const Select = React.forwardRef((props: ISelectProps, ref: React.Ref<HTMLSelectElement>) => {
    const { type, label, annotation, id, validations, errors } = props;
    switch (type) {
        case 'inline':
            return (
                <div style={selectStyles} className="w-full p-2 my-1 flex flex-row bg-white">
                    {label ? (
                        <label className="w-full font-regular text-sm mr-2" htmlFor={id}>
                            {label}
                        </label>
                    ) : (
                        ''
                    )}
                    {annotation ? (
                        <p className="text-xs font-regular w-full text-gray-2 mb-1 whitespace-pre-wrap">{annotation}</p>
                    ) : (
                        ''
                    )}
                    <select className="cursor-pointer w-full outline-none text-sm font-regular" {...props} id={id}>
                        {props.children}
                    </select>
                </div>
            );
        default:
            return (
                <div>
                    {label ? (
                        <label className="font-bold text-sm" htmlFor={id}>
                            {label}
                            {validations?.required ? <span className="text-red">*</span> : ''}
                        </label>
                    ) : (
                        ''
                    )}
                    {annotation ? (
                        <p className="text-xs font-regular w-full text-gray-2 mb-1 whitespace-pre-wrap">{annotation}</p>
                    ) : (
                        ''
                    )}
                    <select
                        ref={ref}
                        name={id}
                        style={errors ? (errors[id] ? inputTextErrorStyle : selectStyles) : selectStyles}
                        className="p-2 pr-6 my-1 cursor-pointer w-full outline-none text-sm font-regular"
                        {...props}
                    >
                        {props.children}
                    </select>
                    {errors ? (
                        errors[id] ? (
                            <p className="text-xs font-regular text-red">{errors[id].message}</p>
                        ) : (
                            ''
                        )
                    ) : (
                        ''
                    )}
                </div>
            );
    }
});

Select.displayName = 'Select';

export default Select;
