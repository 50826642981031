import PropTypes from 'prop-types';
import * as React from 'react';
import { Control, Controller, DeepMap, FieldError, FieldValues } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import search from '../../assets/img/search.svg';
import { TemplateValidation } from '../../models/TemplateValidation';
import Label from './Label';

export interface IInputProps extends React.ComponentPropsWithoutRef<'input'> {
    id: string;
    label?: string;
    type: string;
    errors?: DeepMap<FieldValues, FieldError>;
    validations?: TemplateValidation;
    annotation?: string;
    control?: Control<FieldValues>;
    children?: React.ReactNode;
    register?: any;
}

export const inputTextStyle: React.CSSProperties = {
    border: '0.25px solid #afafaf',
    borderRadius: '5px',
    outline: 'none',
};

export const inputTextErrorStyle: React.CSSProperties = {
    ...inputTextStyle,
    border: '0.25px solid #D62F5C',
};

export const searchIconStyle: React.CSSProperties = {
    width: '15px',
    height: '15px',
};

const Input = React.forwardRef((props: IInputProps, ref: React.Ref<HTMLInputElement>) => {
    const { id, label, type, errors, validations, annotation } = props;

    const getInputErrorMessage = (error: DeepMap<FieldValues, FieldError>) => {
        if (error[id].type === 'customError') {
            return error[id].message;
        }
        return error[id].message;
    };

    switch (type) {
        case 'text':
            return (
                <>
                    {label ? (
                        <Label htmlFor={id}>
                            {label} {validations?.required ? <span className="text-red">*</span> : ''}
                        </Label>
                    ) : (
                        ''
                    )}
                    {annotation ? (
                        <p className="text-xs font-regular w-full break-words text-gray-2 mb-1 whitespace-pre-wrap">
                            {annotation}
                        </p>
                    ) : (
                        ''
                    )}
                    <input
                        ref={ref}
                        style={errors ? (errors[id] ? inputTextErrorStyle : inputTextStyle) : inputTextStyle}
                        name={id}
                        className="py-2 px-4 my-1 font-regular text-sm w-full bg-white"
                        {...props}
                    />
                    {errors ? (
                        errors[id] ? (
                            <p className="text-xs font-regular text-red">{getInputErrorMessage(errors)}</p>
                        ) : (
                            ''
                        )
                    ) : (
                        ''
                    )}
                </>
            );
        case 'number':
            return (
                <div>
                    {label ? (
                        <Label htmlFor={id}>
                            {label} {validations?.required ? <span className="text-red">*</span> : ''}
                        </Label>
                    ) : (
                        ''
                    )}
                    {annotation ? (
                        <p className="text-xs font-regular w-full break-words text-gray-2 mb-1 whitespace-pre-wrap">
                            {annotation}
                        </p>
                    ) : (
                        ''
                    )}
                    <input
                        ref={ref}
                        style={errors ? (errors[id] ? inputTextErrorStyle : inputTextStyle) : inputTextStyle}
                        name={id}
                        className="py-2 px-4 my-1 font-regular text-sm w-full"
                        {...props}
                    />
                    {errors ? (
                        errors[id] ? (
                            <p className="text-xs font-regular text-red">{getInputErrorMessage(errors)}</p>
                        ) : (
                            ''
                        )
                    ) : (
                        ''
                    )}
                </div>
            );
        case 'currency':
            return (
                <>
                    {label ? (
                        <Label htmlFor={id}>
                            {label} {validations?.required ? <span className="text-red">*</span> : ''}
                        </Label>
                    ) : (
                        ''
                    )}
                    {annotation ? (
                        <p className="text-xs font-regular w-full break-words text-gray-2 mb-1 whitespace-pre-wrap">
                            {annotation}
                        </p>
                    ) : (
                        ''
                    )}
                    {props.control ? (
                        <Controller
                            control={props.control}
                            id={id}
                            name={id}
                            defaultValue={props.defaultValue}
                            render={({ onChange, ref, name }) => (
                                <NumberFormat
                                    id={id}
                                    getInputRef={ref}
                                    defaultValue={props.defaultValue as any}
                                    onChange={(e: any) => {
                                        props.onChange ? props.onChange(e) : null;
                                        onChange(e);
                                    }}
                                    name={name}
                                    style={
                                        errors ? (errors[id] ? inputTextErrorStyle : inputTextStyle) : inputTextStyle
                                    }
                                    placeholder={props.placeholder}
                                    isNumericString
                                    prefix=""
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    className="py-2 px-4 my-1 font-regular text-sm w-full"
                                    tabIndex={props.tabIndex}
                                    aria-label={id}
                                    data-testid={id}
                                />
                            )}
                            rules={{
                                required: {
                                    value: validations ? (validations.required as boolean) : false,
                                    message: 'This field is required.',
                                },
                            }}
                        />
                    ) : (
                        <NumberFormat
                            id={id}
                            getInputRef={ref}
                            defaultValue={props.defaultValue as any}
                            onChange={props.onChange}
                            name={id}
                            style={errors ? (errors[id] ? inputTextErrorStyle : inputTextStyle) : inputTextStyle}
                            placeholder={props.placeholder}
                            isNumericString
                            prefix=""
                            thousandSeparator={true}
                            decimalScale={2}
                            className="py-2 px-4 my-1 font-regular text-sm w-full"
                            tabIndex={props.tabIndex}
                        />
                    )}
                    {errors ? (
                        errors[id] ? (
                            <p className="text-xs font-regular text-red">{getInputErrorMessage(errors)}</p>
                        ) : (
                            ''
                        )
                    ) : (
                        ''
                    )}
                </>
            );
        case 'email':
            return (
                <div>
                    {label ? <Label htmlFor={id}>{label}</Label> : ''}
                    <input style={inputTextStyle} className="py-2 px-4 my-1 font-regular text-sm w-full" {...props} />
                </div>
            );
        case 'search':
            return (
                <div>
                    {label ? <Label htmlFor={id}>{label}</Label> : ''}
                    <div style={inputTextStyle} className="px-2 my-1 flex flex-row items-center">
                        <img style={searchIconStyle} className="mx-1" src={search} alt="search" />
                        <input className="py-2 px-2 font-regular text-sm w-full outline-none" {...props} />
                    </div>
                </div>
            );
        case 'search-no-border':
            return (
                <div>
                    {label ? <Label htmlFor={id}>{label}</Label> : ''}
                    <div style={{ ...inputTextStyle, border: 'none' }} className="px-2 my-1 flex flex-row items-center">
                        <img style={searchIconStyle} className="mx-1" src={search} alt="search" />
                        <input className="py-2 px-2 font-regular text-sm w-full outline-none" {...props} />
                    </div>
                </div>
            );
        case 'checkbox':
            return (
                <div>
                    <div style={{ ...inputTextStyle, border: 'none' }} className="px-2 my-1 flex flex-row items-center">
                        <input
                            ref={ref}
                            className="py-2 px-2 mr-2 font-regular text-sm outline-none transform scale-125"
                            {...props}
                        />
                        {label ? (
                            <label htmlFor={id} className="font-regular text-xs">
                                {label}
                            </label>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            );
        case 'radio':
            return (
                <div>
                    <div style={{ ...inputTextStyle, border: 'none' }} className="px-2 my-1 flex flex-row items-center">
                        <input
                            ref={ref}
                            className="py-2 px-2 mr-2 font-regular text-sm outline-none transform scale-125"
                            style={errors ? (errors[id] ? inputTextErrorStyle : inputTextStyle) : inputTextStyle}
                            {...props}
                        />
                        <div className="w-full h-auto flex flex-col">
                            {label ? (
                                <label htmlFor={id} className="font-regular text-xs">
                                    {label}
                                </label>
                            ) : (
                                ''
                            )}
                            {annotation ? (
                                <p className="text-xs font-regular w-full break-words text-gray-2 mb-1 whitespace-pre-wrap">
                                    {annotation}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            );
        case 'date':
            return (
                <div className="flex flex-row items-center">
                    {label ? (
                        <Label htmlFor={id} color="secondary">
                            {label}
                        </Label>
                    ) : (
                        ''
                    )}
                    <div style={inputTextStyle} className="px-2 my-1 mx-2 flex flex-row items-center w-full">
                        <input className="py-2 px-2 font-regular text-sm w-full outline-none" {...props} />
                    </div>
                </div>
            );

        default:
            return null;
    }
});

Input.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
};

Input.displayName = 'Input';

export default Input;
