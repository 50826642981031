import * as React from 'react';
import PropTypes from 'prop-types';
import Title from '../typography/Title';
import Text from '../typography/Text';

export interface IModalProps {
    visible: boolean;
    title?: string;
    subtitle?: string;
    type?: string;
    children?: React.ReactNode;
    width?: string;
}

export const modalContainerStyles: React.CSSProperties = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(255,255,255,0.8)',
    width: '100%',
    height: '100vh',
    zIndex: 99999,
};

export const modalBoxStyles: React.CSSProperties = {
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '8px',
    width: '650px',
};

const Modal: React.FunctionComponent<IModalProps> = (props) => {
    const { title, subtitle, visible, width } = props;

    React.useEffect(() => {
        if (visible) {
            document.body.style.overflowY = 'hidden';
        }
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [props]);

    const getLayout = (title: string | undefined, subtitle: string | undefined) => {
        return (
            <div style={modalContainerStyles} className="flex items-center justify-center">
                <div
                    style={{ ...modalBoxStyles, width: width || '500px' }}
                    className="h-auto flex flex-col p-8 bg-white modal-box"
                >
                    <div className="w-full flex flex-col">
                        <Title type="h4">{title}</Title>
                        <Text>{subtitle}</Text>
                    </div>
                    <div className="w-full flex my-4">{props.children}</div>
                </div>
            </div>
        );
    };

    return visible ? getLayout(title, subtitle) : null;
};

Modal.propTypes = {
    visible: PropTypes.bool.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.node,
};

export default Modal;
