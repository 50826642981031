import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import form_empty_state from '../../../../assets/img/form-empty-state.svg';
import Input from '../../../../layouts/form/Input';
import { TemplateContent } from '../../../../models/TemplateContent';
import { setActiveField, updateSectionMetadata } from '../../../../redux/FormBuilderSlice';
import { RootState } from '../../../../redux/RootReducer';
import { AppDispatch } from '../../../../redux/Store';
import FormGrid from './FormGrid';
import TemplateSectionHeader from './TemplateSectionHeader';
/**
 * Represents the props for a Template Section
 */
interface ITemplateSectionProps extends TemplateContent {
    validations: any;
    onUpdate: (index: number, content: TemplateContent) => void;
    onRemove: (id: string) => void;
}

/**
 * Returns a single Template Section view.
 * @param props
 * @returns
 */
const TemplateSection = (props: ITemplateSectionProps) => {
    // Local state for managing collapsed and exand functionality
    const [collapsed, setCollapsed] = React.useState(false);

    // Local state for managing focused and blur functionality
    const [focused, setFocused] = React.useState(false);

    // Connect to the central state in Redux.
    const { template_content, _editing } = useSelector((state: RootState) => state.FormBuilder);
    const dispatch: AppDispatch = useDispatch();

    // Handles event when a section is collapsed.
    const onCollapseClick = () => {
        setCollapsed(!collapsed);
    };

    // Handles event when a section is being focused.
    const onFocus = () => {
        setFocused(true);
    };

    // Handles event when a section is not being focused
    const onBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        // Check if the blur event is coming from outside of the section
        setTimeout(() => {
            const sectionElement = document.getElementById(props.field_id);
            const panelElement = document.getElementById('panel-editor');
            if (sectionElement || panelElement) {
                if (
                    !sectionElement?.contains(e.relatedTarget as any) &&
                    !panelElement?.contains(e.relatedTarget as any)
                ) {
                    setFocused(false);
                    dispatch(setActiveField(null));
                }
            }
        });
    };

    // This is an internal function that passes the selected section ID to the
    // onRemove props.
    const _onRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
        const id = e.currentTarget.dataset.parentId || (e as any).target.value;
        if (id) {
            props.onRemove(id);
            dispatch(setActiveField(null));
        }
    };

    /**
     * Handles onChange event for template name
     * @param e
     */
    const onNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const content = _.omit(
            {
                ...props,
                field_name: e.currentTarget.value,
            },
            'onUpdate',
            'onRemove',
        );
        props.onUpdate(props.sectionIndex, content);
    };

    const onNameFocused = () => {
        if (_editing) {
            dispatch(setActiveField(null));
        }
    };

    /**
     * Constructs a Form Item object based on the given item type string
     * @param itemType
     */
    const constructFormItem = (itemType: string): TemplateContent => {
        const id = nanoid();
        switch (itemType) {
            case 'TEXT':
                return {
                    field_type: 'TEXT',
                    field_name: 'Untitled',
                    field_id: id,
                    annotation: 'This is your description',
                    placeholder: 'This is your placeholder',
                    validations: {
                        required: true,
                        max_length: 300,
                    },
                    sectionIndex: props.sectionIndex,
                    sectionId: props.sectionId,
                };
            case 'TEXT_CURRENCY':
                return {
                    field_type: 'TEXT_CURRENCY',
                    field_name: 'Untitled',
                    field_id: id,
                    annotation: 'This is your description',
                    placeholder: 'This is your placeholder',
                    validations: {
                        required: true,
                    },
                    sectionIndex: props.sectionIndex,
                    sectionId: props.sectionId,
                };
            case 'TEXT_RTE':
                return {
                    field_type: 'TEXT_RTE',
                    field_name: 'Untitled',
                    field_id: id,
                    annotation: 'This is your description',
                    placeholder: 'This is your placeholder',
                    validations: {
                        required: true,
                    },
                    constant: '<p>Click Edit icon to activate and edit the content for rich text editor</p>',
                    sectionIndex: props.sectionIndex,
                    sectionId: props.sectionId,
                };
            case 'FILE_UPLOAD':
                return {
                    field_type: 'FILE_UPLOAD',
                    field_name: 'Untitled',
                    field_id: id,
                    annotation: 'This is your description',
                    placeholder: 'This is your placeholder',
                    validations: {
                        required: false,
                        file_types: [],
                    },
                    sectionIndex: props.sectionIndex,
                    sectionId: props.sectionId,
                };
            case 'DROPDOWN':
                return {
                    field_type: 'DROPDOWN',
                    field_name: 'Untitled',
                    field_id: id,
                    annotation: 'This is your description',
                    placeholder: 'This is your placeholder',
                    validations: {
                        required: true,
                    },
                    constant: [
                        {
                            label: 'Option 1',
                            value: nanoid(),
                        },
                    ],
                    sectionIndex: props.sectionIndex,
                    sectionId: props.sectionId,
                };
            case 'CHECKBOX':
                return {
                    field_type: 'CHECKBOX',
                    field_name: 'Untitled',
                    field_id: id,
                    annotation: 'This is your description',
                    placeholder: 'This is your placeholder',
                    validations: {
                        required: true,
                    },
                    constant: [
                        {
                            label: 'Option 1',
                            value: nanoid(),
                        },
                    ],
                    sectionIndex: props.sectionIndex,
                    sectionId: props.sectionId,
                };
            case 'RADIO':
                return {
                    field_type: 'RADIO',
                    field_name: 'Untitled',
                    field_id: id,
                    annotation: 'This is your description',
                    placeholder: 'This is your placeholder',
                    validations: {
                        required: true,
                    },
                    constant: [
                        {
                            label: 'Option 1',
                            value: nanoid(),
                        },
                    ],
                    sectionIndex: props.sectionIndex,
                    sectionId: props.sectionId,
                };
            case 'TIMEPICKER':
                return {
                    field_type: 'TIMEPICKER',
                    field_name: 'Untitled',
                    field_id: id,
                    annotation: 'This is your description',
                    placeholder: 'This is your placeholder',
                    validations: {
                        required: true,
                    },
                    sectionIndex: props.sectionIndex,
                    sectionId: props.sectionId,
                };
            case 'DATEPICKER':
                return {
                    field_type: 'DATEPICKER',
                    field_name: 'Untitled',
                    field_id: id,
                    annotation: 'This is your description',
                    placeholder: 'This is your placeholder',
                    validations: {
                        required: true,
                    },
                    sectionIndex: props.sectionIndex,
                    sectionId: props.sectionId,
                };
            default:
                return {
                    field_type: 'TEXT',
                    field_name: 'Untitled',
                    field_id: id,
                    annotation: 'This is your description',
                    placeholder: 'This is your placeholder',
                    validations: {
                        required: true,
                    },
                    sectionIndex: props.sectionIndex,
                    sectionId: props.sectionId,
                };
        }
    };

    const getScaleByFormItem = (itemType: string) => {
        switch (itemType) {
            case 'TEXT':
                return {
                    w: 2,
                    h: 3,
                };
            case 'TEXT_RTE':
                return {
                    w: 2,
                    h: 8,
                };
            case 'FILE_UPLOAD':
                return {
                    w: 2,
                    h: 6,
                };
            default:
                return {
                    w: 2,
                    h: 3,
                };
        }
    };

    // TODO: To add an item to the list, try the following:
    // 1. Create an object that contains the unique ID of the form item
    // 2. Assign the ID to a layout object. Set the Y value to Infinity for it to be added at the bottom.
    // 3. Update the layout first.
    // 4. Update the items next.
    // Handles the menu item click event and adds form item inside the grid drag and drop area.
    const addFormItem = (value: string) => {
        const y = Infinity;
        // if (template_content[props.index]._metadata) {
        //     const lastInsertedItem =
        //         template_content[props.index]._metadata[template_content[props.index]._metadata.length - 1];
        //     y = lastInsertedItem.h;
        // }
        // console.log(y);

        let contentValue = [];
        const formItem = constructFormItem(value);
        const { w, h } = getScaleByFormItem(value);
        const formItemMetadata = {
            w: w,
            h: h,
            x: 0,
            y: y,
            i: formItem.field_id,
            minW: 1,
            maxW: 2,
            minH: 3,
            maxH: 3,
        };
        dispatch(setActiveField(null));
        dispatch(
            updateSectionMetadata({
                index: props.sectionIndex,
                metadata: template_content[props.sectionIndex]._metadata
                    ? [...template_content[props.sectionIndex]._metadata, formItemMetadata]
                    : [formItemMetadata],
            }),
        );

        // If there are no values existing inside a template_content, assign a first one.
        if (!template_content[props.sectionIndex].value) {
            contentValue = [formItem];
        }
        // Else, append it to the last item
        else {
            contentValue = [...template_content[props.sectionIndex].value, formItem];
        }

        // Create a payload with the assigned values.
        const content = _.omit(
            {
                ...props,
                value: contentValue,
            },
            'onUpdate',
            'onRemove',
        );

        // Emit the handler
        props.onUpdate(props.sectionIndex, content);
        dispatch(
            setActiveField({
                ...formItem,
                sectionId: props.field_id,
                sectionIndex: props.sectionIndex,
            }),
        );
    };

    const containsMemoSubject = (formItems: TemplateContent[]) => {
        const hasMemoSubject = formItems.find((item) => item.field_id === 'TEXT_SUBJECT');
        return hasMemoSubject ? true : false;
    };

    return (
        /**
         * A Draggable is a component in `react-beautiful-dnd` that wraps a JSX element and make it a draggable component
         * inside of the Droppable component.
         *
         * This accepts a draggableId and index as props
         *
         * This component takes a function as a child. The function shall render an element with the DraggableProvided
         * as its props. This will make sure that the element that will be rendered will be a draggable component.
         */
        <Draggable draggableId={props.field_id} index={props.sectionIndex}>
            {/* Renders the function that will render a div to be draggable */}
            {(provided: DraggableProvided) => (
                <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    id={props.field_id}
                    className="section w-full h-auto mt-4"
                    tabIndex={props.sectionIndex + 1}
                >
                    {/* Represents the actual template section view */}
                    <div className={`w-full h-auto flex flex-col bg-blue-light`}>
                        {/* Section Title */}
                        <TemplateSectionHeader
                            id={props.field_id}
                            name={props.field_name}
                            dragHandleProps={provided.dragHandleProps}
                            collapsed={collapsed}
                            onCollapseClick={onCollapseClick}
                            isRemovable={!containsMemoSubject(template_content[props.sectionIndex].value)}
                            onRemoveClick={_onRemove}
                        />

                        {/* Section Content */}
                        <div
                            className={`w-full bg-blue-light px-4 flex flex-col ${
                                focused ? 'border-gray-dark' : 'border-blue-light'
                            } ${collapsed ? 'overflow-hidden h-0 py-0' : 'border-2 h-auto py-4'}`}
                        >
                            <p className="text-sm font-regular mt-2">
                                This section will appear as the first tile in the form, as seen in preview on the side
                                bar
                            </p>
                            <div className="w-full h-auto inline-flex flex-row justify-between items-center mt-4">
                                <div className="w-full h-auto">
                                    <Input
                                        id="txt_section_input"
                                        label="Section name"
                                        type="text"
                                        placeholder="Enter section name"
                                        defaultValue={props.field_name}
                                        onChange={onNameChanged}
                                    />
                                </div>
                            </div>
                            <span className="w-full h-px bg-gray mt-6 mb-3"></span>
                            <div className="w-full h-auto">
                                <h4 className="text-sm font-bold">Form fields</h4>
                                {/* 
                            
                                This will the be the location of form item drag and drop functionality.
                                For now, it will be included in the TemplateSection view.
                                It is recommended that it will be moved to its own component which will implement
                                a grid based drag and drop.
                                
                                */}
                                {template_content[props.sectionIndex].value &&
                                template_content[props.sectionIndex].value.length ? (
                                    <div className="w-full h-auto flex flex-col mt-1">
                                        {Array.isArray(template_content[props.sectionIndex].value) && (
                                            <FormGrid
                                                sectionIndex={props.sectionIndex}
                                                sectionId={props.sectionId}
                                                field_id={props.field_id}
                                                field_type={props.field_type}
                                                field_name=""
                                                validations={null}
                                                focused={focused}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div className="w-full h-auto flex p-4 mt-5">
                                        <div
                                            className="w-full h-auto flex flex-col items-center justify-center p-4 mt-5"
                                            style={{ border: '0.5px dashed #afafaf', borderRadius: '5px' }}
                                        >
                                            <img
                                                style={{ maxWidth: '50%', width: '30%' }}
                                                src={form_empty_state}
                                                alt="Form Builder"
                                            />
                                            <h4 className="mt-2">
                                                Select element from the dropdown to build this section
                                            </h4>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="w-full h-auto">
                                <button
                                    type="button"
                                    className="text-sm font-regular text-blue p-2"
                                    onClick={() => addFormItem('TEXT')}
                                >
                                    + Add a question
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default TemplateSection;
