import { Redirect, Router } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import './App.css';
import Login from './components/basic/auth/Login';
import LoginRedirect from './components/basic/auth/LoginRedirect';
import LoginRoute from './components/basic/auth/LoginRoute';
import ProtectedRoute from './components/basic/auth/ProtectedRoute';
import PublicRoute from './components/basic/auth/PublicRoute';
import Dashboard from './components/basic/dashboard/Dashboard';
import MyApprovals from './components/basic/for-my-approvals/ForMyApprovals';
import SupportPrivate from './components/basic/help-support/SupportPrivate';
import SupportPublic from './components/basic/help-support/SupportPublic';
import MemoForm from './components/basic/memo-form/MemoForm';
import MemoSelection from './components/basic/templates-selection/MemoSelection';
import MemoView from './components/basic/memo-view/MemoView';
import MemosToWatch from './components/basic/memos-to-watch/MemosToWatch';
import MyMemos from './components/basic/my-memos/MyMemos';
import NotificationsFullView from './components/basic/notifications/NotificationsFullView';
import SearchResults from './components/basic/search-results/SearchResults';
import Container from './layouts/container/Container';
import Title from './layouts/typography/Title';

interface IAppProps {
    children?: React.ReactNode;
}

const NotFound = () => {
    return (
        <Container>
            <Title>Page not found. :(</Title>
        </Container>
    );
};

const App: React.FunctionComponent<IAppProps> = () => {
    return (
        <div className="w-full h-full">
            <Router style={{ width: '100%', height: '100%' }}>
                {/* Authentication & Dashboard */}
                <LoginRoute path="/" component={Login} />
                <LoginRoute path="login" title="Login | Memo App (Beta)" component={Login} />
                <LoginRedirect path="/redirect" title="Auth" />

                {/* Help & Support Pages - Static */}
                {/* Public */}
                <PublicRoute path="/terms" title="Terms &amp; Conditions" component={SupportPublic} />
                <PublicRoute path="/privacy" title="Privacy Policy" component={SupportPublic} />

                {/* Help & Support Pages - Static */}
                {/* Private */}
                <ProtectedRoute path="support/" title="Help &amp; Suppport | Contact Us" component={SupportPrivate} />
                <ProtectedRoute
                    path="support/contact"
                    title="Help &amp; Suppport | Contact Us"
                    component={SupportPrivate}
                />
                <ProtectedRoute
                    path="support/terms"
                    title="Help &amp; Suppport | Terms &amp; Conditions"
                    component={SupportPrivate}
                />
                <ProtectedRoute
                    path="support/privacy"
                    title="Help &amp; Suppport | Privacy Policy"
                    component={SupportPrivate}
                />

                {/* Dashboard */}
                <ProtectedRoute path="dashboard" title="Dashboard" component={Dashboard} />

                {/* My Active Memos */}
                <ProtectedRoute path="memos" title="My Memos" component={MyMemos} />
                <ProtectedRoute path="memos/drafts" title="Drafts | My Memos" component={MyMemos} />
                <ProtectedRoute path="memos/history" title="History | My Memos" component={MyMemos} />
                <ProtectedRoute path="memos/active/:memo_id" title="My Memos" component={MemoView} />

                {/* For My Approval Memos */}
                <ProtectedRoute path="approvals" title="For My Approvals" component={MyApprovals} />
                <ProtectedRoute path="approvals/history" title="History | For My Approvals" component={MyApprovals} />
                <ProtectedRoute path="approvals/:memo_id" title="For My Approvals" component={MemoView} />
                <ProtectedRoute
                    path="approvals/history/:memo_id"
                    title="History | For My Approvals"
                    component={MemoView}
                />

                {/* Memos to Watch Memos */}
                <ProtectedRoute path="watch" title="Memos To Watch" component={MemosToWatch} />
                <ProtectedRoute path="watch/history" title="History | Memos To Watch" component={MemosToWatch} />
                <ProtectedRoute path="watch/:memo_id" title="Memos To Watch" component={MemoView} />
                <ProtectedRoute path="watch/history/:memo_id" title="History | Memos To Watch" component={MemoView} />

                {/* MemoView paths */}
                <ProtectedRoute path="memos/drafts/:memo_id" title="Drafts" component={MemoView} />
                <ProtectedRoute path="memos/history/:memo_id" title="History" component={MemoView} />

                {/* Memo Form */}
                <ProtectedRoute path="memo/create" title="Templates | Create a Memo" component={MemoSelection} />
                {/* <ProtectedRoute path="memo/create/:template_id" component={CreateMemoForm} /> */}
                <ProtectedRoute path="memo/create/:template_id" mode="CREATE" component={MemoForm} />
                {/* <ProtectedRoute path="memo/edit/:memo_id" component={EditMemoForm} /> */}
                <ProtectedRoute path="memo/edit/:memo_id" mode="EDIT" component={MemoForm} />

                {/* Notifications */}
                <ProtectedRoute
                    path="notifications"
                    title="Notifications | Memo App (Beta)"
                    component={NotificationsFullView}
                />

                {/* Notifications */}
                <ProtectedRoute
                    path="search/:text"
                    title="Search Results | Memo App (Beta)"
                    component={SearchResults}
                />

                {/* Fallback and 404 */}
                <ProtectedRoute default title="Not Found" component={NotFound} />
                <Redirect from="/memos/active" to="/memos" />
                <Redirect from="/memos/approvals/:id" to="/approvals/:id" />
                <Redirect from="/memos/watch/:id" to="/watch/:id" />
            </Router>
        </div>
    );
};

App.propTypes = {
    children: PropTypes.node,
};

export default App;
