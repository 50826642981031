import React from 'react';
import Status from '../../../layouts/status/Status';
import TableBodyCell from '../../../layouts/table/TableBodyCell';
import TableRow from '../../../layouts/table/TableRow';
import { Memo } from '../../../models/Memo';
import { getDateString } from '../../../utils/date/Date';
import { DatatableComponentProps } from '../datatable/Datatable';

const ApprovalsHistoryTableBody = (props: DatatableComponentProps) => {
    if (!props.memos || !props.headers) return null;

    return (
        <>
            {props.memos.map((_memo: Memo, index) => {
                const memo = (_memo as unknown) as Record<string, any>;
                return (
                    <TableRow key={memo.id} navigateTo={`/approvals/${memo.memo_id}`}>
                        {props.headers!.map((header: any) => {
                            if (header.key === 'date_last_modified') {
                                return (
                                    <TableBodyCell>
                                        {getDateString(
                                            memo.date_last_modified ? memo.date_last_modified : memo.date_submitted,
                                        )}
                                    </TableBodyCell>
                                );
                            }
                            if (header.key === 'memo_status') {
                                return (
                                    <TableBodyCell>
                                        <Status type={memo.memo_status.toLowerCase()} />
                                    </TableBodyCell>
                                );
                            }
                            const field = memo.find(header.key);

                            if (field) {
                                return <TableBodyCell key={memo[header.key] + `${index}`}>{field.value}</TableBodyCell>;
                            }
                            return <TableBodyCell key={memo[header.key]}>{memo[header.key]}</TableBodyCell>;
                        })}
                    </TableRow>
                );
            })}
        </>
    );
};

export default ApprovalsHistoryTableBody;
