import PropTypes from 'prop-types';
import * as React from 'react';
import emptyMemo from '../../../assets/img/memo-empty-state.svg';
import { TableImgStyle } from '../../../layouts/table/Table';
import Text from '../../../layouts/typography/Text';
import Datatable from '../datatable/Datatable';
import ApprovalTableBody from './ApprovalsTableBody';

interface IForApprovalMemosProps {
    memo_type?: string;
    headers?: any;
    children?: React.ReactNode;
    start_date?: any;
    end_date?: any;
}

export const approvalsDefaultHeaders = [
    {
        label: 'Memo ID',
        key: 'memo_id',
    },
    {
        label: 'Memo type',
        key: 'used_template_name',
    },
    {
        label: 'Subject',
        key: 'memo_subject',
    },
    {
        label: 'Requested by',
        key: 'memo_owner_name',
    },
    {
        label: 'Date of Last Action',
        key: 'date_last_modified',
    },
    {
        label: 'Status',
        key: 'memo_status',
    },
];

// const ApprovalEmptyState = () => {
//     return (
//         <div className="w-full py-8 flex flex-col justify-center items-center text-center">
//             <img src={emptyMemo} style={TableImgStyle} />
//             <div className="mt-8">
//                 <Text>You have no memos to approve.</Text>
//             </div>
//         </div>
//     );
// };

const ForApprovalMemos: React.FunctionComponent<IForApprovalMemosProps> = (props: IForApprovalMemosProps) => {
    const [config, setConfig] = React.useState({
        endpoint: 'getForMyApprovalActiveWithUserID',
        templatePath: 'approvals.approval',
        queryParams: {
            limit: 15,
            page_no: 1,
            start_date: null,
            end_date: null,
            memo_type: '',
        },
    });

    const { memo_type, start_date, end_date } = props;

    React.useEffect(() => {
        setConfig({
            ...config,
            queryParams: {
                ...config.queryParams,
                memo_type: memo_type as string,
                start_date: start_date,
                end_date: end_date,
            },
        });
    }, [memo_type, start_date, end_date]);

    return (
        <div>
            <Datatable
                config={config}
                defaultHeaders={approvalsDefaultHeaders}
                bodyComponent={<ApprovalTableBody />}
                // emptyStateComponent={<ApprovalEmptyState />}
                pageRedirectTo="/approvals"
            />
        </div>
    );
};

ForApprovalMemos.propTypes = {
    children: PropTypes.node,
};

export default ForApprovalMemos;
