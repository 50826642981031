import firebase from 'firebase';
import PropTypes from 'prop-types';
import * as React from 'react';
import { navigate, useLocation } from '@reach/router';
import { useSelector } from 'react-redux';
import Container from '../../../layouts/container/Container';
import Select from '../../../layouts/select/Select';
import Tab from '../../../layouts/tab/Tab';
import TabLayout from '../../../layouts/tab/TabLayout';
import Subtitle from '../../../layouts/typography/Subtitle';
import Title from '../../../layouts/typography/Title';
import { RootState } from '../../../redux/RootReducer';
import ToWatchHistory from './MemosToWatchHistory';
import ToWatchMemos from './MemosToWatchMemos';
import { Template } from '../../../models/Template';
import Datepicker from '../../../layouts/datepicker/Datepicker';
import Button from '../../../layouts/buttons/Button';
import { ModalContent } from '../../../models/ModalContent';
import Modal from '../../../layouts/modals/Modal';
import ic_check from '../../../assets/img/ic_check.svg';
import ic_alert from '../../../assets/img/ic_alert.svg';
import Text from '../../../layouts/typography/Text';
import moment from 'moment';
import Preloader from '../../../layouts/preloaders/Preloader';
import DatatableFilters from '../datatable/DatatableFilters';

interface IMemosToWatchProps {
    children?: React.ReactNode;
}

const MemosToWatch: React.FunctionComponent<IMemosToWatchProps> = () => {
    const [selectItems, setSelectItems] = React.useState([
        {
            label: 'All',
            value: 'ALL',
        },
    ]);
    const { user } = useSelector((state: RootState) => state.Auth);
    const [selectedMemoType, setSelectedMemoType] = React.useState('');
    const remoteConfig = firebase.remoteConfig();
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const location = useLocation();
    const [modalContent, setModalContent] = React.useState<ModalContent>({
        isVisible: false,
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    });

    React.useEffect(() => {
        const loadTemplate = async () => {
            try {
                const getTemplates = firebase.app().functions('asia-east2').httpsCallable('get_templates');
                const http_response = await getTemplates({ user_id: user.uid });

                const response = http_response.data;
                if (response) {
                    const templates = response.map((item: Template | any) => {
                        return {
                            label: item.template_name,
                            value: item.template_id,
                            headers: item.headers,
                        };
                    });

                    setSelectItems(templates);
                }
            } catch (err) {
                console.error(err);
            }
        };

        loadTemplate();
    }, []);

    const resetPageNumber = () => {
        navigate(`${location.pathname}?page=1`);
    };

    const onSelectChanged = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        if (event.currentTarget.value) {
            setSelectedMemoType(event.currentTarget.value);
        }
        resetPageNumber();
    };

    /**
     * Handles datepicker event
     * @param event - React Datepicker event object
     */
    const onDateChanged = /* istanbul ignore next */ (event: any) => {
        const [start, end] = event;
        setStartDate(start);
        setEndDate(end);
        resetPageNumber();
    };

    const whichTable = /* istanbul ignore next */ (path: string) => {
        if (path === '/watch' || path === '/watch/active') {
            return 'active';
        } else if (path === '/watch/history') {
            return 'history';
        }

        return '';
    };

    const onDownloadXLSXClick = async () => {
        try {
            setModalContent({ ...modalContent, isVisible: true, loading: true });
            const downloadXLSX = firebase.app().functions('asia-east2').httpsCallable('createXlsxReport');
            const all_types = selectItems.map((item) => {
                return item.value;
            });

            const memo_types = selectedMemoType === '' || selectedMemoType === 'ALL' ? all_types : selectedMemoType;
            const memo_table = whichTable(location.pathname);
            const start_date: any = startDate;
            const end_date: any = endDate;

            const http_response = await downloadXLSX({
                memo_audience_type: 'watch',
                memo_table: memo_table,
                memo_types: memo_types,
                start_date: moment(start_date).toISOString(),
                end_date: moment(end_date).toISOString(),
            });

            const response = http_response.data;
            if (response.code === 'ok') {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_check,
                    title: 'Report is being generated.',
                    subtitle: `Check your email for the download link to the generated report.<br />It may take a few minutes to receive the email depending on the size of the report.`,
                    buttons: [
                        {
                            label: 'Back to the list',
                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                        },
                    ],
                });
            } else if (response.code === 'not-found') {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_alert,
                    title: response.message,
                    subtitle: response.details ? response.details : '',
                    buttons: [
                        {
                            label: 'Back to the list',
                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                        },
                    ],
                });
            } else {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_alert,
                    title: response.code,
                    subtitle: response.message ? response.message : '',
                    buttons: [
                        {
                            label: 'Back to the list',
                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                        },
                    ],
                });
            }
        } catch (err) {
            console.error(err);
            setModalContent({
                isVisible: true,
                loading: false,
                icon: '',
                title: `${err.code}: Unable to generate report.`,
                subtitle: `${err.message}. <br> Please contact memo-support@globe.com.ph`,
                buttons: [
                    {
                        label: 'Back to the list',
                        event: () => setModalContent({ ...modalContent, isVisible: false }),
                    },
                ],
            });
        }
    };

    return (
        <Container>
            <div className="w-full ">
                <Title>Memos to watch</Title>
                <div className="my-2">
                    <Subtitle>Never miss memos assigned to you</Subtitle>
                </div>
            </div>
            <div className="w-full mt-8">
                <TabLayout>
                    <Tab label="Memos to watch" href="/watch">
                        <div className="py-4">
                            <DatatableFilters
                                isEnabled={remoteConfig.getBoolean('is_table_options_visible')}
                                isDateFilterEnabled={remoteConfig.getBoolean('is_filter_by_date_range_enabled')}
                                isMemoTypeFilterEnabled={remoteConfig.getBoolean('is_filter_by_memo_type_enabled')}
                                isDownloadReportEnabled={
                                    remoteConfig.getBoolean('is_download_xlsx_enabled') && selectItems.length > 0
                                }
                                memoTypes={selectItems}
                                onDateChanged={onDateChanged}
                                onMemoTypeChanged={onSelectChanged}
                                onDownloadReportClicked={onDownloadXLSXClick}
                            />
                            <ToWatchMemos memo_type={selectedMemoType} start_date={startDate} end_date={endDate} />
                        </div>
                    </Tab>
                    <Tab label="History" href="/watch/history">
                        <div className="py-4">
                            <DatatableFilters
                                isEnabled={remoteConfig.getBoolean('is_table_options_visible')}
                                isDateFilterEnabled={remoteConfig.getBoolean('is_filter_by_date_range_enabled')}
                                isMemoTypeFilterEnabled={remoteConfig.getBoolean('is_filter_by_memo_type_enabled')}
                                isDownloadReportEnabled={
                                    remoteConfig.getBoolean('is_download_xlsx_enabled') && selectItems.length > 0
                                }
                                memoTypes={selectItems}
                                onDateChanged={onDateChanged}
                                onMemoTypeChanged={onSelectChanged}
                                onDownloadReportClicked={onDownloadXLSXClick}
                            />
                            <ToWatchHistory memo_type={selectedMemoType} start_date={startDate} end_date={endDate} />
                        </div>
                    </Tab>
                </TabLayout>
            </div>
            <Modal visible={modalContent.isVisible as boolean}>
                {modalContent.loading ? (
                    <Text>Please wait...</Text>
                ) : (
                    <div className={`w-full flex flex-col ${modalContent.icon ? `items-center` : `items-start`}`}>
                        {modalContent.icon ? (
                            <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                        ) : (
                            ''
                        )}
                        <Title type="h4">{modalContent.title}</Title>
                        <div className={`${modalContent.icon ? 'my-2 text-center' : 'my-0'}`}>
                            <Text type="innerHTML">{modalContent.subtitle}</Text>
                        </div>
                        {modalContent.buttons.length > 1 ? (
                            <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        weight="font-regular"
                                        onClick={modalContent.buttons[0].event}
                                    >
                                        {modalContent.buttons[0].label}
                                    </Button>
                                </div>
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        color="primary"
                                        weight="font-regular"
                                        type="submit"
                                        onClick={modalContent.buttons[1].event}
                                    >
                                        {modalContent.buttons[1].label}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="my-2">
                                <Button
                                    type="button"
                                    color="primary"
                                    weight="font-regular"
                                    onClick={modalContent.buttons[0].event}
                                >
                                    {modalContent.buttons[0].label}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        </Container>
    );
};

MemosToWatch.propTypes = {
    children: PropTypes.node,
};

export default MemosToWatch;
