import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import _ from 'lodash';
import { MemoLog, MemoLogEntry } from '../models/MemoLog';
import { Reply } from '../models/Reply';

export interface HttpState extends firebase.functions.HttpsCallableResult {
    http_request_state: string;
    data: any[];
    error: string | null;
}

export interface ReplyState extends HttpState {
    data: Reply[];
}

export interface ReplyableMemoLogEntry extends MemoLogEntry {
    replies: ReplyState;
}
export interface ReplyableMemoLogGroup extends MemoLog {
    logs: ReplyableMemoLogEntry[];
}

export interface MemoLogsState extends HttpState {
    data: ReplyableMemoLogGroup[];
    is_all_expanded: boolean;
}

export const getLogs = createAsyncThunk<MemoLogsState, string, { rejectValue: MemoLogsState }>(
    'memo-logs/get',
    async (payload, { rejectWithValue }) => {
        try {
            const getFn = firebase.app().functions('asia-east2').httpsCallable('get_memo_logs');
            const httpResponse = await getFn({ memo_id: payload });
            return {
                http_request_state: 'fulfilled',
                data: _.map(httpResponse.data as ReplyableMemoLogGroup[], (log) => {
                    return {
                        ...log,
                        expanded: log.is_current,
                        logs: _.map(log.logs, (l) => ({
                            ...l,
                            replies: {
                                http_request_state: 'pending',
                                data: [],
                                error: null,
                            },
                        })),
                    };
                }),
                is_all_expanded: false,
                error: null,
            };
        } catch (err: any) {
            console.error(err);
            return rejectWithValue({
                http_request_state: 'rejected',
                data: [],
                error: err.message,
                is_all_expanded: false,
            });
        }
    },
);

interface ReplyArgs {
    log_id: string;
    memo_id: string;
    start_after: string | null;
    index: number;
    level_no: number;
}

export const getReplies = createAsyncThunk<ReplyState, ReplyArgs, { rejectValue: ReplyState; pendingMeta: ReplyState }>(
    'memo-logs/getReplies',
    async (payload, { rejectWithValue }) => {
        try {
            const getFn = firebase.app().functions('asia-east2').httpsCallable('get_replies');
            const httpResponse = await getFn({
                log_id: payload.log_id,
                memo_id: payload.memo_id,
                start_after: payload.start_after,
                limit: 3,
            });
            return {
                http_request_state: 'fulfilled',
                data: httpResponse.data,
                error: null,
            };
        } catch (err: any) {
            console.error(err);
            return rejectWithValue({
                http_request_state: 'rejected',
                data: [],
                error: err.message,
            });
        }
    },
);

export const createReply = createAsyncThunk<
    Reply,
    Reply & { meta: { level_no: number; index: number } },
    { rejectValue: { message: string } }
>('replies/create', async (payload, { rejectWithValue }) => {
    try {
        const createFn = firebase.app().functions('asia-east2').httpsCallable('create_reply');
        const httpResponse = await createFn(payload);

        return httpResponse.data;
    } catch (err: any) {
        console.error(err);
        return rejectWithValue({ message: JSON.stringify(err) });
    }
});

const MemoLogsSlice = createSlice({
    name: 'memo-logs',
    initialState: {
        http_request_state: 'idle',
        is_all_expanded: false,
        data: [],
        error: null,
    } as MemoLogsState,
    reducers: {
        toggleExpansion(state, action: PayloadAction<boolean>) {
            state.data = state.data.map((logGroup) => ({ ...logGroup, expanded: action.payload }));
            state.is_all_expanded = action.payload;
        },
        clearReplies(state) {
            state.data = _.map(state.data, (log) => {
                return {
                    ...log,
                    logs: _.map(log.logs, (l) => ({
                        ...l,
                        replies: {
                            http_request_state: 'idle',
                            data: [],
                            error: null,
                        },
                    })),
                };
            });
        },
        clearLogs(state) {
            state.http_request_state = 'idle';
            state.data = [];
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLogs.pending, (state) => {
            state.http_request_state = 'pending';
            state.data = [];
            state.error = null;
        });
        builder.addCase(getLogs.fulfilled, (state, action: PayloadAction<MemoLogsState>) => {
            state.http_request_state = 'fulfilled';
            state.data = action.payload.data;
            state.error = null;
        });
        builder.addCase(getLogs.rejected, (state, action: PayloadAction<MemoLogsState | undefined>) => {
            state.http_request_state = 'rejected';
            state.data = [];
            state.error = action.payload!.error;
        });
        builder.addCase(
            getReplies.pending,
            (state, action: PayloadAction<(ReplyState & { meta: any }) | undefined>) => {
                const index = (action as any).meta.arg.index;
                const level_no = (action as any).meta.arg.level_no;

                if (state.data[level_no] && state.data[level_no].logs[index]) {
                    state.data[level_no].logs[index].replies.http_request_state = 'pending';
                    state.data[level_no].logs[index].replies.error = null;
                }
            },
        );
        builder.addCase(getReplies.fulfilled, (state, action: PayloadAction<ReplyState>) => {
            const index = (action as any).meta.arg.index;
            const level_no = (action as any).meta.arg.level_no;

            if (state.data[level_no] && state.data[level_no].logs[index]) {
                state.data[level_no].logs[index].replies.http_request_state = 'fulfilled';
                state.data[level_no].logs[index].replies.data = [
                    ...state.data[level_no].logs[index].replies.data,
                    ...action.payload.data,
                ];
                state.data[level_no].logs[index].replies.error = null;
            }
        });
        builder.addCase(getReplies.rejected, (state, action: PayloadAction<ReplyState | undefined>) => {
            const index = (action as any).meta.arg.index;
            const level_no = (action as any).meta.arg.level_no;

            if (state.data[level_no] && state.data[level_no].logs[index]) {
                state.data[level_no].logs[index].replies.http_request_state = 'rejected';
                state.data[level_no].logs[index].replies.error = action.payload!.error;
            }
        });
        builder.addCase(
            createReply.pending,
            (state, action: PayloadAction<(ReplyState & { meta: any }) | undefined>) => {
                const { level_no, index } = (action as any).meta.arg.meta;

                if (state.data[level_no] && state.data[level_no].logs[index]) {
                    state.data[level_no].logs[index].replies.error = null;
                }
            },
        );
        builder.addCase(createReply.fulfilled, (state, action: PayloadAction<Reply>) => {
            const { level_no, index } = (action as any).meta.arg.meta;
            const { locked, ...reply } = action.payload;
            if (state.data[level_no] && state.data[level_no].logs[index]) {
                state.data[level_no].logs[index].replies.data = [
                    reply,
                    ...state.data[level_no].logs[index].replies.data,
                ];
                state.data[level_no].logs[index].replies.error = null;
                state.data[level_no].logs[index].locked = locked;
            }
        });
        builder.addCase(createReply.rejected, (state, action: PayloadAction<{ message: string } | undefined>) => {
            const { level_no, index } = (action as any).meta.arg.meta;
            state.data[level_no].logs[index].replies.error = action.payload!.message;
        });
    },
});

export const { toggleExpansion, clearLogs, clearReplies } = MemoLogsSlice.actions;

export default MemoLogsSlice.reducer;
