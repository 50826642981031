import { TemplateCondition } from '../../models/TemplateContent';

type EvalCallback = (cond: TemplateCondition) => boolean;

export const evalTruthList = (conditions: TemplateCondition[], callback: EvalCallback) => {
    const truthTable: boolean[] = [];

    for (const condition of conditions) {
        truthTable.push(callback(condition));
    }

    return truthTable;
};
