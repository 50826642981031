import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

export const useNotificationsUnread = (initialValue: number, email?: string) => {
    const [unread, setUnread] = React.useState(initialValue);

    React.useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection('notifications')
            .where('email', '==', email)
            .where('isRead', '==', false)
            .onSnapshot((data) => {
                setUnread(data.size);
            });
        return () => {
            unsubscribe();
        };
    }, []);

    return { unread };
};
