import React from 'react';
import carousel_1 from '../../assets/img/carousel_1.svg';
import carousel_2 from '../../assets/img/carousel_2.svg';
import carousel_3 from '../../assets/img/carousel_3.svg';

const Carousel = () => {
    const buttonsContainerRef = React.useRef<HTMLDivElement>(null);
    const artContainerRef = React.useRef<HTMLDivElement>(null);

    const updateActiveButton = () => {
        /* istanbul ignore if */
        if (!buttonsContainerRef.current) return;

        const active = buttonsContainerRef.current.querySelector('.animate-carousel-button-enter');

        /* istanbul ignore else */
        if (active) {
            active.classList.remove('animate-carousel-button-enter');
            active.classList.add('animate-carousel-button-exit');
        }

        if (active?.nextElementSibling) {
            active.nextElementSibling.classList.remove('animate-carousel-button-exit');
            active.nextElementSibling.classList.add('animate-carousel-button-enter');
        } else {
            buttonsContainerRef.current!.firstElementChild!.classList.remove('animate-carousel-button-exit');
            buttonsContainerRef.current!.firstElementChild!.classList.add('animate-carousel-button-enter');
        }
    };

    const updateActiveArt = () => {
        /* istanbul ignore if */
        if (!artContainerRef.current) return;
        const active = artContainerRef.current.querySelector('.animate-carousel-art-enter');

        /* istanbul ignore else */
        if (active) {
            active.classList.remove('animate-carousel-art-enter');
            active.classList.add('animate-carousel-art-exit');
        }

        if (active?.nextElementSibling) {
            active.nextElementSibling.classList.remove('animate-carousel-art-exit');
            active.nextElementSibling.classList.add('animate-carousel-art-enter');
        } else {
            artContainerRef.current!.firstElementChild!.classList.remove('animate-carousel-art-exit');
            artContainerRef.current!.firstElementChild!.classList.add('animate-carousel-art-enter');
        }
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            updateActiveArt();
            updateActiveButton();
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="w-full relative flex flex-col items-center">
            <h4
                className="w-3/5 text-center text-xl font-bold absolute -top-80 lg:-top-90"
                style={{ color: '#1352AB' }}
            >
                Welcome to your all-in-one tool for memo tracking & approval requests
            </h4>
            <div
                ref={artContainerRef}
                className="relative w-full flex justify-center items-center"
                data-testid="art-container"
            >
                <div className="w-3/4 flex flex-col items-center absolute opacity-0 animate-carousel-art-enter">
                    <img className="lg:w-2/4" src={carousel_1} alt="Simplify memo creation through templates" />
                    <p className="text-lg font-regular mt-6">Simplify memo creation through templates</p>
                </div>
                <div className="w-3/4 flex flex-col items-center absolute opacity-0">
                    <img className="lg:w-2/4" src={carousel_2} alt="Streamline multi-level approval processes" />
                    <p className="text-lg font-regular mt-4">Streamline multi-level approval processes</p>
                </div>
                <div className="w-3/4 flex flex-col items-center absolute opacity-0">
                    <img className="lg:w-3/5" src={carousel_3} alt="Monitor memos through your dashboard" />
                    <p className="text-lg font-regular mt-6">Monitor memos through your dashboard</p>
                </div>
            </div>
            <div ref={buttonsContainerRef} className="flex flex-row gap-1 absolute top-56 lg:top-60">
                <button id="1" className="w-3 h-3 rounded-full bg-blue-lglight animate-carousel-button-enter" />
                <button id="2" className="w-3 h-3 rounded-full bg-blue-lglight" />
                <button id="3" className="w-3 h-3 rounded-full bg-blue-lglight" />
            </div>
        </div>
    );
};

export default Carousel;
