import * as React from 'react';
import PropTypes from 'prop-types';

interface INavItemInactiveProps {
    label: string;
}

const SideMenuItemInactive: React.FunctionComponent<INavItemInactiveProps> = (props) => {
    return (
        <div className="w-full bg-blue-light px-4 py-2 cursor-pointer flex justify-start items-center text-sm font-regular">
            {props.label}
        </div>
    );
};

SideMenuItemInactive.propTypes = {
    label: PropTypes.string.isRequired,
};

export default SideMenuItemInactive;
