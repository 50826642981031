import React from 'react';
import Title from '../../../layouts/typography/Title';

interface IMemoViewHeaderTitle {
    title?: string;
}
const MemoViewHeaderTitle = (props: IMemoViewHeaderTitle) => {
    return (
        <Title>
            <p className="break-words" data-testid="memo-view-header-title">
                {props.title}
            </p>
        </Title>
    );
};

export default MemoViewHeaderTitle;
