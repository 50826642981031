import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Text from '../typography/Text';

export interface BreadcrumbsItem {
    label: string;
    link: string;
}

interface IBreadcrumbProps {
    items: BreadcrumbsItem[];
}

const Breadcrumb: React.FunctionComponent<IBreadcrumbProps> = (props) => {
    const { items } = props;
    return (
        <ul className="w-full flex flex-row items-center">
            {items.map((item, index) => {
                return (
                    <div key={index} className="flex flex-row">
                        {index < items.length - 1 ? (
                            <div className="flex flex-row items-center">
                                <Link to={item.link}>
                                    <li>
                                        <Text color="secondary">
                                            <p className="pr-2"> {item.label} </p>
                                        </Text>
                                    </li>
                                </Link>
                                <Text color="secondary">
                                    <p className="pr-2"> &gt; </p>
                                </Text>
                            </div>
                        ) : (
                            <div className="flex flex-row">
                                <Link to={item.link}>
                                    <li>
                                        <Text>
                                            <p className="pr-2 font-bold"> {item.label} </p>
                                        </Text>
                                    </li>
                                </Link>
                            </div>
                        )}
                    </div>
                );
            })}
        </ul>
    );
};

Breadcrumb.propTypes = {
    items: PropTypes.array.isRequired,
};
export default Breadcrumb;
