import React from 'react';
import TableHeaderCell from '../../../layouts/table/TableHeaderCell';
import TableRow from '../../../layouts/table/TableRow';
import Tooltip from '../../../layouts/tooltip/Tooltip';
import { DatatableComponentProps, ITableHeader } from '../datatable/Datatable';
import ic_info from '../../../assets/img/ic_info.svg';

const ActiveMemosTableHeader = (props: DatatableComponentProps) => {
    if (!props.headers) {
        return <TableRow type="header" />;
    }
    return (
        <TableRow type="header">
            {props.headers!.map((header: ITableHeader) => {
                return (
                    <TableHeaderCell key={header.key}>
                        {header.key === 'action' ? (
                            <>
                                <div className="flex flex-row items-center">
                                    {header.label}
                                    <div className="hidden md:flex">
                                        <Tooltip type="info">
                                            <img
                                                src={ic_info}
                                                className="mx-1"
                                                style={{ width: '12px', height: '12px' }}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div>{header.label}</div>
                        )}
                    </TableHeaderCell>
                );
            })}
        </TableRow>
    );
};

export default ActiveMemosTableHeader;
