import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import more_horizontal from '../../../../assets/img/more-horizontal.svg';

/**
 * Represents the props for TemplateSectionHeader
 */
interface ITemplateSectionHandlerProps {
    id: string;
    name: string;
    collapsed: boolean;
    dragHandleProps?: DraggableProvidedDragHandleProps;
    onCollapseClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isRemovable?: boolean;
    onRemoveClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Renders a Template Section Header.
 * @param props
 * @returns
 */
const TemplateSectionHeader = (props: ITemplateSectionHandlerProps) => {
    /**
     * This renders the header that takes in a DraggableProvided object
     * from Template Section.
     *
     * This enables the drag indicator to be customized from a separate view.
     */
    return (
        <div className="w-full relative flex flex-row items-center justify-between py-2 px-4 bg-gray-dark">
            <div {...props.dragHandleProps} className="w-auto h-auto flex flex-row items-center">
                {/* Section Draggable Indicator */}
                <div style={{ top: '-1px' }} className="w-auto h-auto flex flex-col cursor-pointer mr-3 relative">
                    <img style={{ marginBottom: '1.5px' }} src={more_horizontal} alt="drag here" />
                    <img style={{ marginTop: '1.5px' }} src={more_horizontal} alt="drag here" />
                </div>
                <p className="text-sm text-white font-bold">{props.name}</p>
            </div>

            <div className="w-auto h-auto flex flex-row items-center">
                {props.isRemovable && (
                    <button
                        type="button"
                        style={{ top: '-2px' }}
                        className="w-6 h-6 text-white font-bold text-lg relative mr-4"
                        onClick={props.onRemoveClick}
                        data-parentId={props.id}
                        value={props.id}
                    >
                        &times;
                    </button>
                )}
                <button
                    type="button"
                    style={{
                        top: props.collapsed ? '2px' : '0px',
                        transform: `rotate(${props.collapsed ? '0deg' : '180deg'})`,
                    }}
                    className="w-6 h-6 text-white font-bold text-lg relative"
                    onClick={props.onCollapseClick}
                >
                    &#x2303;
                </button>
            </div>
        </div>
    );
};

export default TemplateSectionHeader;
