import * as React from 'react';
import PropTypes from 'prop-types';

interface INavItemInactiveProps {
    label: string;
}

const NavItemInactive: React.FunctionComponent<INavItemInactiveProps> = (props) => {
    return (
        <div className="w-full h-xl md:h-full px-4 py-2 cursor-pointer flex flex-row md:justify-center justify-start items-center text-sm text-white font-regular">
            {props.label}
        </div>
    );
};

NavItemInactive.propTypes = {
    label: PropTypes.string.isRequired,
};

export default NavItemInactive;
