import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/RootReducer';

interface IMemoViewContent {
    children: React.ReactNode;
}

const MemoViewContent = (props: IMemoViewContent) => {
    const { memo } = useSelector((state: RootState) => state.MemoView);
    return (
        <div className="w-full mr-4 flex flex-col md:flex-row" id="page-content" test-id={memo.memo_id}>
            {props.children}
        </div>
    );
};

export default MemoViewContent;
