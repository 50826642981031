import * as React from 'react';
import PropTypes from 'prop-types';
import TabActive from './TabActive';
import TabInactive from './TabInactive';
import { Link } from '@reach/router';

interface ITabItemProps extends React.ComponentPropsWithoutRef<'li'> {
    label: string;
    activeTab?: string;
    href: string;
    isActive?: boolean;
}

const TabItem: React.FunctionComponent<ITabItemProps> = (props) => {
    const { label, href, isActive } = props;
    return (
        <Link to={href}>
            <li onClick={props.onClick}>{isActive ? <TabActive label={label} /> : <TabInactive label={label} />}</li>
        </Link>
    );
};

TabItem.propTypes = {
    label: PropTypes.string.isRequired,
    activeTab: PropTypes.string,
    href: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    onClick: PropTypes.any,
};
export default TabItem;
