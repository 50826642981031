/**
 * ContactsSlice is for user's Google Contacts controller.
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from 'firebase/app';

export interface ApproverState {
    remarks: string;
    http_request: { state: string; message: string; status_code?: number | firebase.functions.FunctionsErrorCode };
}

interface ApprovalPayload {
    user_id: string;
    memo_id: string;
    remarks: string;
    action: string;
    sequence_no?: number;
}

interface ApprovalResponse {
    message: string;
    code: any;
}

const initialState: ApproverState = {
    remarks: '',
    http_request: {
        state: 'idle',
        message: '',
    },
};

export const updateMemoStatus = createAsyncThunk<
    ApprovalResponse,
    Partial<ApprovalPayload> | ApprovalPayload,
    {
        rejectValue: ApprovalResponse;
    }
>('memos/updateMemoStatus', async (payload, { rejectWithValue }) => {
    try {
        if (!payload.action) {
            throw new Error('An action type is required for this request.');
        }
        const updateAction = await firebase.app().functions('asia-east2').httpsCallable(payload.action);
        const response = await updateAction(payload);

        return response.data;
    } catch (err) {
        return rejectWithValue({
            code: (err as firebase.functions.HttpsError).code,
            message: (err as firebase.functions.HttpsError).message,
        });
    }
});

const ApproverSlice = createSlice({
    name: 'ContactsSlice',
    initialState,
    reducers: {
        setRemarks(state, action: PayloadAction<string>) {
            state.remarks = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateMemoStatus.pending, (state) => {
            state.http_request.state = 'pending';
            state.http_request.message = 'Approval process ongoing.';
        });
        builder.addCase(updateMemoStatus.rejected, (state, action) => {
            state.http_request.state = 'rejected';
            if (action.payload) {
                state.http_request.status_code = action.payload.code;
                state.http_request.message = action.payload.message;
            }
        });
        builder.addCase(updateMemoStatus.fulfilled, (state, action) => {
            state.http_request.state = 'fulfilled';
            state.http_request.message = action.payload.message;
            state.http_request.status_code = action.payload.code;
        });
    },
});

export const { setRemarks } = ApproverSlice.actions;

export default ApproverSlice.reducer;
