/**
 * AuthSlice is for auth managament. This includes action and reducer for auth data available in Firebase.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../models/User';
import { UserState } from '../models/UserState';

const initialState: UserState = {
    user: {
        displayName: null,
        email: null,
        phoneNumber: null,
        photoURL: null,
        providerId: '',
        uid: '',
    },
    date_refreshed: '',
};

const AuthSlice = createSlice({
    name: 'AuthSlice',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<User>) {
            state.date_refreshed = new Date().toISOString();
            state.user = action.payload;
        },
    },
});

export const { setUser } = AuthSlice.actions;

export default AuthSlice.reducer;
