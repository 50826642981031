import { navigate } from '@reach/router';
import firebase from 'firebase/app';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import memo_icon from '../../../assets/img/ic_my-approvals.svg';
import MemoFeed from '../../../layouts/memo-feed/MemoFeed';
import { Memo } from '../../../models/Memo';
import { setApprovalItems, setApprovalState } from '../../../redux/MemoFeedSlice';
import { RootState } from '../../../redux/RootReducer';
interface IForMyApprovalSelectionProps {
    children?: React.ReactNode;
}

const ForMyApprovalSelection: React.FunctionComponent<IForMyApprovalSelectionProps> = () => {
    const { approvals } = useSelector((state: RootState) => state.MemoFeed);
    const dispatch = useDispatch();

    React.useEffect(() => {
        const loadMemos = async () => {
            try {
                const requestPayload: any = {
                    limit: 15,
                    page_no: 1,
                };

                const getForApprovalMemos = firebase
                    .app()
                    .functions('asia-east2')
                    .httpsCallable('getForMyApprovalActiveWithUserID');
                const http_response = await getForApprovalMemos(requestPayload);
                const response = http_response.data.memos;

                let memos = [];
                memos = response.map((memo: Memo) => {
                    return {
                        title: memo.memo_subject,
                        memo_id: memo.memo_id,
                        memo_type: memo.used_template_name,
                        owner: memo.memo_owner_name,
                        timestamp: memo.date_submitted,
                        type: 'kanban_timeago',
                    };
                });
                dispatch(setApprovalItems(memos));
                dispatch(setApprovalState('fulfilled'));
            } catch (err) {
                console.error(err);
                dispatch(setApprovalItems([]));
                dispatch(setApprovalState('rejected'));
            }
        };
        loadMemos();
    }, []);

    const onItemClick = (event: React.MouseEvent<HTMLElement>) => {
        const id = event.currentTarget.dataset.value;
        const parent = '/approvals';
        navigate(parent + '/' + id);
    };

    return (
        <MemoFeed
            title="For my approval"
            icon={memo_icon}
            redirectTo="/approvals"
            items={approvals.items}
            state={approvals.state}
            count={approvals.items.length}
            actionTitle="View all memos"
            onItemClick={onItemClick}
            feed_type="forMyApproval"
        />
    );
};

export default ForMyApprovalSelection;
