import React from 'react';

interface ICodePropts {
    children?: React.ReactNode;
}
const Code = (props: ICodePropts) => {
    return <div className="w-full h-auto p-4 bg-gray-light rounded">{props.children}</div>;
};

export default Code;
