import * as React from 'react';
import PropTypes from 'prop-types';
import { flavor } from '../../AppFlavor';

interface INavItemActiveProps {
    label: string;
}

export const Triangle: React.CSSProperties = {
    position: 'relative',
    top: '0px',
    borderStyle: 'solid',
    borderWidth: '12px 12px 0px 12px',
    borderColor: `${flavor.theme.navigation.active.borderColor} transparent transparent transparent`,
};

const NavItemActive: React.FunctionComponent<INavItemActiveProps> = (props) => {
    return (
        <div className="w-full h-xl flex flex-col items-center md:h-xxxxl">
            <div
                className={`w-full h-full px-4 py-2 cursor-pointer flex md:justify-center justify-start items-center text-sm font-bold text-white md:${flavor.theme.navigation.active.backgroundColor}`}
            >
                {props.label}
            </div>
            <div className="hidden md:flex" style={Triangle}></div>
        </div>
    );
};

NavItemActive.propTypes = {
    label: PropTypes.string.isRequired,
};

export default NavItemActive;
