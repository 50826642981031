import React from 'react';
import Text from '../../layouts/typography/Text';
import { MemoFeedItems } from '../../models/MemoFeedItems';
import KanbanCard from '../cards/kanban/KanbanCard';
import Preloader from '../preloaders/Preloader';
import './MemoFeed.css';
import MemoFeedSummary from './MemoFeedSummary';

interface IMemoFeedContainerProps {
    items: Array<MemoFeedItems>;
    redirectTo: string;
    returned_count?: number;
    onItemClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const MemoFeedList = (props: IMemoFeedContainerProps) => {
    const { returned_count } = props;

    if (props.items.length > 0) {
        return (
            <div className="w-full p-2 memo-feed overflow-y-scroll">
                {(() => {
                    if (returned_count !== undefined && returned_count > 0) {
                        return <MemoFeedSummary type="returned" count={returned_count} />;
                    } else if (returned_count === undefined || returned_count === 0) {
                        return '';
                    }
                    return <Preloader type="dots" />;
                })()}

                {props.items.map(({ type, title, timestamp, memo_id, memo_type, memo_status, owner }) => (
                    <div
                        className="cursor-pointer px-4 py-2"
                        key={memo_id}
                        data-value={memo_id}
                        onClick={props.onItemClick}
                        data-testid="memo-feed-item"
                    >
                        <KanbanCard
                            timestamp={timestamp}
                            type={type}
                            title={title}
                            memo_id={memo_id}
                            memo_type={memo_type}
                            owner={owner}
                            status={memo_status}
                        />
                    </div>
                ))}
            </div>
        );
    }
    return (
        <div
            className="w-full p-2 memo-feed flex flex-col justify-center items-center"
            style={{ height: '600px', overflowY: 'hidden' }}
            data-testid="memo-feed"
        >
            <Text>
                <p>No memos listed.</p>
            </Text>
        </div>
    );
};

export default MemoFeedList;
