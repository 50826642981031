import * as React from 'react';
import PropTypes from 'prop-types';
import { error } from 'console';

interface ITableHeaderCellProps extends React.ComponentPropsWithoutRef<'th'> {
    children?: React.ReactNode;
}

const TableHeaderCell: React.FunctionComponent<ITableHeaderCellProps> = (props) => {
    return (
        <th className={`py-4 px-3 text-sm font-bold text-left`} data-testid="table-header-cell">
            {props.children}
        </th>
    );
};

TableHeaderCell.propTypes = {
    children: PropTypes.node,
};

export default TableHeaderCell;
