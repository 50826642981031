import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import _, { template } from 'lodash';
import { nanoid } from 'nanoid';
import { Category } from '../models/Category';
import { Template } from '../models/Template';
import { TemplateContent } from '../models/TemplateContent';
import firebase from 'firebase/app';

const initialID = nanoid();
export interface FormBuilderError {
    sectionId: string;
    sectionIndex: number;
    form_item: {
        id: string;
        message: string;
    };
}
export interface FormBuilderActiveField extends TemplateContent {
    sectionId: string;
    sectionIndex: number;
}
export interface FormBuilderState extends Template {
    _editing: FormBuilderActiveField | null;
    _categories: Category[];
    _http_request_state: string;
    _http_template_request_state: string;
    _http_category_request_state: string;
    errors: FormBuilderError[] | null;
    hasRTE?: boolean;
    hasFileUpload?: boolean;
    workflow: any;
}
export const initialState: FormBuilderState = {
    api_version: 'v2',
    template_id: '',
    template_name: '',
    creator_user_id: '',
    status: '',
    date_created: '',
    date_last_modified: '',
    template_content: [
        {
            field_id: initialID,
            field_name: 'Untitled',
            field_type: 'SECTION',
            validations: {
                required: false,
            },
            sectionIndex: 0,
            sectionId: initialID,
            value: [
                {
                    field_id: 'TEXT_SUBJECT',
                    field_name: 'Memo Subject',
                    annotation: 'Briefly describe your memo',
                    placeholder: 'Your memo subject here',
                    field_type: 'TEXT',
                    validations: {
                        required: true,
                        min_length: 0,
                        max_length: 150,
                    },
                    sectionId: initialID,
                    sectionIndex: 0,
                },
            ],
            _metadata: [
                {
                    x: 0,
                    y: 0,
                    w: 2,
                    h: 3,
                    i: 'TEXT_SUBJECT',
                    minW: 1,
                    maxW: 2,
                    minH: 3,
                    maxH: 3,
                },
            ],
        },
    ],
    template_code: '',
    workflow: null,
    guidelines: '<ul><li>Your list of guidelines here.</li></ul>',
    _editing: null,
    _categories: [],
    _http_request_state: '',
    _http_template_request_state: '',
    _http_category_request_state: '',
    errors: null,
    hasRTE: false,
    hasFileUpload: false,
};

export const getCategories = createAsyncThunk('categories/getAll', async (query: any, { rejectWithValue }) => {
    try {
        const endpoint = 'admin-get_categories';
        const fetch = firebase.app().functions('asia-east2').httpsCallable(endpoint);
        const httpResponse = await fetch(query);
        return httpResponse;
    } catch (err: any) {
        return rejectWithValue({
            message: err.message,
            status_code: err.status_code,
            data: [],
        });
    }
});

export const verifyTemplateCode = createAsyncThunk(
    'categories/verify',
    async (template_code: string, { rejectWithValue }) => {
        try {
            const endpoint = 'admin-check_template_code';
            const fetch = firebase.app().functions('asia-east2').httpsCallable(endpoint);
            const httpResponse = await fetch({ template_code: template_code });
            return httpResponse;
        } catch (err: any) {
            return rejectWithValue({
                message: err.message,
                status_code: err.status_code,
                data: [],
            });
        }
    },
);

export const saveTemplate = createAsyncThunk(
    'templates/publish',
    async (data: { template: Template; action: string; endpoint: string }, { rejectWithValue }) => {
        try {
            const fetch = firebase.app().functions('asia-east2').httpsCallable(data.endpoint);
            const payload = {
                ...data.template,
                action: data.action,
            };
            const httpResponse = await fetch(payload);
            return httpResponse;
        } catch (err: any) {
            return rejectWithValue({
                message: err.message,
                status_code: err.status_code,
                data: [],
            });
        }
    },
);

export const getTemplateById = createAsyncThunk(
    'templates/get',
    async (data: { id: string; version_id?: string }, { rejectWithValue }) => {
        try {
            const endpoint = 'admin-get_template_by_id';
            const fetch = firebase.app().functions('asia-east2').httpsCallable(endpoint);

            let payload: any = {
                template_id: data.id,
            };

            if (data.version_id) {
                payload = {
                    ...payload,
                    version_id: data.version_id,
                };
            }
            const httpResponse = await fetch(payload);
            return httpResponse.data;
        } catch (err: any) {
            return rejectWithValue({
                message: err.message,
                status_code: err.status_code,
                data: [],
            });
        }
    },
);

export const updateSectionIndexes = (template_content: TemplateContent[]) => {
    const contents = _.cloneDeep(template_content);
    for (let i = contents.length - 1; i >= 0; i--) {
        contents[i] = {
            ...contents[i],
            sectionIndex: i,
            value: _.map(contents[i].value, (section) => {
                return {
                    ...section,
                    sectionIndex: i,
                };
            }),
        };
    }
    return contents;
};

const FormBuilderSlice = createSlice({
    name: 'FormBuilderSlice',
    initialState: initialState,
    reducers: {
        addSection(state, action: PayloadAction<TemplateContent>) {
            // Create a copy of the current template contents
            const contents = Array.from(state.template_content);

            state.template_content = [...contents, action.payload];
        },
        updateSection(
            state,
            action: PayloadAction<{
                index: number;
                content: TemplateContent;
            }>,
        ) {
            const content = {
                ...state.template_content[action.payload.index],
                ...action.payload.content,
            };
            state.template_content[action.payload.index] = content;
        },
        updateSectionMetadata(state, action: PayloadAction<{ index: number; metadata: any }>) {
            state.template_content[action.payload.index]._metadata = action.payload.metadata;
        },
        moveSection(
            state,
            action: PayloadAction<{
                sourceIndex: number;
                destinationIndex: number;
                data: TemplateContent;
            }>,
        ) {
            // Copies the current section list
            const contents = Array.from(state.template_content);

            // // Removes the section from its index
            // contents.splice(action.payload.sourceIndex, 1);

            // // Moves the section to its destined index.
            // contents.splice(action.payload.destinationIndex, 0, { ...action.payload.data });

            // Get the section that will be moved
            let sourceSection = action.payload.data;

            // Update the sectionIndex of the section that will be moved.
            sourceSection = {
                ...sourceSection,
                sectionIndex: action.payload.destinationIndex,
                value: _.map(sourceSection.value, (field) => {
                    return {
                        ...field,
                        sectionIndex: action.payload.destinationIndex,
                    };
                }),
            };

            // Removes the section from its index
            contents.splice(action.payload.sourceIndex, 1);

            // Moves the section to its destined index.
            contents.splice(action.payload.destinationIndex, 0, { ...sourceSection });

            // Updates the state
            state.template_content = updateSectionIndexes(contents);
        },
        deleteSection(state, action: PayloadAction<string>) {
            const contents = state.template_content.filter((content) => content.field_id !== action.payload);
            state.template_content = updateSectionIndexes(contents);
        },
        setActiveField(state, action: PayloadAction<FormBuilderActiveField | null>) {
            state._editing = action.payload;
        },
        setHiddenFields(state, action: PayloadAction<string[]>) {
            state.hiddenFields = action.payload;
        },
        updateField(
            state,
            action: PayloadAction<{
                sectionIndex: number;
                formItemIndex: number;
                content: TemplateContent | any;
            }>,
        ) {
            const field =
                state.template_content[action.payload.sectionIndex].value[action.payload.formItemIndex].value || '';
            const value = {
                ...field,
                ...action.payload.content,
            };
            state.template_content[action.payload.sectionIndex].value[action.payload.formItemIndex] = value;
        },
        updateTemplate(state, action: PayloadAction<FormBuilderState>) {
            return (state = {
                ...state,
                ...action.payload,
            });
        },
        setErrors(state, action: PayloadAction<FormBuilderError>) {
            if (state.errors === null) {
                state.errors = [action.payload];
            } else {
                const errors = _.cloneDeep(state.errors);
                const c = _.find(errors, { form_item: { id: action.payload.form_item.id } });
                if (!c) {
                    state.errors = [...state.errors, action.payload];
                }
            }
        },
        removeError(state, action: PayloadAction<string>) {
            if (state.errors !== null) {
                state.errors = state.errors.filter((error) => error.form_item.id !== action.payload);
            }
        },
        setHasRTE(state, action: PayloadAction<boolean>) {
            state.hasRTE = action.payload;
        },
        setHasFileUpload(state, action: PayloadAction<boolean>) {
            state.hasFileUpload = action.payload;
        },
        resetFormBuilder(state) {
            state.api_version = initialState.api_version;
            state.template_id = initialState.template_id;
            state.template_name = initialState.template_name;
            state.creator_user_id = initialState.creator_user_id;
            state.status = initialState.status;
            state.date_created = initialState.date_created;
            state.date_last_modified = initialState.date_last_modified;
            state.template_content = initialState.template_content;
            state.template_code = initialState.template_code;
            state.workflow = initialState.workflow;
            state.guidelines = initialState.guidelines;
            state.category_id = initialState.category_id;
            state.category_name = initialState.category_name;
            state.description = initialState.description;
            state._editing = null;
            state._categories = [];
            state._http_request_state = '';
            state._http_template_request_state = '';
            state._http_category_request_state = '';
            state.errors = null;
            state.hasRTE = false;
            state.hasFileUpload = false;
        },
    },
    extraReducers: (builder) => {
        // Thunks for getting categories
        builder.addCase(getCategories.pending, (state) => {
            state._categories = [];
            state._http_category_request_state = 'pending';
        });
        builder.addCase(getCategories.rejected, (state) => {
            state._categories = [];
            state._http_category_request_state = 'rejected';
        });
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state._categories = action.payload.data;
            state._http_category_request_state = 'fulfilled';
        });

        // Thunks for verifying template code
        builder.addCase(verifyTemplateCode.pending, (state) => {
            state._http_category_request_state = 'pending';
        });
        builder.addCase(verifyTemplateCode.rejected, (state) => {
            state._http_category_request_state = 'rejected';
        });
        builder.addCase(verifyTemplateCode.fulfilled, (state) => {
            state._http_category_request_state = 'fulfilled';
        });

        // Thunks for creating template
        builder.addCase(saveTemplate.pending, (state) => {
            state._http_request_state = 'pending';
        });
        builder.addCase(saveTemplate.rejected, (state) => {
            state._http_request_state = 'rejected';
        });
        builder.addCase(saveTemplate.fulfilled, (state) => {
            state._http_request_state = 'fulfilled';
        });

        // Thunks for editing a template

        // Thunk for getting template
        builder.addCase(getTemplateById.pending, (state) => {
            state._http_template_request_state = 'pending';
        });
        builder.addCase(getTemplateById.rejected, (state) => {
            state._http_template_request_state = 'rejected';
        });
        builder.addCase(getTemplateById.fulfilled, (state, action) => {
            if (!action.payload) {
                state._http_template_request_state = 'rejected';
                return;
            }
            state._http_template_request_state = 'fulfilled';
            state.api_version = action.payload.api_version;
            state.template_id = action.payload.template_id;
            state.template_name = action.payload.template_name;
            state.creator_user_id = action.payload.creator_user_id;
            state.status = action.payload.status;
            state.date_created = action.payload.date_created;
            state.date_last_modified = action.payload.date_last_modified;
            state.description = action.payload.description;
            state.template_content = action.payload.template_content;
            state.template_code = action.payload.template_code;
            state.guidelines = action.payload.guidelines;
            state.workflow = action.payload.workflow;
            state.category_id = action.payload.category_id;
            state.category_name = action.payload.category_name;
        });
    },
});

export const {
    addSection,
    updateSection,
    updateSectionMetadata,
    moveSection,
    deleteSection,
    setActiveField,
    setHiddenFields,
    updateField,
    updateTemplate,
    setErrors,
    removeError,
    setHasRTE,
    setHasFileUpload,
    resetFormBuilder,
} = FormBuilderSlice.actions;
export default FormBuilderSlice.reducer;
