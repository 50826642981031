import React from 'react';
import TableHeaderCell from '../../../layouts/table/TableHeaderCell';
import TableRow from '../../../layouts/table/TableRow';
import { DatatableComponentProps, ITableHeader } from '../datatable/Datatable';

const DraftsMemosTableHeader = (props: DatatableComponentProps) => {
    if (!props.headers) {
        return <TableRow type="header" />;
    }
    return (
        <TableRow type="header">
            {props.headers.map((header) => {
                return <TableHeaderCell key={header.key}>{header.label}</TableHeaderCell>;
            })}
            <TableHeaderCell>Action</TableHeaderCell>
        </TableRow>
    );
};

export default DraftsMemosTableHeader;
