import React from 'react';
import { flavor } from '../../AppFlavor';

interface IToggleProps {
    state: boolean;
    background?: string;
    onToggle?: (e: any) => void;
}

const Toggle = (props: IToggleProps) => {
    const { onToggle, state, background } = props;
    const backgroundTheme = background || flavor.theme.toggle.active.background;

    const _onToggle = () => {
        if (onToggle) {
            onToggle(!state);
        }
    };

    return (
        <div className="w-8 inline-flex items-center relative cursor-pointer" onClick={_onToggle} data-testid="toggle">
            <div
                style={{ boxShadow: '0px 3px 6px #00000029' }}
                className={`${
                    state ? 'left-1/2' : 'left-0'
                } w-4 h-4 bg-white rounded-full shallow-md duration-300 ease-in-out absolute shadow-md`}
                data-testid={state ? 'toggle-active' : 'toggle-inactive'}
            ></div>
            <div
                className={`${
                    state ? backgroundTheme : 'bg-gray-status'
                } duration-300 ease-in-out w-full h-3 rounded-full p-1`}
            ></div>
        </div>
    );
};

export default Toggle;
