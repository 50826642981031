import React from 'react';
import Datepicker from '../../../layouts/datepicker/Datepicker';
import Preloader from '../../../layouts/preloaders/Preloader';
import Select, { SelectItems } from '../../../layouts/select/Select';
import Text from '../../../layouts/typography/Text';

interface IDatatableFilters {
    isEnabled: boolean;
    isDateFilterEnabled: boolean;
    isMemoTypeFilterEnabled: boolean;
    isDownloadReportEnabled: boolean;
    memoTypes: SelectItems[];
    onDateChanged: (event: any) => void;
    onMemoTypeChanged: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
    onDownloadReportClicked: () => void;
}
const DatatableFilters = ({
    isEnabled,
    isDateFilterEnabled,
    isMemoTypeFilterEnabled,
    isDownloadReportEnabled,
    memoTypes,
    onDateChanged,
    onMemoTypeChanged,
    onDownloadReportClicked,
}: IDatatableFilters) => {
    return (
        <>
            {isEnabled && (
                <div id="table-options" className="flex flex-col md:flex-row justify-between">
                    <div id="table-filter-search" className="w-full md:w-2/3">
                        <div className="w-full flex flex-col md:flex-row md:items-center md:mb-4">
                            <Text>Filter by:</Text>
                            {isDateFilterEnabled && (
                                <div className="w-auto md:ml-4" data-testid="table-filter-by-date">
                                    <Datepicker
                                        id="dp"
                                        label="Date created"
                                        onChange={onDateChanged}
                                        data-testid="memo-datepicker"
                                    />
                                </div>
                            )}
                            {isMemoTypeFilterEnabled && (
                                <div className="w-auto md:w-1/3 md:ml-4" data-testid="table-filter-by-memo-type">
                                    <Select
                                        id="memotype_dropdown"
                                        type="inline"
                                        placeholder="Memo Type"
                                        onChange={onMemoTypeChanged}
                                        data-testid="select"
                                    >
                                        <option value="" disabled selected hidden>
                                            Memo Type
                                        </option>
                                        <option value="ALL">All</option>
                                        {memoTypes.map((item) => (
                                            <option key={item.value} value={item.value} data-testid="select-option">
                                                {item.label}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            )}
                        </div>
                    </div>
                    {isDownloadReportEnabled && (
                        <div
                            id="download-csv"
                            className="w-full md:w-1/3 flex md:justify-end"
                            data-testid="table-report-download-csv"
                        >
                            <div className="w-auto flex flex-row items-center mb-4">
                                <div className="w-full md:ml-4 flex-nowrap">
                                    <button onClick={onDownloadReportClicked}>
                                        <Text>
                                            <p className="py-2 underline text-sm text-blue">Download report</p>
                                        </Text>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default DatatableFilters;
