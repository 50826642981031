import React from 'react';
import PropTypes from 'prop-types';
import Text from '../typography/Text';
import Card from '../cards/Card';
import { navigate } from '@reach/router';
import Title from '../typography/Title';

interface IMemoSelectionTemplates {
    templates: SelectionTemplates[];
    http_state: string;
}

interface SelectionTemplates {
    template_id: string;
    template_name: string;
    category_id?: string;
    category_name?: string;
    description?: string;
}

const MemoSelectionTemplates = (props: IMemoSelectionTemplates) => {
    const { templates, http_state } = props;

    switch (http_state) {
        case 'pending':
            return (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 ml-4">
                    {Array.from({ length: 8 }, (_, index) => (
                        <Card key={index} type="category-loading" />
                    ))}
                </div>
            );
        case 'rejected':
            return <p>Unable to load templates</p>;
        case 'fulfilled':
            return (
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 ml-4">
                    {!templates.length ? (
                        <Text>No templates available at the moment.</Text>
                    ) : (
                        templates.map((template) => (
                            <Card
                                key={template.template_id}
                                type="category"
                                title={template.template_name}
                                subtitle={template.description || template.template_id}
                                category={template.category_name}
                                onClick={() => navigate(`/memo/create/${template.template_id}`)}
                            />
                        ))
                    )}
                </div>
            );
        default:
            return null;
    }
};

export default MemoSelectionTemplates;
