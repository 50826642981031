import * as React from 'react';
import PropTypes from 'prop-types';

interface ITreeProps {
    children?: React.ReactNode;
}

const Tree: React.FunctionComponent<ITreeProps> = (props) => {
    return (
        <div className="flex flex-col" role="tree">
            {props.children}
        </div>
    );
};

Tree.propTypes = {
    children: PropTypes.node,
};

export default Tree;
