import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDebounce } from '../../../utils/hooks/useDebounce';
import Preloader from '../../../layouts/preloaders/Preloader';

export interface IContactsProps {
    id?: string;
    field?: string;
    selectedPeople?: string[];
    searchToken: string;
    onPersonSelected: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    children?: React.ReactNode;
}

const contactListContainerStyles: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: 'auto',
    zIndex: 1,
    boxShadow: '0px 3px 6px #00000029',
};

const contactPhotoStyles: React.CSSProperties = {
    minWidth: '30px',
    minHeight: '30px',
    maxWidth: '30px',
    maxHeight: '30px',
    borderRadius: '50%',
};

const contactNoPhotoStyles: React.CSSProperties = {
    minWidth: '30px',
    minHeight: '30px',
    maxWidth: '30px',
    maxHeight: '30px',
    borderRadius: '50%',
};

const Contacts = (props: IContactsProps) => {
    const getDisplayName = (names: gapi.client.people.Name[] | undefined | any) => {
        if (names) {
            return names[0].unstructuredName;
        }
        return '';
    };
    const getEmail = (emailAddresses: gapi.client.people.EmailAddress[] | undefined | any) => {
        if (emailAddresses) {
            return emailAddresses[0].value;
        }
        return 'Email unavailable';
    };
    const getPhotoUrl = (photos: gapi.client.people.Photo[] | undefined) => {
        if (photos) {
            return photos[0].url;
        }
        return '';
    };

    const [searchResult, setSearchResult] = React.useState<gapi.client.people.Person[]>([]);
    const [requestState, setRequestState] = React.useState('idle');
    const searchToken = useDebounce(props.searchToken, 800);

    const searchDirectoryV2 = async (token: string, pageSize: number) => {
        try {
            const searchFn = firebase.app().functions('asia-east2').httpsCallable('get_contacts');
            const response = await searchFn({
                query: {
                    token: token,
                    pageSize: pageSize,
                },
            });
            return response.data;
        } catch (err) {
            console.log(err);
        }
    };

    const searchExternal = async (token: string, pageSize: number) => {
        try {
            const searchFn = firebase.app().functions('asia-east2').httpsCallable('get_ext_contacts');
            const response = await searchFn({
                query: {
                    token: token,
                    pageSize: pageSize,
                },
            });
            return response.data;
        } catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        let isMounted = true;
        setSearchResult([]);
        /**
         * Search for a particular user under the domain.
         * @param token - Search token for user account.
         */
        const searchUsers = async (token: string) => {
            setSearchResult([]);
            setRequestState('pending');

            try {
                const directorydomain: any[] = await searchDirectoryV2(token, 3);
                const externaldomain: any[] = await searchExternal(token, 3);
                const res = directorydomain.concat(externaldomain);
                if (isMounted) {
                    setRequestState('fulfilled');
                    setSearchResult(filterResultsFromSelectedPeople(res, props.selectedPeople));
                }
            } catch (err) {
                console.error(err);
            }
        };

        if (props.searchToken.length >= 3) {
            searchUsers(props.searchToken);
        }

        return () => {
            isMounted = false;
        };
    }, [searchToken]);

    const filterResultsFromSelectedPeople = (
        search_result: gapi.client.people.Person[] | any,
        selectedPeople: string[] | any,
    ) => {
        return search_result.filter((person: { emailAddresses: any }) => {
            if (person.emailAddresses) {
                const email = getEmail(person.emailAddresses);
                return !selectedPeople.includes(email);
            }
            return person;
        });
    };

    const onEnterPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const person = searchResult[0];
            if (person) {
                const data = {
                    currentTarget: {
                        dataset: {
                            email: getEmail(person.emailAddresses),
                            name: getDisplayName(person.names),
                            photo: getPhotoUrl(person.photos),
                            sequence: props.id,
                        },
                    },
                };
                props.onPersonSelected(data as any);
            }
        }
    };

    return (
        <>
            {React.Children.map(props.children, (child) => {
                if (React.isValidElement(child) && child.props.type === 'text') {
                    return React.cloneElement(child, { onKeyPress: onEnterPressed });
                }
                return child;
            })}
            {props.searchToken.length > 0 && (
                <div style={contactListContainerStyles} role="listbox" className="top-full">
                    {searchResult.map((person, index) => {
                        const email = getEmail(person.emailAddresses);
                        const photo = getPhotoUrl(person.photos);
                        const name = getDisplayName(person.names);

                        return (
                            <div
                                key={index}
                                className="w-full bg-white p-4 cursor-pointer flex flex-row items-center focus:ring-2"
                                data-sequence={props.id}
                                data-field={props.field}
                                data-email={email}
                                data-photo={photo}
                                data-name={name}
                                onClick={props.onPersonSelected}
                                role="option"
                                data-testid="contact-list-item"
                            >
                                {photo ? (
                                    <img style={contactPhotoStyles} src={photo} />
                                ) : (
                                    <div
                                        style={contactNoPhotoStyles}
                                        className="bg-blue-dark flex items-center justify-center text-sm text-white"
                                    >
                                        {email ? email[0].toUpperCase() : 'G'}
                                    </div>
                                )}
                                <div className="w-full flex flex-col pl-2 pr-8 justify-center">
                                    <p className="text-sm font-bold overflow-ellipsis whitespace-nowrap overflow-hidden">
                                        {name}
                                    </p>
                                    <p className="text-sm font-regular overflow-ellipsis whitespace-nowrap overflow-hidden">
                                        {email}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                    {requestState === 'pending' && (
                        <div className="w-full bg-white p-4 cursor-pointer flex flex-row items-center">
                            <Preloader type="dots" />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Contacts;
