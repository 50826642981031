import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useSelector } from 'react-redux';
import ic_alert from '../../../assets/img/ic_alert.svg';
import ic_check from '../../../assets/img/ic_check.svg';
import Button from '../../../layouts/buttons/Button';
import Modal from '../../../layouts/modals/Modal';
import Preloader from '../../../layouts/preloaders/Preloader';
import Text from '../../../layouts/typography/Text';
import Title from '../../../layouts/typography/Title';
import { Memo } from '../../../models/Memo';
import { ModalContent } from '../../../models/ModalContent';
import { RootState } from '../../../redux/RootReducer';
import Datatable from '../datatable/Datatable';
import DraftsMemosTableBody from './DraftsMemosTableBody';
import DraftsMemosTableHeaders from './DraftsMemosTableHeader';

interface IDraftMemosProps {
    memo_type?: string;
    headers?: any;
    children?: React.ReactNode;
    start_date?: any;
    end_date?: any;
}

const DraftMemos: React.FunctionComponent<IDraftMemosProps> = (props: IDraftMemosProps) => {
    const defaultHeaders = [
        {
            label: 'Memo ID',
            key: 'memo_id',
        },
        {
            label: 'Memo type',
            key: 'used_template_name',
        },
        {
            label: 'Subject',
            key: 'memo_subject',
        },
        {
            label: 'Date last modified',
            key: 'date_last_modified',
        },
        {
            label: 'Status',
            key: 'memo_status',
        },
    ];

    const initialModal: ModalContent = {
        isVisible: false,
        loading: true,
        icon: '',
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    };

    const [modalContent, setModalContent] = React.useState<ModalContent>(initialModal);

    const { memo_type, start_date, end_date } = props;

    const deleteDraft = async (memo: Memo) => {
        try {
            setModalContent({
                isVisible: true,
                loading: true,
                icon: '',
                title: '',
                subtitle: 'Loading...',
                buttons: [
                    {
                        label: '',
                        event: () => {
                            return;
                        },
                    },
                ],
            });

            const fn = firebase.app().functions('asia-east2').httpsCallable('delete_draft');
            const rs = await fn({ memo_id: memo.memo_id });
            if (rs.data.code && rs.data.code === 200) {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_check,
                    title: `Draft deleted`,
                    subtitle: `The selected draft has been deleted`,
                    buttons: [
                        {
                            label: 'Okay, got it!',
                            event: () => {
                                setModalContent({
                                    ...modalContent,
                                    isVisible: false,
                                });
                            },
                        },
                    ],
                });
            } else {
                throw new Error('Failed to receive response from the server.');
            }
        } catch (err) {
            const error: any = err;
            setModalContent({
                ...modalContent,
                loading: false,
                isVisible: true,
                icon: ic_alert,
                title: 'There was an error while deleting the draft',
                subtitle: error.message,
                buttons: [
                    {
                        label: 'Dismiss',
                        event: () => {
                            setModalContent({
                                ...initialModal,
                                isVisible: false,
                            });
                        },
                    },
                ],
            });
        }
    };

    const [config, setConfig] = React.useState({
        endpoint: 'getMyMemosDraftWithUserID',
        templatePath: 'mymemos.drafts',
        queryParams: {
            limit: 15,
            page_no: 1,
            start_date: null,
            end_date: null,
            memo_type: '',
        },
    });

    React.useEffect(() => {
        setConfig({
            ...config,
            queryParams: {
                ...config.queryParams,
                memo_type: memo_type as string,
                start_date: start_date,
                end_date: end_date,
            },
        });
    }, [memo_type, start_date, end_date, modalContent]);

    const onDeleteClick = (memo: Memo) => {
        setModalContent({
            isVisible: true,
            loading: false,
            icon: ic_alert,
            title: `Your draft will be deleted.`,
            subtitle: `Are you sure you want to delete this draft?`,
            buttons: [
                {
                    label: 'No',
                    event: () =>
                        setModalContent({
                            ...modalContent,
                            isVisible: false,
                        }),
                },
                {
                    label: 'Yes',
                    event: async () => deleteDraft(memo),
                },
            ],
        });
    };

    return (
        <div>
            {/* Table Section */}
            <Datatable
                config={config}
                defaultHeaders={defaultHeaders}
                deps={modalContent}
                headerComponent={<DraftsMemosTableHeaders />}
                bodyComponent={<DraftsMemosTableBody onDeleteClick={onDeleteClick} />}
                pageRedirectTo="/memos/drafts"
            />

            {/* Modal Section */}
            <Modal visible={modalContent.isVisible as boolean}>
                {modalContent.loading ? (
                    <div className="w-full flex flex-col items-center justify-center">
                        <Preloader type="dots" text="Loading..." />
                    </div>
                ) : (
                    <div className="w-full flex flex-col items-center">
                        <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                        <Title type="h4">{modalContent.title}</Title>
                        <div className="my-2">
                            <Text>{modalContent.subtitle}</Text>
                        </div>
                        {modalContent.buttons.length > 1 ? (
                            <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        weight="font-regular"
                                        onClick={modalContent.buttons[0].event}
                                    >
                                        {modalContent.buttons[0].label}
                                    </Button>
                                </div>
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        color="primary"
                                        weight="font-regular"
                                        type="button"
                                        onClick={modalContent.buttons[1].event}
                                    >
                                        {modalContent.buttons[1].label}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="my-2">
                                <Button
                                    type="button"
                                    color="primary"
                                    weight="font-regular"
                                    onClick={modalContent.buttons[0].event}
                                >
                                    {modalContent.buttons[0].label}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        </div>
    );
};

DraftMemos.propTypes = {
    children: PropTypes.node,
};

export default DraftMemos;
