import _ from 'lodash';
import React from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ic_trash from '../../../assets/img/ic_trash.svg';
import Button from '../../../layouts/buttons/Button';
import Contacts from '../contacts/Contacts';
import Input from '../../../layouts/form/Input';
import Person from '../../../layouts/panels/Person';
import Branch from '../../../layouts/tree/Branch';
import Tree from '../../../layouts/tree/Tree';
import Text from '../../../layouts/typography/Text';
import { Sequence } from '../../../models/Sequence';
import { RootState } from '../../../redux/RootReducer';

export interface ISequenceItemProps {
    sequence: Sequence;
    sequence_no: number;
    onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onAllowSubApprovalChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onApprovalTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onPersonSelected: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    clearErrors: (name?: string | string[] | undefined) => void;
    onRemoveAudience: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    register: any;
    errors?: DeepMap<FieldValues, FieldError>;
}

const SequenceItem = (props: ISequenceItemProps) => {
    const {
        sequence,
        sequence_no,
        onSearchChange,
        onApprovalTypeChange,
        onAllowSubApprovalChange,
        onPersonSelected,
        onRemove,
        onRemoveAudience,
        register,
        errors,
    } = props;
    const { sequences, template, _mode } = useSelector((state: RootState) => state.MemoFormV2);
    const { user } = useSelector((state: RootState) => state.Auth);

    const getTreeView = (mode: string) => {
        switch (mode) {
            case 'CREATE':
                return (
                    <>
                        {/* Input field for adding approver */}
                        <Branch>
                            <div className="w-full flex flex-col">
                                <div className="w-2/3 flex flex-col md:inline-block relative">
                                    {/* The dropdown and input field container*/}
                                    <Contacts
                                        id={`approver-${sequence.sequence_id}`}
                                        field="approvers"
                                        searchToken={sequence.approverSearchToken}
                                        onPersonSelected={onPersonSelected}
                                        selectedPeople={sequences
                                            .map((audience) => [
                                                user.email as string,
                                                ...audience.approvers.map((approver) => approver.email),
                                                ...audience.watchers.map((watcher) => watcher.email),
                                            ])
                                            .flat()}
                                    >
                                        {/* The input field */}
                                        <Input
                                            ref={register({
                                                validate: {
                                                    shouldHaveApprovers: () =>
                                                        sequence.approvers.length || 'This field is required',
                                                },
                                            })}
                                            errors={errors}
                                            id={`approver-${sequence.sequence_id}`}
                                            label="Approvers"
                                            type="text"
                                            placeholder="Enter email address"
                                            onChange={onSearchChange}
                                            data-testid="audience-input"
                                        />
                                    </Contacts>
                                </div>

                                {/* List of cards that contains a person's name, email, and photo */}
                                <div className="w-full inline-block md:flex md:flex-wrap">
                                    {sequence.approvers.map((approver, index) => (
                                        <Person
                                            id={sequence.sequence_id}
                                            field="approvers"
                                            key={index}
                                            contact={approver}
                                            onRemove={onRemove}
                                        />
                                    ))}
                                </div>

                                {/* Checkboxes */}
                                <div className="w-full flex flex-col mt-2 mb-4 ml-1">
                                    <div className="w-full flex flex-row-reverse justify-end items-center mb-2">
                                        <label
                                            className="font-regular text-xs text-gray-2"
                                            htmlFor={`anyOrAll-${sequence.sequence_id}`}
                                        >
                                            To be approved by ANY of the assigned approvers
                                        </label>
                                        <input
                                            className="mr-3 transform scale-125"
                                            id={`anyOrAll-${sequence.sequence_id}`}
                                            type="checkbox"
                                            onChange={onApprovalTypeChange}
                                            value={sequence.approval_type}
                                            data-testid="approval-type"
                                        />
                                    </div>
                                    {template.template_config?.allow_sub_approvals && (
                                        <div className="w-full flex flex-row-reverse justify-end items-center">
                                            <label
                                                className="font-regular text-xs text-gray-2"
                                                htmlFor={`subApprovals-${sequence.sequence_id}`}
                                            >
                                                Allow approvers to add sub-level approvers
                                            </label>
                                            <input
                                                className="mr-3 transform scale-125"
                                                id={`subApprovals-${sequence.sequence_id}`}
                                                type="checkbox"
                                                onChange={onAllowSubApprovalChange}
                                                value={sequence.approval_type}
                                                data-testid="allow-sub-approvals"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Branch>

                        {/* Input field for adding watchers */}
                        <Branch>
                            <div className="w-full md:w-2/3 flex flex-col md:inline-block relative">
                                <Contacts
                                    id={`watcher-${sequence.sequence_id}`}
                                    field="watchers"
                                    searchToken={sequence.watcherSearchToken}
                                    onPersonSelected={onPersonSelected}
                                    selectedPeople={sequences
                                        .map((audience) => [
                                            user.email as string,
                                            ...audience.approvers.map((approver) => approver.email),
                                            ...audience.watchers.map((watcher) => watcher.email),
                                        ])
                                        .flat()}
                                >
                                    <Input
                                        id={`watcher-${sequence.sequence_id}`}
                                        label="Watchers"
                                        type="text"
                                        placeholder="Enter email address"
                                        onChange={onSearchChange}
                                        data-testid="audience-input"
                                    />
                                </Contacts>
                            </div>
                            <div className="w-full md:w-1/3"></div>
                            <div className="w-full inline-block md:flex md:flex-wrap">
                                {sequence.watchers.map((watcher, index) => (
                                    <Person
                                        id={sequence.sequence_id}
                                        field="watchers"
                                        key={index}
                                        contact={watcher}
                                        onRemove={onRemove}
                                    />
                                ))}
                            </div>
                        </Branch>
                    </>
                );
            case 'EDIT':
                return (
                    <>
                        {/* 
                            If the form is set to edit mode, and there are already approvers with approved remarks,
                            we need to display them with greyed cards.
                        */}
                        {_.some(sequence.approvers, (approver) => approver.approval_status === 'APPROVED') ? (
                            <Branch>
                                <p className="font-bold text-sm text-normal">Approved by:</p>
                                <div className="w-full inline-block md:flex md:flex-wrap">
                                    {_.map(
                                        _.filter(
                                            sequence.approvers,
                                            (approver) => approver.approval_status === 'APPROVED',
                                        ),
                                        (approver, index) => (
                                            <Person
                                                id={sequence.sequence_id}
                                                field="approvers"
                                                key={index}
                                                contact={approver}
                                                onRemove={onRemove}
                                                disabled={true}
                                            />
                                        ),
                                    )}
                                </div>
                            </Branch>
                        ) : (
                            ''
                        )}
                        {sequence.is_cleared === false || sequence.is_cleared === null ? (
                            <Branch>
                                <div className="w-full flex flex-col">
                                    <div className="w-2/3 flex flex-col md:inline-block relative">
                                        <Contacts
                                            id={`approver-${sequence.sequence_id}`}
                                            field="approvers"
                                            searchToken={sequence.approverSearchToken || ''}
                                            onPersonSelected={onPersonSelected}
                                            selectedPeople={sequences
                                                .map((audience) => [
                                                    user.email as string,
                                                    ...audience.approvers.map((approver) => approver.email),
                                                    ...audience.watchers.map((watcher) => watcher.email),
                                                ])
                                                .flat()}
                                        >
                                            <Input
                                                ref={register({
                                                    validate: {
                                                        shouldHaveApprovers: () =>
                                                            sequence.approvers.length || 'This field is required',
                                                    },
                                                })}
                                                errors={errors}
                                                id={`approver-${sequence.sequence_id}`}
                                                label="Approvers"
                                                type="text"
                                                placeholder="Enter email address"
                                                onChange={onSearchChange}
                                                data-testid="audience-input"
                                            />
                                        </Contacts>
                                    </div>
                                    {/* Checkboxes */}
                                    <div className="w-full flex flex-col mt-2 mb-4 ml-1">
                                        <div className="w-full flex flex-row-reverse justify-end items-center mb-2">
                                            <label
                                                className="font-regular text-xs text-gray-2"
                                                htmlFor={`anyOrAll-${sequence.sequence_id}`}
                                            >
                                                To be approved by ANY of the assigned approvers
                                            </label>
                                            <input
                                                className="mr-3 transform scale-125"
                                                id={`anyOrAll-${sequence.sequence_id}`}
                                                type="checkbox"
                                                onChange={onApprovalTypeChange}
                                                value={sequence.approval_type}
                                                data-testid="approval-type"
                                            />
                                        </div>
                                        {(() => {
                                            if (
                                                template.template_config?.allow_sub_approvals ||
                                                sequence.allow_sub_approvals
                                            ) {
                                                return (
                                                    <div className="w-full flex flex-row-reverse justify-end items-center">
                                                        <label
                                                            className="font-regular text-xs text-gray-2"
                                                            htmlFor={`subApprovals-${sequence.sequence_id}`}
                                                        >
                                                            Allow approvers to add sub-level approvers
                                                        </label>
                                                        <input
                                                            className="mr-3 transform scale-125"
                                                            id={`subApprovals-${sequence.sequence_id}`}
                                                            type="checkbox"
                                                            onChange={onAllowSubApprovalChange}
                                                            value={sequence.approval_type}
                                                            defaultChecked={
                                                                sequence.approvers
                                                                    .map((approvers) => approvers.sub_approvers)
                                                                    .flat().length > 0
                                                            }
                                                            data-testid="allow-sub-approvals"
                                                        />
                                                    </div>
                                                );
                                            }
                                        })()}
                                    </div>
                                    <div className="w-full inline-block md:flex md:flex-wrap">
                                        {_.map(
                                            _.filter(sequence.approvers, (approver) =>
                                                ['PENDING', 'WAITING', 'RETURNED', ''].includes(
                                                    approver.approval_status,
                                                ),
                                            ),
                                            (approver, index) => (
                                                <Person
                                                    id={sequence.sequence_id}
                                                    field="approvers"
                                                    key={index}
                                                    contact={approver}
                                                    onRemove={onRemove}
                                                />
                                            ),
                                        )}
                                    </div>
                                </div>
                            </Branch>
                        ) : (
                            ''
                        )}
                        {sequence.is_cleared ? (
                            <Branch>
                                <p className="font-bold text-sm text-normal">Watched by:</p>
                                {sequence.watchers.length > 0 ? (
                                    <div className="w-full inline-block md:flex md:flex-wrap">
                                        {sequence.watchers.map((watcher, index) => (
                                            <Person
                                                id={sequence.sequence_id}
                                                field="watchers"
                                                key={index}
                                                contact={watcher}
                                                disabled={true}
                                                onRemove={onRemove}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <p className="px-2 text-sm my-4">No watchers for this sequence.</p>
                                )}
                            </Branch>
                        ) : (
                            // Else, display a standard view of watchers.
                            <Branch>
                                <div className="w-full md:w-2/3 flex flex-col md:inline-block relative">
                                    <Contacts
                                        id={`watcher-${sequence.sequence_id}`}
                                        field="watchers"
                                        searchToken={sequence.watcherSearchToken || ''}
                                        onPersonSelected={onPersonSelected}
                                        selectedPeople={sequences
                                            .map((audience) => [
                                                user.email as string,
                                                ...audience.approvers.map((approver) => approver.email),
                                                ...audience.watchers.map((watcher) => watcher.email),
                                            ])
                                            .flat()}
                                    >
                                        <Input
                                            id={`watcher-${sequence.sequence_id}`}
                                            label="Watchers"
                                            type="text"
                                            placeholder="Enter email address"
                                            onChange={onSearchChange}
                                            data-testid="audience-input"
                                        />
                                    </Contacts>
                                </div>
                                <div className="w-full md:w-1/3"></div>
                                <div className="w-full inline-block md:flex md:flex-wrap">
                                    {sequence.watchers.map((watcher, index) => (
                                        <Person
                                            id={sequence.sequence_id}
                                            field="watchers"
                                            key={index}
                                            contact={watcher}
                                            onRemove={onRemove}
                                        />
                                    ))}
                                </div>
                            </Branch>
                        )}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="w-full mt-4">
            <div className="w-full flex flex-row items-center justify-between">
                <Text color="secondary" type="bold">
                    Sequence {sequence_no + 1}
                </Text>
                {sequences[0].sequence_id !== sequence.sequence_id && !sequence.is_cleared ? (
                    <Button
                        type="button"
                        onClick={onRemoveAudience}
                        id={sequence.sequence_id}
                        value={sequence.sequence_id}
                        data-testid="sequence-item-remove-btn"
                    >
                        <img style={{ width: '16px', height: '16px' }} src={ic_trash} />
                    </Button>
                ) : (
                    ''
                )}
            </div>
            <div className="w-full py-2 flex flex-col">
                {/* Tree View here */}
                {/* <pre>{JSON.stringify(sequence, null, 2)}</pre> */}
                <Tree>{getTreeView(_mode)}</Tree>
            </div>
        </div>
    );
};

export default SequenceItem;
