import { Link, LinkGetProps } from '@reach/router';
import PropTypes from 'prop-types';
import * as React from 'react';
import NavItemActive from './NavItemActive';
import NavItemInactive from './NavItemInactive';

interface INavItemProps extends React.ComponentPropsWithoutRef<'li'> {
    label: string;
    link: string;
}

const NavItem: React.FunctionComponent<INavItemProps> = (props) => {
    const { label, link } = props;
    const [isActive, setIsActive] = React.useState(false);

    const isCurrent = (linkProps: LinkGetProps) => {
        const { isPartiallyCurrent } = linkProps;
        setIsActive(isPartiallyCurrent);
        return {};
    };

    return (
        <Link to={link} getProps={isCurrent} className="w-auto md:w-1/5 h-full flex items-center md:justify-center">
            <li className="w-full h-full md:h-xxxl">
                {isActive ? <NavItemActive label={label} /> : <NavItemInactive label={label} />}
            </li>
        </Link>
    );
};

NavItem.propTypes = {
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    onClick: PropTypes.any,
};
export default NavItem;
