import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateConstant, TemplateContent } from '../../../../models/TemplateContent';
import { FormBuilderActiveField, setActiveField, updateField, updateSection } from '../../../../redux/FormBuilderSlice';
import { RootState } from '../../../../redux/RootReducer';
import { AppDispatch } from '../../../../redux/Store';
import FormGridCellAnswerSelection from './FormGridCellAnswerSelection';
import FormGridCellAnswerView from './FormGridCellAnswerView';
import FormGridCellQuestion from './FormGridCellQuestion';

/**
 * Interface for the Form Grid cell panel.
 */
interface IFormGridCellPanelProps {
    /**
     * The section ID where the cell is located.
     */
    sectionId: string;

    /**
     * The section index where the cell is located.
     */
    sectionIndex: number;

    /**
     * The form item being edited
     */
    formItem: FormBuilderActiveField;
}

/**
 * Returns a panel where users can customize their form fields.
 * @param props
 * @returns {JSX.Element}
 */
const FormGridCellPanel = (props: IFormGridCellPanelProps) => {
    // Connect to Redux store.
    const { template_content, errors } = useSelector((state: RootState) => state.FormBuilder);

    // Create the dispatch function
    const dispatch: AppDispatch = useDispatch();

    /**
     * Updates the form field item data to Redux
     * @param field
     * @param value
     */
    const onFormFieldChanged = (field: string, value: any) => {
        // Copy the form item that is being edited
        const data = _.cloneDeep(props.formItem);

        // Update the value based on the given path
        _.set(data as any, field, value);

        // Activate the cell panel editor
        dispatch(setActiveField(data));

        // Get the index of the form item from Redux store.
        const formItemIndex = _.findIndex(
            template_content[props.sectionIndex].value,
            (v: TemplateContent) => v.field_id === props.formItem?.field_id,
        );

        // Dispatch the event to Redux.
        // Updates the form field's content with the updated field data based on a given index.
        dispatch(
            updateField({
                sectionIndex: props.sectionIndex,
                formItemIndex: formItemIndex,
                content: data,
            }),
        );
    };

    const onQuestionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        const field = e.currentTarget.dataset.field as string;
        onFormFieldChanged(field, value);
    };

    const onQuestionRequiredChanged = () => {
        const value = !props.formItem.validations.required;
        const field = 'validations.required';
        onFormFieldChanged(field, value);
    };

    const onBranchingQuestionToggled = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data = _.cloneDeep(props.formItem);
        _.set(data, 'hasBranchingQuestions', e.currentTarget.checked);

        if (!e.currentTarget.checked) {
            const updatedConstants = _.map(data.constant, (constant: TemplateConstant) => {
                if (constant.branchingSection) {
                    // clear the branching section;

                    const section = _.cloneDeep(template_content[constant.branchingSection.sectionIndex]);
                    _.set(section, 'conditions', null);

                    onSectionChanged(section.sectionIndex, section);
                }

                return {
                    ...constant,
                    branchingQuestions: null,
                    branchingSection: null,
                };
            });

            _.set(data, 'constant', updatedConstants);
        }

        dispatch(setActiveField(data));

        // Get the index of the form item from Redux store.
        const formItemIndex = _.findIndex(
            template_content[props.formItem.sectionIndex].value,
            (v: TemplateContent) => v.field_id === props.formItem?.field_id,
        );

        // Dispatch the event to Redux.
        // Updates the form field's content with the updated field data based on a given index.
        dispatch(
            updateField({
                sectionIndex: props.formItem.sectionIndex,
                formItemIndex: formItemIndex,
                content: data,
            }),
        );
    };

    const onAnswerTypeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const data = _.cloneDeep(props.formItem);
        _.set(data, 'field_type', e.currentTarget.value);

        switch (e.currentTarget.value) {
            case 'DROPDOWN':
            case 'RADIO':
            case 'CHECKBOX':
                if (!Array.isArray(data.constant)) {
                    _.set(data, 'constant', []);
                }
                break;
            case 'TEXT':
            case 'TEXT_CURRENCY':
            case 'FILE_UPLOAD':
            case 'DATEPICKER':
            case 'TIMEPICKER':
                _.set(data, 'constant', []);
                break;
            case 'TEXT_RTE':
                _.set(data, 'constant', '<p></p>');
                break;
            default:
                _.set(data, 'constant', []);
        }

        // Get the index of the form item from Redux store.
        const formItemIndex = _.findIndex(
            template_content[props.formItem.sectionIndex].value,
            (v: TemplateContent) => v.field_id === props.formItem?.field_id,
        );

        // Dispatch the event to Redux.
        // Updates the form field's content with the updated field data based on a given index.
        dispatch(
            updateField({
                sectionIndex: props.formItem.sectionIndex,
                formItemIndex: formItemIndex,
                content: data,
            }),
        );
    };

    const onSectionChanged = (sectionIndex: number, value: any) => {
        dispatch(updateSection({ index: sectionIndex, content: value }));
    };

    // Return view
    return (
        <div
            id={`panel-editor`}
            className={`w-1-full h-auto bg-white px-2 pt-2 pb-4 z-100 flex flex-col visible`}
            tabIndex={0}
            data-testid="form-grid-cell-panel"
        >
            <FormGridCellQuestion
                formItem={props.formItem}
                onQuestionChanged={onQuestionChanged}
                onQuestionRequiredChanged={onQuestionRequiredChanged}
            />
            <FormGridCellAnswerSelection
                formItem={props.formItem}
                disableBranchingQuestions={false}
                onAnswerTypeChanged={onAnswerTypeChanged}
                onBranchingQuestionToggled={onBranchingQuestionToggled}
            />
            <FormGridCellAnswerView
                formItem={props.formItem}
                errors={errors}
                onFormFieldChanged={onFormFieldChanged}
                onSectionChanged={onSectionChanged}
            />
        </div>
    );
};

export default FormGridCellPanel;
