import * as React from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { TemplateValidation } from '../../models/TemplateValidation';
import Button from '../buttons/Button';
import File from '../panels/File';
import Text from '../typography/Text';

export interface IDropZoneProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'> {
    label?: string;
    annotation?: string;
    placeholder?: string;
    disabled?: boolean;
    fileState: File[];
    validations?: TemplateValidation;
    register?: any;
    onChange: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
    onDropzoneFileRemoved: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const drpStyle: React.CSSProperties = {
    border: '0.5px dashed #afafaf',
    borderRadius: '5px',
};

const DropZone = (props: IDropZoneProps) => {
    const formatBytes = (bytes: number | undefined, decimals = 2) => {
        if (bytes) {
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
        return '0 Bytes';
    };

    const formatFileTypes = (str: string[] | undefined) => {
        if (str) {
            const copy_values = [...str];
            const last = copy_values.pop();
            return `${copy_values.join(', ')} or ${last}`;
        }
        return 'or';
    };

    return (
        <div>
            <Text type="bold">{props.label ? props.label : 'Upload supporting documents (optional)'}</Text>
            {props.annotation ? (
                <p className="text-xs font-regular w-full text-gray-2 mb-1 whitespace-pre-wrap">{props.annotation}</p>
            ) : (
                <>
                    <Text color="secondary">{`
                        File must be less than ${formatBytes(props.validations?.max_size)} of ${formatFileTypes(
                        props.validations?.file_types,
                    )} file(s).
                    `}</Text>
                    <Text color="secondary">{`
                        Maximum of 5 file attachments are allowed.
                    `}</Text>
                </>
            )}

            <Dropzone onDrop={props.onChange} disabled={props.disabled}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                        {...getRootProps()}
                        className={`w-full md:w-1/2 my-2 ${
                            isDragActive ? 'bg-blue-dark' : 'bg-white'
                        } p-2 md:p-8 flex items-center justify-center text-center`}
                        style={drpStyle}
                    >
                        <input {...getInputProps()} tabIndex={props.tabIndex} data-testid="file-upload" />
                        {isDragActive ? (
                            <div className="px-4 py-4 my-2">
                                <Text>
                                    <p className="text-white">Drop here.</p>
                                </Text>
                            </div>
                        ) : (
                            <div className="w-full h-auto flex flex-col">
                                <Text type="bold">{props.placeholder ? props.placeholder : 'Drop your files or'}</Text>
                                <Button type="button" color="secondary" weight="font-regular">
                                    <div className="md:p-4 p-2">Select files from computer</div>
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Dropzone>
            <>
                {props.fileState.map((file, index) => (
                    <File
                        type="upload"
                        register={props.register}
                        key={`${file.name}-${index}`}
                        index={index}
                        name={file.name}
                        size={file.size}
                        accepts={props.validations?.file_types}
                        max_size={props.validations?.max_size}
                        onIconClick={props.onDropzoneFileRemoved}
                    />
                ))}
            </>
        </div>
    );
};

export default DropZone;
