import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateContent } from '../../../../models/TemplateContent';
import { setActiveField, updateField } from '../../../../redux/FormBuilderSlice';
import { RootState } from '../../../../redux/RootReducer';
import { AppDispatch } from '../../../../redux/Store';

export const withReduxConnector = (Component: React.ComponentType<any>) => {
    const wrappedComponent = React.forwardRef((props: any, ref: any) => {
        const [isEditable, setIsEditable] = React.useState(false);
        const { template_content, _editing } = useSelector((state: RootState) => state.FormBuilder);
        const dispatch: AppDispatch = useDispatch();

        const toggleEditableState = () => {
            setIsEditable(!isEditable);
        };

        const updateFormField = (field: string, value: any) => {
            let formItemIndex = _.findIndex(
                template_content[props.sectionIndex].value,
                (v: TemplateContent) => v.field_id === props.id,
            );

            if (formItemIndex === -1) {
                formItemIndex = 0;
            }
            const formItem = _.find(template_content[props.sectionIndex].value, (v) => v.field_id === props.id);

            const data = _.cloneDeep(formItem);

            _.set(data as any, field, value);
            if (_editing) {
                dispatch(
                    setActiveField({
                        ...data,
                        sectionIndex: props.sectionIndex,
                    }),
                );
            }

            dispatch(
                updateField({
                    sectionIndex: props.sectionIndex,
                    formItemIndex: formItemIndex,
                    content: data,
                }),
            );
        };

        const onChange = (e: any, editor: any) => {
            if (props.field_type === 'TEXT_RTE' || props.field_type === 'TEXT_RTE') {
                updateFormField('constant', editor.getData());
            }
            return e;
        };

        if (props.field_type === 'TEXT_RTE' || props.field_type === 'TEXT_RTE_PREFILLED') {
            const RTEFooter = (
                <div
                    className="w-full h-auto absolute bg-gray-1"
                    style={{
                        bottom: '0%',
                        background: '#EFEFEF',
                        borderBottomRightRadius: '5px',
                        borderBottomLeftRadius: '5px',
                        borderLeft: '1px solid #c4c4c4',
                        borderRight: '1px solid #c4c4c4',
                        borderBottom: '1px solid #c4c4c4',
                    }}
                >
                    {isEditable ? (
                        <button
                            className="my-1 ml-2 p-1 w-auto h-auto text-sm text-yellow-light font-bold"
                            onClick={toggleEditableState}
                            type="button"
                        >
                            Cancel
                        </button>
                    ) : (
                        <button
                            className="my-1 ml-2 p-1 w-auto h-auto text-sm text-yellow-light font-bold"
                            onClick={toggleEditableState}
                            type="button"
                        >
                            Edit
                        </button>
                    )}
                </div>
            );
            return (
                <>
                    <Component
                        ref={ref}
                        disabled={!isEditable}
                        onChange={onChange}
                        editorInstance={null}
                        editorContainerRef={React.createRef<HTMLDivElement>()}
                        onReady={(editor: any) => {
                            if (editor) {
                                editor.editing.view.change((writer: any) => {
                                    writer.setStyle('min-height', '325px', editor.editing.view.document.getRoot());
                                    writer.setStyle('padding-bottom', '40px', editor.editing.view.document.getRoot());
                                });
                                updateFormField('constant', props.data);
                            }
                        }}
                        hasError={false}
                        {...props}
                        footer={RTEFooter}
                    />
                </>
            );
        }
        return <Component {...props} ref={ref} />;
    });
    wrappedComponent.displayName;
    return wrappedComponent;
};
