import { navigate } from '@reach/router';
import firebase from 'firebase/app';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import memo_icon from '../../../assets/img/ic_memos-to-watch.svg';
import MemoFeed from '../../../layouts/memo-feed/MemoFeed';
import { Memo } from '../../../models/Memo';
import { setWatchItems, setWatchState } from '../../../redux/MemoFeedSlice';
import { RootState } from '../../../redux/RootReducer';

interface IMemosToWatchSelectionProps {
    children?: React.ReactNode;
}

const MemosToWatchSelection: React.FunctionComponent<IMemosToWatchSelectionProps> = () => {
    const { watch } = useSelector((state: RootState) => state.MemoFeed);
    const { user } = useSelector((state: RootState) => state.Auth);
    const dispatch = useDispatch();

    React.useEffect(() => {
        const loadMemos = async () => {
            try {
                const requestPayload: any = {
                    user_id: user.uid,
                    limit: 15,
                    page_no: 1,
                };

                const getMyMemos = firebase
                    .app()
                    .functions('asia-east2')
                    .httpsCallable('getMemosToWatchActiveWithUserID');
                const http_response = await getMyMemos(requestPayload);
                const response = http_response.data.memos;

                let memos = [];
                memos = response.map((memo: Memo) => {
                    return {
                        title: memo.memo_subject,
                        memo_id: memo.memo_id,
                        memo_type: memo.used_template_name,
                        owner: memo.memo_owner_name,
                        timestamp: memo.date_submitted,
                        type: 'kanban_timeago',
                    };
                });
                dispatch(setWatchItems(memos));
                dispatch(setWatchState('fulfilled'));
            } catch (err) {
                dispatch(setWatchItems([]));
                dispatch(setWatchState('rejected'));
                console.log(err);
            }
        };
        loadMemos();
    }, []);

    const onItemClick = (event: React.MouseEvent<HTMLElement>) => {
        const id = event.currentTarget.dataset.value;
        const parent = '/watch';
        navigate(parent + '/' + id);
    };

    return (
        <MemoFeed
            title="Memos to watch"
            icon={memo_icon}
            redirectTo="/watch"
            items={watch.items}
            state={watch.state}
            count={watch.items.length}
            actionTitle="View all memos"
            onItemClick={onItemClick}
            feed_type="memosToWatch"
        />
    );
};

export default MemosToWatchSelection;
