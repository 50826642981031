import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Notifications from '../../../layouts/notifications/Notifications';
import { Notification } from '../../../models/Notification';
import { clearNotifications, loadNotifications, setHasUnread, setUnreadCount } from '../../../redux/NotificationSlice';
import { RootState } from '../../../redux/RootReducer';
import { getOffsetISOString } from '../../../utils/date/Date';
import firebase from 'firebase/app';
import 'firebase/functions';
import { navigate } from '@reach/router';
import { useNotificationsUnread } from '../../../utils/hooks/useNotificationsUnread';

interface INotificationsViewProps {
    disabled?: boolean;
}

const NotificationsView = (props: INotificationsViewProps) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.Auth);
    const { hasUnread, notifications, http_request } = useSelector((state: RootState) => state.Notifications);
    const [lastDocId, setLastDocId] = React.useState('');
    const { unread } = useNotificationsUnread(0, user.email as string);

    React.useEffect(() => {
        if (notifications.length) {
            const last_doc_id = notifications[notifications.length - 1].notification_id;
            setLastDocId(last_doc_id);
        }
    }, [notifications]);

    const onNotifBellToggle = async (toggle: boolean) => {
        if (toggle) {
            const currentDate = new Date().toISOString();
            localStorage.setItem('dlv', getOffsetISOString(currentDate));
            dispatch(setHasUnread(false));
            dispatch(setUnreadCount(unread));

            /* istanbul ignore else */
            if (user.email) {
                dispatch(clearNotifications());
                dispatch(loadNotifications({ email: user.email, limit: 10 }));
            }
        }
    };

    const onNotifScrollEnd = async (end: boolean) => {
        /* istanbul ignore if */
        if (!end) return;

        /* istanbul ignore else */
        if (lastDocId && user.email && http_request.state === 'fulfilled') {
            dispatch(loadNotifications({ email: user.email, limit: 5, last_doc_id: lastDocId }));
        }
    };

    const onClick = (notification: Notification) => {
        const updateNotification = firebase.app().functions('asia-east2').httpsCallable('update_notification');
        updateNotification({ notification_id: notification.notification_id });
        const url = notification.url.replace(/^.*\/\/[^\/]+/, '');
        navigate(url);
    };

    return (
        <>
            <Notifications
                onToggle={onNotifBellToggle}
                onScrollEnd={onNotifScrollEnd}
                onClick={onClick}
                hasUnread={hasUnread}
                unread={unread}
                state={http_request.state}
                notifications={notifications}
                disableBell={props.disabled as boolean}
            />
        </>
    );
};

export default NotificationsView;
