import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import * as React from 'react';
import emptyMemo from '../../../assets/img/memo-empty-state.svg';
import Table, { TableImgStyle } from '../../../layouts/table/Table';
import TableBodyCell from '../../../layouts/table/TableBodyCell';
import TableHeaderCell from '../../../layouts/table/TableHeaderCell';
import TableRow from '../../../layouts/table/TableRow';
import Text from '../../../layouts/typography/Text';
import { Category } from '../../../models/Category';
import { getDateString } from '../../../utils/date/Date';
import firebase from 'firebase/app';
import 'firebase/functions';
import { ModalContent } from '../../../models/ModalContent';
import Modal from '../../../layouts/modals/Modal';
import Title from '../../../layouts/typography/Title';
import Button from '../../../layouts/buttons/Button';
import Preloader from '../../../layouts/preloaders/Preloader';
import ic_alert from '../../../assets/img/ic_alert.svg';
import ic_check from '../../../assets/img/ic_check.svg';
import loadError from '../../../assets/img/load_error.svg';
import TableHeader from '../../../layouts/table/TableHeader';
import TableBody from '../../../layouts/table/TableBody';

interface IToWatchMemosProps {
    children?: React.ReactNode;
}

const ToWatchMemos: React.FunctionComponent<IToWatchMemosProps> = () => {
    const headers = [
        {
            label: 'Category name',
            key: 'category_name',
        },
        {
            label: 'Updated by',
            key: 'modified_by',
        },
        {
            label: 'Date updated',
            key: 'date_last_modified',
        },
        {
            label: 'Templates',
            key: 'number_of_memo',
        },
        {
            label: 'Action',
            key: 'action',
        },
    ];

    const [modalContent, setModalContent] = React.useState<ModalContent>({
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    });

    const [requestState, setRequestState] = React.useState('idle');
    const [errors, setErrors] = React.useState('');
    const [data, setData] = React.useState([]);

    const fetch = async (functionName: string, payload: any) => {
        try {
            const callableFunction = firebase.app().functions('asia-east2').httpsCallable(functionName);
            const httpResponse = await callableFunction(payload);
            return httpResponse.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    };

    const getCategories = async () => {
        try {
            setRequestState('pending');
            const res = await fetch('admin-get_categories', {});
            setData(res);
            setRequestState('fulfilled');
        } catch (err) {
            console.log(err);
            setRequestState('rejected');
            setErrors(JSON.stringify(err));
        }
    };

    React.useEffect(() => {
        getCategories();
    }, []);

    const deleteCategory = async (category: Category) => {
        setModalContent({
            isVisible: true,
            loading: true,
            icon: '',
            title: '',
            subtitle: 'Loading...',
            buttons: [
                {
                    label: '',
                    event: () => {
                        return;
                    },
                },
            ],
        });

        try {
            await fetch('admin-delete_category', category);
            setModalContent({
                isVisible: true,
                loading: false,
                icon: ic_check,
                title: `Category has been deleted.`,
                subtitle: `No more templates/memos will be created under the category`,
                buttons: [
                    {
                        label: 'Okay',
                        event: () => {
                            setModalContent({
                                ...modalContent,
                                isVisible: false,
                            });
                            getCategories();
                        },
                    },
                ],
            });
        } catch (err) {
            setModalContent({
                isVisible: true,
                loading: false,
                icon: ic_alert,
                title: `There was an error while deleting the category`,
                subtitle: (err as any).message || `Please try again later`,
                buttons: [
                    {
                        label: 'No',
                        event: () =>
                            setModalContent({
                                ...modalContent,
                                isVisible: false,
                            }),
                    },
                ],
            });
        }
    };

    return (
        <div>
            <Table>
                <TableHeader>
                    <TableRow type="header">
                        {headers.map((header) => {
                            return <TableHeaderCell key={header.key}>{header.label}</TableHeaderCell>;
                        })}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {(data as Array<Category>).map((item) => {
                        return (
                            <TableRow key={item.category_id}>
                                <TableBodyCell>{item.category_name}</TableBodyCell>
                                <TableBodyCell>{item.modified_by.displayName}</TableBodyCell>
                                <TableBodyCell>{getDateString(item.date_last_modified)}</TableBodyCell>
                                <TableBodyCell>{item.template_count}</TableBodyCell>
                                <TableBodyCell>
                                    <button
                                        className="text-sm text-blue underline md:mx-2"
                                        onClick={() => {
                                            navigate(`/categories/edit/${item.category_id}`);
                                        }}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="text-sm text-blue underline md:mx-2"
                                        onClick={() => {
                                            setModalContent({
                                                isVisible: true,
                                                loading: false,
                                                icon: ic_alert,
                                                title: `Your category will be deleted.`,
                                                subtitle: `Are you sure you want to delete this category?`,
                                                buttons: [
                                                    {
                                                        label: 'No',
                                                        event: () =>
                                                            setModalContent({
                                                                ...modalContent,
                                                                isVisible: false,
                                                            }),
                                                    },
                                                    {
                                                        label: 'Yes',
                                                        event: async () => {
                                                            deleteCategory(item);
                                                        },
                                                    },
                                                ],
                                            });
                                        }}
                                    >
                                        Delete
                                    </button>
                                </TableBodyCell>
                            </TableRow>
                        );
                    })}
                    {requestState === 'pending' ? (
                        <tr>
                            <td colSpan={headers.length}>
                                <div className="w-full mx-auto">
                                    <Preloader type="dots" />
                                </div>
                            </td>
                        </tr>
                    ) : (
                        ''
                    )}
                </TableBody>
            </Table>
            {requestState === 'fulfilled' && (data as Array<Category>).length === 0 ? (
                <div className="w-full py-8 flex flex-col justify-center items-center text-center">
                    <img src={emptyMemo} style={TableImgStyle} />
                    <div className="mt-8">
                        <Text>You have no categories yet.</Text>
                    </div>
                </div>
            ) : null}
            {requestState === 'rejected' ? (
                <div className="w-full py-8 flex flex-col justify-center items-center text-center">
                    <img src={loadError} style={TableImgStyle} alt="Request Error" />
                    <div className="mt-8">
                        <Text>Something went wrong in fetching data</Text>
                        <Text>{errors}</Text>
                    </div>
                    <div className="mt-2 p-2">
                        <Button color="primary" onClick={() => window.location.reload()}>
                            Try again
                        </Button>
                    </div>
                </div>
            ) : null}

            <Modal visible={modalContent.isVisible as boolean}>
                {modalContent.loading ? (
                    <div className="w-full flex flex-col items-center justify-center">
                        <Preloader type="dots" text="Loading..." />
                    </div>
                ) : (
                    <div className="w-full flex flex-col items-center">
                        <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                        <Title type="h4">{modalContent.title}</Title>
                        <div className="my-2">
                            <Text>{modalContent.subtitle}</Text>
                        </div>
                        {modalContent.buttons.length > 1 ? (
                            <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        weight="font-regular"
                                        onClick={modalContent.buttons[0].event}
                                    >
                                        {modalContent.buttons[0].label}
                                    </Button>
                                </div>
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        color="primary"
                                        weight="font-regular"
                                        type="button"
                                        onClick={modalContent.buttons[1].event}
                                    >
                                        {modalContent.buttons[1].label}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="my-2">
                                <Button
                                    type="button"
                                    color="primary"
                                    weight="font-regular"
                                    onClick={modalContent.buttons[0].event}
                                >
                                    {modalContent.buttons[0].label}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        </div>
    );
};

ToWatchMemos.propTypes = {
    children: PropTypes.node,
};

export default ToWatchMemos;
