/**
 * AppSlice is for app's general settings. This contains config and settings for the app.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
    isReady: boolean;
}

const initialState: AppState = {
    isReady: false,
};

const AppSlice = createSlice({
    name: 'AppSlice',
    initialState,
    reducers: {
        setIsReady(state, action: PayloadAction<boolean>) {
            state.isReady = action.payload;
        },
    },
});

export const { setIsReady } = AppSlice.actions;

export default AppSlice.reducer;
