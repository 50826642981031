import React from 'react';
import ic_check from '../../assets/img/ic_check.svg';
import { Notification } from '../../models/Notification';
import { getDateString } from '../../utils/date/Date';
import Preloader from '../preloaders/Preloader';

interface INotificationsList {
    scrollRef?: any;
    state?: string;
    notifications: Notification[];
    onClick: (data: Notification) => void;
}

const NotificationsList = (props: INotificationsList) => {
    const { notifications } = props;

    const onClick = (event: React.MouseEvent<HTMLLIElement>) => {
        const id = event.currentTarget.dataset.id;
        if (id) {
            const notif = notifications.reduce((acc, current) => {
                if (current.notification_id === id) {
                    acc = current;
                }
                return acc;
            }, {});
            props.onClick(notif as Notification);
        }
    };

    if (props.state === 'fulfilled' && notifications.length === 0) {
        return (
            <div className="w-full my-auto flex flex-col items-center" data-testid="notif-empty">
                <img width="80px" height="80px" src={ic_check} alt="No notifications for now" />
                <p className="text-sm mt-4 font-regular">Nothing to show.</p>
            </div>
        );
    }
    if (props.state === 'rejected') {
        return (
            <div className="w-full my-auto flex flex-col items-center" data-testid="notif-error">
                <p className="text-sm mt-4 font-regular">There was an error while sending the request.</p>
            </div>
        );
    }
    return (
        <ul
            ref={props.scrollRef}
            id="notifications-list"
            className="list-content w-full flex flex-col relative custom-scrollbar divide-y divide-gray-light"
            data-testid="notif-list"
        >
            {notifications.map((notification) => {
                if (!notification.isRead) {
                    return (
                        <li
                            key={notification.notification_id}
                            className="w-full h-auto active p-3 cursor-pointer inline-block relative bg-blue-mdlight hover:bg-blue-lglight"
                            onClick={onClick}
                            data-id={notification.notification_id}
                            data-testid="notif-item"
                        >
                            <div className="w-11/12 p-3">
                                <h4 className="font-bold text-sm">{notification.title}</h4>
                                <p className="text-xs">{getDateString(notification.date_created)}</p>
                            </div>
                        </li>
                    );
                }
                return (
                    <li
                        key={notification.notification_id}
                        className="w-full p-6 cursor-pointer bg-white hover:bg-blue-light"
                        onClick={onClick}
                        data-id={notification.notification_id}
                        data-testid="notif-item"
                    >
                        <h4 className="font-bold text-sm">{notification.title}</h4>
                        <p className="text-xs">{getDateString(notification.date_created)}</p>
                    </li>
                );
            })}
            {props.state === 'pending' && <Preloader type="dots" />}
        </ul>
    );
};

export default NotificationsList;
