import * as React from 'react';
import { dpContainerStyle, dpStyle } from './ProfilePicture';
import PropTypes from 'prop-types';

interface IProfileInitialsProps {
    initials: string;
}

const ProfileInitials: React.FunctionComponent<IProfileInitialsProps> = (props) => {
    return (
        <div className="m-4 cursor-pointer" style={dpContainerStyle}>
            <div className="bg-blue-dark flex items-center justify-center" style={dpStyle}>
                <p className="font-bold text-white text-md">{props.initials}</p>
            </div>
        </div>
    );
};

ProfileInitials.propTypes = {
    initials: PropTypes.string.isRequired,
};

export default ProfileInitials;
