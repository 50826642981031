import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../../../layouts/typography/Text';
import Title from '../../../../layouts/typography/Title';
import { Approver } from '../../../../models/Approver';
import { Contact } from '../../../../models/Contact';
import { Sequence } from '../../../../models/Sequence';
import { Watcher } from '../../../../models/Watcher';
import { setSequences } from '../../../../redux/MemoFormV2Slice';
import { RootState } from '../../../../redux/RootReducer';
import Store, { AppDispatch } from '../../../../redux/Store';
import { ISequenceProps } from '../SequenceTree';

export const SequenceProvider = <T extends ISequenceProps>(Component: React.ComponentType<T>) => {
    const WrappedComponent = (props: Omit<T, 'sequences' | 'onPersonSelected'>) => {
        const { sequences, memo } = useSelector((state: RootState) => state.MemoFormV2);
        const dispatch: AppDispatch = useDispatch();

        const isEmailExisting = (email: string) => {
            if (!email) return false;
            return _.map(Store.getState().MemoFormV2.sequences, (sequence: Sequence) => [
                ..._.map(sequence.approvers, (approver) => approver.email),
                ..._.map(sequence.watchers, (watcher) => watcher.email),
            ])
                .flat()
                .includes(email);
        };

        // Handles adding user to the sequence.
        const onPersonSelected = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // The the Contact data from the event
            const email = event.currentTarget.dataset.email;
            const photo = event.currentTarget.dataset.photo;
            const name = event.currentTarget.dataset.name;
            const sequenceId = event.currentTarget.dataset.sequence;

            // Iterate to multiple sequence levels
            const newSequence = _.map(sequences, (sequence: Sequence) => {
                // If there are no email defined, return the sequence data as is.
                if (!email) return sequence;

                // If the email is already existing, return the sequence as is.
                if (isEmailExisting(email)) {
                    return {
                        ...sequence,
                        approverSearchToken: '',
                        watcherSearchToken: '',
                    };
                }

                // If the event is coming from the approver input field,
                // assign the created audience to approver array
                if (sequenceId === `approver-${sequence.sequence_id}`) {
                    clearField(`approver-${sequence.sequence_id}`);
                    const approver: Contact & Approver = {
                        parent_memo_id: memo.memo_id,
                        parent_sequence_id: sequenceId,
                        parent_sequence_no: sequence.sequence_no,
                        approver_id: '',
                        approval_status: '',
                        approver_user_id: '',
                        email: email,
                        photoURL: photo,
                        name: name,
                    };
                    return {
                        ...sequence,
                        approvers: [...Array.from(new Set([...sequence.approvers, approver]))],
                        approverSearchToken: '',
                    };
                }
                // If the event is coming from the watcher input field,
                // assign the created audience to watcher array
                if (sequenceId === `watcher-${sequence.sequence_id}`) {
                    clearField(`watcher-${sequence.sequence_id}`);
                    const watcher: Contact & Watcher = {
                        parent_memo_id: memo.memo_id,
                        parent_sequence_id: '',
                        parent_sequence_no: 0,
                        watcher_id: '',
                        watcher_user_id: '',
                        email: email,
                        photoURL: photo,
                        name: name,
                    };
                    return {
                        ...sequence,
                        watchers: [...Array.from(new Set([...sequence.watchers, watcher]))],
                        watcherSearchToken: '',
                    };
                }

                // If everything else does not match the criteria above,
                // return the sequence as is.
                return sequence;
            });

            // Update the store.
            dispatch(setSequences(newSequence));
        };

        const clearField = (id: string) => {
            const field = document.getElementById(id) as HTMLInputElement;
            if (!field) return;
            field.value = '';
        };

        return (
            <div className="w-full bg-white p-8 relative inline-block">
                <div className="w-full">
                    <Title type="h4">Approval workflow</Title>
                    <Text color="secondary">Search using email or name for the assignees.</Text>
                </div>
                <Component {...(props as T)} onPersonSelected={onPersonSelected} sequences={sequences} />
            </div>
        );
    };
    WrappedComponent.displayName = Component.displayName || 'SequenceTree';
    return WrappedComponent;
};
