import firebase from 'firebase/app';
import 'firebase/storage';
import { TempFile } from '../../models/TempFile';

export const createObject = (file: TempFile, fileName: string) => {
    const storage = firebase.storage();
    const user = firebase.auth().currentUser;
    return new Promise((resolve, reject) => {
        const remote_file_name = `${Date.now().toString()}_${user?.uid}_${file.name}`;
        const storage_ref = storage
            .ref()
            .child('files')
            .child(`${user?.uid}`)
            .child(encodeURIComponent(remote_file_name));
        const url = storage_ref.fullPath;
        storage_ref
            .put(file, { customMetadata: { original_name: fileName } })
            .then(() => {
                resolve({
                    ...file,
                    url: url,
                    name: file.name,
                });
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const uploadFiles = async (files: TempFile[]): Promise<TempFile[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const responses = await Promise.all(
                files.map(async (file: TempFile) => {
                    return await createObject(file, file.name);
                }),
            );
            resolve(responses as TempFile[]);
        } catch (err) {
            reject(err);
        }
    });
};

export const removeFile = async (filePath: string): Promise<void> => {
    try {
        const storage = firebase.storage();
        const storageRef = storage.ref().child(filePath);
        await storageRef.delete();
    } catch (err) {
        throw err;
    }
};
