import { navigate, useLocation } from '@reach/router';
import firebase from 'firebase/app';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ic_alert from '../../../assets/img/ic_alert.svg';
import ic_check from '../../../assets/img/ic_check.svg';
import Button from '../../../layouts/buttons/Button';
import Container from '../../../layouts/container/Container';
import TextArea from '../../../layouts/form/Textarea';
import Modal from '../../../layouts/modals/Modal';
import Preloader from '../../../layouts/preloaders/Preloader';
import { SelectItems } from '../../../layouts/select/Select';
import Tab from '../../../layouts/tab/Tab';
import TabLayout from '../../../layouts/tab/TabLayout';
import Subtitle from '../../../layouts/typography/Subtitle';
import Text from '../../../layouts/typography/Text';
import Title from '../../../layouts/typography/Title';
import { ModalContent } from '../../../models/ModalContent';
import { Template } from '../../../models/Template';
import { setRemarks, updateMemoStatus } from '../../../redux/ApproverSlice';
import { RootState } from '../../../redux/RootReducer';
import { AppDispatch } from '../../../redux/Store';
import DatatableFilters from '../datatable/DatatableFilters';
import ActiveMemos from './ActiveMemos';
import MyDraftMemos from './DraftMemos';
import HistoryMemos from './HistoryMemos';

const MyMemos = () => {
    const dispatch: AppDispatch = useDispatch();
    const { remarks } = useSelector((state: RootState) => state.Approver);
    const [selectItems, setSelectItems] = React.useState<SelectItems[]>([]);
    const [actionMemoID, setActionMemoID] = React.useState('');
    const [actionCurrentSequence, setActionCurrentSequence] = React.useState(0);
    const { user } = useSelector((state: RootState) => state.Auth);
    const [selectedMemoType, setSelectedMemoType] = React.useState('');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const remoteConfig = firebase.remoteConfig();
    const location = useLocation();

    const [modalContent, setModalContent] = React.useState<ModalContent>({
        isVisible: false,
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    });

    React.useEffect(() => {
        // TODO: Write code to get templates here.
        let isMounted = true;
        const loadTemplate = async () => {
            try {
                await remoteConfig.fetchAndActivate();
                const getTemplates = firebase.app().functions('asia-east2').httpsCallable('get_templates');
                const http_response = await getTemplates({ user_id: user.uid });

                const response = http_response.data;
                if (response && Array.isArray(response)) {
                    const templates = response.map((item: Template | any) => {
                        return {
                            label: item.template_name,
                            value: item.template_id,
                            headers: item.headers,
                        };
                    });

                    if (isMounted) {
                        setSelectItems(templates);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };

        loadTemplate();

        return () => {
            isMounted = false;
            dispatch(setRemarks(''));
        };
    }, []);

    const resetPageNumber = () => {
        navigate(`${location.pathname}?page=1`);
    };

    /** Handles dropdown event change from Select component.
     */
    const onSelectChanged = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        if (event.currentTarget.value) {
            setSelectedMemoType(event.currentTarget.value);
        }
        resetPageNumber();
    };

    /**
     * Handles datepicker event
     * @param event - React Datepicker event object
     */
    const onDateChanged = /* istanbul ignore next */ (event: any) => {
        const [start, end] = event;
        setStartDate(start);
        setEndDate(end);
        resetPageNumber();
    };

    /** logic for Download Report */
    const whichTable = /* istanbul ignore next */ (path: string) => {
        if (path === '/memos' || path === '/memos/active') {
            return 'active';
        } else if (path === '/memos/history') {
            return 'history';
        }
        return '';
    };

    /**
     * Function for Download Report
     */
    const onDownloadXLSXClick = async () => {
        try {
            setModalContent({ ...modalContent, isVisible: true, loading: true });
            const downloadXLSX = firebase.app().functions('asia-east2').httpsCallable('createXlsxReport');
            const all_types = selectItems.map((item) => {
                return item.value;
            });

            const memo_types = selectedMemoType === '' || selectedMemoType === 'ALL' ? all_types : selectedMemoType;
            const memo_table = whichTable(location.pathname);
            const start_date: any = startDate;
            const end_date: any = endDate;

            const http_response = await downloadXLSX({
                memo_audience_type: 'memos',
                memo_table: memo_table,
                memo_types: memo_types,
                start_date: moment(start_date).toISOString(),
                end_date: moment(end_date).toISOString(),
            });
            const response = http_response.data;
            if (response.code === 'ok') {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_check,
                    title: 'Report is being generated.',
                    subtitle: `<center>Check your email for the download link to the generated report.<br />It may take a few minutes to receive the email depending on the size of the report.</center>`,
                    buttons: [
                        {
                            label: 'Back to the list',
                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                        },
                    ],
                });
            } else if (response.code === 'not-found') {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_alert,
                    title: response.message,
                    subtitle: response.details ? response.details : '',
                    buttons: [
                        {
                            label: 'Back to the list',
                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                        },
                    ],
                });
            } else {
                setModalContent({
                    isVisible: true,
                    loading: false,
                    icon: ic_alert,
                    title: response.code,
                    subtitle: response.message ? response.message : '',
                    buttons: [
                        {
                            label: 'Back to the list',
                            event: () => setModalContent({ ...modalContent, isVisible: false }),
                        },
                    ],
                });
            }
        } catch (err: any) {
            console.error(err);
            setModalContent({
                isVisible: true,
                loading: false,
                icon: ic_alert,
                title: `${err.code}: Unable to generate report.`,
                subtitle: `${err.message}. <br> Please contact memo-support@globe.com.ph`,
                buttons: [
                    {
                        label: 'Back to the list',
                        event: () => setModalContent({ ...modalContent, isVisible: false }),
                    },
                ],
            });
        }
    };

    /**
     * Handles change event for remarks field
     */
    const onRemarksChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(setRemarks(e.currentTarget.value));
    };

    /**
     * Opens a modal for cancelling a memo
     */
    const openCancelModal = () => {
        /** for cancelling a memo */
        setModalContent({
            ...modalContent,
            isVisible: true,
            loading: false,
            title: 'Cancel this memo?',
            subtitle: `Please provide remarks below.`,
            extras: 'withdraw_memo',
            extraComponent: (
                <TextArea
                    id="txt-reason"
                    label="Remarks"
                    rows={4}
                    placeholder="Type your remarks here."
                    onChange={onRemarksChanged}
                    data-testid="textarea-remarks"
                />
            ),
            buttons: [
                {
                    label: 'Close',
                    event: () => {
                        setModalContent({ ...modalContent, isVisible: false });
                        dispatch(setRemarks(''));
                    },
                },
                {
                    label: 'Proceed',
                    event: () => {
                        return;
                    },
                },
            ],
        });
    };

    /**
     * Opens a modal for withdraw memo.
     */
    const openWithdrawModal = () => {
        setModalContent({
            ...modalContent,
            isVisible: true,
            loading: false,
            icon: ic_alert,
            title: 'Withdraw this memo?',
            subtitle: `Are you sure you want to withdraw this memo?`,
            extras: 'self_return_memo',
            buttons: [
                {
                    label: 'Close',
                    event: () => {
                        setModalContent({ ...modalContent, isVisible: false });
                    },
                },
                {
                    label: 'Proceed',
                    event: () => {
                        return;
                    },
                },
            ],
        });
    };

    /**
     * Handles action button on active memo table
     */
    const onUserAction = async (action: string, memo_id: string, current_sequence: number) => {
        try {
            setModalContent({ ...modalContent, isVisible: true, loading: true });
            setActionMemoID(memo_id);
            setActionCurrentSequence(current_sequence);

            switch (action) {
                case 'cancel':
                    openCancelModal();
                    break;
                case 'withdraw':
                    openWithdrawModal();
                    break;
            }
        } catch (err) {
            console.error(err);
            setModalContent({
                isVisible: true,
                loading: false,
                icon: ic_alert,
                title: `catch`,
                subtitle: `Please contact memo-support@globe.com.ph`,
                buttons: [
                    {
                        label: 'Back to the list',
                        event: () => setModalContent({ ...modalContent, isVisible: false }),
                    },
                ],
            });
        }
    };

    /**
     * Sends payload to CFs for respective User Actions
     * @param event
     */
    const sendModalRequest = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            setModalContent({ ...modalContent, loading: true });
            /** CANCEL MEMO */
            if (user.uid && actionMemoID && ['withdraw_memo', 'self_return_memo'].includes(modalContent.extras)) {
                const payload = {
                    user_id: user.uid,
                    memo_id: actionMemoID,
                    remarks: remarks,
                    action: modalContent.extras,
                    sequence_no: actionCurrentSequence,
                };
                const response = await dispatch(updateMemoStatus(payload));
                const status = response.meta.requestStatus;
                if (status === 'fulfilled') {
                    switch (modalContent.extras) {
                        case 'withdraw_memo':
                            setModalContent({
                                isVisible: true,
                                loading: false,
                                icon: ic_check,
                                title: 'Memo cancelled',
                                subtitle: `<center>Your memo has been cancelled. You may revisit previous memos in My Memos > <b>History</b> tab.</center>`,
                                buttons: [
                                    {
                                        label: 'Back to my memos',
                                        event: () => navigate('/memos/active'),
                                    },
                                ],
                            });
                            break;
                        case 'self_return_memo':
                            setModalContent({
                                isVisible: true,
                                loading: false,
                                icon: ic_check,
                                title: 'Memo withdrawn',
                                subtitle: `<center>Your memo has been withdrawn.`,
                                buttons: [
                                    {
                                        label: 'Back to my memos',
                                        event: () => navigate('/memos/active'),
                                    },
                                ],
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    throw new Error('There was an error while sending the request. Please try again later.');
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * TODO: Refactor redundant code.
     */
    return (
        <Container>
            <div className="w-full">
                <Title>My memos</Title>
                <div className="my-2">
                    <Subtitle>Manage your memos</Subtitle>
                </div>
            </div>
            <div className="w-full mt-8">
                <TabLayout>
                    <Tab label="Active memos" href="/memos">
                        <div className="py-4">
                            <DatatableFilters
                                isEnabled={remoteConfig.getBoolean('is_table_options_visible')}
                                isDateFilterEnabled={remoteConfig.getBoolean('is_filter_by_date_range_enabled')}
                                isMemoTypeFilterEnabled={remoteConfig.getBoolean('is_filter_by_memo_type_enabled')}
                                isDownloadReportEnabled={
                                    remoteConfig.getBoolean('is_download_xlsx_enabled') && selectItems.length > 0
                                }
                                memoTypes={selectItems}
                                onDateChanged={onDateChanged}
                                onMemoTypeChanged={onSelectChanged}
                                onDownloadReportClicked={onDownloadXLSXClick}
                            />
                            <ActiveMemos
                                memo_type={selectedMemoType}
                                start_date={startDate}
                                end_date={endDate}
                                onUserAction={onUserAction}
                            />
                        </div>
                    </Tab>
                    <Tab label="Drafts" href="/memos/drafts">
                        <div className="py-4">
                            <DatatableFilters
                                isEnabled={remoteConfig.getBoolean('is_table_options_visible')}
                                isDateFilterEnabled={remoteConfig.getBoolean('is_filter_by_date_range_enabled')}
                                isMemoTypeFilterEnabled={remoteConfig.getBoolean('is_filter_by_memo_type_enabled')}
                                isDownloadReportEnabled={false}
                                memoTypes={selectItems}
                                onDateChanged={onDateChanged}
                                onMemoTypeChanged={onSelectChanged}
                                onDownloadReportClicked={onDownloadXLSXClick}
                            />
                            <MyDraftMemos memo_type={selectedMemoType} start_date={startDate} end_date={endDate} />
                        </div>
                    </Tab>
                    <Tab label="History" href="/memos/history">
                        <div className="py-4">
                            <DatatableFilters
                                isEnabled={remoteConfig.getBoolean('is_table_options_visible')}
                                isDateFilterEnabled={remoteConfig.getBoolean('is_filter_by_date_range_enabled')}
                                isMemoTypeFilterEnabled={remoteConfig.getBoolean('is_filter_by_memo_type_enabled')}
                                isDownloadReportEnabled={
                                    remoteConfig.getBoolean('is_download_xlsx_enabled') && selectItems.length > 0
                                }
                                memoTypes={selectItems}
                                onDateChanged={onDateChanged}
                                onMemoTypeChanged={onSelectChanged}
                                onDownloadReportClicked={onDownloadXLSXClick}
                            />
                            <HistoryMemos memo_type={selectedMemoType} start_date={startDate} end_date={endDate} />
                        </div>
                    </Tab>
                </TabLayout>
            </div>
            <Modal visible={modalContent.isVisible ? modalContent.isVisible : false}>
                {modalContent.loading ? (
                    <div className="w-full flex flex-col items-center justify-center">
                        <Preloader type="dots" text="Loading..." />
                    </div>
                ) : (
                    <form className="w-full" onSubmit={sendModalRequest}>
                        <fieldset>
                            <div
                                className={(() => {
                                    if (modalContent.icon) {
                                        return `w-full flex flex-col items-center`;
                                    } else {
                                        return 'w-full flex flex-col items-start';
                                    }
                                })()}
                                data-testid="modal-content"
                            >
                                {modalContent.icon && (
                                    <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                                )}
                                <Title type="h4">{modalContent.title}</Title>
                                <div className={`${modalContent.icon ? 'my-2' : 'my-0'}`}>
                                    <Text type="innerHTML">{modalContent.subtitle}</Text>
                                </div>
                                {modalContent.extraComponent && (
                                    <div className="w-full mt-4">{modalContent.extraComponent}</div>
                                )}
                                {(() => {
                                    if (modalContent.buttons.length > 1) {
                                        return (
                                            <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                                <div className="w-full flex flex-col px-2">
                                                    <Button
                                                        type="button"
                                                        color="secondary"
                                                        weight="font-regular"
                                                        onClick={modalContent.buttons[0].event}
                                                        data-testid="modal-button-secondary"
                                                    >
                                                        {modalContent.buttons[0].label}
                                                    </Button>
                                                </div>
                                                <div className="w-full flex flex-col px-2">
                                                    <Button
                                                        color="primary"
                                                        weight="font-regular"
                                                        type="submit"
                                                        disabled={
                                                            remarks === '' &&
                                                            ![
                                                                'approve_memo',
                                                                'delete_draft',
                                                                'withdraw_memo',
                                                                'self_return_memo',
                                                                'remind_solo_approver',
                                                                'remind_multi_approver',
                                                            ].includes(modalContent.extras)
                                                        }
                                                        onClick={modalContent.buttons[1].event}
                                                        data-testid="modal-button-primary"
                                                    >
                                                        {modalContent.buttons[1].label}
                                                    </Button>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="my-2">
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    weight="font-regular"
                                                    onClick={modalContent.buttons[0].event}
                                                >
                                                    {modalContent.buttons[0].label}
                                                </Button>
                                            </div>
                                        );
                                    }
                                })()}
                            </div>
                        </fieldset>
                    </form>
                )}
            </Modal>
        </Container>
    );
};

export default MyMemos;
