import * as React from 'react';
import PropTypes from 'prop-types';
import Text from '../typography/Text';

interface IToolbarProps {
    icon?: string;
    text?: string;
    count?: number;
    children?: React.ReactNode;
}

const toolbarStyles: React.CSSProperties = {
    borderBottom: '3px solid #5492C6',
};

const toolbarImgStyles: React.CSSProperties = {
    height: '40px',
    marginRight: '8px',
};

const Toolbar: React.FunctionComponent<IToolbarProps> = (props) => {
    return (
        <div className="w-full bg-white flex flex-row items-center py-3 px-6" style={toolbarStyles}>
            <span>
                <img style={toolbarImgStyles} src={props.icon} />
            </span>
            <Text type="bold">
                <p className="text-base">{props.text}</p>
            </Text>
            <Text>
                <p className="text-base ml-1">({props.count})</p>
            </Text>
        </div>
    );
};

Toolbar.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
    count: PropTypes.number,
    children: PropTypes.node,
};

export default Toolbar;
