import React from 'react';
import SideMenu from '../menus/side-menu/SideMenu';
import SideMenuItem from '../menus/side-menu/SideMenuItem';

const MemoSelectionCategory = (props: {
    categories: string[];
    selectedCategory: string;
    http_state: string;
    onSelect: (params: any) => void;
}) => {
    switch (props.http_state) {
        case 'pending':
            return (
                <div className="w-1/4">
                    <ol className="w-full flex flex-col" data-testid="card-skeleton-loading">
                        <li
                            style={{ padding: '0.88rem ' }}
                            className="w-full bg-blue-light cursor-pointer flex justify-start items-center text-sm font-regular"
                        >
                            <div className="w-1/2 h-2 skeleton-loader" />
                        </li>
                        <li
                            style={{ padding: '0.88rem ' }}
                            className="w-full bg-blue-light cursor-pointer flex justify-start items-center text-sm font-regular"
                        >
                            <div className="w-1/2 h-2 skeleton-loader" />
                        </li>
                        <li
                            style={{ padding: '0.88rem ' }}
                            className="w-full bg-blue-light cursor-pointer flex justify-start items-center text-sm font-regular"
                        >
                            <div className="w-1/2 h-2 skeleton-loader" />
                        </li>
                    </ol>
                </div>
            );
        case 'fulfilled':
            return (
                <div className="w-1/4 bg-blue-lglight font-regular text-sm">
                    <SideMenu>
                        {props.categories.map((category, index) => (
                            <SideMenuItem
                                key={index}
                                label={category}
                                value={category}
                                active={props.selectedCategory === category}
                                onClick={props.onSelect}
                            />
                        ))}
                    </SideMenu>
                </div>
            );
        case 'rejected':
        default:
            return null;
    }
};

export default MemoSelectionCategory;
