import React from 'react';
import { Contact } from '../../models/Contact';
import ic_eye from '../../assets/img/ic_eye.svg';
import Tooltip from '../tooltip/Tooltip';

interface IMemoLogFooterProps {
    watchers: Contact[];
}

const MemoLogFooter = (props: IMemoLogFooterProps) => {
    const { watchers } = props;
    return (
        <div className="w-full flex p-1 self-end items-center border border-dashed border-gray-2 text-sm font-regular">
            <span className="p-1 ml-1 mr-2">
                <img src={ic_eye} width="20px" height="20px" />
            </span>
            <p>
                {watchers.length
                    ? watchers
                          .slice(0, 1)
                          .map((watcher) => watcher.name)
                          .join(',  ')
                    : 'No watchers added'}
            </p>
            {watchers.length > 1 && (
                <Tooltip type="approvers" items={watchers.slice(0).map((watcher) => ({ displayName: watcher.name }))} />
            )}
        </div>
    );
};

export default MemoLogFooter;
