import { navigate, RouteComponentProps } from '@reach/router';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppHeader from '../../../layouts/app-header/AppHeader';
import Branding from '../../../layouts/navbar/branding/Branding';
import Nav from '../../../layouts/navbar/Navigation';
import { NavItemLayoutProps } from '../../../layouts/navbar/NavItemLayout';
import Profile from '../../../layouts/navbar/profile/Profile';
import Preloader from '../../../layouts/preloaders/Preloader';
import Text from '../../../layouts/typography/Text';
import { User } from '../../../models/User';
import { setIsReady } from '../../../redux/AppSlice';
import { setUser } from '../../../redux/AuthSlice';
import { RootState } from '../../../redux/RootReducer';
import menu_close from '../../../assets/img/menu_close.svg';
import menu from '../../../assets/img/menu.svg';

export interface IAdminProtectedRouteProps extends RouteComponentProps {
    component: React.ComponentType<any>;
    template_id?: string;
    title?: string;
}

const AdminProtectedRoute: React.FunctionComponent<IAdminProtectedRouteProps> = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.Auth);
    const [toggled, setToggle] = React.useState(false);

    const updateUser = (user: User) => {
        const payload: User = {
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
            providerId: user.providerId,
            uid: user.uid,
        };
        dispatch(setUser(payload));
    };

    useEffect(() => {
        firebase.analytics().setCurrentScreen(location.pathname);
        firebase.analytics().logEvent('page_view', { page_path: location.pathname, page_title: rest.title });

        document.title = rest.title as string;
        firebase.auth().onAuthStateChanged((user) => {
            if (!user) {
                navigate('/login');
            } else {
                setIsReady(true);
                updateUser(user);
            }
        });
    }, []);

    const logout = async () => {
        // run code here
        try {
            // Sign out to google via firebase
            await firebase.auth().signOut();
        } catch (err) {
            console.error(err);
        }
    };

    const nav_links: NavItemLayoutProps[] = [
        {
            label: 'Category',
            link: '/categories',
        },
        {
            label: 'Templates',
            link: '/templates',
        },
        // {
        //     label: 'Workflow',
        //     link: '/workflows',
        // },
        // {
        //     label: 'Users',
        //     link: '/users',
        // },
    ];

    const toggleMenu = () => {
        setToggle(!toggled);
    };

    if (user.email) {
        return (
            <div className="w-full h-full">
                <div style={{ zIndex: 99999 }} className="w-full h-auto fixed">
                    <AppHeader>
                        <button className="visible md:hidden p-2" onClick={toggleMenu}>
                            <img width="23px" src={toggled ? menu_close : menu} alt="menu" />
                        </button>
                        <div className="w-auto flex flex-row items-center">
                            <Branding />
                            <Text>
                                <div className="w-auto h-auto flex flex-row">
                                    <p className="text-black lg:ml-4">Administrator</p>
                                    <span
                                        className="hidden lg:block"
                                        style={{
                                            background: '#528dff',
                                            fontSize: '0.7rem',
                                            color: 'white',
                                            borderRadius: '16px',
                                            padding: '0px 8px',
                                            margin: '0px 8px',
                                        }}
                                    >
                                        Technical Preview
                                    </span>
                                </div>
                            </Text>
                        </div>
                        <div className="w-full md:w-auto flex flex-row items-center">
                            <Profile user={user} logout={logout} />
                        </div>
                    </AppHeader>
                    <div
                        className={`w-full absolute transition-left delay-100 duration-300 ease-in-out ${
                            toggled ? 'left-0' : '-left-full md:left-0'
                        }`}
                    >
                        <Nav links={nav_links} user={user} logout={() => logout()}></Nav>
                    </div>{' '}
                </div>
                <Component {...rest} />
            </div>
        );
    }
    return <Preloader />;
};

AdminProtectedRoute.propTypes = {
    component: PropTypes.any,
};

export default AdminProtectedRoute;
