import React from 'react';
import Text from '../../../layouts/typography/Text';

export const bullet: React.CSSProperties = {
    content: '',
    color: 'transparent',
    backgroundColor: '#6F8191',
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    marginTop: '5px',
    marginRight: '10px',
    paddingRight: '5px',
};

const Privacy = (): any => {
    return (
        <div className="p-8">
            <div className="pb-2">
                <Text type="bold">
                    <h1 className="text-base">Privacy Policy</h1>
                </Text>
            </div>
            <div>
                <div className="py-4" id="privacyPolicy">
                    <Text type="bold" color="tertiary">
                        <h2>Privacy Policy</h2>
                    </Text>
                    <Text color="tertiary">
                        <p className="py-1">
                            To create a wonderful world for our customers, partners, community, and the nation as a
                            whole, Globe Telecom, Inc. and its <b>subsidiaries</b> (collectively &quot;Globe,&quot;
                            &quot;we,&quot; &quot;us,&quot; &quot;our&quot;) put you, our customers, first. Because we
                            care for you, we regard your privacy with the utmost importance.
                        </p>
                        <p className="py-1">
                            This Privacy Policy outlines our policy in relation to the collection, use, and protection
                            of your Customer Data to provide you with a wonderful customer experience. From time to
                            time, we may update our Privacy Policy to reflect current changes in our policy and the law.
                            When we do so, we will notify you by posting it on our website for your information and
                            reference.
                        </p>
                    </Text>
                </div>
                <div className="py-4" id="rightsAsCustomer">
                    <Text type="bold" color="tertiary">
                        <h2>Your Rights as our Customer</h2>
                    </Text>
                    <Text color="tertiary">
                        <p className="py-1">
                            Globe recognizes that you should be the ultimate decision-maker on matters that involve your
                            Personal Information. To this end, Globe is mindful of the fundamental right to privacy of
                            every Globe customer under the{' '}
                            <a
                                href="https://www.privacy.gov.ph/data-privacy-act-primer/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <b>
                                    <u>Data Privacy Act of 2012</u>
                                </b>
                            </a>
                            .
                        </p>
                        <p className="py-1">
                            By accepting the Terms and Conditions for the use of Globe products and services, you agree
                            to the collection, processing, use, and sharing of your Personal Information in accordance
                            with this Privacy Policy that will enable us to provide you with your desired Globe products
                            and services.
                        </p>
                        <p className="py-1">
                            You have the right to be informed of the Personal Information that we collect, process, use,
                            and share. We can provide you with such Personal Information, provided that such Personal
                            Information does not amount to information deemed confidential or proprietary by Globe, or
                            that your request for Personal Information is not vexatious or unreasonable.
                        </p>
                        <p className="py-1">
                            You have the right to object or withhold your consent to the collection, processing, use,
                            and sharing of your Personal Information. However, we may be constrained to terminate your
                            Globe product or service as your Personal Information may be required to deliver such Globe
                            product or service. Without your Personal Information, we will be unable to provide you with
                            updates on Globe’s latest offerings; similarly, you will be unable to participate in our
                            events, promotions, or other activities.
                        </p>
                        <p className="py-1">
                            You have the right to suspend, withdraw, or order the blocking, removal, or destruction of
                            your Personal Information in our processing systems upon discovery and substantial proof
                            that your Personal Information is no longer necessary for the purpose or purposes for which
                            it was collected, and for such other cases provided in the{' '}
                            <a
                                href="https://www.privacy.gov.ph/data-privacy-act-primer/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <b>
                                    <u>Data Privacy Act of 2012</u>
                                </b>
                            </a>
                            .
                        </p>
                        <p className="py-1">
                            You have the right to seek indemnity for damages sustained, if any, due to inaccurate,
                            incomplete, outdated, false, unlawfully obtained, or unauthorized use of your Personal
                            Information.
                        </p>
                        <p className="py-1">
                            Should you feel that there has been mishandling or misuse of your Personal Information, or
                            that any of your data privacy rights have been violated, you may email us at
                            privacy@globe.com.ph
                        </p>
                    </Text>
                </div>
                <div className="py-4" id="collectionData">
                    <Text type="bold" color="tertiary">
                        <h2>Collection of Customer Data</h2>
                    </Text>
                    <Text color="tertiary">
                        <p className="py-1">
                            As part of our continuing relationship with you, we collect, process, use, and share your
                            Customer Data in accordance with this Privacy Policy and the Terms and Conditions for the
                            use of Globe products and services, as may be applicable.
                        </p>
                        <p className="py-1">
                            The Customer Data that we collect, process, use, and share are either Personal Information
                            or Non-Personal Information:
                        </p>
                        <ol type="a">
                            <li className="flex flex-row">
                                <div className="mr-3">a.</div>
                                <p>
                                    <b>Personal Information</b> is any information from which the identity of an
                                    individual can be reasonably and directly ascertained, or when put together with
                                    other information would directly and certainly identify an individual, such as name,
                                    gender, date of birth, address, telephone/mobile number, email address, proof of
                                    identification, etc. It also includes information about:
                                    <ol type="i">
                                        <li className="flex flex-row">
                                            <div className="mr-3">i.</div>
                                            <p>
                                                The services provided to you, such as call/SMS details, location
                                                information, and certain information about your rate plans and features,
                                                as required by law;
                                            </p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">ii.</div>
                                            <p>
                                                The location of your device whenever it is switched on, if you subscribe
                                                to location-based services, subject to coverage limitations; and
                                            </p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">iii.</div>
                                            <p>
                                                Your use of our network, your Internet Protocol addresses, network
                                                performance experience, diagnostics, such as signal strength, dropped
                                                calls, data failures, and other network performance issues, to help us
                                                improve our network and quality of products and services, improve your
                                                user experience, determine tailored content, create new products and
                                                services, and for other legitimate business purposes.
                                            </p>
                                        </li>
                                    </ol>
                                </p>
                            </li>
                            <li className="flex flex-row">
                                <div className="mr-3">b.</div>
                                <p>
                                    <b>Non-Personal Information</b> is any information that does not identify you
                                    individually, and includes statistical or analytical data, and anonymized or
                                    aggregated reports.
                                </p>
                            </li>
                        </ol>
                    </Text>
                </div>
                <div className="py-4" id="useData">
                    <Text type="bold" color="tertiary">
                        <h2>Use of Customer Data</h2>
                    </Text>
                    <Text color="tertiary">
                        <ol type="a">
                            <li className="flex flex-row">
                                <div className="mr-3">a.</div>
                                <p>
                                    We may share Customer Data with our subsidiaries, affiliates, partners, and
                                    third-party service providers as part of business operations and provision of
                                    products and services. Î
                                </p>
                            </li>
                            <li className="flex flex-row">
                                <div className="mr-3">b.</div>
                                <p>
                                    Without limiting the generality of the foregoing, Customer Data is used to, among
                                    others:
                                    <ol type="i">
                                        <li className="flex flex-row">
                                            <div className="mr-3">i.</div>
                                            <p>
                                                Provide you with your subscribed products and services, including
                                                customer support;
                                            </p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">ii.</div>
                                            <p>Help us improve our network and quality of products and services;</p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">iii.</div>
                                            <p>Create new products and services;</p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">iv.</div>
                                            <p>
                                                Enhance your customer experience and determine tailored content to meet
                                                your preferences and needs;
                                            </p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">v.</div>
                                            <p>Communicate relevant services and/or advisories to you;</p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">vi.</div>
                                            <p>
                                                Abide by any safety, security, public service or legal requirements and
                                                processes;
                                            </p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">vii.</div>
                                            <p>
                                                Process information for statistical, analytical, and research purposes.
                                                The use of your information for statistical, analytical, and research
                                                purposes enables us to create anonymized and aggregated insight reports
                                                that we use to further improve your user experience and to provide new
                                                products and services;
                                            </p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">viii.</div>
                                            <p>
                                                Create a customer portrait based on your demographic, and your
                                                behavioral, transaction, and interaction data across all products,
                                                systems, devices, and/or interaction channels, which we may use as a
                                                basis for sending you commercial and promotional alerts, personalized
                                                advertisements, financial service offers, and surveys. To be clear,
                                                should we need to share data with third parties for advertising and
                                                marketing purposes, we only share anonymized and aggregated data, and
                                                not your Personal Information;
                                            </p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">ix.</div>
                                            <p>
                                                Compute for a telco score which may be shared with our subsidiaries,
                                                affiliates, partners and/or third parties, for various purposes such as,
                                                but not limited to, credit scoring, premium subscription offerings,
                                                product bundling, consistent with our goal to provide you with new
                                                products and relevant offerings to meet your changing needs; and
                                            </p>
                                        </li>
                                        <li className="flex flex-row">
                                            <div className="mr-3">x.</div>
                                            <p>
                                                Comply with the requirements of the law and legal process, such as a
                                                court order; to comply with a legal obligation; or to prevent imminent
                                                harm to public security, safety, or order, collectively referred to as
                                                “Data Use Purpose.”
                                            </p>
                                        </li>
                                    </ol>
                                </p>
                            </li>
                        </ol>
                        <p className="py-1">
                            When required by our Privacy Policy and the law, and before we collect, process, use, or
                            share your Personal Information for any other purpose other than enumerated above, we will
                            ask for your consent.
                        </p>
                        <p className="py-1">
                            You may avail of our broadcast messages relevant to you through written correspondence, text
                            messaging, internet, or other similar means of communication. You may also change your mind
                            anytime and stop receiving them.
                        </p>
                        <p className="py-1">
                            When you use our website and electronically communicate with us, depending on your settings,
                            we may use cookies, web beacons, small data text files, or similar technologies to identify
                            your device and record your preferences, with your consent. The completeness and accuracy of
                            your Personal Information help us improve our products and services. Thus, we encourage you
                            to update your Personal Information from time to time.
                        </p>
                        <p className="py-1">
                            We outsource or contract the processing of Customer Data to third parties, such as but not
                            limited to, vendors, service providers, partners, or other telecommunications operators, to
                            fulfill any of the above purposes. They are only authorized to use Customer Data for such
                            contracted purposes. They may have access to Customer Data for a limited time under
                            reasonable contractual and technical safeguards to limit their use of such Customer Data. We
                            require them to protect Customer Data consistent with our Privacy Policy.
                        </p>
                        <p className="py-1">
                            During roaming or when availing of services offered by foreign service providers through our
                            network, the storage, treatment, and transfer of your Personal Information may be subject to
                            regulations different from Philippine regulations.
                        </p>
                    </Text>
                </div>
                <div className="py-4" id="protectionPersonalInformation">
                    <Text type="bold" color="tertiary">
                        <h2>Protection of Personal Information</h2>
                    </Text>
                    <Text color="tertiary">
                        <p className="py-1">
                            We respect your privacy. We take paramount care in protecting your Personal Information. As
                            such, we secure and protect your Personal Information with proper safeguards to ensure
                            confidentiality and privacy; prevent loss, theft, or use for unauthorized purposes; and
                            comply with the requirements of the law
                        </p>
                        <p className="py-1">
                            As Information Security threats continue to develop and evolve at such a rapid pace, we make
                            reasonable and appropriate security arrangements and measures that use a variety of
                            physical, electronic, and procedural safeguards to protect Personal Information. Globe runs
                            a state of the art Security Operations Center with a dedicated team and personnel that
                            monitors our network and systems to make sure risks to Globe and your Personal Information
                            are properly managed. We regularly review our information collection, storage, and
                            processing practices, including physical security measures, to guard against unauthorized
                            access to our system and unauthorized alteration, disclosure, or destruction of information
                            we hold.
                        </p>
                        <p className="py-1">
                            We only permit your Personal Information to be collected, processed, used, and shared by our
                            authorized employees, contractors, and subcontractors who hold such Personal Information
                            under strict confidentiality and in accordance with their contractual obligations and who
                            have implemented minimum security features against data leakage, unauthorized access, or
                            disclosure. We restrict access to information to Globe employees, contractors, and
                            subcontractors who need to know such information in order to process it for us, who are
                            subject to strict contractual and technical safeguards, and who are accountable if they fail
                            to meet these obligations.
                        </p>
                        <p className="py-1">
                            We only give you or your authorized representative access to your Personal Information. We
                            do not provide, sell, or share your Personal Information to anyone unless you have given
                            your express consent. We also do not use nor share your Personal Information with content
                            and/or information providers without your prior request or consent. Personal Information
                            will only be disclosed to third parties in accordance with this Privacy Policy.
                        </p>
                        <p className="py-1">
                            We only permit your Personal Information to be collected, processed, used, and shared by our
                            authorized employees, contractors, and subcontractors who hold such Personal Information
                            under strict confidentiality and in accordance with their contractual obligations and who
                            have implemented minimum security features against data leakage, unauthorized access, or
                            disclosure. We restrict access to information to Globe employees, contractors, and
                            subcontractors who need to know such information in order to process it for us, who are
                            subject to strict contractual and technical safeguards, and who are accountable if they fail
                            to meet these obligations.
                        </p>
                        <p className="py-1">
                            We keep our records as accurate as possible. If your Personal Information is wrong, we give
                            you ways to update it. Once you have registered as our customer, you may access your account
                            details and correct your Personal Information by contacting Globe Customer Care (
                            <b>(+632) 7730-1000</b> or <b>211</b> using your mobile phone) or your relationship manager,
                            as may be applicable; or by visiting any Globe Store or our website at{' '}
                            <a href="https://www.globe.com.ph/" target="_blank" rel="noreferrer">
                                <b>
                                    <u>www.globe.com.ph</u>
                                </b>
                            </a>
                            .
                        </p>
                        <p className="py-1">
                            We keep your Personal Information in our business records, as may be applicable, while you
                            are a customer, or as long as it is necessary to fulfill the purpose for which it was
                            collected, or while it is needed by us for business, tax, or legal purposes. When disposing
                            of your Personal Information, we take reasonable measures to ensure that it is done properly
                            and is not accessible to the public.
                        </p>
                        <p className="py-1">
                            We are not responsible for information, content, application, product, or service that we do
                            not provide. But because we care for you and protect you, we take measures to fight spam,
                            fraud, or any unauthorized messages that traverse our network. Further, we will not tolerate
                            the use of our network that violates the law, which shall include but not be limited to:
                            <ol>
                                <li className="flex flex-row">
                                    <div className="mr-3">a.</div>
                                    <p>
                                        Photo or video voyeurism, including the publication or broadcast or transmission
                                        through our network of any sexual act or any similar activity without the
                                        consent of the person involved and under circumstances in which the person has a
                                        reasonable expectation of privacy, is prohibited and unlawful with corresponding
                                        penalties under the law.
                                    </p>
                                </li>
                                <li className="flex flex-row">
                                    <div className="mr-3">b.</div>
                                    <p>
                                        Child pornography is also prohibited and punishable by law and our network shall
                                        not be used in any manner for the storage, transmission, or dissemination of
                                        materials containing child pornography. We will report any instances of such
                                        activity that we become aware of, to the proper authorities as required by law.
                                    </p>
                                </li>
                            </ol>
                        </p>
                        <p className="py-1">
                            We shall cooperate with law enforcement agencies to curtail criminality and prevent
                            criminals from wreaking havoc over the internet, which shall include the blocking of certain
                            sites or prevention of access to certain individuals, all in accordance with the
                            stipulations of the law and with respect to legal due process.
                        </p>
                    </Text>
                </div>
                <div className="py-4" id="privacyPrinciples">
                    <Text type="bold" color="tertiary">
                        <h2>High-level Privacy Principles</h2>
                    </Text>
                    <Text color="tertiary">
                        <p className="py-1">
                            In addition, we only want the happiest customers, so we commit ourselves to abide by Groupe
                            Speciale Mobile Association (GSMA)’s high-level privacy principles based on internationally
                            recognized and accepted principles on privacy and data protection as follows:
                            <ol>
                                <li className="flex flex-row">
                                    <div className="mr-3">a.</div>
                                    <p>
                                        <b>Openness, Transparency, and Notice</b> <br /> Child pornography is also
                                        prohibited and punishable by law and our network shall not be used in any manner
                                        for the storage, transmission, or dissemination of materials containing child
                                        pornography. We will report any instances of such activity that we become aware
                                        of, to the proper authorities as required by law.
                                    </p>
                                </li>
                                <li className="flex flex-row">
                                    <div className="mr-3">b.</div>
                                    <p>
                                        <b>Purpose and Use</b> <br /> We will limit the access, collection, sharing,
                                        disclosure, and further use of your Personal Information to meet legitimate
                                        business purposes or to otherwise meet legal obligations.
                                    </p>
                                </li>
                                <li className="flex flex-row">
                                    <div className="mr-3">c.</div>
                                    <p>
                                        <b>User Choice and Control</b> <br /> We will give you opportunities to exercise
                                        meaningful choice and control over your Personal Information.
                                    </p>
                                </li>
                                <li className="flex flex-row">
                                    <div className="mr-3">d.</div>
                                    <p>
                                        <b>Data Minimization and Retention</b> <br /> We will collect, access, and use
                                        only the minimum Personal Information necessary to meet legitimate business
                                        purposes and to deliver, provision, maintain, or develop applications and
                                        services. Personal Information will not be kept for longer than is necessary for
                                        those legitimate business purposes or to meet legal obligations, and will
                                        subsequently be deleted or rendered anonymous.
                                    </p>
                                </li>
                                <li className="flex flex-row">
                                    <div className="mr-3">e.</div>
                                    <p>
                                        <b>Respect User Rights</b> <br /> You will be provided with information about,
                                        and an easy means to exercise, your rights over the use of your Personal
                                        Information.
                                    </p>
                                </li>
                                <li className="flex flex-row">
                                    <div className="mr-3">f.</div>
                                    <p>
                                        <b>Security</b> <br /> Personal Information will be protected, using reasonable
                                        safeguards appropriate to the sensitivity of the information.
                                    </p>
                                </li>
                                <li className="flex flex-row">
                                    <div className="mr-3">g.</div>
                                    <p>
                                        <b>Education</b> <br /> You will be provided with information about privacy and
                                        security issues and ways to manage and protect your privacy.
                                    </p>
                                </li>
                                <li className="flex flex-row">
                                    <div className="mr-3">h.</div>
                                    <p>
                                        <b>Children</b> <br /> An application or service that is directed at children
                                        will ensure that the collection, access, and use of Personal Information is
                                        appropriate in all given circumstances and compatible with applicable law.
                                    </p>
                                </li>
                                <li className="flex flex-row">
                                    <div className="mr-3">i.</div>
                                    <p>
                                        <b>Accountability and Enforcement</b> <br /> All responsible persons will be
                                        accountable for ensuring that these principles are met.
                                    </p>
                                </li>
                            </ol>
                        </p>
                    </Text>
                </div>
                <div className="py-4" id="contactUs">
                    <Text type="bold" color="tertiary">
                        <h2>Contact Us</h2>
                    </Text>
                    <Text color="tertiary">
                        <p className="py-1">
                            Should you wish not to:
                            <ol>
                                <li className="flex flex-row">
                                    <div className="mr-3">a.</div>
                                    <p>Have your Personal Information disclosed or processed;</p>
                                </li>
                                <li className="flex flex-row">
                                    <div className="mr-3">b.</div>
                                    <p>
                                        Receive marketing alerts and promotional messages from us, our subsidiaries,
                                        affiliates, and partners,
                                    </p>
                                </li>
                            </ol>
                        </p>
                        <p className="py-1">
                            you may immediately get in touch with us through our website at www.globe.com.ph or by
                            contacting Globe Customer Care ((+632) 7730-1000 or 211 using your mobile phone).
                        </p>
                        <p className="py-1">
                            For any questions or concerns, you may contact our Data Protection Officer as follows:
                        </p>
                        <p className="py-1">
                            Data Protection Officer <br /> Globe Telecom, Inc.
                            <br /> The Globe Tower
                            <br /> 32nd Street corner 7th Avenue,
                            <br />
                            Bonifacio Global City,
                            <br /> 1634 Taguig City,
                            <br /> Metro Manila, Philippines
                            <br /> Email: privacy@globe.com.ph
                        </p>
                    </Text>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
