import 'firebase/functions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../../../layouts/container/Container';
import NotificationsList from '../../../layouts/notifications/NotificationsList';
import Subtitle from '../../../layouts/typography/Subtitle';
import Title from '../../../layouts/typography/Title';
import { clearNotifications, loadNotifications } from '../../../redux/NotificationSlice';
import { RootState } from '../../../redux/RootReducer';
import { useDetectOnEndScroll } from '../../../utils/hooks/useDetectOnEndScroll';
import firebase from 'firebase/app';
import 'firebase/functions';
import { Notification } from '../../../models/Notification';
import { navigate } from '@reach/router';

const NotificationsFullView = () => {
    const { user } = useSelector((state: RootState) => state.Auth);
    const { notifications, http_request } = useSelector((state: RootState) => state.Notifications);
    const { isEnd, setEnd } = useDetectOnEndScroll(false);
    const [lastDocId, setLastDocId] = React.useState('');

    const dispatch = useDispatch();

    const onClick = (notification: Notification) => {
        const updateNotification = firebase.app().functions('asia-east2').httpsCallable('update_notification');
        updateNotification({ notification_id: notification.notification_id });
        const url = notification.url.replace(/^.*\/\/[^\/]+/, '');
        navigate(url);
    };

    /** TODO: Convert this to a hook for reusability */
    React.useEffect(() => {
        if (notifications.length) {
            const last_doc_id = notifications[notifications.length - 1].notification_id;
            setLastDocId(last_doc_id);
        }
    }, [notifications]);

    React.useEffect(() => {
        /* istanbul ignore else */
        if (user.email) {
            dispatch(clearNotifications());
            dispatch(loadNotifications({ email: user.email, limit: 15 }));
            setTimeout(
                /* istanbul ignore next */ () => {
                    setEnd(true);
                },
                1000,
            );
        }
    }, []);

    /** TODO: Convert this to a hook for reusability */
    React.useEffect(() => {
        /* istanbul ignore if */
        if (!isEnd) return;

        /* istanbul ignore else */
        if (lastDocId && user.email && http_request.state === 'fulfilled') {
            dispatch(loadNotifications({ email: user.email, limit: 15, last_doc_id: lastDocId }));
        }
    }, [isEnd]);

    return (
        <Container>
            <div className="w-full">
                <Title>Notifications</Title>
                <div className="my-2">
                    <Subtitle>Here are the notifications waiting for you.</Subtitle>
                </div>
            </div>
            <div className="w-full mt-8 relative">
                <NotificationsList state={http_request.state} notifications={notifications} onClick={onClick} />
            </div>
        </Container>
    );
};

export default NotificationsFullView;
