import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../../layouts/form/Input';
import Label from '../../../../layouts/form/Label';
import Modal from '../../../../layouts/modals/Modal';
import { ModalContent } from '../../../../models/ModalContent';
import { RootState } from '../../../../redux/RootReducer';
import { ITemplatesStepperFormProps } from './TemplatesForm';
import ic_check from '../../../../assets/img/ic_check.svg';
import ic_alert from '../../../../assets/img/ic_alert.svg';
import Title from '../../../../layouts/typography/Title';
import Text from '../../../../layouts/typography/Text';
import Button from '../../../../layouts/buttons/Button';
import { AppDispatch } from '../../../../redux/Store';
import { saveTemplate, resetFormBuilder } from '../../../../redux/FormBuilderSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { navigate } from '@reach/router';

/**
 * Renders the workflow form.
 * Currently unavailable at the moment.
 * @returns
 */
const TemplatesWorkflowForm = (props: ITemplatesStepperFormProps) => {
    const template = useSelector((state: RootState) => state.FormBuilder);
    const dispatch: AppDispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const initialModal = {
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    };
    const [modalContent, setModalContent] = React.useState<ModalContent>(initialModal);

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setIsModalVisible(true);
            e.preventDefault();

            const data = _.omit(
                { ...template },
                'errors',
                'hasRTE',
                'hasFileUpload',
                '_editing',
                '_categories',
                '_http_request_state',
                '_http_template_request_state',
                '_http_category_request_state',
            );

            let action = null;

            if (props.formMode === 'CREATE') {
                action = await dispatch(
                    saveTemplate({
                        template: data,
                        action: props.formAction.toUpperCase(),
                        endpoint: 'admin-create_template',
                    }),
                );
            } else {
                action = await dispatch(
                    saveTemplate({
                        template: data,
                        action: props.formAction.toUpperCase(),
                        endpoint: 'admin-edit_template',
                    }),
                );
            }

            const result = unwrapResult(action);
            console.log(result);

            const success_modal = {
                loading: false,
                title:
                    props.formAction.toUpperCase() === 'PUBLISHED'
                        ? 'Your template has been published'
                        : 'Your template has been saved.',
                icon: ic_check,
                subtitle: 'Go to Templates to view your list of templates',
                buttons: [
                    {
                        label: 'Okay, got it!',
                        event: () => {
                            setIsModalVisible(false);
                            setModalContent(initialModal);
                            navigate('/templates');
                        },
                    },
                ],
            };
            setModalContent(success_modal);
        } catch (err: any) {
            setModalContent({
                loading: false,
                icon: ic_alert,
                title: 'There was an error while sending the request',
                subtitle: err.message,
                buttons: [
                    {
                        label: 'Retry',
                        event: () => {
                            setIsModalVisible(false);
                            setModalContent(initialModal);
                        },
                    },
                ],
            });
        }
    };
    return (
        <form id={props.formId} onSubmit={onSubmit}>
            <div className="w-4/6 h-auto p-4 bg-white">
                <div className="w-full h-auto p-4">
                    <h4 className="text-md font-bold">Workflow</h4>
                </div>
                <div className="w-full h-auto px-4 mb-12">
                    <Label htmlFor="approval_workflow">Approval workflow</Label>
                    <div className="w-auto h-auto block mt-2">
                        <Input
                            id="option-all"
                            label="Anyone"
                            type="radio"
                            defaultChecked
                            annotation="Anyone from your organization can be assigned as an approver or watcher by the user"
                        />
                    </div>
                </div>
            </div>
            <Modal visible={isModalVisible}>
                {modalContent.loading ? (
                    <div className="w-full flex flex-col items-center justify-center">
                        <Text>Loading...</Text>
                    </div>
                ) : (
                    <div className="w-full flex flex-col items-center">
                        <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                        <Title type="h4">{modalContent.title}</Title>
                        <div className="my-2 text-center">
                            <Text>{modalContent.subtitle}</Text>
                        </div>
                        {modalContent.buttons.length > 1 ? (
                            <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        weight="font-regular"
                                        onClick={modalContent.buttons[0].event}
                                    >
                                        {modalContent.buttons[0].label}
                                    </Button>
                                </div>
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        color="primary"
                                        weight="font-regular"
                                        type="button"
                                        onClick={modalContent.buttons[1].event}
                                    >
                                        {modalContent.buttons[1].label}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="my-2">
                                <Button
                                    type="button"
                                    color="primary"
                                    weight="font-regular"
                                    onClick={modalContent.buttons[0].event}
                                >
                                    {modalContent.buttons[0].label}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Modal>
        </form>
    );
};

export default TemplatesWorkflowForm;
