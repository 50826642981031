import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reply from '../../../assets/img/reply.svg';
import Button from '../../../layouts/buttons/Button';
import TextArea from '../../../layouts/form/Textarea';
import MemoLogGroupList from '../../../layouts/memo-logs/MemoLogGroupList';
import MemoLogGroupListItem from '../../../layouts/memo-logs/MemoLogGroupListItem';
import MemoLogListItem from '../../../layouts/memo-logs/MemoLogListItem';
import Modal from '../../../layouts/modals/Modal';
import Preloader from '../../../layouts/preloaders/Preloader';
import ReplyList from '../../../layouts/reply-list/ReplyList';
import Tooltip from '../../../layouts/tooltip/Tooltip';
import Text from '../../../layouts/typography/Text';
import Title from '../../../layouts/typography/Title';
import { MemoLog, MemoLogEntry } from '../../../models/MemoLog';
import { ModalContent } from '../../../models/ModalContent';
import { clearLogs, createReply, getLogs, toggleExpansion } from '../../../redux/MemoLogsSlice';
import { RootState } from '../../../redux/RootReducer';
import { AppDispatch } from '../../../redux/Store';
import MemoLogReplies from '../replies/MemoLogReplies';
import ic_download from '../../../assets/img/ic_download.svg';
import firebase from 'firebase/app';
import 'firebase/functions';
import { SubApprovalsProvider } from '../sub-approvals/SubApprovalsProvider';
import { Contact } from '../../../models/Contact';
import { Approver } from '../../../models/Approver';

interface IMemoViewContentLogs extends React.ComponentPropsWithoutRef<'div'> {
    id: string;
}

const RepliesProvider = MemoLogReplies(ReplyList);
const SubApprovalsModal = SubApprovalsProvider(Modal);
const MemoViewContentLogs = (props: IMemoViewContentLogs) => {
    const { user, memo, http_request_state, data, is_all_expanded } = useSelector((rootState: RootState) => ({
        user: rootState.Auth.user,
        memo: rootState.MemoView.memo,
        http_request_state: rootState.MemoLogs.http_request_state,
        data: rootState.MemoLogs.data,
        is_all_expanded: rootState.MemoLogs.is_all_expanded,
    }));

    const dispatch: AppDispatch = useDispatch();
    const initialModal = {
        isVisible: false,
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    };
    const [modalContent, setModalContent] = React.useState<ModalContent>(initialModal);
    const [remarksLocation, setRemarksLocation] = React.useState({ level_no: 0, index: 0 });
    const [remarks, setRemarks] = React.useState('');

    const [subApprovers, setSubApprovers] = React.useState<Array<Contact & Approver>>([]);
    const [subApprovalModalVisible, setSubApprovalModalVisible] = React.useState(false);
    const [subApprovalExclusions, setSubApprovalExclusions] = React.useState([user.email as string]);
    const [subApprovalGroup, setSubApprovalGroup] = React.useState<MemoLog | null>(null);
    const [subApprovalHttpState, setSubApprovalHttpState] = React.useState('idle');

    React.useEffect(() => {
        /** istanbul ignore else */
        if (memo && memo.memo_id) {
            dispatch(getLogs(memo.memo_id));
        }
        return () => {
            dispatch(clearLogs());
        };
    }, [memo]);

    const reset = () => {
        setModalContent(initialModal);
        setRemarksLocation({ level_no: 0, index: 0 });
        setRemarks('');
    };

    const downloadFile = async (url: string) => {
        const downloadMemoFile = firebase.app().functions('asia-east2').httpsCallable('download_memo_file');
        try {
            setModalContent({ ...modalContent, isVisible: true, loading: true });
            const response = await downloadMemoFile({
                memo_id: memo.memo_id,
                fileName: url,
            });

            const [signedURL] = response.data.signedURL;
            window.open(signedURL, '_blank');
        } catch (err: any) {
            alert(`There was an error downloading the file. ${err.message ? err.message : err}`);
        }
        setModalContent({ ...modalContent, isVisible: false });
    };

    const handleModal = (log: MemoLogEntry, level_no: number, index: number) => {
        setRemarksLocation({ level_no: level_no, index: index });
        setModalContent({
            isVisible: true,
            loading: false,
            title: `Replying to ${log.user_id === user.uid ? 'your remark' : log.name + "'s remark"}`,
            subtitle: '',
            buttons: [
                {
                    label: 'Cancel',
                    event: reset,
                },
                {
                    label: 'Submit',
                    event: () => {
                        return;
                    },
                },
            ],
        });
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (remarks.length > 0) {
            try {
                setModalContent({
                    ...modalContent,
                    isVisible: true,
                    loading: true,
                });
                const action = await dispatch(
                    createReply({
                        email: user.email as string,
                        log_id: data[remarksLocation.level_no].logs[remarksLocation.index].log_id,
                        memo_id: memo.memo_id,
                        message: remarks,
                        name: user.displayName as string,
                        reply_date: '',
                        reply_id: '',
                        sequence_no: remarksLocation.level_no,
                        meta: remarksLocation,
                    }),
                );
                unwrapResult(action);
                reset();
            } catch (err: any) {
                console.error(err.message);
                alert(`There was an error submitting your reply. ${err.code ? err.code : err}`);
                reset();
            }
        }
    };

    const toggleListItemExpanded = () => {
        dispatch(toggleExpansion(!is_all_expanded));
    };

    const handleSubApprovalClicked = (group: MemoLog) => {
        setSubApprovalGroup(group);

        const approverUserId = user.uid;
        const approvers = group.approvers.map((approver) => approver.email);
        const watchers = group.watchers.map((watcher) => watcher.email);
        const subApprovers = group.approvers
            .filter((approver) => approver.approver_user_id === approverUserId)
            .map((approver) => approver.sub_approvers || [])
            .flat();
        const allSubApprovers = group.approvers.map((approver) => approver.sub_approvers || []).flat();
        const subApproverEmails = allSubApprovers.map((approver) => approver!.email);
        const memoOwnerEmail = memo.memo_owner_email as string;

        setSubApprovers(subApprovers);
        setSubApprovalExclusions(
            [...subApprovalExclusions, memoOwnerEmail].concat(approvers).concat(watchers).concat(subApproverEmails),
        );
        setSubApprovalModalVisible(true);
    };

    const handleSubApproverAdded = (email: string) => {
        setSubApprovalExclusions([...subApprovalExclusions, email]);
    };

    const handleSubApproverRemoved = (emails: string[]) => {
        setSubApprovalExclusions(emails);
    };

    const handleSubApprovalClose = () => {
        setSubApprovalModalVisible(false);
        setSubApprovalExclusions([user.email as string]);
        setSubApprovalGroup(null);
        setSubApprovers([]);
    };

    const handleSubApprovalSubmit = async (subApprovers: string[]) => {
        setSubApprovalHttpState('pending');

        const payload = {
            memo_id: memo.memo_id,
            sequence_id: subApprovalGroup?.sequence_id,
            sub_approvers: subApprovers,
            min_approvers: 0, // for enhancement
        };
        try {
            const editSubapprovers = firebase.app().functions('asia-east2').httpsCallable('editSubapprovers');
            const httpResponse = await editSubapprovers(payload);
            if (httpResponse.data) {
                setSubApprovalModalVisible(false);
                setSubApprovalHttpState('idle');
                dispatch(getLogs(memo.memo_id));
            }
            return httpResponse.data;
        } catch (err) {
            alert((err as { message: string }).message || 'Unable to submit sub-approval request');
            setSubApprovalHttpState('idle');
        }
    };

    switch (http_request_state) {
        case 'pending':
            return <Preloader type="dots" text="Loading..." />;
        case 'fulfilled':
            return (
                <div className="pl-1 pr-1">
                    <div className="w-full bg-blue-light pr-12 md:ml-8" id={props.id}>
                        <div className="w-full flex flex-row content-end px-4 py-4 space-between relative">
                            <h4 className="font-bold text-md p-1" data-testid="logs-title">
                                Memo logs
                            </h4>
                            <button
                                className="text-xs p-2 font-regular text-blue absolute right-0 underline transform translate-x-10"
                                onClick={toggleListItemExpanded}
                                role="logs-toggle"
                            >
                                {data.length > 1 && (is_all_expanded ? 'Collapse all' : 'Expand all')}
                            </button>
                        </div>
                        <div style={{ transform: 'translateY(-25px)' }}>
                            {/* allow_sub_approvals comes from Memo Data */}
                            <MemoLogGroupList>
                                {data.map((group: MemoLog, level_no) => (
                                    <MemoLogGroupListItem
                                        key={group.sequence_id}
                                        log_entry={group}
                                        user={user}
                                        allow_sub_approvals={
                                            memo.memo_status === 'SUBMITTED' && group.allow_sub_approvals
                                        }
                                        onSubApprovalClicked={() => handleSubApprovalClicked(group)}
                                    >
                                        {group.logs.map((log: MemoLogEntry, index) => (
                                            <MemoLogListItem key={log.log_id} log={log}>
                                                <div className="w-full flex flex-col mt-1">
                                                    {log.attachments &&
                                                        log.attachments.map((attachment, attachmentIndex) => (
                                                            <div
                                                                key={attachmentIndex}
                                                                className="w-full flex flex-row items-center p-2 bg-white border border-gray-light mb-1 rounded"
                                                            >
                                                                <p className="text-xs font-regular truncate">
                                                                    {attachment.name}
                                                                </p>
                                                                <button
                                                                    className="flex ml-auto p-1"
                                                                    onClick={() =>
                                                                        downloadFile(attachment.url as string)
                                                                    }
                                                                    role="file-download"
                                                                >
                                                                    <img
                                                                        src={ic_download}
                                                                        width="10px"
                                                                        height="10px"
                                                                        alt="Download"
                                                                    />
                                                                </button>
                                                            </div>
                                                        ))}
                                                    <Tooltip
                                                        type="component"
                                                        className="w-auto text-xs text-blue flex flex-row items-center self-start relative"
                                                    >
                                                        <button
                                                            className={`w-auto mt-2 text-xs text-blue flex flex-row items-center self-start ${
                                                                log.locked ? 'opacity-50' : ''
                                                            }`}
                                                            onClick={() => handleModal(log, level_no, index)}
                                                            disabled={log.locked}
                                                            role="reply"
                                                        >
                                                            <span className="mr-1">
                                                                <img src={reply} width="10px" alt="reply" />
                                                            </span>
                                                            Reply
                                                        </button>
                                                        {log.locked && (
                                                            <span
                                                                className="tooltiptext absolute text-sm"
                                                                role="tooltip"
                                                                style={{ width: '200px', whiteSpace: 'pre-wrap' }}
                                                            >
                                                                This thread is now closed. For any more concerns, kindly
                                                                contact them directly through{' '}
                                                                <a
                                                                    className="text-blue"
                                                                    href="http://chat.google.com"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    Google Chat
                                                                </a>
                                                            </span>
                                                        )}
                                                    </Tooltip>
                                                    <RepliesProvider
                                                        level_no={level_no}
                                                        log_id={log.log_id}
                                                        memo_id={memo.memo_id}
                                                        index={index}
                                                    />
                                                </div>
                                            </MemoLogListItem>
                                        ))}
                                    </MemoLogGroupListItem>
                                ))}
                            </MemoLogGroupList>
                        </div>
                    </div>
                    <Modal visible={modalContent.isVisible ? modalContent.isVisible : false}>
                        {modalContent.loading ? (
                            <div className="w-full flex flex-col items-center justify-center">
                                <Preloader type="dots" text="Loading..." />
                            </div>
                        ) : (
                            <form className="w-full" onSubmit={onSubmit} role="reply-form">
                                <fieldset>
                                    <div className="w-full flex flex-col items-start">
                                        <Title type="h4">{modalContent.title}</Title>
                                        <div className="my-0">
                                            <Text type="innerHTML">{modalContent.subtitle}</Text>
                                        </div>
                                        <div className="w-full flex flex-col items-start">
                                            <TextArea
                                                id="txt-reason"
                                                label="Reply"
                                                rows={4}
                                                placeholder="Type your remarks here."
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                                    setRemarks(e.target.value)
                                                }
                                                maxLength={3000}
                                                role="reply-field"
                                            />
                                        </div>
                                        <p className="text-xs ml-auto text-right font-regular">{remarks.length}/3000</p>
                                        {modalContent.buttons.length > 1 && (
                                            <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                                <div className="w-full lg:w-1/2 flex flex-col">
                                                    <Button
                                                        type="button"
                                                        color="secondary"
                                                        weight="font-regular"
                                                        onClick={modalContent.buttons[0].event}
                                                    >
                                                        {modalContent.buttons[0].label}
                                                    </Button>
                                                </div>
                                                <div className="w-full lg:w-1/2 flex flex-col">
                                                    <Button
                                                        color="primary"
                                                        weight="font-regular"
                                                        type="submit"
                                                        disabled={remarks.length === 0}
                                                        onClick={modalContent.buttons[1].event}
                                                    >
                                                        {modalContent.buttons[1].label}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </fieldset>
                            </form>
                        )}
                    </Modal>

                    <SubApprovalsModal
                        defaultSubApprovers={subApprovers}
                        httpState={subApprovalHttpState}
                        visible={subApprovalModalVisible}
                        onSubmit={handleSubApprovalSubmit}
                        onClose={handleSubApprovalClose}
                        onApproverAdded={handleSubApproverAdded}
                        onApproverRemoved={handleSubApproverRemoved}
                        width="650px"
                        exclusions={subApprovalExclusions}
                    />
                </div>
            );
        case 'rejected':
            return <Text>There was an error while sending the request.</Text>;
        default:
            return null;
    }
};

export default MemoViewContentLogs;
