import React from 'react';
import ReactTimeago from 'react-timeago';
import { Reply } from '../../models/Reply';
import { getDateString } from '../../utils/date/Date';

const ReplyListItem = (props: Reply) => {
    const [isTruncated, setIsTruncated] = React.useState(true);

    const truncateMessage = (str: string) => {
        if (isTruncated && str.length >= 180) {
            return str.slice(0, 180) + '...';
        }
        return str;
    };

    const onClick = () => {
        setIsTruncated(!isTruncated);
    };

    return (
        <li key={props.reply_id} className="reply w-full p-2 rounded mb-2" style={{ background: '#F5FBFF' }}>
            <div className="w-full h-aut flex flex-row justify-between">
                <h4 className="font-bold truncate">{props.name}</h4>
                <p>
                    <ReactTimeago date={getDateString(props.reply_date)} />
                </p>
            </div>
            <p className="italic mt-2 whitespace-pre-line break-words">
                {truncateMessage(props.message)}{' '}
                {props.message.length >= 180 && (
                    <button className="text-xs text-blue mt-2" onClick={onClick} role="truncate">
                        See {isTruncated ? 'more' : 'less'}
                    </button>
                )}
            </p>
        </li>
    );
};

export default ReplyListItem;
