import * as React from 'react';
import PropTypes from 'prop-types';

interface ITitleProps {
    type?: string;
    color?: string;
    children: React.ReactNode;
}

const Title: React.FunctionComponent<ITitleProps> = (props) => {
    const { type, color } = props;
    const getTextColor = (color: string | undefined) => {
        switch (color) {
            case 'secondary':
                return 'text-gray-1';
            default:
                return 'text-display';
        }
    };
    switch (type) {
        case 'h1':
            return (
                <div className={`font-bold ${getTextColor(color)} w-auto text-3xl`} data-testid="title-h1">
                    {props.children}
                </div>
            );
        case 'h2':
            return (
                <div className={`font-bold ${getTextColor(color)} w-auto text-2xl`} data-testid="title-h2">
                    {props.children}
                </div>
            );
        case 'h3':
            return (
                <div className={`font-bold ${getTextColor(color)} w-auto text-xl`} data-testid="title-h3">
                    {props.children}
                </div>
            );
        case 'h4':
            return (
                <div className={`font-bold ${getTextColor(color)} w-auto text-lg`} data-testid="title-h4">
                    {props.children}
                </div>
            );
        default:
            return (
                <>
                    <div className={`font-bold ${getTextColor(color)} w-auto text-4xl`} data-testid="title-default">
                        {props.children}
                    </div>
                </>
            );
    }
};

Title.propTypes = {
    type: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),
    children: PropTypes.node,
};

export default Title;
