import React from 'react';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { TemplateConstant, TemplateContent } from '../../../../models/TemplateContent';
import Input from '../../../../layouts/form/Input';
import Select, { selectStyles } from '../../../../layouts/select/Select';
import Label from '../../../../layouts/form/Label';
import TextArea from '../../../../layouts/form/Textarea';
import { FormBuilderError } from '../../../../redux/FormBuilderSlice';

interface IFormGridCellAnswerViewProps {
    formItem: TemplateContent;
    errors?: FormBuilderError[] | null;
    onFormFieldChanged: (field: string, value: any) => void;
}

const FormGridCellNestedAnswerView = (props: IFormGridCellAnswerViewProps) => {
    /**
     * Adds a form item to constants array
     */
    const addNestedFormConstant = () => {
        const formField: TemplateContent = {
            field_id: nanoid(),
            field_name: 'Untitled',
            field_type: 'TEXT',
            validations: {
                required: false,
                min_length: 0,
                max_length: 300,
            },
            placeholder: '...',
            sectionIndex: props.formItem.sectionIndex,
        };

        const newConstants = [...Array.from(props.formItem.constant as any[]), formField];
        props.onFormFieldChanged('constant', newConstants);
    };

    const removeFormConstant = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (Array.isArray(props.formItem.constant)) {
            const constants = _.clone(props.formItem.constant);
            const index = Number(e.currentTarget.dataset.index);
            constants.splice(index, 1);
            props.onFormFieldChanged('constant', constants);
        }
    };

    const onChange = (e: React.ChangeEvent<any>) => {
        const value = e.currentTarget.value;
        const field = e.currentTarget.dataset.field;
        props.onFormFieldChanged(field, value);
    };

    const onAnswerTypeChanged = (index: string, e: React.ChangeEvent<HTMLSelectElement>) => {
        const field = _.cloneDeep(props.formItem.constant[index]) as TemplateContent;
        _.set(field, 'field_type', e.currentTarget.value);

        switch (field.field_type) {
            case 'DROPDOWN':
                _.set(field, 'constant', [
                    {
                        label: 'Untitled',
                        value: nanoid(),
                    },
                ]);
                break;
            default:
                _.set(field, 'constant', null);
        }

        props.onFormFieldChanged(`constant.${index}`, field);
    };

    const addNestedFormDropdownItem = (content: TemplateContent, index: string) => {
        const field = _.cloneDeep(content);
        const fieldConstants = [
            ...field.constant,
            {
                label: 'Untitled',
                value: nanoid(),
            },
        ];

        _.set(field, 'constant', fieldConstants);
        props.onFormFieldChanged(`constant.${index}`, field);
    };

    const removeNestedFormDropdownItem = (e: React.MouseEvent<HTMLButtonElement>, index: string) => {
        const field = props.formItem.constant[index];
        if (!field && !Array.isArray(field.constant)) return;

        const constants = _.cloneDeep(field.constant);
        constants.splice(e.currentTarget.dataset.index, 1);

        props.onFormFieldChanged(`constant.${index}.constant`, constants);
    };

    const onNestedFormDropdownItemChanged = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: string,
        innerConstantIndex: string,
    ) => {
        const value = e.currentTarget.value;
        const field = _.cloneDeep(props.formItem.constant[index].constant[innerConstantIndex]);

        if (!field) return;
        _.set(field, 'label', value);

        props.onFormFieldChanged(`constant.${index}.constant.${innerConstantIndex}`, field);
    };

    return (
        <>
            {_.map(props.formItem.constant, (constant: TemplateContent, index) => {
                return (
                    <div key={constant.field_id} className="w-full flex flex-col bg-blue-light my-1 relative">
                        {props.formItem.constant && (props.formItem.constant as any[]).length > 1 && (
                            <button
                                className="w-3 h-3 p-2 mt-1 mr-1 flex items-center justify-center text-blue font-bold absolute right-0"
                                onClick={removeFormConstant}
                                type="button"
                                data-index={index}
                                data-testid="form-builder-constant-remove"
                            >
                                &times;
                            </button>
                        )}
                        <div className="w-full my-3 flex flex-col">
                            <div className="w-full h-auto py-1 px-5">
                                <Select
                                    id={`type-${constant.field_id}`}
                                    onChange={(e) => {
                                        onAnswerTypeChanged(index, e);
                                    }}
                                    defaultValue={constant.field_type}
                                    data-field={`constant.${index}.field_type`}
                                    annotation="Field type"
                                >
                                    <option value="TEXT">Text field</option>
                                    <option value="TEXT_CURRENCY">Number field</option>
                                    <option value="DROPDOWN">Dropdown</option>
                                </Select>
                            </div>
                            <div className="w-full h-auto py-1 px-5">
                                <Input
                                    id={`label-${constant.field_id}`}
                                    label=""
                                    annotation="Field label"
                                    type="text"
                                    placeholder="Enter field label"
                                    value={constant.field_name}
                                    onChange={onChange}
                                    data-field={`constant.${index}.field_name`}
                                    defaultValue={constant.field_name}
                                />
                            </div>
                            <div className="w-full h-auto py-1 px-5">
                                <TextArea
                                    id="panel-field-description"
                                    label=""
                                    annotation="Field description (optional)"
                                    placeholder="This is your field description"
                                    defaultValue={constant.annotation}
                                    onChange={onChange}
                                    data-field={`constant.${index}.annotation`}
                                    rows={4}
                                />
                            </div>
                            <div className="w-full h-auto py-1 px-5">
                                <Input
                                    id={`placeholder-${constant.field_id}`}
                                    label=""
                                    annotation="Placeholder text (optional)"
                                    type="text"
                                    placeholder="Enter placeholder text"
                                    defaultValue={constant.field_name}
                                    onChange={onChange}
                                    data-field={`constant.${index}.placeholder`}
                                />
                            </div>
                        </div>
                        {constant.field_type === 'DROPDOWN' && (
                            <div className="w-full h-auto bg-blue-light">
                                <p className="px-5 font-regular text-sm">Dropdown options</p>
                                {_.map(constant.constant, (innerConstant, innerConstantIndex) => (
                                    <div
                                        key={innerConstant.value as string}
                                        className="w-full h-auto flex flex-row items-start pl-4"
                                        data-testid="form-builder-multi-option"
                                    >
                                        {/* The icon for deletion */}
                                        <div className="w-5 h-11 flex items-center justify-center">
                                            {constant.constant && (constant.constant as any[]).length > 1 && (
                                                <button
                                                    className="w-3 h-3 p-2  ml-4 flex items-center justify-center text-blue font-bold"
                                                    type="button"
                                                    data-index={innerConstantIndex}
                                                    data-testid="form-builder-constant-remove"
                                                    onClick={(e) => {
                                                        removeNestedFormDropdownItem(e, index);
                                                    }}
                                                >
                                                    &times;
                                                </button>
                                            )}
                                        </div>

                                        {/* The content of the constant */}
                                        <div className="w-full ml-4 pr-6">
                                            <div className="w-full h-auto flex flex-row">
                                                <div className="w-full h-auto block">
                                                    {/* Input field for constant's label */}
                                                    <Input
                                                        id="panel-field-min-char"
                                                        label=""
                                                        annotation=""
                                                        type="text"
                                                        defaultValue={innerConstant.label}
                                                        data-index={innerConstantIndex}
                                                        onChange={(e) => {
                                                            onNestedFormDropdownItemChanged(
                                                                e,
                                                                index,
                                                                innerConstantIndex,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="w-auto ml-4 mb-2 h-auto p-2 block self-start text-sm text-blue font-regular underline"
                                    data-testid="form-builder-constant-add"
                                    onClick={() => {
                                        addNestedFormDropdownItem(constant, index);
                                    }}
                                >
                                    + Add option
                                </button>
                            </div>
                        )}

                        <div className="w-full h-auto flex flex-row py-2 px-5 mb-3">
                            <div className="w-full h-auto block mr-4">
                                <Input
                                    id="panel-field-min-char"
                                    label=""
                                    annotation="Minimum characters"
                                    type="number"
                                    placeholder="0"
                                    min={0}
                                    defaultValue={Number(constant.validations.min_length)}
                                    onChange={onChange}
                                    data-field={`constant.${index}.validations.min_length`}
                                />
                                <p className="text-xs font-regular text-red">
                                    {props.errors?.find((error) => error.form_item.id === props.formItem.field_id) &&
                                        props.errors?.find((error) => error.form_item.id === props.formItem.field_id)
                                            ?.form_item.message}
                                </p>
                            </div>
                            <div className="w-full h-auto block">
                                <Input
                                    id="panel-field-max-char"
                                    label=""
                                    annotation="Maximum characters"
                                    type="number"
                                    placeholder="0"
                                    defaultValue={Number(constant.validations.max_length)}
                                    onChange={onChange}
                                    data-field={`constant.${index}.validations.max_length`}
                                />
                            </div>
                        </div>
                        <div className="w-full h-auto flex flex-row py-2 px-5 mb-3">
                            <div className="w-full h-auto block mr-4">
                                <Input
                                    id="panel-field-required"
                                    label="Mark field as optional"
                                    type="checkbox"
                                    defaultChecked={!constant.validations.required}
                                    onChange={onChange}
                                    data-field={`constant.${index}.validations.required`}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
            <button
                type="button"
                onClick={addNestedFormConstant}
                className="w-auto h-auto p-2 block self-start text-sm text-blue font-regular underline"
                data-testid="form-builder-constant-add"
            >
                + Add option
            </button>
        </>
    );
};

export default FormGridCellNestedAnswerView;
