import React from 'react';

interface IBadgeProps {
    text: string;
}

const Badge = (props: IBadgeProps) => {
    return (
        <div className="w-auto inline-block p-1 bg-gray-dark rounded-full">
            <p className="text-white text-xs">{props.text}</p>
        </div>
    );
};

export default Badge;
