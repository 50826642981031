import { navigate, useLocation, useMatch } from '@reach/router';
import { unwrapResult } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import 'firebase/remote-config';
import 'firebase/storage';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ic_alert from '../../../assets/img/ic_alert.svg';
import ic_check from '../../../assets/img/ic_check.svg';
import Button from '../../../layouts/buttons/Button';
import Modal from '../../../layouts/modals/Modal';
import Preloader from '../../../layouts/preloaders/Preloader';
import Text from '../../../layouts/typography/Text';
import Title from '../../../layouts/typography/Title';
import { ModalContent } from '../../../models/ModalContent';
import { TempFile } from '../../../models/TempFile';
import { setRemarks, updateMemoStatus } from '../../../redux/ApproverSlice';
import { RootState } from '../../../redux/RootReducer';
import { AppDispatch } from '../../../redux/Store';
import { uploadFiles } from '../../../utils/googleapis/StorageAPI';
import CTAButtons from './CTAButtons';
import RemarksView from './RemarksView';

interface IMemoViewActions extends React.ComponentPropsWithoutRef<'div'> {
    id: string;
}

const MemoViewActions = (props: IMemoViewActions) => {
    const [modalContent, setModalContent] = React.useState<ModalContent>({
        isVisible: false,
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    });

    const remoteConfig = firebase.remoteConfig();
    const [fileUploads, setFileUploads] = React.useState<{
        max_bytes: number;
        files: TempFile[];
    }>({
        max_bytes: 0,
        files: [],
    });
    const [max_remarks_attachment, setMaxAttachment] = React.useState(3);
    const [isRemarksFieldVisible, setRemarksFieldVisibile] = React.useState(true);

    React.useEffect(() => {
        const loadRemoteConfig = async () => {
            try {
                await remoteConfig.fetchAndActivate();
                const max_files = remoteConfig.getNumber('max_remarks_attachment');

                if (max_files) {
                    setMaxAttachment(max_files);
                }
            } catch (err) {
                console.error(err);
            }
        };
        loadRemoteConfig();
        return () => {
            dispatch(setRemarks(''));
            setRemarksFieldVisibile(true);
        };
    }, []);

    const dispatch: AppDispatch = useDispatch();
    const { state, memo, user, remarks } = useSelector((rootState: RootState) => ({
        state: rootState.MemoView.state,
        user: rootState.Auth.user,
        memo: rootState.MemoView.memo,
        remarks: rootState.Approver.remarks,
    }));
    const { pathname } = useLocation();

    const getPathType = () => {
        if (useMatch('/approvals/:memo_id')) {
            return 'approvals';
        } else if (useMatch('/memos/drafts/:memo_id')) {
            return 'draft';
        } else if (useMatch('/memos/active/:memo_id')) {
            return 'owned';
        }
        return '';
    };

    const onRemarksChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        dispatch(setRemarks(e.currentTarget.value));
    };

    const sendMemoAction = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            setRemarksFieldVisibile(false);
            setModalContent({ ...modalContent, loading: true });

            const attachments = await uploadFiles(fileUploads.files);

            if (user.uid && memo.memo_id) {
                const payload = {
                    user_id: user.uid,
                    memo_id: memo.memo_id,
                    remarks: remarks,
                    action: modalContent.extras,
                    sequence_no: memo.current_sequence,
                    attachments: attachments,
                };

                const _ = await dispatch(updateMemoStatus(payload));
                unwrapResult(_);

                switch (modalContent.extras) {
                    case 'approve_memo':
                        setModalContent({
                            isVisible: true,
                            loading: false,
                            icon: ic_check,
                            title: 'Memo approved',
                            subtitle: `<center>Memo request from ${memo.memo_owner_name} has been successfully approved. <br /> <br />
                            You may revisit previous memos in For My Approvals > <b>History</b> tab.</center>`,
                            buttons: [
                                {
                                    label: 'Back to my approvals',
                                    event: () => navigate('/approvals'),
                                },
                            ],
                        });
                        break;
                    case 'reject_memo':
                        setModalContent({
                            isVisible: true,
                            loading: false,
                            icon: ic_check,
                            title: 'Memo rejected',
                            subtitle: `<center>Memo request from ${memo.memo_owner_name} has been rejected. <br /> <br />
                            You may revisit previous memos in For My Approvals > <b>History</b> tab.</center>`,
                            buttons: [
                                {
                                    label: 'Back to my approvals',
                                    event: () => navigate('/approvals'),
                                },
                            ],
                        });
                        break;
                    case 'return_memo':
                        setModalContent({
                            isVisible: true,
                            loading: false,
                            icon: ic_check,
                            title: 'Memo returned',
                            subtitle: `<center>Memo request from ${memo.memo_owner_name} has been returned. <br /> <br />
                            You may revisit previous memos in For My Approvals > <b>History</b> tab.</center>`,
                            buttons: [
                                {
                                    label: 'Back to my approvals',
                                    event: () => navigate('/approvals'),
                                },
                            ],
                        });
                        break;
                    case 'self_return_memo':
                        setModalContent({
                            isVisible: true,
                            loading: false,
                            icon: ic_check,
                            title: 'Memo withdrawn',
                            subtitle: `<center>Your memo has been withdrawn.`,
                            buttons: [
                                {
                                    label: 'Back to my memos',
                                    event: () => navigate('/memos/active'),
                                },
                            ],
                        });
                        break;
                    case 'withdraw_memo':
                        setModalContent({
                            isVisible: true,
                            loading: false,
                            icon: ic_check,
                            title: 'Memo cancelled',
                            subtitle: `<center>Your memo has been cancelled. You may revisit previous memos in My Memos > <b>History</b> tab.</center>`,
                            buttons: [
                                {
                                    label: 'Back to my memos',
                                    event: () => navigate('/memos/active'),
                                },
                            ],
                        });
                        break;
                    case 'delete_draft':
                        setModalContent({
                            isVisible: true,
                            loading: false,
                            icon: ic_check,
                            title: 'Draft deleted',
                            subtitle: `<center>Your draft has been deleted.</center>`,
                            buttons: [
                                {
                                    label: 'Back to my drafts',
                                    event: () => navigate('/memos/drafts'),
                                },
                            ],
                        });
                        break;
                    default:
                        break;
                }
            }
        } catch (err: any) {
            console.log(err);
            alert(err.message ? err.message : err);
            setModalContent({ ...modalContent, isVisible: false });
        }
    };

    const onFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files) return;

        const files = [...fileUploads.files, ...((event.currentTarget.files as unknown) as TempFile[])].slice(
            0,
            max_remarks_attachment,
        );

        const bytes = files.reduce((acc, curr) => {
            acc = acc + curr.size;
            return acc;
        }, 0);

        setFileUploads({
            ...fileUploads,
            max_bytes: bytes,
            files: files,
        });
    };

    const onFilesRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
        const index = Number(event.currentTarget.value);
        const currentFiles = _.cloneDeep(fileUploads.files);
        currentFiles.splice(index, 1);
        setFileUploads({
            ...fileUploads,
            max_bytes: 0,
            files: currentFiles,
        });
    };

    const onActionClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const id = event.currentTarget.id;
        switch (id) {
            case 'btn_approve':
                setModalContent({
                    ...modalContent,
                    isVisible: true,
                    loading: false,
                    title: 'Approve memo',
                    subtitle: `Please provide remarks below.`,
                    extras: 'approve_memo',
                    buttons: [
                        {
                            label: 'Cancel',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                                dispatch(setRemarks(''));
                            },
                        },
                        {
                            label: 'Proceed',
                            event: () => {
                                return;
                            },
                        },
                    ],
                });
                return;
            case 'btn_reject':
                setModalContent({
                    isVisible: true,
                    loading: false,
                    title: 'Reject memo',
                    subtitle: `Please provide remarks below.`,
                    extras: 'reject_memo',
                    buttons: [
                        {
                            label: 'Cancel',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                                dispatch(setRemarks(''));
                            },
                        },
                        {
                            label: 'Proceed',
                            event: () => {
                                return;
                            },
                        },
                    ],
                });
                return;
            case 'btn_return':
                setModalContent({
                    isVisible: true,
                    loading: false,
                    title: 'Return memo',
                    subtitle: `Please provide remarks below.`,
                    extras: 'return_memo',
                    buttons: [
                        {
                            label: 'Cancel',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                                dispatch(setRemarks(''));
                            },
                        },
                        {
                            label: 'Proceed',
                            event: () => {
                                return;
                            },
                        },
                    ],
                });
                return;
            case 'btn_cancel':
                setModalContent({
                    ...modalContent,
                    isVisible: true,
                    loading: false,
                    title: 'Cancel this memo?',
                    subtitle: `Please provide remarks below.`,
                    extras: 'withdraw_memo',
                    buttons: [
                        {
                            label: 'Close',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                                dispatch(setRemarks(''));
                            },
                        },
                        {
                            label: 'Proceed',
                            event: () => {
                                return;
                            },
                        },
                    ],
                });
                return;
            case 'btn_withdraw':
                setRemarksFieldVisibile(false);
                setModalContent({
                    ...modalContent,
                    isVisible: true,
                    loading: false,
                    icon: ic_alert,
                    title: 'Withdraw this memo?',
                    subtitle: `Are you sure you want to withdraw this memo?`,
                    extras: 'self_return_memo',
                    buttons: [
                        {
                            label: 'Close',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                            },
                        },
                        {
                            label: 'Proceed',
                            event: () => {
                                return;
                            },
                        },
                    ],
                });
                return;
            case 'btn_del_draft':
                setRemarksFieldVisibile(false);
                setModalContent({
                    ...modalContent,
                    isVisible: true,
                    loading: false,
                    icon: ic_alert,
                    title: 'Your draft will be deleted',
                    subtitle: `Are you sure you want to delete this draft?`,
                    extras: 'delete_draft',
                    buttons: [
                        {
                            label: 'No',
                            event: () => {
                                setModalContent({ ...modalContent, isVisible: false });
                                dispatch(setRemarks(''));
                            },
                        },
                        {
                            label: 'Yes',
                            event: () => {
                                return;
                            },
                        },
                    ],
                });
                return;
            default:
                return;
        }
    };

    /**
     * <FileUploadModal visible={modalContent.visible}
     */

    return (
        <div className={`w-full md:w-2/3 mr-4 ${props.className}`} id={props.id} aria-label={props['aria-label']}>
            <div className="px-1 md:px-4">
                <CTAButtons
                    type={getPathType().toUpperCase()}
                    redirectUrl={state === 'fulfilled' ? pathname : ''}
                    onActionClicked={onActionClicked}
                />

                <Modal visible={modalContent.isVisible ? modalContent.isVisible : false}>
                    {modalContent.loading ? (
                        <div className="w-full flex flex-col items-center justify-center">
                            <Preloader type="dots" text="Loading..." />
                        </div>
                    ) : (
                        <form className="w-full" onSubmit={sendMemoAction} data-testid="action-modal">
                            <fieldset disabled={memo.memo_status === 'APPROVED'}>
                                <div
                                    className={(() => {
                                        if (modalContent.icon) {
                                            return `w-full flex flex-col items-center`;
                                        } else {
                                            return 'w-full flex flex-col items-start';
                                        }
                                    })()}
                                >
                                    {modalContent.icon && (
                                        <img src={modalContent.icon} style={{ width: '120px', height: '120px' }} />
                                    )}
                                    <Title type="h4">{modalContent.title}</Title>
                                    <div
                                        className={(() => {
                                            if (modalContent.icon) {
                                                return 'my-2';
                                            } else {
                                                return 'my-0';
                                            }
                                        })()}
                                    >
                                        <Text type="innerHTML">{modalContent.subtitle}</Text>
                                    </div>
                                    {isRemarksFieldVisible && (
                                        <RemarksView
                                            id="txt-reason_a3"
                                            label="Remarks (optional)"
                                            rows={5}
                                            placeholder="Type your remarks here."
                                            files={fileUploads.files}
                                            onChange={onRemarksChanged}
                                            onFilesChange={onFilesChange}
                                            onFilesRemove={onFilesRemove}
                                        />
                                    )}
                                    {(() => {
                                        if (modalContent.buttons.length > 1) {
                                            return (
                                                <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                                    <div className="w-full flex flex-col px-2">
                                                        <Button
                                                            type="button"
                                                            color="secondary"
                                                            weight="font-regular"
                                                            onClick={modalContent.buttons[0].event}
                                                        >
                                                            {modalContent.buttons[0].label}
                                                        </Button>
                                                    </div>
                                                    <div className="w-full flex flex-col px-2">
                                                        <Button
                                                            color="primary"
                                                            weight="font-regular"
                                                            type="submit"
                                                            disabled={
                                                                remarks === '' &&
                                                                ![
                                                                    'approve_memo',
                                                                    'delete_draft',
                                                                    'withdraw_memo',
                                                                    'self_return_memo',
                                                                ].includes(modalContent.extras)
                                                            }
                                                            onClick={modalContent.buttons[1].event}
                                                        >
                                                            {modalContent.buttons[1].label}
                                                        </Button>
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="my-2">
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        weight="font-regular"
                                                        onClick={modalContent.buttons[0].event}
                                                    >
                                                        {modalContent.buttons[0].label}
                                                    </Button>
                                                </div>
                                            );
                                        }
                                    })()}
                                </div>
                            </fieldset>
                        </form>
                    )}
                </Modal>
            </div>
        </div>
    );
};

export default MemoViewActions;
