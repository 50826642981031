import { ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Memo } from '../models/Memo';
import { Sequence } from '../models/Sequence';
import { Template } from '../models/Template';
import firebase from 'firebase/app';

export interface IMemoFormV2Slice {
    memo: Memo;
    template: Template;
    sequences: Sequence[];
    _mode: string;
    _template_load_state: string;
    _http_request_state: string;
    _http_request_message: string;
    _http_request_status_code: string | firebase.functions.FunctionsErrorCode | null;
}

export interface FieldChange {
    path: string | string[];
    data: any | any[];
}
const initialState: IMemoFormV2Slice = {
    memo: {
        memo_id: '',
        memo_subject: '',
        memo_owner_id: '',
        used_template_id: '',
        used_template_name: '',
        memo_status: '',
        date_created: '',
        date_submitted: '',
        date_last_modified: '',
        attachment_urls: [],
        memo_content: [],
        template_content: [],
        current_sequence: 0,
        approvers: [],
        watchers: [],
    },
    template: {
        template_id: '',
        template_name: '',
        creator_user_id: '',
        status: '',
        template_content: [],
        date_created: '',
        date_last_modified: '',
    },
    sequences: [],
    _mode: '',
    _template_load_state: 'IDLE',
    _http_request_state: 'IDLE',
    _http_request_message: '',
    _http_request_status_code: null,
};

interface FunctionsResponse {
    message: string;
    status_code: string | firebase.functions.FunctionsErrorCode;
    data: firebase.functions.HttpsCallableResult | null;
}

export const submitMemo = createAsyncThunk<
    FunctionsResponse,
    Memo & { _mode: string; action: string; user_id: string; remarks: string },
    { rejectValue: FunctionsResponse }
>(
    'memos/submit',
    async (
        payload: Memo & { _mode: string; action: string; user_id: string; remarks: string },
        { rejectWithValue },
    ) => {
        try {
            let endpoint = '';

            if (payload._mode === 'CREATE') {
                endpoint = 'create_memo';
            } else if (payload._mode === 'EDIT') {
                endpoint = 'edit_memo';
            }

            // send to backend
            const submitMemo = firebase.app().functions('asia-east2').httpsCallable(endpoint);
            const sequences = (payload.sequences as Sequence[]).map((_sequence) => {
                const sequence = _.omit(_sequence, 'approverSearchToken', 'watcherSearchToken'); // cleanup
                return {
                    ...sequence,
                    sequence_no: sequence.sequence_no,
                    approvers: sequence.approvers.map((approver) => approver.email),
                    watchers: sequence.watchers.map((watcher) => watcher.email),
                };
            });

            const memo = {
                ...payload,
                memo_subject: payload.memo_subject,
                memo_content: payload.memo_content,
                attachment_urls: payload.attachment_urls,
                template_id: payload.used_template_id,
                template_name: payload.used_template_name,
                action: payload.action,
                user_id: payload.user_id,
                sequences: sequences,
                remarks: payload.remarks,
            };

            const res = await submitMemo(memo);
            if (res.data.code !== 200) {
                return rejectWithValue({
                    message: res.data.message,
                    status_code: 'error',
                    data: null,
                });
            }

            const templatesUsed = localStorage.getItem('templates_used');
            let templates = '';
            if (!templatesUsed) {
                templates = JSON.stringify([payload.used_template_id]);
            } else if (templatesUsed && !JSON.parse(templatesUsed).includes(payload.used_template_id)) {
                const templatesList = JSON.parse(templatesUsed);
                templatesList.splice(0, 0, payload.used_template_id);
                templatesList.slice(0, 6);
                templates = JSON.stringify(templatesList);
            }

            localStorage.setItem('templates_used', templates);

            return {
                message: 'success',
                status_code: 'success',
                data: null,
            };
        } catch (err) {
            return rejectWithValue({
                message: (err as any).message || 'error',
                status_code: 'error',
                data: null,
            });
        }
    },
);

const MemoFormV2Slice = createSlice({
    name: 'MemoFormV2Slice',
    initialState,
    reducers: {
        setTemplateLoadState(state: IMemoFormV2Slice, action: PayloadAction<string>) {
            state._template_load_state = action.payload;
        },
        setTemplate(state: IMemoFormV2Slice, action: PayloadAction<Template>) {
            state.template = {
                ...state.template,
                ...action.payload,
            };
        },
        setMode(state: IMemoFormV2Slice, action: PayloadAction<string>) {
            state._mode = action.payload;
        },
        updateMemo(state, action: PayloadAction<FieldChange>) {
            let currentMemo = _.cloneDeep(state.memo);
            if (Array.isArray(action.payload.path)) {
                let obj: any = {};
                _.forEach(action.payload.path, (p, index) => {
                    obj = _.set(obj, p, action.payload.data[index]);
                });
                currentMemo = {
                    ...currentMemo,
                    ...obj,
                };
                state.memo = {
                    ...state.memo,
                    ...currentMemo,
                };
                return;
            } else if (action.payload.path === '*') {
                state.memo = {
                    ...currentMemo,
                    ...action.payload.data,
                };
                return;
            }

            _.set(currentMemo, action.payload.path, action.payload.data);

            state.memo = {
                ...state.memo,
                ...currentMemo,
            };
        },
        setSequences(state, action: PayloadAction<Sequence[]>) {
            state.sequences = action.payload;
        },
        clearForm(state) {
            state.template = initialState.template;
            state.memo = initialState.memo;
            state.sequences = initialState.sequences;
            state._mode = '';
            state._template_load_state = 'IDLE';
            state._http_request_state = 'IDLE';
            state._http_request_message = '';
            state._http_request_status_code = null;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<IMemoFormV2Slice>) => {
        builder.addCase(submitMemo.pending, (state) => {
            state._http_request_state = 'PENDING';
        });
        builder.addCase(submitMemo.rejected, (state, action) => {
            state._http_request_state = 'REJECTED';
            if (action.payload) {
                state._http_request_message = action.payload.message;
                state._http_request_status_code = action.payload?.status_code;
            }
        });
        builder.addCase(submitMemo.fulfilled, (state, action) => {
            state._http_request_state = 'FULFILLED';
            state._http_request_message = action.payload.message;
            state._http_request_status_code = action.payload.status_code;
        });
    },
});

export const {
    setTemplateLoadState,
    setTemplate,
    setMode,
    updateMemo,
    setSequences,
    clearForm,
} = MemoFormV2Slice.actions;
export default MemoFormV2Slice.reducer;
