import * as React from 'react';
import PropTypes from 'prop-types';
import Text from '../typography/Text';

interface ITabInactiveProps {
    label: string;
}

const TabInactive: React.FunctionComponent<ITabInactiveProps> = (props) => {
    return (
        <div className="px-4 py-2 cursor-pointer flex flex-row items-center">
            <Text>{props.label}</Text>
        </div>
    );
};

TabInactive.propTypes = {
    label: PropTypes.string.isRequired,
};

export default TabInactive;
