import React from 'react';
import DatePicker from 'react-datepicker';
import DatePickerInput from './DatePickerInput';
import 'react-datepicker/dist/react-datepicker.css';
import { useRef } from 'react';
import { getOffsetISOString, parseDate } from '../../utils/date/Date';
import './Datepicker.css';
import moment from 'moment';
import 'moment-timezone';
import { Control, Controller, DeepMap, FieldError, FieldValues } from 'react-hook-form';

interface IDatepickerProps extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'> {
    onChange?: (dates: [any, any]) => void;
    onSingleChange?: (dates: any) => void;
    control?: Control<FieldValues>;
    errors?: DeepMap<FieldValues, FieldError>;
    closed?: boolean;
    label: string;
    startLabel?: string;
    endLabel?: string;
    type?: string;
    id: string;
    defaultValue?: string;
    value?: string;
    register?: any;
    validations?: any;
    dataIndex?: number;
}

const Datepicker = (props: IDatepickerProps) => {
    const [startDate, setStartDate] = React.useState<Date | null>(null);
    const [endDate, setEndDate] = React.useState<Date | null>(null);
    const { errors, id, tabIndex } = props;

    const dp = useRef<DatePicker>();

    const getOffsetDate = (date: Date | null, dayOffset = 0) => {
        if (!date) {
            return null;
        }
        const dateCopy = new Date(date.getTime());
        if (dayOffset > 0) {
            return new Date(
                getOffsetISOString(new Date(dateCopy.setDate(dateCopy.getDate() + dayOffset)).toISOString()),
            );
        }
        return new Date(getOffsetISOString(dateCopy.toISOString()));
    };

    const handleDateChange = (dates: [any, any]) => {
        const [start, end] = dates;
        const offsetStartingDate = getOffsetDate(start);
        setStartDate(start);
        setEndDate(end);

        let offsetEndingDate;
        if (end === undefined || end === null) {
            offsetEndingDate = getOffsetDate(start, 1);
        } else {
            offsetEndingDate = getOffsetDate(end, 1);
        }

        if (props.onChange) {
            props.onChange([offsetStartingDate, offsetEndingDate]);
        }
    };

    const handleSingleDateChange = (date: any) => {
        setStartDate(date);
        if (props.onSingleChange) {
            const event = {
                currentTarget: {
                    id: props.id,
                    value: new Date(date).toISOString(),
                },
            };
            props.onSingleChange(event);
        }
    };

    const getInputErrorMessage = (error: DeepMap<FieldValues, FieldError>): React.ReactNode => {
        if (props.validations && props.validations.track_field) {
            return props.validations.track_field.message;
        }
        return error[id].message;
    };

    React.useEffect(() => {
        if (Boolean(endDate)) {
            dp.current?.setOpen(false);
        }
    }, [endDate, startDate]);

    React.useEffect(() => {
        if (errors && errors[id]) {
            document.getElementById(props.id)?.focus();
            dp.current?.setOpen(false);
        }
    }, [errors]);

    const getValidations = (validations: any) => {
        if (props.validations && props.validations.track_field) {
            return {
                ...validations,
                validate: (value: Date) => {
                    const element = document.getElementById(props.validations.track_field.field_id) as HTMLInputElement;
                    if (element && value) {
                        const trackedValue = new Date(element.value);
                        return new Date(value).toISOString() > trackedValue.toISOString();
                    }
                    return value;
                },
            };
        } else if (props.validations && props.validations.required) {
            return {
                required: {
                    value: true,
                    message: 'This field is required',
                },
            };
        }
        return validations;
    };

    switch (props.type) {
        case 'singleDate':
            return (
                <>
                    {props.control ? (
                        <Controller
                            id={props.id}
                            name={props.id}
                            control={props.control}
                            defaultValue={props.value ? new Date(props.value) : ''}
                            rules={getValidations(props.validations)}
                            render={({ onChange }) => {
                                return (
                                    <DatePicker
                                        id={id}
                                        ref={dp as any}
                                        onChange={(e) => {
                                            onChange(e);
                                            handleSingleDateChange(e);
                                        }}
                                        selected={props.value ? new Date(props.value) : null}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText={props.label}
                                        customInput={
                                            <DatePickerInput
                                                id={id}
                                                type="text"
                                                hasError={errors && errors[id] ? true : false}
                                                placeholderText={props.label}
                                                value={props.value}
                                                tabIndex={tabIndex}
                                            />
                                        }
                                    />
                                );
                            }}
                        />
                    ) : (
                        <DatePicker
                            id={id}
                            ref={dp as any}
                            onChange={(e) => {
                                handleSingleDateChange(e);
                            }}
                            placeholderText={props.label}
                            customInput={
                                <DatePickerInput
                                    id={props.id}
                                    name={props.id}
                                    type="text"
                                    value={props.value}
                                    hasError={errors && errors[id] ? true : false}
                                    tabIndex={tabIndex}
                                />
                            }
                        />
                    )}

                    {errors && errors[id] && (
                        <p className="text-xs font-regular text-red">{getInputErrorMessage(errors)}</p>
                    )}
                </>
            );
        case 'timeOnly':
            return (
                <>
                    {props.control ? (
                        <Controller
                            id={props.id}
                            name={props.id}
                            control={props.control}
                            defaultValue={props.defaultValue ? new Date(props.defaultValue) : ''}
                            rules={getValidations(props.validations)}
                            render={({ onChange }) => {
                                return (
                                    <DatePicker
                                        id={id}
                                        ref={dp as any}
                                        onChange={(e) => {
                                            onChange(e);
                                            handleSingleDateChange(e);
                                        }}
                                        placeholderText={props.label}
                                        selected={
                                            props.value ? moment(props.value).subtract(0, 'hours').toDate() : null
                                        }
                                        startDate={startDate ? startDate : null}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        customInput={
                                            <DatePickerInput
                                                id={props.id}
                                                name={props.id}
                                                type="text"
                                                value={props.value}
                                                placeholderText={props.label}
                                                hasError={errors && errors[id] ? true : false}
                                                tabIndex={tabIndex}
                                            />
                                        }
                                    />
                                );
                            }}
                        />
                    ) : (
                        <DatePicker
                            id={id}
                            ref={dp as any}
                            onChange={(e) => {
                                handleSingleDateChange(e);
                            }}
                            placeholderText={props.label}
                            startDate={startDate ? startDate : null}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            customInput={
                                <DatePickerInput
                                    id={props.id}
                                    name={props.id}
                                    type="text"
                                    value={props.value}
                                    placeholderText={props.label}
                                    hasError={errors && errors[id] ? true : false}
                                    tabIndex={tabIndex}
                                />
                            }
                        />
                    )}
                    {errors && errors[id] && (
                        <p className="text-xs font-regular text-red">{getInputErrorMessage(errors)}</p>
                    )}
                </>
            );
        default:
            return (
                <>
                    <DatePicker
                        ref={dp as any}
                        selected={null}
                        onChange={handleDateChange}
                        startDate={startDate ? startDate : null}
                        endDate={endDate ? endDate : null}
                        selectsRange
                        shouldCloseOnSelect={false}
                        customInput={
                            <DatePickerInput
                                id={props.id}
                                type="text"
                                placeholderText={props.label}
                                tabIndex={tabIndex}
                            />
                        }
                        id={props.id}
                        value={startDate ? `${parseDate(startDate)} ${endDate ? `to ${parseDate(endDate)}` : ''}` : ''}
                    />
                </>
            );
    }
};

Datepicker.displayName = 'Datepicker';

export default Datepicker;
