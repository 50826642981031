import * as React from 'react';
import ic_delete from '../../assets/img/ic_delete.svg';
import ic_delete_invalid from '../../assets/img/ic_delete.png';
import ic_file from '../../assets/img/ic_file.svg';
import ic_file_invalid from '../../assets/img/ic_file.png';
import ic_download from '../../assets/img/ic_download.svg';
import Text from '../typography/Text';
import { nanoid } from 'nanoid';

interface IFileProps {
    name: string;
    type: string;
    index?: number;
    children?: React.ReactNode;
    size?: number;
    register?: any;
    accepts?: string[];
    max_size?: number;
    value?: string;
    url?: string;
    // onIconClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onIconClick?: any;
    onClick?: any;
}

const filePanelStyles: React.CSSProperties = {
    background: '#EFF9FF',
    border: '0.5px solid #C8D7E2',
    borderRadius: '7px',
};

const filePanelErrorStyles: React.CSSProperties = {
    background: '#FFD8D8',
    border: '0.5px solid red',
    borderRadius: '7px',
};

const fileTextErrorStyles: React.CSSProperties = {
    color: '#D62F5C',
};

const File = (props: IFileProps) => {
    const [is_invalid, setInvalid] = React.useState(false);
    const [error_message, setErrorMessage] = React.useState('');
    const [file_type, setFileType] = React.useState('');
    const { name, size, max_size, accepts } = props;

    React.useEffect(() => {
        if (size && max_size) {
            if (Number(size) > Number(max_size)) {
                setInvalid(true);
                setErrorMessage('The file exceeds more than 3MB');
            }
        } else if (accepts) {
            const file_type = name.split('.').pop()?.toLowerCase();
            if (file_type) {
                if (!accepts.includes(file_type)) {
                    setFileType(file_type);
                    setInvalid(true);
                    setErrorMessage('This file is unsupported');
                }
            }
        } else {
            setInvalid(false);
        }
    });

    switch (props.type) {
        case 'upload':
            return (
                <div className="w-full md:w-auto h-auto flex md:inline-flex flex-col m-1">
                    <div
                        style={is_invalid ? filePanelErrorStyles : filePanelStyles}
                        className="w-full items-center flex flex-row justify-between py-2 px-4 items-center"
                    >
                        <img
                            className="mr-2"
                            src={is_invalid ? ic_file_invalid : ic_file}
                            style={{ maxWidth: '15px', maxHeight: '15px' }}
                        />
                        <Text>
                            <p className="break-all" style={is_invalid ? fileTextErrorStyles : {}}>
                                {props.name}
                            </p>
                        </Text>
                        <button
                            type="button"
                            style={{ outline: 'none' }}
                            className="ml-0 md:ml-8 p-2 flex items-center outline-none"
                            onClick={props.onIconClick}
                            value={props.index}
                            data-url={props.url}
                            data-testid="btn-file-delete"
                        >
                            <img
                                style={{ maxWidth: '10px', maxHeight: '10px', outline: 'none' }}
                                src={is_invalid ? ic_delete_invalid : ic_delete}
                            />
                        </button>
                    </div>
                    <input
                        name={props.name.trim()}
                        ref={
                            props.register
                                ? props.register({
                                      validate: {
                                          isValidFile: () => !is_invalid,
                                      },
                                  })
                                : null
                        }
                        type="hidden"
                        value={file_type}
                    />

                    {error_message ? (
                        <p style={fileTextErrorStyles} className="text-xs">
                            {error_message}
                        </p>
                    ) : (
                        ''
                    )}
                </div>
            );
        case 'download':
            return (
                <div
                    id="pota"
                    className="w-full md:w-auto h-auto flex md:inline-flex flex-col m-1 cursor-pointer relative"
                    onClick={() => props.onClick(props.url)}
                    role="button"
                >
                    {/* <div className="w-11/12 h-full absolute" data-url={props.url}></div> */}
                    <div
                        style={is_invalid ? filePanelErrorStyles : filePanelStyles}
                        className="w-full items-center flex flex-row justify-between py-2 px-4 items-center"
                    >
                        <img
                            className="mr-2"
                            src={is_invalid ? ic_file_invalid : ic_file}
                            style={{ maxWidth: '15px', maxHeight: '15px' }}
                        />
                        <Text>
                            <p style={is_invalid ? fileTextErrorStyles : {}}>{props.name}</p>
                        </Text>
                        <button
                            type="button"
                            style={{ outline: 'none' }}
                            className="ml-0 md:ml-8 p-2 flex items-center outline-none"
                            onClick={(e) => {
                                e.stopPropagation();
                                props.onIconClick(e);
                            }}
                            value={props.value}
                            data-url={props.url}
                            data-testid="btn-file-download"
                        >
                            <img style={{ maxWidth: '15px', maxHeight: '15px', outline: 'none' }} src={ic_download} />
                        </button>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export default File;
