import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReplyListProps } from '../../../layouts/reply-list/ReplyList';
import { clearReplies, getReplies } from '../../../redux/MemoLogsSlice';
import { RootState } from '../../../redux/RootReducer';
import { AppDispatch } from '../../../redux/Store';

const MemoLogReplies = <T extends IReplyListProps>(Component: React.ComponentType<T>) => {
    const WrappedComponent = (
        props: Omit<T, 'replies' | 'http_response_status' | 'is_empty' | 'onLoad'> & {
            level_no: number;
            index: number;
        },
    ) => {
        const { log_id, memo_id, index, level_no } = props;
        const { data } = useSelector((state: RootState) => state.MemoLogs);
        const [isResponseEmpty, setIsResponseEmpty] = React.useState(false);

        const dispatch: AppDispatch = useDispatch();

        const onLoad = async (reply_id: string | null) => {
            try {
                const res = await dispatch(
                    getReplies({
                        log_id: log_id,
                        memo_id: memo_id,
                        level_no: level_no,
                        index: index,
                        start_after: reply_id,
                    }),
                );
                const state = unwrapResult(res);
                setIsResponseEmpty(state.http_request_state === 'fulfilled' && state.data.length === 0);
            } catch (err) {
                console.error(`Error: ${err}`);
            }
        };

        React.useEffect(() => {
            onLoad(null);
            return () => {
                dispatch(clearReplies());
            };
        }, []);

        return (
            <Component
                {...((props as unknown) as T)}
                replies={data[level_no].logs[index].replies.data || []}
                http_response_status={data[level_no].logs[index].replies.http_request_state}
                is_empty={isResponseEmpty}
                onLoad={onLoad}
            />
        );
    };

    WrappedComponent.displayName = Component.displayName || 'MemoLogReplies';
    return WrappedComponent;
};

export default MemoLogReplies;
