import React from 'react';

interface ITableHeaderProps {
    children: React.ReactNode;
}

const TableHeader = (props: ITableHeaderProps) => {
    // TODO: Customize if necessary
    return <thead>{props.children}</thead>;
};

export default TableHeader;
