import 'firebase/auth';
import { nanoid } from 'nanoid';
import * as React from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import plus from '../../../assets/img/ic_plus.svg';
import Button from '../../../layouts/buttons/Button';
import { Sequence } from '../../../models/Sequence';
import { setSequences } from '../../../redux/MemoFormV2Slice';
import { RootState } from '../../../redux/RootReducer';
import { AppDispatch } from '../../../redux/Store';
import SequenceItem from './SequenceItem';

export interface ISequenceProps {
    sequences: Sequence[];
    register?: any;
    errors?: DeepMap<FieldValues, FieldError>;
    clearErrors: (name?: string | string[] | undefined) => void;
    onPersonSelected: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const SequenceTree = (props: ISequenceProps) => {
    const { sequences, register, errors, clearErrors, onPersonSelected } = props;
    const { memo } = useSelector((state: RootState) => state.MemoFormV2);
    const dispatch: AppDispatch = useDispatch();

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const new_audience_list = sequences.map((audience: Sequence) => {
            if (event.currentTarget.id === `approver-${audience.sequence_id}`) {
                clearErrors(`approver-${audience.sequence_id}`);
                return {
                    ...audience,
                    approverSearchToken: event.target.value,
                };
            }
            if (event.currentTarget.id === `watcher-${audience.sequence_id}`) {
                clearErrors(`watcher-${audience.sequence_id}`);
                return {
                    ...audience,
                    watcherSearchToken: event.target.value,
                };
            }

            return audience;
        });
        dispatch(setSequences(new_audience_list));
    };

    const removeEmail = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const email = event.currentTarget.value;
        const sequence_id = event.currentTarget.dataset.sequence;
        const field = event.currentTarget.dataset.field;

        const new_audience = sequences.map((audience: Sequence) => {
            if (sequence_id === audience.sequence_id) {
                if (field === 'approvers') {
                    return {
                        ...audience,
                        approvers: audience.approvers.filter((approver) => {
                            return approver.email !== email;
                        }),
                    };
                }
                if (field === 'watchers') {
                    return {
                        ...audience,
                        watchers: audience.watchers.filter((watcher) => {
                            return watcher.email !== email;
                        }),
                    };
                }
                return audience;
            }
            return audience;
        });
        dispatch(setSequences(new_audience));
    };

    /**
     * Adds audience fields to the sequence array.
     */
    const addAudience = () => {
        const sequence = {
            sequence_no: sequences.length,
            sequence_id: nanoid(),
            approvers: [],
            approverSearchToken: '',
            watchers: [],
            watcherSearchToken: '',
            approval_type: 'ALL_APPROVERS',
            is_cleared: false,
        };
        dispatch(setSequences([...sequences, sequence]));
    };

    /**
     * Removes selected audience to the sequence array.
     */
    const removeAudience = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const res = sequences.filter((sequence) => sequence.sequence_id !== event.currentTarget.id);
        const seq = res.map((item, index) => {
            return {
                ...item,
                sequence_no: index,
            };
        });
        dispatch(setSequences(seq));
    };

    const onApprovalTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const audienceList = sequences.map((audience: Sequence) => {
            if (event.currentTarget.id === `anyOrAll-${audience.sequence_id}`) {
                if (event.target.checked) {
                    return {
                        ...audience,
                        approval_type: 'MIN_APPROVERS',
                        min_approvers: 1,
                    };
                } else {
                    return {
                        ...audience,
                        approval_type: 'ALL_APPROVERS',
                    };
                }
            }
            return audience;
        });
        dispatch(setSequences(audienceList));
    };
    const onAllowSubApprovalChange = (event: any) => {
        const audienceList = sequences.map((audience: Sequence) => {
            if (event.currentTarget.id === `subApprovals-${audience.sequence_id}`) {
                if (event.target.checked) {
                    return {
                        ...audience,
                        allow_sub_approvals: true,
                        min_approvers: 1,
                    };
                } else {
                    return {
                        ...audience,
                        allow_sub_approvals: false,
                    };
                }
            }
            return audience;
        });
        dispatch(setSequences(audienceList));
    };

    return (
        <div className="w-full h-auto">
            {sequences.map((sequence) => {
                return (
                    <SequenceItem
                        key={sequence.sequence_id}
                        sequence_no={sequence.sequence_no}
                        sequence={sequence}
                        onAllowSubApprovalChange={onAllowSubApprovalChange}
                        onApprovalTypeChange={onApprovalTypeChange}
                        onSearchChange={onSearchChange}
                        onPersonSelected={onPersonSelected}
                        onRemove={removeEmail}
                        onRemoveAudience={removeAudience}
                        register={register}
                        errors={errors}
                        clearErrors={clearErrors}
                    />
                );
            })}
            <div className="w-full flex">
                {['APPROVED', 'REJECTED', 'CANCELLED'].includes(memo.memo_status) ? null : (
                    <div className="w-auto m-0 md:ml-auto">
                        <Button type="button" color="primary" icon={plus} weight="font-regular" onClick={addAudience}>
                            Add approver sequence
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SequenceTree;
