import React from 'react';

export const useDetectOnEndScroll = (initialValue: boolean) => {
    const [isEnd, setEnd] = React.useState(initialValue);
    const ref = React.useRef<any>(null);

    const handleEvent = (event: any) => {
        try {
            /**
             * See Stackoverflow code @
             * https://stackoverflow.com/questions/45585542/detecting-when-user-scrolls-to-bottom-of-div-with-react-js/49573628
             */
            if (ref.current) {
                // do calculation as per defined component height
                const target = event.target;
                const element = ref.current;

                const scrollValue = Math.ceil(element.scrollHeight - target.scrollTop) - 5;
                const clientHeight = target.clientHeight;
                setEnd(scrollValue <= clientHeight);
            } else {
                const scrollHeight = document.body.scrollHeight;
                const offset = Math.ceil(window.scrollY + window.innerHeight);
                setEnd(offset >= scrollHeight);
            }
        } catch (err) {}
    };

    React.useEffect(() => {
        document.addEventListener('scroll', handleEvent, true);
        return () => {
            document.removeEventListener('scroll', handleEvent, true);
        };
    });

    return { ref, isEnd, setEnd };
};
