import firebase from 'firebase/app';
import React from 'react';
import Modal from '../../../layouts/modals/Modal';
import Preloader from '../../../layouts/preloaders/Preloader';
import Title from '../../../layouts/typography/Title';
import { Approver } from '../../../models/Approver';
import { ReminderRequest } from '../../../models/ReminderRequest';
import ic_alert from '../../../assets/img/ic_alert.svg';
import ic_check from '../../../assets/img/ic_check.svg';
import Text from '../../../layouts/typography/Text';
import Button from '../../../layouts/buttons/Button';
import Input from '../../../layouts/form/Input';
import { getDateString } from '../../../utils/date/Date';

interface IRemindApproverPromptProps {
    memo_id: string;
    current_sequence: string;
    current_approver: Approver;
}

enum RemindApproverStatuses {
    IS_LOADING = 'IS_LOADING',
    IS_ERROR = 'IS_ERROR',
    SINGLE_APPROVER = 'SINGLE_APPROVER',
    MULTIPLE_APPROVER = 'MULTIPLE_APPROVER',
    ALREADY_NOTIFIED = 'ALREADY_NOTIFIED',
    REMINDER_SENT = 'REMINDER_SENT',
}

const RemindApproverPrompt = (props: IRemindApproverPromptProps) => {
    const [modalState, setModalState] = React.useState('');
    const [modalError, setModalError] = React.useState<firebase.functions.HttpsError | null>(null);
    const [approverDetails, setApproverDetails] = React.useState<ReminderRequest[]>([]);
    const [reminderRecipients, setReminderRecipients] = React.useState<Partial<ReminderRequest>[]>([]);

    const sendReminder = async () => {
        setModalState(RemindApproverStatuses.IS_LOADING);
        const sendReminders = firebase.app().functions('asia-east2').httpsCallable('remindCurrentApprover');
        try {
            await Promise.all(
                reminderRecipients.map(async (recipient) => {
                    await sendReminders(recipient);
                }),
            );
            setModalState(RemindApproverStatuses.REMINDER_SENT);
        } catch (err) {
            const error = err as firebase.functions.HttpsError;
            setModalError(error);
            setModalState(RemindApproverStatuses.IS_ERROR);
        } finally {
            setReminderRecipients([]);
        }
    };

    const getApproverDetails = async (memo_id: string, current_sequence: string) => {
        const getFn = firebase.app().functions('asia-east2').httpsCallable('getReminderRecipients');
        const httpResponse = await getFn({
            memo_id: memo_id,
            current_sequence: String(current_sequence),
        });

        return httpResponse.data.approvers as ReminderRequest[];
    };

    const dismiss = () => {
        setModalState('');
        setReminderRecipients([]);
        setModalError(null);
    };

    const onClick = async () => {
        setModalState(RemindApproverStatuses.IS_LOADING);
        try {
            const approverDetails = await getApproverDetails(props.memo_id, props.current_sequence);
            setApproverDetails(approverDetails);

            if (approverDetails.length === 1 && approverDetails[0].can_be_reminded) {
                setModalState(RemindApproverStatuses.SINGLE_APPROVER);
                setReminderRecipients(approverDetails);
            } else if (approverDetails.every((approver) => approver.can_be_reminded === false)) {
                setModalState(RemindApproverStatuses.ALREADY_NOTIFIED);
            } else {
                setModalState(RemindApproverStatuses.MULTIPLE_APPROVER);
            }
        } catch (err) {
            const error = err as firebase.functions.HttpsError;
            setModalError(error);
            setModalState(RemindApproverStatuses.IS_ERROR);
        }
    };

    const insertRecipient = (recipient_: Partial<ReminderRequest>) => {
        const isExists = reminderRecipients.find((recipient) => recipient.approver_id === recipient_.approver_id);
        if (isExists) return;

        setReminderRecipients([...reminderRecipients, recipient_]);
    };

    const removeRecipient = (recipient_: Partial<ReminderRequest>) => {
        const recipients = reminderRecipients.filter((recipient) => recipient.approver_id !== recipient_.approver_id);
        setReminderRecipients(recipients);
    };

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const recipient = {
            approver_id: event.currentTarget.value,
            approver_email: event.currentTarget.id,
        };

        if (event.currentTarget.checked) {
            insertRecipient(recipient);
        } else {
            removeRecipient(recipient);
        }
    };

    const getModalContent = (state: string) => {
        switch (state) {
            case RemindApproverStatuses.IS_LOADING:
                return (
                    <Modal visible>
                        <div className="w-full flex flex-col items-center justify-center" data-testid="modal-content">
                            <Preloader type="dots" text="Loading..." />
                        </div>
                    </Modal>
                );
            case RemindApproverStatuses.IS_ERROR:
                return (
                    <Modal visible>
                        <div className="w-full flex flex-col items-center" data-testid="modal-content">
                            <img src={ic_alert} style={{ width: '120px', height: '120px' }} />
                            <Title type="h4">{modalError?.message}</Title>
                            <div className="my-2">
                                <Text type="innerHTML">{modalError?.details}</Text>
                            </div>
                            <div className="my-2">
                                <Button type="button" color="primary" weight="font-regular" onClick={dismiss}>
                                    Okay, got it.
                                </Button>
                            </div>
                        </div>
                    </Modal>
                );
            case RemindApproverStatuses.ALREADY_NOTIFIED:
                return (
                    <Modal visible>
                        <div className="w-full flex flex-col items-center">
                            <img src={ic_alert} style={{ width: '120px', height: '120px' }} />
                            <Title type="h4">You have already notified the approvers recently</Title>
                            <div className="my-2">
                                <Text type="innerHTML">{`<center><center>We have sent email notifications for your approvers. You may remind them after the next 24 hours. `}</Text>
                            </div>
                            <div className="my-2">
                                <Button type="button" color="primary" weight="font-regular" onClick={dismiss}>
                                    Okay, got it.
                                </Button>
                            </div>
                        </div>
                    </Modal>
                );
            case RemindApproverStatuses.SINGLE_APPROVER:
                return (
                    <Modal visible>
                        <div className="w-full flex flex-col items-center" data-testid="modal-content">
                            <img src={ic_alert} style={{ width: '120px', height: '120px' }} />
                            <Title type="h4">Send reminder.</Title>
                            <div className="my-2">
                                <Text type="innerHTML">{`<center>A reminder email will be sent to the current approver. Are you sure you want to proceed?.</center>`}</Text>
                            </div>
                            <div className="w-full mt-4 mb-2 flex flex-col-reverse md:flex-row justify-center">
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        weight="font-regular"
                                        onClick={dismiss}
                                        data-testid="modal-button-secondary"
                                    >
                                        Cancel.
                                    </Button>
                                </div>
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        color="primary"
                                        weight="font-regular"
                                        type="submit"
                                        disabled={reminderRecipients.length === 0}
                                        data-testid="modal-button-primary"
                                        onClick={sendReminder}
                                    >
                                        Send a reminder.
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                );
            case RemindApproverStatuses.MULTIPLE_APPROVER:
                return (
                    <Modal visible>
                        <div className="w-full flex flex-col items-start" data-testid="modal-content">
                            <Title type="h4">Send reminder.</Title>
                            <div className="my-2">
                                <Text type="innerHTML">
                                    Select which of the current approvers who have not yet responded you would like to
                                    send a reminder email to:
                                </Text>
                            </div>
                            <div className="w-full mt-2">
                                <ul>
                                    {approverDetails.map((approver: ReminderRequest) => (
                                        <li key={approver.approver_id} data-testid="multi-approver-item">
                                            <Input
                                                type="checkbox"
                                                id={approver.approver_email}
                                                label={
                                                    approver.date_reminded
                                                        ? `${approver.name} - Reminded on ${getDateString(
                                                              approver.date_reminded,
                                                          )}`
                                                        : approver.approval_status === 'APPROVED'
                                                        ? `${approver.name} - Approved`
                                                        : approver.name
                                                }
                                                onChange={onCheckboxChange}
                                                disabled={(() => {
                                                    if (
                                                        approver.approval_status === 'APPROVED' ||
                                                        !approver.can_be_reminded
                                                    )
                                                        return true;
                                                    return false;
                                                })()}
                                                value={approver.approver_id}
                                                data-testid="multi-approver-item-option"
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="w-full mt-4 mb-2 flex flex-col-reverse md:flex-row justify-center">
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        weight="font-regular"
                                        onClick={dismiss}
                                        data-testid="modal-button-secondary"
                                    >
                                        Cancel.
                                    </Button>
                                </div>
                                <div className="w-full flex flex-col px-2">
                                    <Button
                                        color="primary"
                                        weight="font-regular"
                                        type="submit"
                                        disabled={reminderRecipients.length === 0}
                                        data-testid="modal-button-primary"
                                        onClick={sendReminder}
                                    >
                                        Send a reminder.
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                );
            case RemindApproverStatuses.REMINDER_SENT:
                return (
                    <Modal visible>
                        <div className="w-full flex flex-col items-center" data-testid="modal-content">
                            <img src={ic_check} style={{ width: '120px', height: '120px' }} />
                            <Title type="h4">Reminder sent.</Title>
                            <div className="my-2">
                                <Text type="innerHTML">A reminder has been sent to your approver(s). </Text>
                            </div>
                            <div className="my-2">
                                <Button type="button" color="primary" weight="font-regular" onClick={dismiss}>
                                    Okay, got it.
                                </Button>
                            </div>
                        </div>
                    </Modal>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <button
                className="p-1 text-sm text-blue underline m-1"
                onClick={onClick}
                data-testid="active-memo-item-remind"
            >
                Remind
            </button>
            {getModalContent(modalState)}
        </>
    );
};

export default RemindApproverPrompt;
