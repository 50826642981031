import * as React from 'react';
import PropTypes from 'prop-types';
import Text from '../typography/Text';
import { flavor } from '../../AppFlavor';
const { tabs } = flavor.theme;

interface ITabActiveProps {
    label: string;
}

const tabActiveStyle: React.CSSProperties = {
    borderBottom: `5px solid ${tabs.active.borderColor}`,
};

const TabActive: React.FunctionComponent<ITabActiveProps> = (props) => {
    return (
        <div className="px-4 py-2 cursor-pointer flex flex-row items-center" style={tabActiveStyle}>
            <Text type="bold">{props.label}</Text>
        </div>
    );
};

TabActive.propTypes = {
    label: PropTypes.string.isRequired,
};

export default TabActive;
