import * as React from 'react';
import { inputTextStyle } from './Input';
import Label from './Label';

interface ITextAreaProps extends React.ComponentPropsWithoutRef<'textarea'> {
    id: string;
    label: string;
    annotation?: string;
    children?: React.ReactNode;
}

const TextArea = (props: ITextAreaProps) => {
    return (
        <div className="w-full flex flex-col">
            <Label htmlFor={props.id}>{props.label}</Label>
            {props.annotation ? (
                <p className="text-xs font-regular w-full break-words text-gray-2 mb-1 whitespace-pre-wrap">
                    {props.annotation}
                </p>
            ) : (
                ''
            )}
            <textarea style={inputTextStyle} className="py-2 px-2 my-1 font-regular text-sm w-full" {...props} />
        </div>
    );
};

export default TextArea;
