import * as React from 'react';
import PropTypes from 'prop-types';

interface ITableProps extends React.ComponentPropsWithoutRef<'table'> {
    children: React.ReactNode;
}

export const TableImgStyle: React.CSSProperties = {
    height: '170px',
};

const Table: React.FunctionComponent<ITableProps> = (props) => {
    return <table className="w-full block lg:table overflow-x-auto">{props.children}</table>;
};

Table.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Table;
