import React, { useEffect, useState } from 'react';
import arrow from '../../assets/img/chevron-down.svg';

export interface IMemoLogHeaderProps {
    level_no: number;
    status: string;
    is_current: boolean;
    is_expanded: boolean;
    days_elapsed: number;
    approvalType: string;
    approvalCount: number;
    totalApprovers: number;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const MemoLogHeader = (props: IMemoLogHeaderProps) => {
    const [approvalType, setApprovalType] = useState('all_approvers');

    useEffect(() => {
        if (props.approvalType != null) {
            setApprovalType(props.approvalType.toLocaleLowerCase());
        }
    }, []);

    return (
        <div
            className="w-full h-auto flex flex-row md:flex-col lg:flex-row items-center relative justify-between text-sm p-1 cursor-pointer"
            style={{
                transform: 'translateY(18px)',
                zIndex: 1,
            }}
            onClick={props.onClick}
            role="toggle"
        >
            <div className="flex flex-row">
                <h4
                    className={`${
                        props.status === 'pending' && !props.is_current ? 'font-regular' : 'font-bold'
                    } relative flex bg-blue-light p-1`}
                >
                    <span className="ml-2 bg-blue-light truncate">Level {props.level_no}</span>
                </h4>
                <p className="font-regular flex items-center text-gray-1 bg-blue-light p-1 text-xs">
                    <span className="flex flex-row bg-blue-light truncate">
                        {props.approvalCount + '/' + props.totalApprovers + ' '}
                        {approvalType === 'min_approvers'
                            ? 'Only one required'
                            : approvalType === 'all_approvers'
                            ? 'All required'
                            : null}
                    </span>
                </p>
            </div>
            {props.is_current && ['pending', 'returned'].includes(props.status) && (
                <span className="font-regular p-1 bg-gray-light-accent rounded mr-4">
                    <h5 className="truncate">Days elapsed: {props.days_elapsed}</h5>
                </span>
            )}
            <button className="p-2 bg-blue-light absolute right-0" style={{ transform: 'translateX(12px)' }}>
                <img
                    className={`w-3 h-3 ${props.status === 'pending' && !props.is_current && 'opacity-50'} transform ${
                        props.is_expanded && 'rotate-180'
                    }`}
                    src={arrow}
                    alt="Down"
                />
            </button>
        </div>
    );
};

export default MemoLogHeader;
