import * as React from 'react';
import PropTypes from 'prop-types';

interface ILabelProps extends React.ComponentPropsWithoutRef<'label'> {
    htmlFor: string;
    color?: string;
    weight?: string;
    children?: React.ReactNode;
}

const Label: React.FunctionComponent<ILabelProps> = (props) => {
    const { color, weight } = props;

    switch (color) {
        case 'secondary':
            return (
                <label htmlFor={props.htmlFor} className={`text-sm text-gray-1 ${weight ? weight : 'font-bold'}`}>
                    {props.children}
                </label>
            );
        default:
            return (
                <label htmlFor={props.htmlFor} className={`text-sm ${weight ? weight : 'font-bold'}`}>
                    {props.children}
                </label>
            );
    }
};

Label.propTypes = {
    htmlFor: PropTypes.string.isRequired,
    color: PropTypes.string,
    weight: PropTypes.string,
    children: PropTypes.node,
};

export default Label;
