import firebase from 'firebase/app';
import _ from 'lodash';
import React from 'react';
import Card from '../../../layouts/cards/Card';
import Container from '../../../layouts/container/Container';
import MemoSelectionCategory from '../../../layouts/memo-selection/MemoSelectionCategory';
import MemoSelectionTemplates from '../../../layouts/memo-selection/MemoSelectionTemplates';
import Preloader from '../../../layouts/preloaders/Preloader';
import Subtitle from '../../../layouts/typography/Subtitle';
import Text from '../../../layouts/typography/Text';
import Title from '../../../layouts/typography/Title';
import { Template } from '../../../models/Template';

/*
TODO:
- Category lego component and function
  - click a category
  - display templates based on category
*/

const MemoSelection = () => {
    const [selectionState, setSelectionState] = React.useState<{
        state: string;
        templates: Template[];
        categories: string[];
        templates_used: string[];
    }>({
        state: 'pending',
        templates: [],
        categories: [],
        templates_used: [],
    });

    const [selectedCategory, setSelectedCategory] = React.useState('All');

    React.useEffect(() => {
        if (localStorage.getItem('templates_used')) {
            setSelectedCategory('Recently used templates');
        }
        getTemplates();
    }, []);

    const getTemplates = async () => {
        try {
            setSelectionState({ state: 'pending', templates: [], categories: [], templates_used: [] });

            const getMemoTemplates = firebase.app().functions('asia-east2').httpsCallable('get_templates');
            const http_response = await getMemoTemplates({ field_path: 'status', field_value: 'PUBLISHED' });
            const templates = http_response.data.map((template: Template) => {
                return {
                    ...template,
                    type: 'category',
                };
            });

            const categories = _.uniq(
                _.map(templates, (template) => {
                    if (!template.category_name) {
                        return 'Uncategorized';
                    }

                    return template.category_name;
                }),
            )
                .concat('All')
                .sort();

            const templatesUsed = localStorage.getItem('templates_used');
            if (templatesUsed) {
                categories.splice(0, 0, 'Recently used templates');
            }

            // Initialize a default empty list
            let templatesUsedList = [];
            try {
                // Attempt to parse the JSON array of categories
                templatesUsedList = JSON.parse(templatesUsed as string);
            } catch (err) {
                // If it causes an error, log for now. Else, it will use an empty array.
                console.error('Unable to load templates', err);
            }

            // Set the fulfilled state
            setSelectionState({
                state: 'fulfilled',
                templates: templates,
                categories: categories,
                templates_used: templatesUsedList,
            });
        } catch (err) {
            console.log(err);
            setSelectionState({ state: 'rejected', templates: [], categories: [], templates_used: [] });
            // throw err;
        }
    };

    const onSelect = (filter: string) => {
        setSelectedCategory(filter);
    };

    return (
        <Container>
            <div className="w-full">
                <Title>Create new memo</Title>
                <div className="my-2">
                    <Subtitle>Please select a new memo form</Subtitle>
                </div>
            </div>
            <div className="w-full flex flex-row items-start mt-8">
                <MemoSelectionCategory
                    http_state={selectionState.state}
                    categories={selectionState.categories}
                    selectedCategory={selectedCategory}
                    onSelect={onSelect}
                />
                <MemoSelectionTemplates
                    http_state={selectionState.state}
                    templates={selectionState.templates.filter((template) => {
                        if (selectedCategory && selectedCategory !== 'All') {
                            if (selectedCategory === 'Uncategorized') {
                                return !template.category_name;
                            }

                            if (selectedCategory === 'Recently used templates') {
                                return selectionState.templates_used.includes(template.template_id);
                            }

                            return template.category_name === selectedCategory;
                        }

                        return true;
                    })}
                />
            </div>
        </Container>
    );
};

export default MemoSelection;
