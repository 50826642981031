import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MemoFeedItems } from '../models/MemoFeedItems';

export interface Feed {
    items: MemoFeedItems[];
    state: string;
}

export interface MemoFeedState {
    active: Feed;
    approvals: Feed;
    watch: Feed;
}

const feedInitialState: MemoFeedState = {
    active: {
        items: [],
        state: 'loading',
    },
    approvals: {
        items: [],
        state: 'loading',
    },
    watch: {
        items: [],
        state: 'loading',
    },
};

const MemoFeedSlice = createSlice({
    name: 'MemoFeedSlice',
    initialState: feedInitialState,
    reducers: {
        setActiveItems(state, action: PayloadAction<MemoFeedItems[]>) {
            state.active.items = action.payload;
        },
        setActiveState(state, action: PayloadAction<string>) {
            state.active.state = action.payload;
        },
        setApprovalItems(state, action: PayloadAction<MemoFeedItems[]>) {
            state.approvals.items = action.payload;
        },
        setApprovalState(state, action: PayloadAction<string>) {
            state.approvals.state = action.payload;
        },
        setWatchItems(state, action: PayloadAction<MemoFeedItems[]>) {
            state.watch.items = action.payload;
        },
        setWatchState(state, action: PayloadAction<string>) {
            state.watch.state = action.payload;
        },
    },
});

export const {
    setActiveItems,
    setActiveState,
    setApprovalItems,
    setApprovalState,
    setWatchItems,
    setWatchState,
} = MemoFeedSlice.actions;
export default MemoFeedSlice.reducer;
