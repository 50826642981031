import { navigate, RouteComponentProps, useLocation, useMatch } from '@reach/router';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import menu from '../../../assets/img/menu.svg';
import menu_close from '../../../assets/img/menu_close.svg';
import AppHeader from '../../../layouts/app-header/AppHeader';
import Button from '../../../layouts/buttons/Button';
import Input from '../../../layouts/form/Input';
import MemoSearch from '../memo-search/MemoSearch';
import Branding from '../../../layouts/navbar/branding/Branding';
import Nav from '../../../layouts/navbar/Navigation';
import { NavItemLayoutProps } from '../../../layouts/navbar/NavItemLayout';
import Profile from '../../../layouts/navbar/profile/Profile';
import Preloader from '../../../layouts/preloaders/Preloader';
import { User } from '../../../models/User';
import { setIsReady } from '../../../redux/AppSlice';
import { setUser } from '../../../redux/AuthSlice';
import { setHasUnread } from '../../../redux/NotificationSlice';
import { RootState } from '../../../redux/RootReducer';
import NotificationsView from '../notifications/NotificationsView';

interface IProtectedRouteProps {
    path?: RouteComponentProps;
    default?: boolean;
    title?: string;
    memo_id?: string;
    mode?: string;
    component: React.ComponentType;
}

const ProtectedRoute: React.FunctionComponent<IProtectedRouteProps> = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.Auth);
    const { isReady } = useSelector((state: RootState) => state.App);
    const [toggled, setToggle] = React.useState(false);
    const [remoteConfigAdmins, setRemoteConfigAdmins] = React.useState(['']);
    const location = useLocation();
    const isOnNotificationsPage = useMatch('/notifications');
    const remoteConfig = firebase.remoteConfig();

    let timeout: any = null;

    const updateUser = (user: firebase.User | null) => {
        if (user) {
            const payload: User = {
                displayName: user.displayName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                providerId: user.providerId,
                uid: user.uid,
            };
            dispatch(setUser(payload));
        }
    };

    useEffect(() => {
        let page_title = 'Memo App (Beta)';

        if (rest.title) {
            if (rest.memo_id) {
                page_title = `${rest.memo_id} | ${rest.title}`;
            } else {
                page_title = rest.title;
            }

            firebase.analytics().setCurrentScreen(location.pathname);
            firebase.analytics().logEvent('page_view', { page_path: location.pathname, page_title: page_title });
        }

        document.title = page_title;

        firebase.auth().onAuthStateChanged((firebaseUser) => {
            if (!firebaseUser) {
                navigate('/login');
            } else {
                updateUser(firebaseUser);
                dispatch(setIsReady(true));
            }
        });

        const dateLastViewed = localStorage.getItem('dlv');
        const unsubscribe = firebase
            .firestore()
            .collection('notifications')
            .where('email', '==', user.email)
            .where('date_created', '>=', dateLastViewed ? dateLastViewed : new Date().toISOString())
            .where('isRead', '==', false)
            .limit(25)
            .onSnapshot((data) => {
                if (data.size > 0) {
                    /**
                     * Update title
                     * document.title = `${data.size} document.title`
                     */
                    dispatch(setHasUnread(true));
                }
            });

        const loadConfig = async () => {
            await remoteConfig.fetchAndActivate();

            const admins = await remoteConfig.getString('admin_emails');
            setRemoteConfigAdmins(JSON.parse(admins).values);
        };

        loadConfig();

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            unsubscribe();
        };
    }, []);

    const logout = async () => {
        try {
            // Sign out to google via firebase
            await firebase.auth().signOut();
        } catch (err) {
            console.error(err);
        }
    };

    const nav_links: NavItemLayoutProps[] = [
        {
            label: 'Dashboard',
            link: '/dashboard',
        },
        {
            label: 'My memos',
            link: '/memos',
        },
        {
            label: 'For my approval',
            link: '/approvals',
        },
        {
            label: 'Memos to watch',
            link: '/watch',
        },
    ];

    const goToCreateMemoForm = () => {
        setToggle(!toggled);
        timeout = setTimeout(() => {
            navigate('/memo/create');
        }, 300);
    };

    const toggleMenu = () => {
        setToggle(!toggled);
    };
    if (isReady) {
        return (
            <div className="w-full h-auto">
                <div style={{ zIndex: 99999 }} className="w-full h-auto fixed">
                    <AppHeader>
                        <button className="visible md:hidden p-2" onClick={toggleMenu}>
                            <img width="23px" src={toggled ? menu_close : menu} alt="menu" />
                        </button>
                        <Branding />
                        <div className="w-full hidden md:w-1/3 flex flex-col md:inline-block relative">
                            <MemoSearch id="global-search" field="global-search" />
                        </div>
                        <div className="w-full md:w-auto flex flex-row items-center">
                            <Profile user={user} logout={logout} />
                            <NotificationsView disabled={isOnNotificationsPage ? true : false} />
                        </div>
                    </AppHeader>
                    <div
                        className={`w-full absolute transition-left delay-100 duration-300 ease-in-out ${
                            toggled ? 'left-0' : '-left-full md:left-0'
                        }`}
                    >
                        <Nav links={nav_links} user={user} logout={logout}>
                            <div
                                className="w-full hidden md:flex items-center  bg-blue-light justify-center cursor-pointer rounded-md h-auto"
                                onClick={goToCreateMemoForm}
                            >
                                <Button color="navbar" data-testid="create">
                                    <p className="w-full flex text-center text-sm">Create memo</p>
                                </Button>
                            </div>
                            <div
                                className="w-full flex md:hidden items-center cursor-pointer rounded-md h-auto"
                                onClick={goToCreateMemoForm}
                            >
                                <button className="text-white text-sm px-4 py-2">Create a memo</button>
                            </div>
                        </Nav>
                    </div>
                    <hr className="h-lg bg-blue-dark visible md:hidden border-none" />
                </div>
                <Component {...rest} />
            </div>
        );
    }
    return <Preloader />;
};

ProtectedRoute.propTypes = {
    component: PropTypes.any,
};

export default ProtectedRoute;
