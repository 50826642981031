import { navigate, useLocation } from '@reach/router';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import * as React from 'react';
import Input from '../../../layouts/form/Input';
import Preloader from '../../../layouts/preloaders/Preloader';
import { MemoSearchResult, SearchText } from '../../../models/MemoSearchResult';
import { sanitizeHtmlString } from '../../../utils/anti-xss/Sanitize';
import { useDebounce } from '../../../utils/hooks/useDebounce';
import { useDetectOutsideClick } from '../../../utils/hooks/useDetectOutsideClick';
import './MemoSearch.css';

interface IMemoSearchProps {
    id?: string;
    field?: string;
}

const MemoSearch: React.FunctionComponent<IMemoSearchProps> = (props) => {
    const [searchResult, setSearchResult] = React.useState<MemoSearchResult[]>([]);
    const [requestState, setRequestState] = React.useState('idle');
    const [isListVisible, setListVisible] = React.useState(false);
    const [token, setToken] = React.useState('');
    const searchToken = useDebounce(token, 1000);

    const location = useLocation();
    const isOnResultsPage = location.pathname.includes('/search');
    const detectOutsideHook = useDetectOutsideClick(false);

    /**
     * Runs a search for memos
     * @param token The token to search for
     * @param page (Optional) The page of the current search. The default is `1`
     * @param size (Optional) The number of results to return. The default is `20`
     */
    const searchESapi = async (token: string, page: number, size = 5) => {
        try {
            const searchFn = firebase.app().functions('asia-east2').httpsCallable('search_memo');
            const response = await searchFn({
                query: token,
                page: page,
                size: size,
            });
            const memos = response.data.results;
            return memos;
        } catch (err) {
            console.log(err);
            throw err;
        }
    };

    React.useEffect(() => {
        if (!detectOutsideHook.isComponentVisible) {
            setListVisible(false);
        }
    }, [detectOutsideHook]);

    React.useEffect(() => {
        let isMounted = true;
        setSearchResult([]);

        /**
         * Search for a particular user under the domain.
         * @param token - Search token for user account.
         */
        const searchMemo = async (token: string) => {
            setSearchResult([]);
            setRequestState('pending');
            setListVisible(true);

            try {
                const res = await searchESapi(token, 1, 5);
                if (isMounted && res !== undefined) {
                    setRequestState('fulfilled');
                    setSearchResult(res);
                }
            } catch (err) {
                setRequestState('rejected');
                console.error(err);
            }
        };

        if (searchToken.length >= 3) {
            searchMemo(searchToken);
        }

        if (searchToken.length < 3) {
            setRequestState('idle');
            setSearchResult([]);
            setListVisible(false);
        }

        return () => {
            isMounted = false;
        };
    }, [searchToken]);

    // Press ENTER key for more search results, redirects to full page search
    const onEnterPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && searchToken.length >= 3) {
            console.log(e.key, searchToken);
            setSearchResult([]);
            setListVisible(false);
            navigate(`/search/${searchToken}`);
        }
    };

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.currentTarget.value) {
            setListVisible(false);
        }
        setToken(e.currentTarget.value);
    };

    const parseSearchString = (str: SearchText): string => {
        if (!str) return '';

        if (str.snippet) {
            return str.snippet;
        } else if (str.raw) {
            return str.raw;
        } else {
            return '';
        }
    };

    const onMemoSelected = (memo: MemoSearchResult) => {
        const id = memo.memo_id.raw;
        const audienceType = memo.audience_type;

        let parent;
        if (audienceType === 'owner') {
            parent = '/memos/active';
        } else if (audienceType === 'approver') {
            parent = '/approvals';
        } else if (audienceType === 'watcher') {
            parent = '/watch';
        } else {
            parent = '/memos/active';
        }

        setListVisible(false);
        navigate(parent + '/' + id);
    };
    return (
        <div ref={detectOutsideHook.ref} className="w-full relative">
            <Input
                id="search"
                type="search"
                placeholder="Search here"
                onChange={onSearchChange}
                onKeyDown={onEnterPressed}
                data-testid="search-field"
            />

            {isListVisible && (
                <div
                    className="flex flex-col w-full absolute bg-white"
                    style={{
                        maxHeight: '400px',
                        boxShadow: '0px 3px 6px #00000029',
                    }}
                >
                    <ul className="custom-scrollbar relative h-auto overflow-y-auto">
                        {searchResult.map((memo, index) => {
                            const {
                                memo_subject,
                                memo_id,
                                used_template_name,
                                memo_owner_name,
                                memo_rte_content,
                            } = memo;

                            return (
                                <div
                                    key={index}
                                    className="w-full bg-white px-2 py-4 cursor-pointer flex flex-row items-center border-b border-gray-light focus:ring-2"
                                    data-sequence={props.id}
                                    data-field={props.field}
                                    onClick={() => onMemoSelected(memo)}
                                    role="option"
                                    data-testid="search-result"
                                >
                                    <div className="w-full flex flex-col pl-2 pr-8 justify-center">
                                        <p
                                            className="text-sm font-bold overflow-ellipsis whitespace-nowrap overflow-hidden"
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeHtmlString(parseSearchString(memo_subject)),
                                            }}
                                        ></p>
                                        <span className="flex flex-row">
                                            <p
                                                className="text-xs font-regular overflow-ellipsis whitespace-nowrap overflow-hidden"
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtmlString(parseSearchString(memo_id)),
                                                }}
                                            ></p>
                                            <div className="w-0.5 bg-gray-dark opacity-25 mx-1"></div>
                                            <p
                                                className="text-xs font-regular overflow-ellipsis whitespace-nowrap overflow-hidden text-blue"
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtmlString(
                                                        parseSearchString(used_template_name as SearchText),
                                                    ),
                                                }}
                                            ></p>
                                        </span>
                                        <p
                                            className="text-xs font-regular overflow-ellipsis whitespace-nowrap overflow-hidden"
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeHtmlString(
                                                    parseSearchString(memo_owner_name as SearchText),
                                                ),
                                            }}
                                        ></p>
                                        <p
                                            className="text-xs font-regular overflow-ellipsis whitespace-nowrap overflow-hidden"
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeHtmlString(
                                                    String(
                                                        memo_rte_content?.snippet
                                                            ? memo_rte_content?.snippet?.substring(0, 100)
                                                            : memo_rte_content?.raw.substring(0, 100),
                                                    ),
                                                ),
                                            }}
                                        ></p>
                                    </div>
                                </div>
                            );
                        })}
                    </ul>
                    {searchResult.length > 0 && (
                        <div className="w-full mt-auto bg-white p-2 cursor-pointer flex flex-row items-center focus:ring-2">
                            <div className="w-full flex flex-col pl-2 pr-8 justify-center">
                                <p className="text-xs font-regular overflow-ellipsis whitespace-nowrap overflow-hidden text-gray-1">
                                    Press <b>ENTER</b> key for more search results
                                </p>
                            </div>
                        </div>
                    )}
                    {searchResult.length === 0 && requestState === 'fulfilled' && !isOnResultsPage && (
                        <div className="w-full mt-auto bg-white p-2 cursor-pointer flex flex-row items-center focus:ring-2">
                            <div className="w-full flex flex-col pl-2 pr-8 justify-center">
                                <p className="text-xs font-regular overflow-ellipsis whitespace-nowrap overflow-hidden text-gray-1">
                                    No matching results
                                </p>
                            </div>
                        </div>
                    )}
                    {requestState === 'pending' && (
                        <div className="w-full bg-white p-4 cursor-pointer flex flex-row items-center">
                            <Preloader type="dots" />
                        </div>
                    )}
                    {requestState === 'rejected' && (
                        <div className="w-full bg-white p-2 cursor-pointer flex flex-row items-center focus:ring-2">
                            <div className="w-full flex flex-col pl-2 pr-8 justify-center">
                                <p className="text-xs font-regular overflow-ellipsis whitespace-nowrap overflow-hidden text-gray-1">
                                    There was an error. Please try again later.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

MemoSearch.propTypes = {
    id: PropTypes.string,
    field: PropTypes.string,
};

export default MemoSearch;
