import PropTypes from 'prop-types';
import * as React from 'react';
import Datatable from '../datatable/Datatable';
import ActiveMemosTableBody from './ActiveMemosTableBody';
import ActiveMemosTableEmptyState from './ActiveMemosTableEmptyState';
import ActiveMemosTableHeader from './ActiveMemosTableHeader';

interface IActiveMemosProps {
    memo_type?: string;
    headers?: any;
    children?: React.ReactNode;
    start_date?: any;
    end_date?: any;
    onUserAction: any;
}

const ActiveMemos: React.FunctionComponent<IActiveMemosProps> = (props: IActiveMemosProps) => {
    const [config, setConfig] = React.useState({
        endpoint: 'getMyMemosActiveWithUserID',
        templatePath: 'mymemos.active',
        queryParams: {
            limit: 15,
            page_no: 1,
            start_date: null,
            end_date: null,
            memo_type: '',
        },
    });

    const defaultHeaders = [
        {
            label: 'Memo ID',
            key: 'memo_id',
        },
        {
            label: 'Memo type',
            key: 'used_template_name',
        },
        {
            label: 'Subject',
            key: 'memo_subject',
        },
        {
            label: 'Date created',
            key: 'date_submitted',
        },
        {
            label: 'Current approver/s',
            key: 'current_approver',
        },
        {
            label: 'Status',
            key: 'memo_status',
        },
        {
            label: 'Action',
            key: 'action',
        },
    ];

    const { memo_type, start_date, end_date } = props;

    React.useEffect(() => {
        setConfig({
            ...config,
            queryParams: {
                ...config.queryParams,
                memo_type: memo_type as string,
                start_date: start_date,
                end_date: end_date,
            },
        });
    }, [memo_type, start_date, end_date]);

    return (
        <div>
            <Datatable
                config={config}
                defaultHeaders={defaultHeaders}
                headerComponent={<ActiveMemosTableHeader />}
                bodyComponent={<ActiveMemosTableBody onUserAction={props.onUserAction} />}
                emptyStateComponent={<ActiveMemosTableEmptyState />}
                pageRedirectTo="/memos"
            />
        </div>
    );
};

ActiveMemos.propTypes = {
    children: PropTypes.node,
};

export default ActiveMemos;
