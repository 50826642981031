import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Link from '@ckeditor/ckeditor5-link/src/link';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import _ from 'lodash';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../../layouts/form/Input';
import Label from '../../../../layouts/form/Label';
import TextArea from '../../../../layouts/form/Textarea';
import Select from '../../../../layouts/select/Select';
import { updateTemplate, verifyTemplateCode } from '../../../../redux/FormBuilderSlice';
import { RootState } from '../../../../redux/RootReducer';
import Store, { AppDispatch } from '../../../../redux/Store';
import { ITemplatesStepperFormProps } from './TemplatesForm';

/**
 * Returns a view for that handles the creation of the template category.
 * @returns {JSX.Element}
 */
const TemplateCategoryForm = (props: ITemplatesStepperFormProps) => {
    const template = useSelector((state: RootState) => state.FormBuilder);
    const dispatch: AppDispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm({ mode: 'onSubmit' });

    /**
     * An object that contains the configuration needed for building the richtext editor.
     * This conforms to the configuration specifications of CKEditor.
     * The documentation is available at: https://ckeditor.com/docs/ckeditor4/latest/guide/dev_configuration.html
     */
    const editorConfiguration = {
        plugins: [
            Essentials,
            Heading,
            Bold,
            Italic,
            Underline,
            Strikethrough,
            Paragraph,
            Link,
            BlockQuote,
            AutoLink,
            Indent,
            IndentBlock,
            ListStyle,
        ],
        toolbar: {
            items: [
                'bold',
                'italic',
                'underline',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'outdent',
                'indent',
                '|',
                'undo',
                'redo',
            ],
            viewportTopOffset: 160,
            shouldNotGroupWhenFull: false,
        },
    };

    /**
     * Handles the event on the initialization of the editor.
     * @param editorInstance
     */
    const onEditorReady = (editorInstance: any) => {
        if (editorInstance) {
            // Set the minimum height of the RTE on initialization.
            editorInstance.editing.view.change((writer: any) => {
                writer.setStyle('min-height', '200px', editorInstance.editing.view.document.getRoot());
            });
        }
    };

    /**
     * The default placeholder of the RTE.
     * TODO: Move this to a state object so it can be updated on edit.
     */

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const newTemplate = _.cloneDeep(template);
        const value = e.currentTarget.value;
        const field = e.currentTarget.dataset.field;
        if (field) {
            if (field === 'category_id') {
                _.set(
                    newTemplate,
                    'category_name',
                    template._categories.find((category) => category.category_id === value)?.category_name,
                );
            }
            _.set(newTemplate, field, value);
        }
        dispatch(
            updateTemplate({
                ...newTemplate,
            }),
        );
    };

    /**
     * Event handler for clicking the `Next` button.
     */
    const _onSubmit = async () => {
        if (props.onSubmit) {
            props.onSubmit({
                currentTarget: {
                    value: 'next',
                } as any,
            });
        }
    };

    /**
     * Error handler for form validation.
     * @param error
     */
    const _onError = (error: FieldValues) => {
        const errors = Object.values(error);
        if (errors.length > 0) {
            errors[0].ref.scrollIntoView(false);
            errors[0].ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    // Return view.
    return (
        <div className="w-1/2 relative">
            <form id={props.formId} onSubmit={handleSubmit(_onSubmit, _onError)}>
                {/* General Information section */}
                <div className="w-full h-auto p-4 bg-white">
                    <div className="w-full h-auto p-4">
                        <h4 className="text-md font-bold">General information</h4>
                        <div className="w-full py-2">
                            <Select
                                id="category_name"
                                label="Category"
                                onChange={onChange}
                                value={template.category_id}
                                data-field={'category_id'}
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Please choose a category.',
                                    },
                                    validate: {
                                        shouldNotBeDefault: (value) => value !== '' || 'Please choose a category',
                                    },
                                })}
                                defaultValue={props.formMode === 'EDIT' ? template.category_id : ''}
                                errors={errors}
                            >
                                <option value="" disabled hidden>
                                    Select category
                                </option>
                                {_.map(template._categories, (category) => (
                                    <option key={category.category_id} value={category.category_id}>
                                        {category.category_name}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className="w-full py-2">
                            <Input
                                id="template_code"
                                label="Template Code"
                                type="text"
                                placeholder="i.e. GEN"
                                onChange={onChange}
                                data-field="template_code"
                                defaultValue={template.template_code}
                                disabled={props.formMode === 'EDIT'}
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Please enter a template code.',
                                    },
                                    validate: {
                                        asyncValidate: async (value) => {
                                            if (props.formMode === 'CREATE') {
                                                const res = (await dispatch(verifyTemplateCode(value))) as any;
                                                return res.payload.message;
                                            }
                                        },
                                    },
                                })}
                                errors={errors}
                            />
                        </div>
                        <div className="w-full py-2">
                            <Input
                                id="template_name"
                                name="template_name"
                                label="Template name"
                                type="text"
                                placeholder="Your template name"
                                onChange={onChange}
                                data-field="template_name"
                                defaultValue={template.template_name}
                                ref={register({
                                    required: {
                                        value: true,
                                        message: 'Please enter a template name',
                                    },
                                })}
                                errors={errors}
                            />
                        </div>
                        <div className="w-full py-2">
                            <TextArea
                                id="description"
                                label="Description"
                                rows={4}
                                placeholder="Your description on your template."
                                onChange={onChange}
                                data-field="description"
                                value={template.description}
                            />
                        </div>
                    </div>
                </div>

                {/* Guidelines section */}
                <div className="w-full h-auto p-4 bg-white mt-4">
                    <div className="w-full h-auto p-4">
                        <h4 className="text-md font-bold">Guidelines</h4>

                        <div className="w-full mt-3">
                            <Label htmlFor="">Guidelines</Label>
                            <p className="text-xs font-regular w-full text-gray-2">
                                Set your own guidelines to help users in filling out the memo form.
                            </p>
                            <div className="w-full py-2 unreset">
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={editorConfiguration}
                                    data={template.guidelines}
                                    onReady={onEditorReady}
                                    placeholder="•Your list of guidelines here"
                                    onChange={(_: any, editor: any) => {
                                        const e: any = {
                                            currentTarget: {
                                                value: editor.getData(),
                                                dataset: {
                                                    field: 'guidelines',
                                                },
                                            },
                                        };
                                        onChange(e);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {template._http_category_request_state === 'pending' ? (
                <div className="w-full h-full bg-white opacity-50 absolute z-50 top-0" />
            ) : (
                ''
            )}
        </div>
    );
};

export default TemplateCategoryForm;
