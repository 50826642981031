import firebase from 'firebase/app';
import 'firebase/auth';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import ic_check from '../../../assets/img/ic_check.svg';
import Button from '../../../layouts/buttons/Button';
import Card from '../../../layouts/cards/Card';
import DataLayout, {
    DataLayoutProps,
    getRadioView,
    getRTEOutputView,
    getUploadView,
} from '../../../layouts/cards/DataLayout';
import Modal, { modalContainerStyles } from '../../../layouts/modals/Modal';
import Preloader from '../../../layouts/preloaders/Preloader';
import Text from '../../../layouts/typography/Text';
import Title from '../../../layouts/typography/Title';
import { MemoContent } from '../../../models/MemoContent';
import { ModalContent } from '../../../models/ModalContent';
import { TemplateCondition, TemplateConstant, TemplateContent } from '../../../models/TemplateContent';
import { RootState } from '../../../redux/RootReducer';
import { sanitizeHtmlString } from '../../../utils/anti-xss/Sanitize';
import { parseDate, parseTime } from '../../../utils/date/Date';
import { evalTruthList } from '../../../utils/misc/evalTruthList';
import StaticGrid from '../memo-form/StaticGrid';

interface IMemoViewContentData extends React.ComponentPropsWithoutRef<'div'> {
    id: string;
}

const FilePreloader = (props: { visible: boolean }) => {
    React.useEffect(() => {
        if (props.visible) {
            document.body.style.overflowY = 'hidden';
        }
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [props]);

    if (!props.visible) {
        return null;
    }

    return (
        <div className="flex items-center justify-center" style={modalContainerStyles}>
            <div className="w-full">
                <Preloader type="dots" text="If the redirected link does not work, please try to reload the page." />
            </div>
        </div>
    );
};

const MemoViewContentData = (props: IMemoViewContentData) => {
    const { state, memo } = useSelector((state: RootState) => state.MemoView);

    const [content, setMemoContent] = React.useState<DataLayoutProps[]>([]);
    const [modalContent, setModalContent] = React.useState<ModalContent>({
        isVisible: false,
        loading: true,
        title: '',
        subtitle: '',
        buttons: [
            {
                label: '',
                event: () => {
                    return;
                },
            },
        ],
    });

    const [fileRequestState, setFileRequestState] = React.useState('idle');

    const parseTemplateData = (content: MemoContent) => {
        const label = content.field_name;
        if (content.field_type === 'TEXT_RTE' || content.field_type === 'TEXT_RTE_PREFILLED') {
            return {
                type: content.field_type,
                label: label,
                data: sanitizeHtmlString(content.value as string),
            };
        }
        if (
            content.field_type === 'DROPDOWN' ||
            content.field_type === 'DROPDOWN_DEFAULT' ||
            content.field_type === 'RADIO'
        ) {
            const constant = content.constant as any[];
            return {
                type: content.field_type,
                label: label,
                data: constant.filter((option) => option.value === content.value)[0]
                    ? constant.filter((option) => option.value === content.value)[0].label
                    : '',
            };
        }
        if (content.field_type === 'CHECKBOX') {
            return {
                type: content.field_type,
                label: label,
                data: _.filter(Array.from(content.constant as any), (c: any) =>
                    Array.from(content.value).includes(c.value),
                ),
            };
        }
        if (content.field_type === 'DATEPICKER') {
            return {
                type: content.field_type,
                label: label,
                data: parseDate(content.value),
            };
        }
        if (content.field_type === 'TIMEPICKER') {
            return {
                type: content.field_type,
                label: label,
                data: parseTime(content.value),
            };
        }
        if (content.field_type === 'FILE_UPLOAD') {
            return {
                type: content.field_type,
                label: label,
                data: memo.attachment_urls,
            };
        }
        return {
            type: content.field_type,
            label: label,
            data: content.value as React.ReactNode,
        };
    };

    React.useEffect(() => {
        if (memo) {
            if (memo.api_version !== 'v2') {
                const memo_content: DataLayoutProps[] = memo.memo_content.map((content_field: Record<string, any>) => {
                    const template = memo.template_content.filter(
                        (field: TemplateContent) => field.field_id === content_field.field_id,
                    )[0];
                    if (template) {
                        const data = parseTemplateData({
                            ...content_field,
                            ...template,
                        } as MemoContent);
                        return data;
                    }
                    return {
                        label: null,
                        data: null,
                    };
                });
                setMemoContent(memo_content);
            }
        }
    }, [memo]);

    const onDownloadIconClick = async (event: any) => {
        try {
            setModalContent({ ...modalContent, isVisible: true, loading: true });
            const { url } = event.currentTarget.dataset;
            const response = await downloadFile(url);
            const [signedURL] = response.data.signedURL;
            const dlElement = document.createElement('a');
            dlElement.setAttribute('href', signedURL);
            dlElement.setAttribute('download', response.data.metadata.name);
            document.body.appendChild(dlElement);
            setModalContent({
                isVisible: true,
                loading: false,
                icon: ic_check,
                title: 'Download has started.',
                subtitle: `Your download should automatically start within seconds.<br /> If it doesn't, <a href=${signedURL} download class="text-blue-dark">restart the dowload.</a>`,
                buttons: [
                    {
                        label: 'Back to memo',
                        event: () => setModalContent({ ...modalContent, isVisible: false }),
                    },
                ],
            });
            setTimeout(() => {
                dlElement.click();
                document.body.removeChild(dlElement);
            }, 1000);
        } catch (err) {
            setModalContent({
                isVisible: true,
                loading: false,
                icon: '',
                title: 'Unable to download file.',
                subtitle: `It seems there was a problem while sending the request. Please try again later.`,
                buttons: [
                    {
                        label: 'Back to memo',
                        event: () => setModalContent({ ...modalContent, isVisible: false }),
                    },
                ],
            });
        }
    };

    const downloadFile = async (url: string, responseType: string | null = null) => {
        const cf = firebase.app().functions('asia-east2').httpsCallable('download_memo_file');
        const payload = responseType
            ? {
                  memo_id: memo.memo_id,
                  fileName: url,
                  responseType: responseType,
              }
            : {
                  memo_id: memo.memo_id,
                  fileName: url,
              };

        const response = await cf(payload);
        return response;
    };

    const onFileClick = async (e: any) => {
        try {
            setFileRequestState('pending');
            const request = await downloadFile(e, 'inline');
            const token = await firebase.auth().currentUser?.getIdToken();
            const [signedURL] = request.data.signedURL;

            const redirectURI =
                window.location.hostname === 'localhost'
                    ? `${window.location.protocol}//${window.location.hostname}:5001/${process.env.REACT_APP_PROJECT_ID}/us-central1/openFile/viewer/view`
                    : `${window.location.origin}/viewer/view`;

            window.open(
                `${redirectURI}?redirectURI=${encodeURIComponent(signedURL)}&contentType=${
                    request.data.metadata.contentType
                }&token=${token}`,
                '_blank',
            );

            setFileRequestState('fulfilled');
        } catch (err) {
            alert(err);
            setFileRequestState('idle');
        }
    };

    const getMultiAnswerValue = (constantList: TemplateConstant[], value: any, type: string) => {
        switch (type) {
            case 'SINGLE':
                const selectedItems = _.filter(constantList, (constant: TemplateConstant) => constant.value === value);
                return selectedItems[0] ? selectedItems[0].label : '';
            case 'MULTIPLE':
                return _.filter(constantList, (constant: TemplateConstant) =>
                    Array.from(value).includes(constant.value),
                );
        }
    };

    const generateGridCells = (items: MemoContent[]) => {
        return _.map(items, (content) => {
            switch (content.field_type) {
                case 'CHECKBOX':
                    return (
                        <div key={content.field_id} className="w-full h-auto">
                            <div className="w-full h-auto flex flex-col py-1" id={`cell-content-${content.field_id}`}>
                                <Text type="bold">{content.field_name}</Text>
                                {Array.from(
                                    getMultiAnswerValue(
                                        content.constant,
                                        content.value,
                                        'MULTIPLE',
                                    ) as TemplateContent[],
                                ).map((item: TemplateConstant, index: number) => (
                                    <Text key={index}>
                                        <p className="text-sm w-full">{item.label}</p>
                                    </Text>
                                ))}
                            </div>
                        </div>
                    );
                case 'DROPDOWN':
                case 'DROPDOWN_DEFAULT':
                    return (
                        <div key={content.field_id} className="w-full h-auto">
                            <div className="w-full h-auto flex flex-col py-1" id={`cell-content-${content.field_id}`}>
                                <Text type="bold">{content.field_name}</Text>
                                <Text>
                                    <p className="text-sm w-full break-words">
                                        {getMultiAnswerValue(content.constant, content.value, 'SINGLE')}
                                    </p>
                                </Text>
                                {(() => {
                                    const selectedValueIndex = _.findIndex(
                                        content.constant,
                                        (constant: TemplateConstant) => constant.value === content.value,
                                    );

                                    const selectedValue = content.constant[selectedValueIndex] as TemplateConstant;
                                    if (
                                        selectedValueIndex !== -1 &&
                                        selectedValue.branchingQuestions &&
                                        selectedValue.branchingQuestions.length
                                    ) {
                                        return (
                                            <div
                                                className="px-4 py-2 bg-blue-light mt-2"
                                                style={{ border: '1px solid #C5E2FD' }}
                                            >
                                                {generateGridCells(selectedValue.branchingQuestions as MemoContent[])}
                                            </div>
                                        );
                                    }
                                })()}
                            </div>
                        </div>
                    );
                case 'RADIO':
                    return (
                        <div key={content.field_id} className="w-full h-auto">
                            <div className="w-full h-auto flex flex-col py-1" id={`cell-content-${content.field_id}`}>
                                <Text type="bold">{content.field_name}</Text>

                                {getRadioView(
                                    {
                                        label: content.field_name,
                                        data: getMultiAnswerValue(content.constant, content.value, 'SINGLE'),
                                    },
                                    content.field_id,
                                )}
                            </div>
                        </div>
                    );
                case 'TEXT_RTE':
                case 'TEXT_RTE_PREFILLED':
                    return (
                        <div key={content.field_id} className="w-full h-auto">
                            <div className="w-full h-auto flex flex-col py-1" id={`cell-content-${content.field_id}`}>
                                <Text type="bold">{content.field_name}</Text>
                                {getRTEOutputView(sanitizeHtmlString(content.value))}
                            </div>
                        </div>
                    );
                case 'DATEPICKER':
                    return (
                        <div key={content.field_id} className="w-full h-auto">
                            <div className="w-full h-auto flex flex-col py-1" id={`cell-content-${content.field_id}`}>
                                <Text type="bold">{content.field_name}</Text>
                                <Text>
                                    <p className="text-sm w-full break-words">{parseDate(content.value)}</p>
                                </Text>
                            </div>
                        </div>
                    );
                case 'TIMEPICKER':
                    return (
                        <div key={content.field_id} className="w-full h-auto">
                            <div className="w-full h-auto flex flex-col py-1" id={`cell-content-${content.field_id}`}>
                                <Text type="bold">{content.field_name}</Text>
                                <Text>
                                    <p className="text-sm w-full break-words">{parseTime(content.value)}</p>
                                </Text>
                            </div>
                        </div>
                    );
                case 'NESTED':
                    return (
                        <div key={content.field_id} className="w-full h-auto">
                            <div className="w-full h-auto flex flex-col py-1" id={`cell-content-${content.field_id}`}>
                                <Text type="bold">{content.field_name}</Text>
                                {_.map(content.value, (fieldSetRow) => {
                                    return (
                                        <div
                                            key={fieldSetRow.field_id}
                                            className="w-full flex flex-row bg-blue-light p-2 mb-2"
                                        >
                                            <div className="w-full grid grid-cols-2 gap-2 p-2">
                                                {/* {_.map(fieldSetRow.fields, (field) => (
                                                    <div id={field.field_id}>
                                                        <p>{field.field_name}</p> <br />
                                                        <p>{JSON.stringify(field.value)}</p>
                                                    </div>
                                                ))} */}
                                                {generateGridCells(fieldSetRow.fields)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                case 'FILE_UPLOAD':
                    return (
                        <div key={content.field_id} className="w-full h-auto">
                            <div className="w-full h-auto flex flex-col py-1" id={`cell-content-${content.field_id}`}>
                                {getUploadView(
                                    memo.attachment_urls,
                                    content.field_name,
                                    onDownloadIconClick,
                                    onFileClick,
                                    content.field_id,
                                )}
                            </div>
                        </div>
                    );
                default:
                    return (
                        <div key={content.field_id} className="w-full h-auto">
                            <div className="w-full h-auto flex flex-col py-1" id={`cell-content-${content.field_id}`}>
                                <Text type="bold">{content.field_name}</Text>
                                <Text>
                                    <p className="text-sm w-full break-words">{content.value}</p>
                                </Text>
                            </div>
                        </div>
                    );
            }
        });
    };

    switch (state) {
        case 'pending':
            return (
                <div
                    className="w-full flex flex-col-reverse md:flex-row justify-between mt-4"
                    id={props.id}
                    aria-label={props['aria-label']}
                >
                    <div className="w-full h-64 bg-white flex items-center">
                        <Preloader type="dots" />
                    </div>
                </div>
            );
        case 'fulfilled':
            return (
                <div className="mt-4 px-1 md:px-4" id={props.id} aria-label={props['aria-label']}>
                    {(() => {
                        if (memo.api_version === 'v2') {
                            /**
                             * Add conditional rendering if the section
                             * should be visible or not
                             */
                            const visibleSections = _.filter(memo.memo_content, (content) => {
                                if (!content.conditions) return true;

                                const conditions = content.conditions as TemplateCondition[];

                                const truthTable = evalTruthList(conditions, (condition) => {
                                    const referencedValue = _.get(memo.memo_content, condition.field_ref);
                                    switch (condition.operator) {
                                        case 'EQUALS':
                                            return referencedValue === condition.value;
                                        default:
                                            return false;
                                    }
                                });

                                return truthTable.includes(true);
                            });

                            return _.map(visibleSections, (section) => (
                                <div key={section.field_id} className="w-full h-auto mb-4">
                                    <Card type="info" title={section.field_name}>
                                        <StaticGrid
                                            _metadata={section._metadata}
                                            items={generateGridCells(section.value)}
                                        />
                                    </Card>
                                </div>
                            ));
                        } else {
                            return (
                                <Card type="info" title="Memo content">
                                    <DataLayout
                                        items={content}
                                        onDownload={(event) => {
                                            onDownloadIconClick(event);
                                        }}
                                        onFileClick={onFileClick}
                                    />
                                </Card>
                            );
                        }
                    })()}

                    <FilePreloader visible={fileRequestState === 'pending'} />

                    <Modal visible={modalContent.isVisible as boolean}>
                        {modalContent.loading ? (
                            <Text>Please wait...</Text>
                        ) : (
                            <div
                                className={`w-full flex flex-col ${modalContent.icon ? `items-center` : `items-start`}`}
                            >
                                {modalContent.icon ? (
                                    <img src={ic_check} style={{ width: '120px', height: '120px' }} />
                                ) : (
                                    ''
                                )}
                                <Title type="h4">{modalContent.title}</Title>
                                <div className={`${modalContent.icon ? 'my-2 text-center' : 'my-0'}`}>
                                    <Text type="innerHTML">{modalContent.subtitle}</Text>
                                </div>
                                {modalContent.buttons.length > 1 ? (
                                    <div className="w-full my-2 flex flex-col-reverse md:flex-row justify-center">
                                        <div className="w-full flex flex-col px-2">
                                            <Button
                                                type="button"
                                                color="secondary"
                                                weight="font-regular"
                                                onClick={modalContent.buttons[0].event}
                                            >
                                                {modalContent.buttons[0].label}
                                            </Button>
                                        </div>
                                        <div className="w-full flex flex-col px-2">
                                            <Button
                                                color="primary"
                                                weight="font-regular"
                                                type="submit"
                                                onClick={modalContent.buttons[1].event}
                                            >
                                                {modalContent.buttons[1].label}
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="my-2">
                                        <Button
                                            type="button"
                                            color="primary"
                                            weight="font-regular"
                                            onClick={modalContent.buttons[0].event}
                                        >
                                            {modalContent.buttons[0].label}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Modal>
                </div>
            );
        default:
            return null;
    }
};

export default MemoViewContentData;
