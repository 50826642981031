import * as React from 'react';
import PropTypes from 'prop-types';
import Text from '../typography/Text';

interface IStatusProps {
    type?: string;
}

export const circle: React.CSSProperties = {
    borderRadius: '50%',
    width: '12px',
    height: '12px',
};

const Status = (props: IStatusProps) => {
    const { type } = props;

    switch (type?.toLowerCase()) {
        case 'rejected':
            return (
                <div className="flex flex-row items-center">
                    <div style={circle} className="bg-red mr-2"></div>
                    <Text>Rejected</Text>
                </div>
            );
        case 'returned':
            return (
                <div className="flex flex-row items-center">
                    <div style={circle} className="bg-blue-status mr-2"></div>
                    <Text>Returned</Text>
                </div>
            );
        case 'self_returned':
            return (
                <div className="flex flex-row items-center">
                    <div style={circle} className="bg-blue-status mr-2"></div>
                    <Text>Withdrawn</Text>
                </div>
            );
        case 'submitted':
            return (
                <div className="flex flex-row items-center">
                    <div style={circle} className="bg-yellow mr-2"></div>
                    <Text>Pending</Text>
                </div>
            );
        case 'approved':
            return (
                <div className="flex flex-row items-center">
                    <div style={circle} className="bg-green mr-2"></div>
                    <Text>Approved</Text>
                </div>
            );
        case 'withdrawn':
            // TODO: update backend to refactor withdrawn to cancelled
            return (
                <div className="flex flex-row items-center">
                    <div style={circle} className="bg-yellow-dark mr-2"></div>
                    <Text>Cancelled</Text>
                </div>
            );
        case 'save_as_draft':
            return (
                <div className="flex flex-row items-center">
                    <div style={circle} className="bg-gray-status mr-2"></div>
                    <Text>Draft</Text>
                </div>
            );
        case 'returned_draft':
            return (
                <div className="flex flex-row items-center">
                    <div style={circle} className="bg-gray-status mr-2"></div>
                    <Text>Draft</Text>
                </div>
            );
        case 'self_returned_draft':
            return (
                <div className="flex flex-row items-center">
                    <div style={circle} className="bg-gray-status mr-2"></div>
                    <Text>Draft</Text>
                </div>
            );
        default:
            return (
                <div className="flex flex-row items-center">
                    <div style={circle} className="bg-yellow mr-2"></div>
                    <Text>Pending</Text>
                </div>
            );
    }
};
export default Status;
