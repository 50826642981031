import React from 'react';
import TimeAgo from 'react-timeago';
import { getDateString } from '../../../utils/date/Date';
import Status from '../../status/Status';
import Text from '../../typography/Text';
import Title from '../../typography/Title';
import Card from '../Card';
import './KanbanCard.css';

interface IKanbanCardProps extends React.ComponentPropsWithoutRef<'div'> {
    type: string;
    title: string;
    subtitle?: string;
    category?: string;
    timestamp?: string;
    memo_id?: string;
    memo_type?: string;
    status?: string;
    owner?: string;
    children?: React.ReactNode;
}

const KanbanCard = (props: IKanbanCardProps) => {
    const { type, title, subtitle, category, timestamp, memo_id, memo_type, status, owner } = props;
    const formattedTimestamp = getDateString(timestamp);
    // TODO: change to utils/date/getDateString func

    switch (type) {
        case 'kanban_status':
            return (
                <Card type={'kanban'}>
                    <Text color="secondary">
                        <p className="text-xs">{formattedTimestamp}</p>
                    </Text>
                    <div className="py-4">
                        <Title type="h4">
                            <p className="text-sm overflow-ellipsis whitespace-nowrap w-full overflow-hidden">
                                {title}
                            </p>
                        </Title>
                        <Text color="secondary">
                            <p className="text-sm">
                                {memo_id} | {memo_type}
                            </p>
                        </Text>
                    </div>
                    <Text color="secondary">
                        <Status type={status} />
                    </Text>
                </Card>
            );
        case 'kanban_timeago':
            return (
                <Card type={'kanban'}>
                    <Text color="secondary">
                        <p className="text-xs">{formattedTimestamp}</p>
                    </Text>
                    <div className="py-4">
                        <Title type="h4">
                            <p className="text-sm overflow-ellipsis whitespace-nowrap w-full overflow-hidden">
                                {title}
                            </p>
                        </Title>
                        <Text color="secondary">
                            <p className="text-sm">
                                {memo_id} | {memo_type}
                            </p>
                        </Text>
                    </div>
                    <Text color="secondary">
                        <p className="text-sm">
                            {owner} | <TimeAgo date={formattedTimestamp} />
                        </p>
                    </Text>
                </Card>
            );
        case 'memo_type':
            return (
                <Card type={'category'}>
                    <Text color="secondary">{category}</Text>
                    <div>
                        <Title type="h4">{title}</Title>
                        <Text color="secondary">{subtitle}</Text>
                    </div>
                </Card>
            );
        default:
            return (
                <Card type={'kanban'}>
                    <Text color="secondary">
                        <p className="text-xs">{formattedTimestamp}</p>
                    </Text>
                    <div className="py-4">
                        <Title type="h4">
                            <h1 className="text-base">{title}</h1>
                        </Title>
                        <Text color="secondary">
                            <p className="text-base">
                                {memo_id} | {memo_type}
                            </p>
                        </Text>
                    </div>
                    <Text color="secondary">
                        <Status type={status && status?.toLowerCase()} />
                    </Text>
                </Card>
            );
    }
};

export default KanbanCard;
