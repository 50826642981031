import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../layouts/buttons/Button';
import { RootState } from '../../../redux/RootReducer';

interface ICTAButtonsProps {
    redirectUrl: string;
    type: string;
    onActionClicked?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

// TODO: modal for CTA

const CTAButtons: React.FunctionComponent<ICTAButtonsProps> = (props) => {
    const { redirectUrl, type, onActionClicked } = props;
    const { state, memo } = useSelector((state: RootState) => state.MemoView);
    const [canApprove, setCanApprove] = React.useState(false);

    const goBack = () => {
        const cs = redirectUrl.split('/');
        cs.splice(cs.length - 1, 1);
        navigate(`${cs.join('/')}`);
    };

    React.useEffect(() => {
        if (state === 'fulfilled' && memo.approval_status === 'PENDING' && memo.memo_status === 'SUBMITTED') {
            setCanApprove(true);
        }
    }, [state, memo]);

    if (canApprove && !memo.flags?.includes('READ_ONLY')) {
        return (
            <div id="CTA" className="w-full md:w-1/2 mt-0 mb-8 md:my-4 flex flex-row items-center">
                <Button id="btn_reject" color="danger" weight="font-regular mr-4" onClick={onActionClicked}>
                    <u>Reject</u>
                </Button>
                <Button id="btn_return" color="secondary" weight="font-regular mr-4" onClick={onActionClicked}>
                    Return
                </Button>
                <Button id="btn_approve" color="primary" weight="font-regular mr-4" onClick={onActionClicked}>
                    Approve
                </Button>
            </div>
        );
    }

    switch (type) {
        case 'DRAFT':
            return (
                <div className="w-full mt-0 mb-8 md:my-4 flex flex-row items-center">
                    <Button id="bck_list" weight="font-regular mr-4" onClick={goBack}>
                        <u>Back to the list</u>
                    </Button>
                    <Button id="btn_del_draft" color="secondary" weight="font-regular mr-4" onClick={onActionClicked}>
                        Delete draft
                    </Button>
                </div>
            );
        case 'OWNED':
            return (
                <div className="w-full mt-0 mb-8 md:my-4 flex flex-row items-center">
                    <Button id="bck_list" weight="font-regular mr-4" onClick={goBack}>
                        <u>Back to the list</u>
                    </Button>
                    <Button id="btn_cancel" color="secondary" weight="font-regular mr-4" onClick={onActionClicked}>
                        Cancel Memo
                    </Button>
                    {memo.memo_status === 'SUBMITTED' ? (
                        <Button id="btn_withdraw" color="primary" weight="font-regular mr-4" onClick={onActionClicked}>
                            Withdraw Memo
                        </Button>
                    ) : (
                        ''
                    )}
                </div>
            );
        default:
            return (
                <div className="w-full mt-0 mb-8 md:my-4 flex flex-row items-center">
                    <Button id="bck_list" color="secondary" weight="font-regular mr-4" onClick={goBack}>
                        <u>Back to the list</u>
                    </Button>
                </div>
            );
    }
};

CTAButtons.propTypes = {
    redirectUrl: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onActionClicked: PropTypes.func,
};

export default CTAButtons;
