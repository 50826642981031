import * as React from 'react';
import PropTypes from 'prop-types';
import './Table.css';
import { flavor } from '../../AppFlavor';
const { header } = flavor.theme.table;

interface ITableRowProps extends React.ComponentPropsWithoutRef<'tr'> {
    type?: string;
    navigateTo?: string;
    children?: React.ReactNode;
}

const TableRow: React.FunctionComponent<ITableRowProps> = (props) => {
    switch (props.type) {
        case 'header':
            return (
                <tr className={`${header.background} w-full text-white`} data-testid="table-header-row">
                    {props.children}
                </tr>
            );
        default:
            return (
                <tr
                    className={`bg-white w-full table-body-row ${
                        props.navigateTo ? 'cursor-pointer' : 'cursor-default'
                    }`}
                    data-testid="table-body-row"
                >
                    {React.Children.map(props.children, (child) => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, { navigateTo: props.navigateTo });
                        }
                        return child;
                    })}
                </tr>
            );
    }
};

TableRow.propTypes = {
    type: PropTypes.string,
    navigateTo: PropTypes.string,
    children: PropTypes.node,
};

export default TableRow;
