import * as React from 'react';
import PropTypes from 'prop-types';
import Title from '../typography/Title';
import Text from '../typography/Text';
import './kanban/KanbanCard.css';

interface ICardProps extends React.ComponentPropsWithoutRef<'div'> {
    title?: string;
    subtitle?: string;
    type?: string;
    category?: string;
    children?: React.ReactNode;
}

export const categoryCard: React.CSSProperties = {
    borderLeft: '9px solid #1352AB',
};

export const categoryCardLoading: React.CSSProperties = {
    borderLeft: '9px solid #b2bbc4',
};

const Card = (props: ICardProps) => {
    const { type, title, subtitle, category } = props;
    switch (type) {
        case 'kanban':
            return (
                <div className="w-auto note bg-white p-4" data-testid="card-kanban">
                    <div className="pin">pin</div>
                    {title && (
                        <div>
                            <Title type="h4">
                                <h1 className="text-base">{title}</h1>
                            </Title>
                            <Text color="secondary">
                                <h2 className="text-base">{subtitle}</h2>
                            </Text>
                        </div>
                    )}
                    {props.children}
                </div>
            );
        case 'category-loading':
            return (
                <div style={categoryCardLoading} className="w-full bg-white p-4" data-testid="card-skeleton-loading">
                    <div className="w-1/3 h-2 skeleton-loader"></div>
                    <div className="w-full md:w-1/2 h-4 skeleton-loader mt-3"></div>
                    <div className="w-full md:w-1/2 h-4 skeleton-loader mt-2"></div>
                </div>
            );
        case 'category':
            return (
                <div
                    style={categoryCard}
                    className="w-full bg-white p-4 cursor-pointer shadow-none hover:shadow-lg"
                    data-testid="card-category"
                    onClick={props.onClick}
                >
                    <Text color="tertiary">
                        <h2 className="text-sm pb-1">{category}</h2>
                    </Text>
                    <h4 className="text-md font-bold">{title}</h4>
                    <Text color="tertiary">
                        <p className="font-regular text-xs">{subtitle}</p>
                    </Text>
                    <div className="w-full">{props.children}</div>
                </div>
            );
        case 'info':
            return (
                <div className="w-full bg-white p-8" data-testid="card-info">
                    <Title color="secondary" type="h4">
                        <h1 className="text-base">{title}</h1>
                    </Title>
                    <Text type="bold">
                        <h2 className="text-gray-2 my-4">{subtitle}</h2>
                    </Text>
                    <div className="flex flex-row">
                        <div className="w-full h-full">
                            <div className="w-full">{props.children}</div>
                        </div>
                    </div>
                </div>
            );
        default:
            return (
                <div className="w-full bg-white p-8">
                    {title && (
                        <div>
                            <Title type="h4">
                                <h1 className="text-base">{title}</h1>
                            </Title>
                            <Text color="secondary">
                                <h2 className="text-base">{subtitle}</h2>
                            </Text>
                        </div>
                    )}
                    {props.children}
                </div>
            );
    }
};

export default Card;
