import PropTypes from 'prop-types';
import * as React from 'react';
import Datatable from '../datatable/Datatable';
import HistoryTableBody from './HistoryMemosTableBody';
import HistoryTableHeaders from './HistoryMemosTableHeader';
interface IMyHistoryMemosProps {
    memo_type?: string;
    headers?: any;
    children?: React.ReactNode;
    start_date?: any;
    end_date?: any;
}

const HistoryMemos: React.FunctionComponent<IMyHistoryMemosProps> = (props: IMyHistoryMemosProps) => {
    const [config, setConfig] = React.useState({
        endpoint: 'getMyMemosHistoryWithUserID',
        templatePath: 'mymemos.history',
        queryParams: {
            limit: 15,
            page_no: 1,
            start_date: null,
            end_date: null,
            memo_type: '',
        },
    });

    const defaultHeaders = [
        {
            label: 'Memo ID',
            key: 'memo_id',
        },
        {
            label: 'Memo type',
            key: 'used_template_name',
        },
        {
            label: 'Subject',
            key: 'memo_subject',
        },
        {
            label: 'Date of action',
            key: 'date_last_modified',
        },
        {
            label: 'Action taken',
            key: 'memo_status',
        },
    ];

    const { memo_type, start_date, end_date } = props;

    React.useEffect(() => {
        setConfig({
            ...config,
            queryParams: {
                ...config.queryParams,
                memo_type: memo_type as string,
                start_date: start_date,
                end_date: end_date,
            },
        });
    }, [memo_type, start_date, end_date]);

    return (
        <div>
            <Datatable
                config={config}
                defaultHeaders={defaultHeaders}
                headerComponent={<HistoryTableHeaders />}
                bodyComponent={<HistoryTableBody />}
                pageRedirectTo="/memos/history"
            />
        </div>
    );
};

HistoryMemos.propTypes = {
    children: PropTypes.node,
};

export default HistoryMemos;
