import firebase from 'firebase/app';
import React from 'react';
import { Reply } from '../../models/Reply';
import Preloader from '../preloaders/Preloader';
import ReplyListItem from './ReplyListItem';

export interface IReplyListProps {
    log_id: string;
    memo_id: string;
    replies: Reply[];
    http_response_status: string;
    is_empty: boolean;
    onLoad: (start_after: string) => void;
}

const ReplyList = (props: IReplyListProps) => {
    const { replies, http_response_status, is_empty } = props;

    return (
        <>
            <ul className="replies bg-transparent w-full flex flex-col mt-2 text-xs">
                {replies.map((reply) => (
                    <ReplyListItem key={reply.reply_id} {...reply} />
                ))}
            </ul>
            {http_response_status !== 'pending' && !is_empty && (
                <button
                    className="w-full bg-gray-light text-xs p-1"
                    style={{ background: '#DBDBDB' }}
                    onClick={() => props.onLoad(replies[replies.length - 1].reply_id)}
                    role="load-more"
                >
                    Load more replies
                </button>
            )}
            {http_response_status === 'pending' && <Preloader type="dots" width="40px" />}
        </>
    );
};

export default ReplyList;
