import React from 'react';
import Label from '../../../layouts/form/Label';
import File from '../../../layouts/panels/File';
import { TempFile } from '../../../models/TempFile';

interface RemarksView extends React.ComponentPropsWithoutRef<'textarea'> {
    id: string;
    label: string;
    files: TempFile[];
    onFilesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFilesRemove: (event: any) => void;
}

const RemarksView = (props: RemarksView) => {
    const { onFilesChange, onFilesRemove, files, ...textAreaProps } = props;
    const fileRef = React.useRef<HTMLInputElement>(null);

    return (
        <div className="w-full mt-4">
            <Label htmlFor={props.id}>{props.label}</Label>
            <div className="w-full" style={{ border: '0.25px solid #afafaf', borderRadius: '5px' }}>
                <textarea
                    className="pt-2 my-0 px-2 my-1 font-regular text-sm w-full"
                    {...textAreaProps}
                    style={{ margin: '0', display: 'block', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                    data-testid="remarks-textarea"
                />
                {files.map((file, index) => (
                    <File key={`${file}-${index}`} name={file.name} type="upload" onIconClick={onFilesRemove} />
                ))}
                <div className="w-full p-1 flex border-t border-dashed border-gray-light" role="upload">
                    <label
                        htmlFor="remarks-upload"
                        className="font-regular text-xs text-blue p-1 ml-auto cursor-pointer"
                    >
                        + Upload file
                    </label>
                    <input
                        ref={fileRef}
                        className="hidden"
                        id="remarks-upload"
                        type="file"
                        multiple
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onFilesChange(event);
                            if (fileRef && fileRef.current) {
                                fileRef.current.value = '';
                            }
                        }}
                        data-testid="remarks-files"
                    />
                </div>
            </div>
        </div>
    );
};

export default RemarksView;
