import * as React from 'react';
import PropTypes from 'prop-types';

interface INavItemActiveProps {
    label: string;
}

const SideMenuItemActive: React.FunctionComponent<INavItemActiveProps> = (props) => {
    return (
        <div className="w-full flex flex-col items-center">
            <div className="w-full bg-blue  px-4 py-2 cursor-pointer flex justify-start items-center text-sm font-bold text-white">
                {props.label}
            </div>
        </div>
    );
};

SideMenuItemActive.propTypes = {
    label: PropTypes.string.isRequired,
};

export default SideMenuItemActive;
