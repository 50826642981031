import React from 'react';
import { MemoFeedItems } from '../../models/MemoFeedItems';
import Preloader from '../preloaders/Preloader';
import './MemoFeed.css';
import MemoFeedList from './MemoFeedList';

interface IMemoFeedContainerProps {
    items: Array<MemoFeedItems>;
    state?: string;
    redirectTo: string;
    returned_count?: number;
    onItemClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const MemoFeedContainer = (props: IMemoFeedContainerProps) => {
    switch (props.state) {
        case 'fulfilled':
            return (
                <div className="w-full">
                    <MemoFeedList
                        items={props.items}
                        returned_count={props.returned_count}
                        redirectTo={props.redirectTo}
                        onItemClick={props.onItemClick}
                    />
                </div>
            );
        case 'rejected':
            return (
                <div className="w-full">
                    <div
                        className="w-full p-2 memo-feed flex justify-center items-center"
                        style={{ height: '600px', overflowY: 'hidden' }}
                    >
                        There was an error performing this request.
                    </div>
                </div>
            );
        default:
            return (
                <div className="w-full">
                    <div
                        className="w-full p-2 memo-feed flex justify-center items-center"
                        style={{ height: '600px', overflowY: 'hidden' }}
                    >
                        <Preloader type="dots" text="Loading..." />
                    </div>
                </div>
            );
    }
};

export default MemoFeedContainer;
