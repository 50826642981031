import * as React from 'react';
import PropTypes from 'prop-types';
import { buttonStyles } from './Button';
import google_logo from '../../assets/img/g-logo.png';
import ms_logo from '../../assets/img/ms-logo.png';
import logo_ayala_disabled from '../../assets/img/logo_ayala_disabled.png';

export interface IButtonSocialProps extends Omit<React.ComponentPropsWithoutRef<'button'>, 'onClick'> {
    provider?: string;
    text: string;
    children?: React.ReactNode;
    onClick?: (provider: string) => void;
}

const ButtonSocial = (props: IButtonSocialProps) => {
    const { provider, text } = props;

    const onClick = props.onClick as (provider: string) => void;

    switch (provider) {
        case 'google':
            return (
                <button
                    className={`${
                        props.disabled ? 'bg-gray-2' : 'bg-blue-google'
                    } pr-5 text-sm text-white font-regular items-center`}
                    {...props}
                    onClick={() => onClick(provider)}
                    style={{ ...buttonStyles, display: 'inline-flex', flexDirection: 'row' }}
                >
                    <span className="mr-4">
                        <div
                            className="flex bg-white"
                            style={{
                                width: '18dp',
                                height: '18dp',
                                padding: '10px',
                                margin: '2px',
                                borderRadius: '4px 0px 0px 4px',
                            }}
                        >
                            <img src={google_logo} width="30px" height="30px" alt="google" />
                        </div>
                    </span>
                    <p className="w-full text-sm p-2">{text}</p>
                </button>
            );
        case 'microsoft':
            // Input button style for microsoft here
            return (
                <button
                    className="w-12 h-12 rounded-full flex items-center justify-center"
                    style={{ border: '1px solid #1352AB', borderRadius: '50%' }}
                    onClick={() => onClick(provider)}
                >
                    <img src={ms_logo} width="24px" alt={text} />
                </button>
            );
        case 'waa':
            // Input button style for microsoft here
            return (
                <button
                    className="w-12 h-12 rounded-full flex items-center justify-center opacity-20"
                    style={{ border: '1px solid #4b4949', borderRadius: '50%', background: '#ebe6e6' }}
                    disabled
                >
                    <img src={logo_ayala_disabled} width="30px" alt={text} />
                </button>
            );
        default:
            return (
                <button {...props} onClick={() => onClick('')}>
                    {text}
                </button>
            );
    }
};

export default ButtonSocial;
