import _ from 'lodash';
import { nanoid } from 'nanoid';
import React from 'react';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import Datepicker from '../../../../layouts/datepicker/Datepicker';
import DropZone from '../../../../layouts/form/DropZone';
import Input from '../../../../layouts/form/Input';
import Label from '../../../../layouts/form/Label';
import RichTextEditorV2 from '../../../../layouts/form/RichTextEditorV2';
import Select from '../../../../layouts/select/Select';
import Title from '../../../../layouts/typography/Title';
import { TemplateContent } from '../../../../models/TemplateContent';
import {
    addSection,
    deleteSection,
    moveSection,
    removeError,
    setActiveField,
    setHasFileUpload,
    setHasRTE,
    updateSection,
} from '../../../../redux/FormBuilderSlice';
import { RootState } from '../../../../redux/RootReducer';
import { AppDispatch } from '../../../../redux/Store';
import Editor from '../../../basic/memo-form/Editor';
import { withReduxConnector } from '../hoc/withReduxConnector';
import TemplateSection from './TemplateSection';
import { ITemplatesStepperFormProps } from './TemplatesForm';

/**
 * Creates a new component that wrapped RichTextEditor and added handlers that connects the richtext editor to Redux.
 * While this is currently under development, it is interesting to note that HOC enables DRY principle for the components
 * that are already written.
 */
const WrappedRichText = withReduxConnector(RichTextEditorV2);

/**
 * Returns the view for the Drag and Drop Template Form Builder.
 * @returns {JSX.Element}
 */
const TemplatesBuilderForm = (props: ITemplatesStepperFormProps) => {
    /**
     * Connects to Redux object for the template content.
     * TODO: move all local state object here going forward
     */
    const { template_content, _editing, errors } = useSelector((state: RootState) => state.FormBuilder);
    const dispatch: AppDispatch = useDispatch();

    React.useEffect(() => {
        const RTEs = _.filter(template_content, (content) => {
            if (
                _.some(
                    content.value,
                    (value) => value.field_type === 'TEXT_RTE' || value.field_type === 'TEXT_RTE_PREFILLED',
                )
            ) {
                return true;
            }
            return false;
        });

        const fileUploads = _.filter(template_content, (content) => {
            if (_.some(content.value, (value) => value.field_type === 'FILE_UPLOAD')) {
                return true;
            }
            return false;
        });

        dispatch(setHasRTE(RTEs.length > 0));
        dispatch(setHasFileUpload(fileUploads.length > 0));
    }, [template_content]);

    /**
     * Handles the event after dragging or after dropping a section
     * @param sections
     */
    const onSectionDragEnd = (sections: DropResult) => {
        // If there is a destination, place the section there.
        if (sections.destination?.index !== undefined) {
            // Creates a payload of source index, destination index, and the item that will be moved.
            const content = template_content[sections.source.index];
            const item = {
                sourceIndex: sections.source.index,
                destinationIndex: sections.destination.index,
                data: content,
            };
            dispatch(moveSection(item));
        }
    };

    /**
     * Adds a section
     * TODO: Use reducer and the Redux store
     */
    const _addSection = () => {
        // Create an ID
        const id = nanoid();

        // Construct a Template section object
        // TODO: create a type for section
        const section: TemplateContent = {
            field_id: id,
            field_name: 'Untitled',
            field_type: 'SECTION',
            validations: {
                required: false,
            },
            value: [],
            sectionId: id,
            sectionIndex: template_content.length,
        };
        dispatch(addSection(section));

        // Sets the new id to focused.
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                element.focus();
            }
        });
    };

    /**
     * Handles updating a section
     * @param id
     * @param data
     */
    const _updateSection = (index: number, data: TemplateContent) => {
        dispatch(updateSection({ index: index, content: data }));
    };

    /**
     * Removes the section from the list
     * @param id
     */
    const removeSection = (id: string) => {
        errors
            ?.filter((error) => error.sectionId === id)
            .forEach((error) => {
                dispatch(removeError(error.form_item.id));
            });
        dispatch(deleteSection(id));
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (errors && errors.length) {
            const element = document.getElementById(`cell-content-${errors[0].form_item.id}`);
            if (element) {
                element.scrollIntoView(false);
                element.scrollIntoView({ behavior: 'auto', block: 'center' });
            }
            return;
        }
        dispatch(setActiveField(null));
        setTimeout(() => {
            if (props.onSubmit) {
                props.onSubmit({
                    currentTarget: {
                        value: 'next',
                    } as any,
                });
            }
        }, 100);
    };

    /**
     * Returns a JSX element depending on the form field type specified/chosen from the section menu.
     * @param itemType
     * @param index
     * @returns {JSX.Element}
     */
    const getFormItem = (contents: TemplateContent[]) => {
        return _.map(contents, (content) => {
            switch (content.field_type) {
                case 'TEXT':
                    return (
                        <Input
                            key={content.field_id}
                            id={content.field_id}
                            label={content.field_name ? content.field_name : 'Untitled'}
                            annotation={content.annotation ? content.annotation : ''}
                            type="text"
                            placeholder={content.placeholder ? content.placeholder : ''}
                        />
                    );
                case 'TEXT_CURRENCY':
                    return (
                        <Input
                            key={content.field_id}
                            id={content.field_id}
                            label={content.field_name ? content.field_name : 'Untitled'}
                            annotation={content.annotation ? content.annotation : ''}
                            type="currency"
                            placeholder={content.placeholder ? content.placeholder : ''}
                        />
                    );
                case 'TEXT_RTE':
                    return (
                        <div key={content.field_id} className="w-full inline-block relative">
                            <Editor
                                id={content.field_id}
                                label={content.field_name}
                                defaultValue={content.constant}
                                sectionIndex={content.sectionIndex}
                            />
                        </div>
                    );
                case 'CHECKBOX':
                    return (
                        <>
                            <Label htmlFor={content.field_id}>{content.field_name}</Label>
                            <p className="text-xs font-regular w-full break-words text-gray-2 mb-1">
                                {content.annotation}
                            </p>
                            {_.map(content.constant, (value: any, i: number) => (
                                <div className="w-auto h-auto block">
                                    <Input
                                        id={`option-${content.field_id}-${i}`}
                                        label={value.label}
                                        name={content.field_id}
                                        type="checkbox"
                                    />
                                </div>
                            ))}
                        </>
                    );
                case 'RADIO':
                    return (
                        <>
                            <Label htmlFor={content.field_id}>{content.field_name}</Label>
                            <p className="text-xs font-regular w-full break-words text-gray-2 mb-1">
                                {content.annotation}
                            </p>
                            {_.map(content.constant, (value: any, i: number) => (
                                <div className="w-auto h-auto block">
                                    <Input
                                        id={`option-${content.field_id}-${i}`}
                                        label={value.label}
                                        name={content.field_id}
                                        type="radio"
                                    />
                                </div>
                            ))}
                        </>
                    );
                case 'FILE_UPLOAD':
                    return (
                        <DropZone
                            label={content.field_name}
                            annotation={content.annotation}
                            placeholder={content.placeholder}
                            fileState={[]}
                            onChange={() => {
                                return;
                            }}
                            onDropzoneFileRemoved={() => {
                                return;
                            }}
                            disabled={true}
                        />
                    );
                case 'DROPDOWN':
                    return (
                        <>
                            <Select
                                id={content.field_name}
                                annotation={content.annotation}
                                placeholder={content.placeholder}
                                label={content.field_name}
                                defaultValue={''}
                            >
                                {''}
                                <option value="" disabled selected hidden>
                                    {content.placeholder}
                                </option>
                                {_.map(content.constant, (value: any) => (
                                    <option value={value.value}>{value.label}</option>
                                ))}
                            </Select>
                        </>
                    );
                case 'TIMEPICKER':
                    return (
                        <>
                            <Label htmlFor={content.field_id}>{content.field_name}</Label>
                            <p className="text-xs font-regular w-full break-words text-gray-2 mb-1">
                                {content.annotation}
                            </p>
                            <Datepicker label={content.field_name} id={content.field_id} type="timeOnly" />
                        </>
                    );
                case 'DATEPICKER':
                    return (
                        <>
                            <Label htmlFor={content.field_id}>{content.field_name}</Label>
                            <p className="text-xs font-regular w-full break-words text-gray-2 mb-1">
                                {content.annotation}
                            </p>
                            <Datepicker label={content.field_name} id={content.field_id} type="singleDate" />
                        </>
                    );
                default:
                    return <p>No controls available for this field.</p>;
            }
        });
    };

    return (
        <>
            <form id={props.formId} onSubmit={onSubmit}>
                <div className="w-full h-auto flex flex-row items-start relative">
                    <div className="w-4/6">
                        <div className="w-full py-6 px-8 bg-blue-light">
                            <ul className="list-disc list-outside">
                                <li className="text-gray">
                                    <p className="text-sm font-regular text-normal">
                                        Select the fields you want to add in your form.
                                    </p>
                                </li>
                                <li className="text-gray">
                                    <p className="text-sm font-regular text-normal">
                                        Create sections based on the groupings you want to do with the fields.
                                    </p>
                                </li>
                                <li className="text-gray">
                                    <p className="text-sm font-regular text-normal">
                                        Drag and drop to rearrange the fields or sections to achieve your desired form
                                        layout.
                                    </p>
                                </li>
                                <li className="text-gray">
                                    <p className="text-sm font-regular text-normal">
                                        Edit field labels, descriptions and its corresponding values on the editor.
                                    </p>
                                </li>
                                <li className="text-gray">
                                    <p className="text-sm font-regular text-normal">
                                        Label the fields properly and provide explanations to assist users.
                                    </p>
                                </li>
                                <li className="text-gray">
                                    <p className="text-sm font-bold text-normal">
                                        There should only be 1 field for memo subject, rich-text editor, and file
                                        upload.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full bg-white h-auto py-6 px-8 mt-8">
                            <h4 className="text-md font-bold">Form Builder</h4>
                            <p className="font-regular text-sm mt-2">
                                The Subject field is a required field that will be the first question of your memo
                                template
                            </p>

                            {/* Section D&D here. */}
                            {/* 
            
                            The DragDropContext creates an area which will serve as the drag and drop area of the form. It has 
                            its own state management that is completely separeted from the app.
                            The handler `onDragEnd` is a required prop from the libray. This handles the event after dragging 
                            the element across the DragDropContext.
                            */}

                            <DragDropContext onDragEnd={onSectionDragEnd}>
                                {/* 
                    
                                A Droppable component is another component from `react-beautiful-dnd`. This serves as the drop area
                                for any draggable item inside the DragDropContext.
                                This component takes a function as a child. The function shall render a div with the DroppableProvided
                                as its props. This will make sure that the droppable area connects to the DragDropContext and the Draggable
                                components.
                                
                                */}
                                <Droppable droppableId="builder_dnd">
                                    {/* 
                    
                                    Renders a function that returns a JSX Element with DroppableProvided props.
                                    */}
                                    {(provided: DroppableProvided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className="w-full h-auto flex flex-col"
                                        >
                                            {/* Iterates the state for the list of draggable section across the form */}
                                            {template_content.map((item) => (
                                                // Returns a template section component.
                                                <TemplateSection
                                                    sectionIndex={item.sectionIndex}
                                                    sectionId={item.sectionId}
                                                    key={item.field_id}
                                                    field_id={item.field_id}
                                                    field_name={item.field_name}
                                                    field_type="SECTION"
                                                    conditions={item.conditions}
                                                    validations={{ required: false }}
                                                    onUpdate={_updateSection}
                                                    onRemove={removeSection}
                                                />
                                            ))}

                                            {/* This renders a placeholder element that is visible during dragging. 
                                            For now, it doesn't render anything. */}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <button
                            className="w-full h-auto p-4 mt-4 bg-transparent flex items-center justify-center cursor-pointer border-dashed-custom-blue"
                            style={{ borderRadius: '5px' }}
                            tabIndex={0}
                            onClick={_addSection}
                            type="button"
                        >
                            <p className="text-sm font-bold text-blue">Add section</p>
                        </button>
                    </div>
                    <div className="w-1/4 bg-blue-light mt-54 ml-5 p-4 box-border fixed right-28 top-48">
                        <div className="w-full h-auto flex flex-row">
                            <h4 className="font-bold text-sm">Preview</h4>
                            {/* Add expand button here */}
                        </div>
                        <p className="text-sm font-regular">Below is how your form will look like in app:</p>

                        <div className="w-full mt-4" style={{ background: '#F4F8FC' }}>
                            <div className="w-full h-auto bg-white p-2 flex flex-row">
                                <div className="w-2 h-2 mr-2 rounded-full bg-red" />
                                <div className="w-2 h-2 mr-2 rounded-full bg-yellow-light" />
                                <div className="w-2 h-2 rounded-full bg-green" />
                            </div>
                            <div className="w-full mt-2 overflow-y-auto" style={{ maxHeight: '380px' }}>
                                {_.map(template_content, (content) => (
                                    <div className="w-full bg-white p-8 mb-4">
                                        <div className="w-full mb-4">
                                            <Title type="h4">{content.field_name}</Title>
                                        </div>
                                        {getFormItem(content.value)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default TemplatesBuilderForm;
