import { combineReducers } from 'redux';
import AuthReducer from './AuthSlice';
import MemoFeedReducer from './MemoFeedSlice';
import ApproverReducer from './ApproverSlice';
import MemoViewReducer from './MemoViewSlice';
import Notifications from './NotificationSlice';
import AppReducer from './AppSlice';
import FormBuilderSlice from './FormBuilderSlice';
import MemoFormV2Slice from './MemoFormV2Slice';
import MemoLogsSlice from './MemoLogsSlice';

const RootReducer = combineReducers({
    App: AppReducer,
    Auth: AuthReducer,
    MemoFeed: MemoFeedReducer,
    Approver: ApproverReducer,
    MemoView: MemoViewReducer,
    Notifications: Notifications,
    FormBuilder: FormBuilderSlice,
    MemoFormV2: MemoFormV2Slice,
    MemoLogs: MemoLogsSlice,
});

export type RootState = ReturnType<typeof RootReducer>;
export default RootReducer;
