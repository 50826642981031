import * as React from 'react';
import PropTypes from 'prop-types';
import { sanitizeHtmlString } from '../../utils/anti-xss/Sanitize';

interface ITextProps {
    type?: string;
    color?: string;
    children: React.ReactNode;
}

const Text: React.FunctionComponent<ITextProps> = (props) => {
    const { type, color } = props;

    const getTextColor = (color: string | undefined) => {
        switch (color) {
            case 'secondary':
                return 'text-gray-1';
            case 'tertiary':
                return 'text-gray-2';
            default:
                return 'text-normal';
        }
    };

    switch (type) {
        case 'bold':
            return <div className={`font-bold ${getTextColor(color)} w-auto text-sm`}>{props.children}</div>;
        case 'italic':
            return <div className={`font-regular ${getTextColor(color)} italic w-auto text-sm`}>{props.children}</div>;
        case 'bold-italic':
            return <div className={`font-bold ${getTextColor(color)} italic w-auto text-sm`}>{props.children}</div>;
        case 'innerHTML':
            return (
                <div
                    className={`font-regular ${getTextColor(color)} w-auto text-sm`}
                    dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(String(props.children)) }}
                ></div>
            );
        default:
            return <div className={`font-regular ${getTextColor(color)} w-auto text-sm`}>{props.children}</div>;
    }
};

Text.propTypes = {
    type: PropTypes.oneOf(['bold', 'italic', 'bold-italic', 'innerHTML']),
    color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    children: PropTypes.node,
};

export default Text;
