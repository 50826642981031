import * as React from 'react';
import PropTypes from 'prop-types';
import Text from '../typography/Text';
import './Tooltip.css';
import ic_info from '../../assets/img/ic_info.svg';

interface ITooltipProps {
    type: string;
    items?: any;
    children?: any;
    className?: string;
}

const Tooltip: React.FunctionComponent<ITooltipProps> = (props) => {
    const { items, type } = props;
    switch (type) {
        case 'info':
            return (
                <button className="tooltip font-regular mx-2 px-1" data-testid="tooltip-info">
                    <img src={ic_info} className="mx-1" style={{ width: '12px', height: '12px' }} />
                    <span className="tooltipleft w-80">
                        <div className="py-2" id="copyright">
                            <Text type="bold">
                                <h2>Remind</h2>
                            </Text>
                            <Text color="tertiary">Sends a reminder email to your memo&apos;s approvers</Text>
                        </div>
                        <div className="py-2" id="copyright">
                            <Text type="bold">
                                <h2>Withdraw</h2>
                            </Text>
                            <Text color="tertiary">
                                Takes back the memo from your approvers in order for you to edit
                            </Text>
                        </div>
                        <div className="py-2" id="copyright">
                            <Text type="bold">
                                <h2>Cancel</h2>
                            </Text>
                            <Text color="tertiary">
                                The memo&apos;s end-of-life. It cannot be edited or resubmitted again
                            </Text>
                        </div>
                    </span>
                </button>
            );
        case 'approvers':
            return (
                <button className="tooltip bg-blue-tooltip font-regular mx-2 px-1 rounded">
                    +{items.length - 1}
                    <span className="tooltiptext">
                        {items.map((item: any, index: number) => {
                            if (index !== 0) {
                                return <p key={index}>{item.displayName}</p>;
                            }
                        })}
                    </span>
                </button>
            );
        case 'component':
            return <div className={`tooltip font-regular rounded ${props.className}`}>{props.children}</div>;
        default:
            return (
                <button className="tooltip font-regular mx-2 px-1 rounded">
                    +{items.length - 1}
                    <span className="tooltiptext">
                        {items.map((item: any, index: number) => {
                            if (index !== 0) {
                                return <p key={index}>{item.displayName}</p>;
                            }
                        })}
                    </span>
                </button>
            );
    }
};

Tooltip.propTypes = {
    type: PropTypes.string.isRequired,
    items: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.string,
};

export default Tooltip;
