import * as React from 'react';
import PropTypes from 'prop-types';
import SideMenuItem from './SideMenuItem';

export interface SideMenuItemLayoutProps {
    label: string;
    data: string;
}

export interface ISideMenuItemLayoutProps {
    onItemClick?: (params: any) => void;
    children: JSX.Element | JSX.Element[];
}
const SideMenu = (props: ISideMenuItemLayoutProps) => {
    return (
        <div className="w-full">
            <ol className="w-full flex flex-col">
                {Array.isArray(props.children)
                    ? props.children.map((child, index) => React.cloneElement(child, { key: index }))
                    : React.cloneElement(props.children)}
            </ol>
        </div>
    );
};

export default SideMenu;
