import React from 'react';
import Container from '../../../layouts/container/Container';
import Subtitle from '../../../layouts/typography/Subtitle';
import Title from '../../../layouts/typography/Title';
import Button from '../../../layouts/buttons/Button';
import CategoryTable from './CategoryTable';
import { navigate } from '@reach/router';

interface ICategory {
    children?: React.ReactNode;
}

const Category: React.FunctionComponent<ICategory> = () => {
    const goToCreateCategoryForm = () => {
        navigate('/categories/create');
    };

    return (
        <Container>
            <div className="w-full">
                <Title>Category</Title>
                <div className="my-2">
                    <Subtitle>Create and manage categories for templates</Subtitle>
                </div>
            </div>
            <div className="w-full mt-8">
                <div className="w-full flex justify-end">
                    <Button color="primary" onClick={goToCreateCategoryForm}>
                        Create category
                    </Button>
                </div>
                <div className="py-4">
                    <CategoryTable />
                </div>
            </div>
        </Container>
    );
};

export default Category;
