import { navigate } from '@reach/router';
import React from 'react';
import Button from '../../../layouts/buttons/Button';

const ActiveMemosTableEmptyState = () => {
    const goToCreateMemoForm = () => {
        navigate('/memo/create');
    };
    return (
        <div className="w-1/5 flex flex-col mt-4">
            <Button color="primary" onClick={goToCreateMemoForm}>
                <p className="text-center text-base">Create memo</p>
            </Button>
        </div>
    );
};

export default ActiveMemosTableEmptyState;
