import { globalHistory, navigate, useLocation } from '@reach/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../layouts/buttons/Button';
import Container from '../../../../layouts/container/Container';
import Stepper from '../../../../layouts/stepper/Stepper';
import StepperItem from '../../../../layouts/stepper/StepperItem';
import Subtitle from '../../../../layouts/typography/Subtitle';
import Title from '../../../../layouts/typography/Title';
import { getCategories, getTemplateById, resetFormBuilder } from '../../../../redux/FormBuilderSlice';
import { RootState } from '../../../../redux/RootReducer';
import { AppDispatch } from '../../../../redux/Store';
import { IAdminProtectedRouteProps } from '../../auth/AdminProtectedRoute';
import '../Templates.css';
import TemplateCategoryForm from './TemplateCategoryForm';
import TemplatesBuilderForm from './TemplatesBuilderForm';
import TemplatesWorkflowForm from './TemplatesWorkflowForm';

export interface ITemplatesStepperFormProps {
    formId: string;
    formAction: string;
    formMode: string;
    onSubmit?: (e: Partial<React.MouseEvent<HTMLButtonElement>>) => void;
}

interface ITemplatesFormProps extends Omit<IAdminProtectedRouteProps, 'component'> {
    template_id?: string;
}

/**
 * Renders the Template Form component
 * @returns
 */
const TemplatesForm = (props: ITemplatesFormProps) => {
    // Create a dispatch object
    const dispatch: AppDispatch = useDispatch();
    const template = useSelector((state: RootState) => state.FormBuilder);
    const [formAction, setFormAction] = React.useState('published');
    const [formMode, setFormMode] = React.useState('CREATE');
    const curLocation = useLocation();

    // Local state array for the stepper items
    const [stepperItems, setStepperItems] = React.useState([
        {
            id: 'general-information',
            label: 'General Information',
            state: 'active',
            component: (
                <TemplateCategoryForm
                    key={1}
                    formId="general-information"
                    formAction={formAction}
                    formMode={formMode}
                />
            ),
        },
        {
            id: 'form-builder',
            label: 'Form Builder',
            state: 'default',
            component: (
                <TemplatesBuilderForm key={2} formId="form-builder" formAction={formAction} formMode={formMode} />
            ),
        },
        {
            id: 'workflow',
            label: 'Workflow',
            state: 'default',
            component: <TemplatesWorkflowForm key={3} formId="workflow" formAction={formAction} formMode={formMode} />,
        },
    ]);
    // Local state value for the current active stepper item
    const [activeStepperItemIndex, setActiveStepperItemIndex] = React.useState(0);

    const updateStepperView = (action: string) => {
        // Get the value of the event object from the next/prev buttons
        // Get the current active stepper item index
        const currentItemIndex = activeStepperItemIndex;

        // Copy the stepper items
        const items = Array.from(stepperItems);

        let upcomingItem = null;
        let upcomingItemIndex = currentItemIndex;

        // If the pressed button is `prev`,
        // go back by decreasing the index value to -1, and
        // update the upcomingItem object to the n[-1] of the stepper items.
        if (action === 'prev') {
            if (currentItemIndex > 0) {
                upcomingItemIndex = currentItemIndex - 1;
            }
            upcomingItem = items[upcomingItemIndex];
        }
        // Else, if the pressed button is `next`,
        // go forward by increaseing the index value to +1, and
        // update the upcomingItem object  to the n[+1] of the stepper items
        else if (action === 'next') {
            if (currentItemIndex !== items.length - 1) {
                upcomingItemIndex = currentItemIndex + 1;
            }
            upcomingItem = items[upcomingItemIndex];
        }

        // Once the upcomingItem is updated,
        // update the object to either `completed` or `default`
        // depending on the pressed button
        if (upcomingItem) {
            // If buttonValue === 'next' the it is completed
            // else it is on default.
            items[currentItemIndex].state = action === 'next' ? 'completed' : 'default';
            upcomingItem.state = 'active';
        }

        // Update local state.
        setStepperItems(items);
        setActiveStepperItemIndex(upcomingItemIndex);
    };

    /**
     *
     * @param e
     */
    const onStepperButtonClick = (
        e: Partial<React.MouseEvent<HTMLButtonElement>> | React.MouseEvent<HTMLButtonElement>,
    ) => {
        if (e && e.currentTarget) {
            updateStepperView(e.currentTarget.value);
        }
    };

    /**
     * On view load, assign the currently active stepper item index to state.
     */
    React.useEffect(() => {
        /**
         * Find the current active stepper item.
         */
        const currentActiveStepperIndex = stepperItems.findIndex((item) => item.state === 'active');
        /**
         * If there is an active item, update the local state.
         */
        if (currentActiveStepperIndex !== -1) {
            setActiveStepperItemIndex(currentActiveStepperIndex);
        }

        if (props.template_id) {
            setFormMode('EDIT');
            dispatch(getCategories({}));

            const params = new URLSearchParams(curLocation.search);
            const version_id = params.get('v');

            dispatch(getTemplateById({ id: props.template_id, version_id: version_id as string }));
        } else {
            dispatch(getCategories({}));
        }

        return () => {
            dispatch(resetFormBuilder());
        };
    }, []);

    React.useEffect(() => {
        return globalHistory.listen(({ action, location }) => {
            if (
                (action === 'PUSH' && !location.pathname.includes(`/templates/edit`) && activeStepperItemIndex === 2) ||
                action === 'POP'
            ) {
                if (!window.confirm('Are you sure you want to leave without saving?')) {
                    navigate(`${curLocation.pathname}`);
                } else {
                    dispatch(resetFormBuilder());
                }
            }
        });
    }, []);

    if (formMode === 'CREATE') {
        return (
            <Container>
                <div className="w-full">
                    <Title>Create template</Title>
                    <div className="my-2">
                        <Subtitle>Build your own memo template without any coding skills</Subtitle>
                    </div>
                </div>

                <div className="w-full py-2 pb-32">
                    <Stepper width="w-1/2">
                        {stepperItems.map((item, index) => (
                            <StepperItem key={index} state={item.state} label={item.label} />
                        ))}
                    </Stepper>
                    <div className="w-auto h-auto bg-gray-light absolute z-50 right-0">
                        {/* <pre className="text-xs">{JSON.stringify(template.template_content, null, 4)}</pre> */}
                    </div>

                    {/* Stepper Content */}
                    <div className="w-full h-auto mt-4 relative">
                        {React.Children.map(
                            stepperItems.filter((item) => item.state === 'active').map((item) => item.component),
                            (child) => {
                                return React.cloneElement(child, {
                                    onSubmit: onStepperButtonClick,
                                    formAction: formAction,
                                    formMode: formMode,
                                });
                            },
                        )}
                    </div>

                    {/* Stepper Buttons */}
                    <div className="w-4/6 flex flex-row justify-between mt-4">
                        {activeStepperItemIndex === 0 ? (
                            // <Button
                            //     onClick={onStepperButtonClick}
                            //     value="prev"
                            //     disabled={template._http_category_request_state === 'pending'}
                            // >
                            //     <u>Save and continue later.</u>
                            // </Button>
                            ''
                        ) : (
                            <Button
                                color="primary"
                                onClick={onStepperButtonClick}
                                value="prev"
                                disabled={template._http_category_request_state === 'pending'}
                            >
                                Previous
                            </Button>
                        )}
                        <div className="w-auto h-auto flex flex-row">
                            {activeStepperItemIndex === stepperItems.length - 1 ? (
                                <Button
                                    color="secondary"
                                    type="submit"
                                    value="unpublish"
                                    name="btn_unpublish"
                                    disabled={template._http_category_request_state === 'pending'}
                                    onClick={() => setFormAction('drafted')}
                                    form={stepperItems[activeStepperItemIndex].id}
                                >
                                    Save as draft
                                </Button>
                            ) : (
                                ''
                            )}
                            <Button
                                color="primary"
                                type="submit"
                                form={stepperItems[activeStepperItemIndex].id}
                                value="next"
                                name="btn_next"
                                onClick={() => setFormAction('published')}
                                disabled={template._http_category_request_state === 'pending'}
                            >
                                {activeStepperItemIndex === stepperItems.length - 1 ? 'Save and publish' : 'Next'}
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
        );
    } else if (formMode === 'EDIT') {
        switch (template._http_template_request_state) {
            case 'pending':
                return (
                    <Container>
                        <div className="w-full">
                            <Title>Loading...</Title>
                        </div>
                    </Container>
                );
            case 'rejected':
                return <p>There was an error while sending the request.</p>;
            case 'fulfilled':
                return (
                    <Container>
                        <div className="w-full">
                            <Title>{template.template_name}</Title>
                            <div className="my-2">
                                <Subtitle>{template.description}</Subtitle>
                            </div>
                        </div>
                        <div className="w-full py-2 pb-32">
                            <Stepper width="w-1/2">
                                {stepperItems.map((item, index) => (
                                    <StepperItem key={index} state={item.state} label={item.label} />
                                ))}
                            </Stepper>
                            <div className="w-auto h-auto bg-gray-light absolute z-50 right-0">
                                {/* <pre className="text-xs">{JSON.stringify(template.template_content, null, 4)}</pre> */}
                            </div>

                            {/* Stepper Content */}
                            <div className="w-full h-auto mt-4 relative">
                                {React.Children.map(
                                    stepperItems
                                        .filter((item) => item.state === 'active')
                                        .map((item) => item.component),
                                    (child) => {
                                        return React.cloneElement(child, {
                                            onSubmit: onStepperButtonClick,
                                            formAction: formAction,
                                            formMode: formMode,
                                        });
                                    },
                                )}
                            </div>

                            {/* Stepper Buttons */}
                            <div className="w-4/6 flex flex-row justify-between mt-4">
                                {activeStepperItemIndex === 0 ? (
                                    // <Button
                                    //     onClick={onStepperButtonClick}
                                    //     value="prev"
                                    //     disabled={template._http_category_request_state === 'pending'}
                                    // >
                                    //     <u>Save and continue later.</u>
                                    // </Button>
                                    ''
                                ) : (
                                    <Button
                                        color="primary"
                                        onClick={onStepperButtonClick}
                                        value="prev"
                                        disabled={template._http_category_request_state === 'pending'}
                                    >
                                        Previous
                                    </Button>
                                )}
                                <div className="w-auto h-auto flex flex-row">
                                    {activeStepperItemIndex === stepperItems.length - 1 ? (
                                        <Button
                                            color="secondary"
                                            type="submit"
                                            value="unpublish"
                                            name="btn_unpublish"
                                            disabled={template._http_category_request_state === 'pending'}
                                            onClick={() => setFormAction('drafted')}
                                            form={stepperItems[activeStepperItemIndex].id}
                                        >
                                            Save as draft
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                    <Button
                                        color="primary"
                                        type="submit"
                                        form={stepperItems[activeStepperItemIndex].id}
                                        value="next"
                                        name="btn_next"
                                        onClick={() => setFormAction('published')}
                                        disabled={template._http_category_request_state === 'pending'}
                                    >
                                        {activeStepperItemIndex === stepperItems.length - 1
                                            ? 'Save and publish'
                                            : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                );
            default:
                return (
                    <Container>
                        <div className="w-full">
                            <Title>bruh...</Title>
                        </div>
                    </Container>
                );
        }
    }
    return null;
};

export default TemplatesForm;
