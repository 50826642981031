import { navigate, useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import Container from '../../../layouts/container/Container';
import SideMenu from '../../../layouts/menus/side-menu/SideMenu';
import SideMenuItem from '../../../layouts/menus/side-menu/SideMenuItem';
import Subtitle from '../../../layouts/typography/Subtitle';
import Title from '../../../layouts/typography/Title';
import Contact from './Contact';
import Privacy from './Privacy';
import Terms from './Terms';
import React from 'react';

const SupportPublic = () => {
    const location = useLocation();
    const displayText = (path: string) => {
        if (path === '/support/terms') {
            return <Terms />;
        } else if (path === '/support/privacy') {
            return <Privacy />;
        } else if (path === '/support/contact') {
            return <Contact />;
        } else {
            navigate(links[0].path);
        }
    };

    const links = [
        {
            label: 'Contact us',
            path: '/support/contact',
        },
        {
            label: 'Terms & conditions',
            path: '/support/terms',
        },
        {
            label: 'Privacy policy',
            path: '/support/privacy',
        },
    ];

    const redirect = (data: any) => {
        navigate(data);
    };

    return (
        <Container>
            <div className="w-full">
                <Title>Help &amp; support</Title>
                <div className="my-2">
                    <Subtitle>We’re here to help. Browse our guides or get in touch with us</Subtitle>
                </div>
            </div>
            <div className="w-full my-2 pt-4 pb-2 h-full flex flex-col justify-between md:flex-row">
                <div className="w-1/4 mr-2 hidden md:flex">
                    <SideMenu>
                        {links.map((link) => (
                            <SideMenuItem
                                key={link.path}
                                label={link.label}
                                value={link.path}
                                active={location.pathname === link.path}
                                onClick={redirect}
                            />
                        ))}
                    </SideMenu>
                </div>
                <div className="w-full md:w-3/4 ml-2 bg-white ">{displayText(location.pathname)}</div>
            </div>
        </Container>
    );
};

SupportPublic.propTypes = {
    children: PropTypes.node,
};

export default SupportPublic;
