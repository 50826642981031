import React from 'react';
import { MemoLogEntry } from '../../models/MemoLog';
import { getDateString } from '../../utils/date/Date';
export interface IMemoLogEntryProps {
    latest?: boolean;
    log: MemoLogEntry;
    children?: JSX.Element;
}

const MemoLogListItem = (props: IMemoLogEntryProps) => {
    const { latest, log } = props;
    const { date_created, remarks, action, name } = log;

    const [isTruncated, setIsTruncated] = React.useState(true);

    const truncateRemarks = (remarks: string) => {
        if (isTruncated && remarks.length >= 180) {
            return remarks.slice(0, 180) + '...';
        }
        return remarks;
    };

    const getAction = (action: string) => {
        switch (action) {
            case 'approve_memo':
                return `Approved by ${name}`;
            case 'reject_memo':
                return `Rejected by ${name}`;
            case 'return_memo':
                return `Returned by ${name}`;
            case 'withdraw_memo':
                return `Cancelled by ${name}`;
            case 'edit_memo':
                return `Re-submitted by ${name}`;
            case 'self_return_memo':
                return `Withdrawn by ${name}`;
            case 'create_memo':
                return `Created by ${name}`;
            case 'edit_subapprover':
                return log.custom_message;
            default:
                return '';
        }
    };

    const onClick = () => {
        setIsTruncated(!isTruncated);
    };

    return (
        <li className={`w-full ${latest && 'latest'}`} role="listitem">
            <div className="w-11/12 bg-white inline-flex flex-col ml-4 my-2 p-4 rounded">
                <h5 className="text-xs text-gray-3">{getDateString(date_created)}</h5>
                <p className="mt-1" role="action">
                    {getAction(action)}
                </p>
                {remarks && (
                    <div className="w-full h-auto bg-gray-lighter p-4 mt-2 mb-1 rounded flex flex-col" role="remarks">
                        <h5 className="font-bold">Remarks:</h5>
                        <p className="italic mt-1 whitespace-pre-line break-words">{truncateRemarks(remarks)}</p>
                        {remarks.length >= 180 && (
                            <button className="text-xs text-blue mt-2 self-start" onClick={onClick} role="truncate">
                                See {isTruncated ? 'more' : 'less'}
                            </button>
                        )}
                    </div>
                )}
                {props.children}
            </div>
        </li>
    );
};

export default MemoLogListItem;
