import * as React from 'react';
import PropTypes from 'prop-types';
import TabItem from './TabItem';
import { useLocation } from '@reach/router';

interface ITabLayoutProps {
    onClick?: () => void;
    children: Array<any>;
}

const tabHeaderStyles: React.CSSProperties = {
    boxSizing: 'border-box',
    borderBottom: '1px solid #DDDDDD',
};

const TabLayout: React.FunctionComponent<ITabLayoutProps> = (props) => {
    const { children } = props;
    const location = useLocation();

    return (
        <div className="w-full h-auto">
            <ol className="w-full h-auto flex flex-row" style={tabHeaderStyles}>
                {children.map((child) => {
                    const { label, href } = child.props;
                    return <TabItem key={label} label={label} href={href} isActive={location.pathname === href} />;
                })}
            </ol>
            <div className="w-full">
                {children.map((child) => {
                    if (child.props.href !== location.pathname) return null;
                    return child.props.children;
                })}
            </div>
        </div>
    );
};

TabLayout.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.instanceOf(Array).isRequired,
};

export default TabLayout;
