import { Router } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import './App.css';
import AdminLogin from './components/admin/auth/AdminLogin';
import AdminLoginRoute from './components/admin/auth/AdminLoginRoute';
import AdminProtectedRoute from './components/admin/auth/AdminProtectedRoute';
import Category from './components/admin/category/Category';
import CategoryForm from './components/admin/category/CategoryForm';
import Templates from './components/admin/templates/Templates';
import TemplatesForm from './components/admin/templates/form/TemplatesForm';
import Users from './components/admin/users/Users';
import Workflow from './components/admin/workflow/Workflow';
import Error404 from './layouts/404/Error404';
import AdminLoginRedirect from './components/admin/auth/AdminLoginRedirect';

interface IAppProps {
    children?: React.ReactNode;
}

const Admin: React.FunctionComponent<IAppProps> = () => {
    try {
        return (
            <div className="w-full h-full">
                <Router style={{ width: '100%', height: 'auto' }}>
                    <AdminLoginRoute path="/" component={AdminLogin} />
                    <AdminLoginRoute path="login" component={AdminLogin} />
                    <AdminLoginRedirect title="Signing in" path="/redirect" />

                    {/* Categories */}
                    <AdminProtectedRoute
                        title="Categories | Memo App Admin Console"
                        path="categories"
                        component={Category}
                    />
                    <AdminProtectedRoute
                        title="Create a category | Memo App Admin Console"
                        path="categories/create"
                        component={CategoryForm}
                    />
                    <AdminProtectedRoute
                        title="Edit a category | Memo App Admin Console"
                        path="categories/edit/:id"
                        component={CategoryForm}
                    />

                    {/* Templates */}
                    <AdminProtectedRoute
                        title="Templates | Memo App Admin Console"
                        path="templates"
                        component={Templates}
                    />
                    <AdminProtectedRoute
                        title="Drafted templates | Memo App Admin Console"
                        path="templates/drafts"
                        component={Templates}
                    />
                    <AdminProtectedRoute
                        title="Unpublished templates | Memo App Admin Console"
                        path="templates/unpublished"
                        component={Templates}
                    />
                    <AdminProtectedRoute
                        title="Create a template | Memo App Admin Console"
                        path="templates/create"
                        component={TemplatesForm}
                    />
                    <AdminProtectedRoute
                        title="Edit a template | Memo App Admin Console"
                        path="templates/edit/:template_id"
                        component={TemplatesForm}
                    />

                    {/* Fallback and 404 */}
                    <AdminProtectedRoute default component={Error404} />
                    <AdminProtectedRoute title="Not Found | Memo App Admin Console" path="404" component={Error404} />
                </Router>
            </div>
        );
    } catch (err) {
        console.log(err);
        return <h1>Oops. There was an error loading this app.</h1>;
    }
};

Admin.propTypes = {
    children: PropTypes.node,
};

export default Admin;
