import * as React from 'react';
import PropTypes from 'prop-types';
import './Branch.css';

interface IBranchProps {
    children?: React.ReactNode;
}

const Branch: React.FunctionComponent<IBranchProps> = (props) => {
    return (
        <div className="branch w-full h-auto" role="branch">
            <div className="w-full flex flex-row items-start">
                <span className="twig" />
                <div className="w-full mb-2 inline-block">{props.children}</div>
            </div>
        </div>
    );
};

Branch.propTypes = {
    children: PropTypes.node,
};

export default Branch;
