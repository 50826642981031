import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import * as React from 'react';
import Text from '../typography/Text';
import Title from '../typography/Title';

interface IMemoFeedSummaryProps {
    type: string;
    count: number;
}

export const categoryCard: React.CSSProperties = {
    borderLeft: '9px solid #1352AB',
    boxShadow: '0px 3px 6px #00000029',
};

const dispType = (type: string) => {
    if (type === 'returned') {
        return {
            title: 'Returned',
            link: '',
            css:
                'bg-blue-mdlight text-blue-status p-4 flex flex-row justify-between items-end rounded-md border-2 border-blue-status',
            textClass1: 'text-sm text-blue-status',
            textClass2: 'text-xs text-blue-status underline',
            click: '/memos/active',
        };
    }
    if (type === 'rejected') {
        return {
            title: 'Rejected',
            link: '',
            css:
                'bg-red-light text-red-dark p-4 flex flex-row justify-between items-end rounded-md border-2 border-red-dark',
            textClass1: 'text-sm text-red-dark',
            textClass2: 'text-xs text-red-dark underline',
            click: '/memos/history',
        };
    }
};

const onItemClick = (event: React.MouseEvent<HTMLElement>) => {
    const id = event.currentTarget.id;
    navigate(id);
};

const MemoFeedSummary: React.FunctionComponent<IMemoFeedSummaryProps> = (props) => {
    const { count, type } = props;
    const disp = dispType(type);
    return (
        <div className="w-auto" data-testid="memo-feed-summary-returned">
            <div className="px-2 py-2">
                <div className={disp?.css}>
                    <Title type="h5">
                        <p className={disp?.textClass1}>
                            {disp?.title} memos ({count})
                        </p>
                    </Title>
                    <Text color="secondary">
                        <button
                            id={disp?.click}
                            className={disp?.textClass2}
                            onClick={onItemClick}
                            data-testid="feed-redirect"
                        >
                            View all
                        </button>
                    </Text>
                </div>
            </div>
        </div>
    );
};

MemoFeedSummary.propTypes = {
    type: PropTypes.oneOf(['returned', 'rejected']).isRequired,
    count: PropTypes.number.isRequired,
};

export default MemoFeedSummary;
