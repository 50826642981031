/**
 * NotificationSlice is for app's notification components.
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '../models/Notification';
import firebase from 'firebase/app';
import 'firebase/functions';
import { MemoHTTPResponse } from '../models/MemoHttpResponse';

export interface NotificationSliceState {
    hasUnread: boolean;
    unread: number;
    notifications: Notification[];
    http_request: {
        state: string;
        message: string;
        status_code?: firebase.functions.FunctionsErrorCode;
    };
}

export interface NotificationsPayload {
    email: string;
    limit?: number;
    last_doc_id?: string;
}
export interface NotificationsHTTPResponse extends MemoHTTPResponse {
    notifications: Notification[];
    status_code: firebase.functions.FunctionsErrorCode;
}

const initialState: NotificationSliceState = {
    hasUnread: false,
    unread: 0,
    notifications: [],
    http_request: {
        state: 'idle',
        message: '',
    },
};

export const loadNotifications = createAsyncThunk<
    NotificationsHTTPResponse,
    Partial<NotificationsPayload> | NotificationsPayload,
    {
        rejectValue: NotificationsHTTPResponse;
    }
>('notifications/load', async (payload, { rejectWithValue }) => {
    try {
        const getNotifications = firebase.app().functions('asia-east2').httpsCallable('get_notifications');

        let requestPayload = {
            email: payload.email,
            limit: payload.limit,
            last_doc_id: '',
        };

        if (payload.last_doc_id) {
            requestPayload = {
                ...requestPayload,
                last_doc_id: payload.last_doc_id,
            };
        }

        const response = await getNotifications(requestPayload);
        const notifications = response.data;
        const result: NotificationsHTTPResponse = {
            notifications: notifications,
            status_code: 'ok',
            message: 'Notification fetched',
        };
        return result;
    } catch (err) {
        const error = err as firebase.functions.HttpsError;
        const { code, message } = error;
        return rejectWithValue({
            status_code: code,
            message: message,
            notifications: [],
        });
    }
});

const NotificationSlice = createSlice({
    name: 'NotificationSlice',
    initialState,
    reducers: {
        setHasUnread(state, action: PayloadAction<boolean>) {
            state.hasUnread = action.payload;
        },
        setUnreadCount(state, action: PayloadAction<number>) {
            state.unread = action.payload;
        },
        clearNotifications(state) {
            state.notifications = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadNotifications.pending, (state) => {
            state.http_request.state = 'pending';
            state.http_request.message = 'Loading your latest notifications';
        });
        builder.addCase(loadNotifications.rejected, (state, action) => {
            state.http_request.state = 'rejected';
            if (action.payload) {
                state.http_request.status_code = action.payload.status_code;
            }
        });
        builder.addCase(loadNotifications.fulfilled, (state, action) => {
            state.http_request.state = 'fulfilled';
            if (action.payload) {
                state.http_request.status_code = action.payload.status_code;
                state.notifications = [
                    ...Array.from(new Set([...state.notifications, ...action.payload.notifications])),
                ];
            }
        });
    },
});

export const { setHasUnread, setUnreadCount, clearNotifications } = NotificationSlice.actions;

export default NotificationSlice.reducer;
